import React, { Component } from "react";
import Cookies from "js-cookie";

import Script from "react-load-script";
//import SideBars from './SideBars.js';
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from "../Constants/BaseURL";
// const refreshPage = (e) => {

//   setTimeout(() => window.location.reload(true), 2900);
//   e.preventDefault();
// };
export default class InviteFriend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      username: "",
      emaill: "",
      pno: "",
      pswd: "",
      cpswd: "",
      inviteFriendID: "",
      FriendName: "",
      name: "",
      mobile: "",
      email: "",
      flag: "",
      Friendmail: "",
      created_Date: "",
      emps: [],
      notify: [],
      ValidEmail: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      loading: false,
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      requiredforr: [],
      self: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      NameRequire: [],
      MailRequire: [],
      termsandcondition: [],
      howitworks: [],
      privacypolicy: [],
      blog: [],
      donateasmile: [],
      invitefriends: [],
      name: [],
      emails: [],
      mobilenumber: [],
      friends: [],
      langID: [],
      SameCredentialAlreadyExists: [],
      InvitedSuccessfully: [],
      formErrors: {},
      copyright_new: [],
      paropakari_new: [],
      entername: [],
      enteremail: [],
      cancel: [],
      entermn: [],
      movedown: true,
    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login",
    };
    this.props.history.push(page);
  };
  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  };
  submit = () => {
    let url = "https://apitest.paropakari.com/api/Invite/Get_Invites";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    //
    //
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          emps: data,
        });

        //
      });
  };

  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    //
    //
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });
        //
        //
      });
  };

  Footer = () => {
    //

    let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    //
    //
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });
        //
        //
      });
  };

  DefaultMenu() {
    //
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
    let RefreshID = 0;
    window.sessionStorage.setItem("reload", RefreshID);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    //

    //

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            privacypolicy: PrivacyPolicy,
            howitworks: Howitwork,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          //
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Invite Friends~Name~Email~Mobile Number~Friends~Same Credentials Already Exists~Invitation sent successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          //
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var InviteFriends = typeof items[3] !== "undefined" ? items[3] : null;
          var Name = typeof items[4] !== "undefined" ? items[4] : null;
          var Email = typeof items[5] !== "undefined" ? items[5] : null;
          var MobileNumber = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var SameCredentialAlreadyExists =
            typeof items[8] !== "undefined" ? items[8] : null;
          var InvitedSuccessfully =
            typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            invitefriends: InviteFriends,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            friends: Friends,
            SameCredentialAlreadyExists: SameCredentialAlreadyExists,
            InvitedSuccessfully: InvitedSuccessfully,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Enter&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var entername = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            entername: entername,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Enter a Valid Email Address~Name is Required~Email Id is Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var ValidEmail = typeof items[2] !== "undefined" ? items[2] : null;
          var NameRequire = typeof items[3] !== "undefined" ? items[3] : null;
          var MailRequire = typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            ValidEmail: ValidEmail,
            NameRequire: NameRequire,
            MailRequire: MailRequire,
          });

          //
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            privacypolicy: PrivacyPolicy,
            howitworks: Howitwork,

            langID: LangID,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Invite Friends~Name~Email~Mobile Number~Friends~Same Credentials Already Exists&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      //
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var InviteFriends = typeof items[3] !== "undefined" ? items[3] : null;
          var Name = typeof items[4] !== "undefined" ? items[4] : null;
          var Email = typeof items[5] !== "undefined" ? items[5] : null;
          var MobileNumber = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var SameCredentialAlreadyExists =
            typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            invitefriends: InviteFriends,
            name: Name,
            email: Email,
            mobilenumber: MobileNumber,
            friends: Friends,
            SameCredentialAlreadyExists: SameCredentialAlreadyExists,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        // "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Enter a Valid Email Address~Name is Required~Email Id is Required~Invitation sent successfully~Enter&lang=" + MID;
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Enter&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var entername = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            entername: entername,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "Invite your friend to paropakari";
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.submit);
    this.submit();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
  }

  handleFormValidation() {
    const { FriendName, Friendmail, mobile } = this.state;

    // let FriendName = document.getElementById('FriendName').value;

    let formErrors = {};
    let formIsValid = true;

    if (!FriendName || FriendName.trim() == "") {
      formIsValid = false;
      formErrors["nameErr"] = "Name is Required.";
    }

    // if (!Friendmail) {
    //
    //   formIsValid = false;
    //   formErrors["emailErr"] = "Email id is required";
    // }
    // if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Friendmail))) {
    //
    //   formIsValid = false;
    //   formErrors["emailErr1"] = "Enter a Valid Email Address";
    // }
    //  if(!mobile){
    //      formIsValid=false;
    //      formErrors["pnoErr"]="Mobile Number is required";
    //  }

    this.setState({ formErrors: formErrors });

    return formIsValid;
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleClick = () => {
    // this.handleSubmitevents();
    this.props.history.push("/Friends");
  };
  handleSubmitevents = async (event) => {
    event.preventDefault();

    if (this.handleFormValidation()) {
      const { Friendmail, password } = this.state;
      const { InvitedSuccessfully, SameCredentialAlreadyExists } = this.state;
      // const data = new FormData(event.target);
      const data = new FormData();
      data.append("name", this.state.FriendName);
      if (this.state.Friendmail.trim() !== "") {
        data.append("email", this.state.Friendmail);
      }
      if (this.state.mobile.trim() !== "") {
        data.append("mobile", this.state.mobile);
      }

      let user = JSON.parse(localStorage.getItem("user"));

      const accessToken = user;
      this.toggleLoader();
      const url = "https://apitest.paropakari.com/api/Invite/Add";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };
      // this.setState(this.initialState)
      const response = await fetch(url, options);
      const result = await response.json();

      console.log("response  %", response);
      console.log("result  %", result);
      if (response.ok) {
        // if (result.ok) {

        alert(
          InvitedSuccessfully.result
            ? InvitedSuccessfully.result
            : "Invitation sent successfully"
        );
        this.props.history.push("/Friends");

        // window.location.reload();
      } else if (result.status == "Already Exist") {
        alert(
          SameCredentialAlreadyExists.result
            ? SameCredentialAlreadyExists.result
            : "Same credentials already exists"
        );
        // window.location.reload();
      }
      // this.setState(this.initialState)
    }
  };

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }
  handleEmailChange = (evt) => {
    this.setState({ emaill: evt.target.value });
  };
  handlePasswordChange = (evt) => {
    this.setState({ mobile: evt.target.value });
  };
  render() {
    const currentYear = new Date().getFullYear();
    const { nameErr, emailErr, emailErr1, mobileErr, pswdErr, cpswdErr } =
      this.state.formErrors;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      others,
      requiredforr,
      familyfriends,
      self,
      submit,
      beneficiaryname,
      privacypolicy,
      invitefriends,
      name,
      emails,
      mobilenumber,
      friends,
      copyright_new,
      FriendName,
      NameRequire,
      MailRequire,
      paropakari_new,
      langID,
      ValidEmail,
      InvitedSuccessfully,
      SameCredentialAlreadyExists,
      entername,
      email,
      Friendmail,
      cancel,
    } = this.state;

    const { emps } = this.state;
    const { notify } = this.state;
    const { emaill, mobile } = this.state;

    // (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Friendmail)))

    const isEnabled = !(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Friendmail))) 
    || /^[6-9]\d{9}$/.test(mobile)
    && FriendName.length > 2;
    const isValidEmail = (email) => {
      return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };

    // Function to validate mobile format
    const isValidMobile = (mobile) => {
      return (
        typeof mobile === "string" && mobile.length === 10 && !isNaN(mobile)
      ); // Assuming mobile is a numeric string of length 10
    };
    //   isEnabled = !(!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Friendmail)))
    // || /^[6-9]\d{9}$/.test(mobile)
    // && FriendName.length > 2;

    //   const isEnabled = name !== '' && ((Friendmail !== '' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(Friendmail)) || (mobile !== '' && mobile.length === 10));
    let { foo, Menu } = this.state;
    var username = JSON.parse(localStorage.username);
    if (this.state.loading) {
      return (
        <center>
          <img
            className="loading-img2"
            src="https://apitest.paropakari.com/images/Paropakari.gif"
          />
        </center>
      );
    } else {
      return (
        <div
          className="VIpgJd-ZVi9od-ORHb-OEVmcd"
          style={{ position: "absolute" }}
        >
          <div
            style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
            className="notranslate"
          >
            {/* <meta http-equiv="refresh" content="10"/>   */}
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            {/*[if lt IE 9]>
              
              
          <![endif]*/}
            <link rel="shortcut icon" href="images/ico/favicon.ico" />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="144x144"
              href="images/ico/apple-touch-icon-144-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="114x114"
              href="images/ico/apple-touch-icon-114-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="72x72"
              href="images/ico/apple-touch-icon-72-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              href="images/ico/apple-touch-icon-57-precomposed.png"
            />
            <select
              className="form-control"
              style={{
                display: "inline",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
            &nbsp;&nbsp; &nbsp;&nbsp;
            {/* <div onClick={this.refreshPage} className="VIpgJd-ZVi9od-ORHb-OEVmcd notranslate" style={{ position: "absolute" }}>
            <select
              className="form-control notranslate"
              style={{
                display: "none",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="English">English</option>
            </select>
          </div> */}
            {/* &nbsp;&nbsp;&nbsp;&nbsp; */}
            <div>
              <div className="mag notranslate">
                <div class="pro-home-nav">
                  <input
                    type="checkbox"
                    id="pro-home-nav-check"
                    onClick={this.checkfun}
                  />
                  <div class="pro-home-nav-header">
                    <div class="pro-home-nav-title notranslate">
                      <a className="navbar-brand" href="#/HomePage">
                        <h1 className="navbar-new-text">
                          <img
                            src="./assets/images/plogo.png"
                            alt="logo"
                            style={{
                              marginBottom: "17px",
                              width: "26%",
                              fontSize: "300px",
                            }}
                          />
                          AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-home-nav-btn">
                    <label for="pro-home-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a
                              className="text-parop-menu"
                              href="#/InviteFriend"
                            >
                              {invite.result}
                            </a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Hindi" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a
                            className="new-design notranslate"
                            href="#/CauseFor"
                          >
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu notranslate">
                            <a
                              className="text-parop-menu"
                              href="#/InviteFriend"
                            >
                              {invite.result}
                            </a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Tamil" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p
                          class="pro-center-tamil"
                          style={{ marginRight: "10px" }}
                        >
                          <a className="new-tamil-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a
                              className="text-parop-menu"
                              href="#/InviteFriend"
                            >
                              {invite.result}
                            </a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Kannada" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a
                              className="text-parop-menu"
                              href="#/InviteFriend"
                            >
                              {invite.result}
                            </a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Spanish" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a
                              className="text-parop-menu"
                              href="#/InviteFriend"
                            >
                              {invite.result}
                            </a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Telugu" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a
                              className="text-parop-menu"
                              href="#/InviteFriend"
                            >
                              {invite.result}
                            </a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* <section id="about-company" className={this.state.movedown == true ? null : "Tiptop"} data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}> */}

              <section
                id="about-company"
                className={
                  this.state.movedown == true
                    ? "wow fadeInUp"
                    : "wow fadeInUp Tiptop"
                }
                data-wow-duration="400ms"
                data-wow-delay="400ms"
                style={{ backgroundColor: "#aee6db" }}
              >
                <div className="container">
                  <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                      <div className="main-body">
                        <div className="page-wrapper">
                          <div className="page-body">
                            <br />
                            <br />
                            <center>
                              {" "}
                              <h2 style={{ color: "black" }}>
                                <b>{invitefriends.result}</b>
                              </h2>
                            </center>
                            <br />
                            <div>
                              <form
                                autoComplete="off"
                                onSubmit={this.handleSubmitevents}
                                method="post"
                                role="form"
                                noValidate="novalidate"
                              >
                                <div className="row">
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                  <div class="form-group col-sm-4 col-lg-4">
                                    {(() => {
                                      if (
                                        langID == "Kannada" ||
                                        langID == "Tamil" ||
                                        langID == "English" ||
                                        langID == "Spanish"
                                      ) {
                                        return (
                                          <label
                                            className
                                            htmlFor="OldPassword"
                                            style={{ float: "left" }}
                                          >
                                            {name.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (
                                        langID == "Telugu" ||
                                        langID == "Hindi"
                                      ) {
                                        return (
                                          <label
                                            className
                                            htmlFor="OldPassword"
                                            style={{ float: "left" }}
                                          >
                                            {name.result}
                                          </label>
                                        );
                                      }
                                    })()}

                                    <input
                                      className="form-control"
                                      id="FriendName"
                                      onChange={this.handleChange}
                                      placeholder={entername.result}
                                      autoComplete="off"
                                      value={this.state.FriendName}
                                      name="FriendName"
                                      style={{ width: "100%" }}
                                      type="text"
                                    />
                                    <span
                                      style={{
                                        textAlign: "left",
                                        color: "red",
                                      }}
                                    >
                                      {" "}
                                      {` ${
                                        nameErr
                                          ? NameRequire.result
                                            ? NameRequire.result
                                            : nameErr
                                          : ""
                                      }`}
                                    </span>
                                  </div>
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                </div>
                                <div className="row">
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                  <div class="form-group col-sm-4 col-lg-4">
                                    {(() => {
                                      if (langID == "Tamil") {
                                        return (
                                          <label
                                            className
                                            htmlFor="NewPassword"
                                            style={{ float: "left" }}
                                          >
                                            {email.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Kannada") {
                                        return (
                                          <label
                                            className
                                            htmlFor="NewPassword"
                                            style={{ float: "left" }}
                                          >
                                            {email.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Spanish") {
                                        return (
                                          <label
                                            className
                                            htmlFor="NewPassword"
                                            style={{ float: "left" }}
                                          >
                                            {email.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Hindi") {
                                        return (
                                          <label
                                            className
                                            htmlFor="NewPassword"
                                            style={{ float: "left" }}
                                          >
                                            {email.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Telugu") {
                                        return (
                                          <label
                                            className
                                            htmlFor="NewPassword"
                                            style={{ float: "left" }}
                                          >
                                            {email.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "English") {
                                        return (
                                          <label
                                            className
                                            htmlFor="NewPassword"
                                            style={{ float: "left" }}
                                          >
                                            {email.result}
                                          </label>
                                        );
                                      }
                                    })()}

                                    <input
                                      className="form-control"
                                      onChange={this.handleChange}
                                      name="Friendmail"
                                      id="Friendmail"
                                      // pattern="^(([-\w\d]+)(\.[-\w\d]+)*@([-\w\d]+)(\.[-\w\d]+)*(\.([a-zA-Z]{2,5}|[\d]{1,3})){1,2})$"
                                      placeholder={entername.result}
                                      autoComplete="off"
                                      style={{ width: "100%" }}
                                      type="text"
                                    />

                                    <input
                                      type="hidden"
                                      value={this.state.Friendmail}
                                      name="email"
                                    ></input>
                                    <span class="md-line"></span>
                                  </div>
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                </div>
                                <div className="row">
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                  <div class="form-group col-sm-4 col-lg-4">
                                    {(() => {
                                      if (langID == "Tamil") {
                                        return (
                                          <label
                                            className
                                            htmlFor="ConfirmPassword"
                                            style={{ float: "left" }}
                                          >
                                            {mobilenumber.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Kannada") {
                                        return (
                                          <label
                                            className
                                            htmlFor="ConfirmPassword"
                                            style={{ float: "left" }}
                                          >
                                            {mobilenumber.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "English") {
                                        return (
                                          <label
                                            className
                                            htmlFor="ConfirmPassword"
                                            style={{ float: "left" }}
                                          >
                                            {mobilenumber.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Hindi") {
                                        return (
                                          <label
                                            className
                                            htmlFor="ConfirmPassword"
                                            style={{ float: "left" }}
                                          >
                                            {mobilenumber.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Telugu") {
                                        return (
                                          <label
                                            className
                                            htmlFor="ConfirmPassword"
                                            style={{ float: "left" }}
                                          >
                                            {mobilenumber.result}
                                          </label>
                                        );
                                      }
                                    })()}
                                    {(() => {
                                      if (langID == "Spanish") {
                                        return (
                                          <label
                                            className
                                            htmlFor="ConfirmPassword"
                                            style={{ float: "left" }}
                                          >
                                            {mobilenumber.result}
                                          </label>
                                        );
                                      }
                                    })()}

                                    <input
                                      className="form-control"
                                      id="mobile"
                                      onChange={this.handleChange}
                                      name="mobile"
                                      style={{ width: "100%" }}
                                      type="text"
                                      onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                          event.preventDefault();
                                        }
                                      }}
                                      value={this.state.mobile}
                                      placeholder={entername.result}
                                      maxLength={10}
                                      autoComplete="off"
                                    />
                                    <span class="md-line"></span>
                                    {mobileErr && (
                                      <div style={{ color: "red" }}>
                                        {mobileErr}
                                      </div>
                                    )}
                                  </div>
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                </div>
                                <div className="row">
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                  <div class="form-group col-sm-4 col-lg-4">
                                    <center>
                                      <button
                                        type="submit"
                                        disabled={!isEnabled}
                                        className="btn btn-secondary"
                                        style={{
                                          backgroundColor: "rgb(102, 71, 18)",
                                          color: "white",
                                        }}
                                      >
                                        {invitefriends.result}
                                      </button>
                                    </center>
                                  </div>
                                  <div class="form-group col-sm-4 col-lg-4"></div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </section>

              {/*/#team*/}
              <footer id="footer" style={{ color: "white" }}>
                <br />
                <div className="container">
                  <div className="copyright">
                    <div className="row col-lg-12">
                      <div className="col-lg-9 mb-6">
                        {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                        <p style={{ marginLeft: "45px" }}>
                          {copyright_new.result} &nbsp;{currentYear} ©{" "}
                          {paropakari_new.result}&nbsp; |&nbsp;
                          <a
                            href="#/Contactuslogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            {contactus.result}&nbsp;
                          </a>
                          |{" "}
                          <a
                            href="#/PrivacyPolicyLogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{privacypolicy.result}&nbsp;
                          </a>
                          |
                          <a
                            href="#/TermsandConditionLogin"
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            &nbsp;{termsandcondition.result}
                          </a>
                        </p>
                      </div>
                      <div className="col-lg-3 mb-3">
                        <div
                          className="social-icons"
                          style={{ color: "white", marginLeft: "72px" }}
                        >
                          <ul className="nav nav-pills">
                            <li>
                              <a
                                target="_blank"
                                href="https://www.facebook.com/beoneparopakari"
                              >
                                <i className="fa fa-facebook" />
                              </a>
                            </li>
                            {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                            <li>
                              <a
                                target="_blank"
                                href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                              >
                                <i className="fa fa-youtube-play" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.instagram.com/paropakari_beone/"
                              >
                                <i className="fa fa-instagram" />
                              </a>
                            </li>
                            <li>
                              <a
                                target="_blank"
                                href="https://www.linkedin.com/company/paropakari/"
                              >
                                <i className="fa fa-linkedin" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerss"></div>
              </footer>
              {/*/#footer*/}
            </div>
          </div>
        </div>
      );
    }
  }
}
