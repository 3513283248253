import React, { useState, useEffect } from "react";

import Modal from "react-modal";
import { BASE_URLs } from '../Constants/BaseURL';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "480px",
    width: "375px",
    borderRadius: "20px",
    backgroundColor: "rgb(174, 230, 219)",
    border: "none",
    position: "fixed",
  },
};

export default function Likepopup({ Id, Translated, refreshcomment }) {
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const [modal, setModal] = useState(false);

  const toggleModal=()=> {
    setIsOpen(!isOpen);
    setModal(false);
   
  }

  useEffect(() => {
    if (modal) {
      loadData();
    }
  }, [modal]);

  const loadData = async () => {
    try {
      //  alert("hi/");

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      //   const urll = new URL(window.location.href);
      //   const Id = urll.searchParams.get("upload_id");
      //
      //
      await fetch(
        "https://apitest.paropakari.com/api/GetProfile/Get_LikedUsers?id=" + Id,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((receivedData) => {
          // refreshcomment();

          setData(receivedData);
        });
      //
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const update = (e) => {
    e.preventDefault();
    setModal(!modal);
    //document.getElementById("modalRegisterForm").style.display = "block";
  };

  return (
    <div className="modallyy" id="modelltoggle" style={{ marginTop: "-19px" }}>
      <button className="toggle notranslate" onClick={(e) => update(e)}>
        {Translated ? Translated : "Like"}
      </button>

      {modal && (
        <div
          id="modalRegisterForm"
          tabindex="-1"
          role="dialog"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            outline: 0,
          }}
        >
          <div
            style={{
              position: "fixed",
              inset: "50% auto auto 50%",
              border: "none",
              background: "rgb(174, 230, 219)",
              overflow: "auto",
              borderRadius: "20px",
              outline: "none",
              padding: "20px",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              height: "480px",
              width: "365px",
            }}
          >
            <div className="modal-header">
              <h3
                className="modal-title"
                style={{ textAlign: "center", fontWeight: "bold" }}
              >
                Liked By
              </h3>
              <button
                type="button"
                className="close"
                onClick={() => toggleModal()}
              >
                ×
              </button>
            </div>

            <div className="App">
              <br />
              {data.map((paropakaris) => (
                <div>
                  <center>{paropakaris.profile_name}</center>
                  <br />
                </div>
              ))}
              <br></br>
              <hr></hr>
            </div>
          </div>
        </div>
      )}

      {/* <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customStyles}
      >

        <div>  <div className="modal-header">
          <h3 className="modal-title" style={{ textAlign: "center", fontWeight: "bold" }}>Liked By</h3>
          <button type="button" className="close" onClick={toggleModal}>×</button>
        </div>
          <br />
          {data.map((paropakaris) => (
            <div>

              <center>{paropakaris.profile_name}</center>
              <br />
            </div>))}
        </div>
     */}
      {/* <button onClick={toggleModal} style={{alignItems:"center",justifyContent:"center",margin: "5px 142px 20px" ,backgroundColor: 'rgb(102, 71, 18)',color: 'white',border:"none",borderRadius:"20px",height:"24px",width:"80px"}}>Close</button> */}
      {/* </Modal> */}
    </div>
  );
}
