import React, { Component } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
//import SideBars from './SideBars.js';
import Resizer from "react-image-file-resizer";
import { BASE_URLs } from "../Constants/BaseURL";
import Cookies from "js-cookie";
import LogoutLink from "./LogoutLink";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { ImageDisplay: "" };
    this.resetFile = this.resetFile.bind(this);
    this.ImagePreview = this.ImagePreview.bind(this);
    this.state = {
      fields: {},
      errors: {},
    };
    this.state = {
      // id : this.props.match.params.id,
      profile_name: "",
      profile_dateOfBirth: "",
      profile_mobileNumber: "",
      today1: new Date().toISOString().split("T")[0], // Today's date in yyyy-MM-dd format

      remove: "",
      profile_dateOfAnniversary: "",
      profile_email: "",
      profile_location: "",
      pro_UPIID: "",
      profile_location: "",
      prof_BankAccount: "",
      prof_IFSC: "",
      pro_UPIID: "",
      formErrors: {},
      NumberHolder: 1,
      emps: [],
      notify: [],
      sam: "",
      foo: [],
      Menu: [],
      regnumexist: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      Back: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      valid_mob: [],
      loading: false,
      privacypolicy: [],
      requiredforr: [],
      self: [],
      valid_location: [],
      valid_Emailid: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      cancel: [],
      causes: [],
      friends: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      provideemail: [],
      providelocation: [],
      providebankdetails: [],
      provideDOA: [],
      validifsc: [],
      validbankacc: [],
      wanttodelete: [],
      validupiid: [],
      termsandcondition: [],
      providename: [],
      providedob: [],
      providemobilenumber: [],
      provideimg: [],
      provideimg1: [],
      howitworks: [],
      updatesuccess: [],
      updateprofile: [],
      deleteprofilepic: [],
      selectprofilepic: [],
      blog: [],
      donateasmile: [],
      bankaccountt: [],
      name: [],
      mobilenumberr: [],
      emaill: [],
      dateofbirth: [],
      dateofaniversary: [],
      location: [],
      ifsccode: [],
      upiId: [],
      edit: [],
      bankaccdet: [],
      ifyouwanttocreate: [],
      close: [],
      langID: [],
      ok: [],
      benId: "",
      file: null,

      movedown: true,
      ProfilepicPop: false,
      copyright_new: [],
      paropakari_new: [],
      clickCount: 0,
      maxDate: this.getMaxDate(),
      ScreenWidth: window.innerWidth,
    };
    this.reader = new FileReader();

    this.initialState = this.state;
    //

    // this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitevents = this.handleSubmitevents.bind(this);

    this.submit = this.submit.bind(this);
    this.handleSubmittrans = this.handleSubmittrans.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }
  proPop = () => {
    this.setState({
      ProfilepicPop: !this.state.ProfilepicPop,
    });
    this.setState({ file: null });
    this.setState({ ImageDisplay: null });
  };

  selected() {}

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login.js",
    };
    this.props.history.push(page);
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  resetFile(event) {
    event.preventDefault();

    this.setState({ ImageDisplay: null });
  }
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    localStorage.clear();
    window.location.href = "#/Login";
  };
  GenerateRandomNumber = () => {
    var RandomNumber = Math.floor(Math.random() * 100) + 1;

    this.setState({
      NumberHolder: RandomNumber,
    });
  };

  ImagePreview = (event) => {
    var fileInput = false;
    var maxSize = "100";
if(event.target.files[0]!==undefined){

  

    const imageFile = event.target.files[0];
    this.setState({ file: imageFile });

    if (event.target.files[0]) {
      fileInput = true;
    }
    const fi = Math.round(imageFile.size / 1024);

    var reader = new FileReader();
    var url = reader.readAsDataURL(imageFile);

    reader.onloadend = function (e) {
      this.setState({
        ImageDisplay: [reader.result],
      });
    }.bind(this);
    if (fileInput) {
      Resizer.imageFileResizer(
        imageFile,
        30,
        30,
        "PNG",
        10,
        0,
        (uri) => {},
        "base64"
      );
    }

    var fsize = event.target.files[0].size / 15;

    if (!imageFile) {
      this.setState({ invalidImage: "Please select image." });
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({ invalidImage: "Please select valid image." });

      return false;
    } else if (fsize < maxSize) {
      this.setState({ invalidImage: "Please select Quality image." });

      return false;
    }
    this.reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        this.setState({ selectedFile: imageFile, invalidImage: null });
      };
      img.onerror = () => {
        this.setState({ invalidImage: "Invalid image content." });

        return false;
      };

      img.src = e.target.result;
    };
    this.reader.readAsDataURL(imageFile);

  }
  };

  emailundisableTxt() {
    document.getElementById("profile_email").readOnly = false;
  }
  DOBundisableTxt() {
    document.getElementById("profile_dateOfBirth").readOnly = false;
  }
  DOAundisableTxt() {
    document.getElementById("profile_dateOfAnniversary").readOnly = false;
  }
  0;
  locationundisableTxt() {
    document.getElementById("profile_location").readOnly = false;
  }
  BankAccundisableTxt() {
    document.getElementById("prof_BankAccount").readOnly = false;
  }
  NamedisableTxt() {
    document.getElementById("profile_name").readOnly = false;
  }
  MobnodisableTxt() {
    document.getElementById("profile_mobileNumber").readOnly = false;
  }
  IFSCundisableTxt() {
    document.getElementById("prof_IFSC").readOnly = false;
  }
  UPIundisableTxt() {
    document.getElementById("pro_UPIID").readOnly = false;
  }
  handleFocus = (e) => {
    if (document.getElementById("profile_dateOfBirth").readOnly === false) {
      e.target.type = "date"; // Change input type to date

      const dateValue = e.target.value || "";

      if (!dateValue) {
        var DOA_default = document.getElementById("DOA_default").value;
        const [day, month, year] = DOA_default.split("-").map(Number);
        const date = new Date(year, month - 1, day); // Note: months are zero-indexed

        if (!isNaN(date.getTime())) {
          // Format to yyyy-mm-dd
          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

          //   const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
          e.target.value = formattedDate;
          // Set the formatted date in the input
          // e.target.value = formattedDate;
        }
      } else {
        //
        const [year, month, day] = dateValue.split("-").map(Number);
        const date = new Date(year, month - 1, day); // Note: months are zero-indexed

        if (!isNaN(date.getTime())) {
          // Format to yyyy-MM-dd
          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
          e.target.value = formattedDate;
        }
      }
    }
    //e.target.value = this.formatDateInput(this.state.originalDate); // Format for date input
  };
  handleFocusDOA(e) {
    if (
      document.getElementById("profile_dateOfAnniversary").readOnly === false
    ) {
      e.target.type = "date"; // Change input type to date

      const dateValue = e.target.value || "";

      if (!dateValue) {
        var DOB_default = document.getElementById("DOB_default").value;
        //  const date = new Date(DOB_default);
        const [day, month, year] = DOB_default.split("-").map(Number);
        const date = new Date(year, month - 1, day); // Note: months are zero-indexed

        if (!isNaN(date.getTime())) {
          // Extract day, month, and year
          // const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
          //
          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

          // Set the formatted date in the input
          e.target.value = formattedDate;
        }
      } else {
        const [year, month, day] = dateValue.split("-").map(Number);
        const date = new Date(year, month - 1, day); // Note: months are zero-indexed

        if (!isNaN(date.getTime())) {
          // Format to yyyy-MM-dd
          const formattedDate = `${date.getFullYear()}-${String(
            date.getMonth() + 1
          ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
          e.target.value = formattedDate;
        }
      }
    }
    // e.target.value = this.formatDateInput(this.state.originalDate); // Format for date input
  }
  handleBlur = (e) => {
    if (document.getElementById("profile_dateOfBirth").readOnly === false) {
      //
      e.target.type = "text"; // Change input type back to text

      // Convert the date from yyyy-MM-dd format to "Jul 09 2006" format
      const dateValue = e.target.value;

      //

      if (dateValue === "") {
        const previousdate = document.getElementById("remainsame").value;
        e.target.value = previousdate;
        return;
      }
      if (!dateValue) return;
      const [year, month, day] = dateValue.split("-").map(Number);
      const date = new Date(year, month - 1, day); // Note: months are zero-indexed
      if (!isNaN(date.getTime())) {
        // Format to dd-mm-yyyy
        // const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
        //
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${date.getFullYear()}`;
        e.target.value = formattedDate;
        // Set the formatted date in the input field
        //  e.target.value = formattedDate;
      }
    }
  };
  handleBlurDOA(e) {
    //vijaaayasri
    if (
      document.getElementById("profile_dateOfAnniversary").readOnly === false
    ) {
      e.target.type = "text"; // Change input type back to text

      // Convert the date from yyyy-MM-dd format to "Jul 09 2006" format
      const dateValue = e.target.value;
      if (dateValue == "") {
        const previousdate = document.getElementById("remainsameanni").value;
        e.target.value = previousdate;
        return;
      }
      if (!dateValue) return;

      const [year, month, day] = dateValue.split("-").map(Number);
      const date = new Date(year, month - 1, day); // Note: months are zero-indexed
      if (!isNaN(date.getTime())) {
        // Format to dd-mm-yyyy
        const formattedDate = `${String(date.getDate()).padStart(
          2,
          "0"
        )}-${String(date.getMonth() + 1).padStart(
          2,
          "0"
        )}-${date.getFullYear()}`;

        // Set the formatted date in the input field
        e.target.value = formattedDate;
      }
    }
  }
  noneditableall() {
    var form = document.getElementById("EditForm");
    var elements = form.elements;

    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      if ((element.id === "profile_dateOfBirth".readOnly) === false) {
        var originalDateValue = document.getElementById("remainsame").value;
        if (originalDateValue === "") {
          document.getElementById("profile_dateOfBirth").value = "";
          document.getElementById("EditForm").reset();
        } else {
          document.getElementById("EditForm").reset();

          const date = new Date(originalDateValue);
          const options = { year: "numeric", month: "short", day: "2-digit" };
          var formattedDate = date.toLocaleDateString("en-US", options);

          //  document.getElementById("EditForm").reset();
          document.getElementById("profile_dateOfBirth").value =
            originalDateValue;

          document.getElementById("profile_dateOfBirth").readOnly = true;
        }
      }
    }
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];

      if ((element.id === "profile_dateOfAnniversary".readOnly) === false) {
        var originalDateValue1 =
          document.getElementById("remainsameanni").value;

        if (originalDateValue1 === "") {
          document.getElementById("profile_dateOfAnniversary").value = "";
          document.getElementById("EditForm").reset();
        } else {
          document.getElementById("EditForm").reset();
          const date = new Date(originalDateValue1);
          const options = { year: "numeric", month: "short", day: "2-digit" };
          var formattedDate1 = date.toLocaleDateString("en-US", options);

          document.getElementById("profile_dateOfAnniversary").value =
            originalDateValue1;

          document.getElementById("profile_dateOfAnniversary").readOnly = true;
          document.getElementById("profile_dateOfBirth").readOnly = true;
        }
      }
    }
    if (
      document.getElementById("profile_email").readOnly === false ||
      document.getElementById("profile_dateOfAnniversary").readOnly === false ||
      document.getElementById("profile_location").readOnly === false ||
      document.getElementById("prof_BankAccount").readOnly === false ||
      document.getElementById("profile_name").readOnly === false ||
      document.getElementById("profile_mobileNumber").readOnly === false ||
      document.getElementById("prof_IFSC").readOnly === false ||
      document.getElementById("pro_UPIID").readOnly === false
    ) {
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i];
        if (
          element.id !== "profile_dateOfBirth" ||
          element.id !== "profile_dateOfAnniversary"
        ) {
          document.getElementById("EditForm").reset();
        }
        if (originalDateValue1 === "") {
          document.getElementById("profile_dateOfAnniversary").value = "";
          document.getElementById("EditForm").reset();
        } else {
          document.getElementById("profile_dateOfAnniversary").value =
            originalDateValue1;
        }

        if (originalDateValue === "") {
          document.getElementById("profile_dateOfBirth").value = "";
          document.getElementById("EditForm").reset();
        } else {
          document.getElementById("profile_dateOfBirth").value =
            originalDateValue;
        }

        document.getElementById("profile_email").readOnly = true;
        document.getElementById("profile_dateOfBirth").readOnly = true;

        document.getElementById("profile_dateOfAnniversary").readOnly = true;

        document.getElementById("profile_location").readOnly = true;

        document.getElementById("prof_BankAccount").readOnly = true;

        document.getElementById("profile_name").readOnly = true;

        document.getElementById("profile_mobileNumber").readOnly = true;

        document.getElementById("prof_IFSC").readOnly = true;

        document.getElementById("pro_UPIID").readOnly = true;
      }
    }
  }

  //document.getElementById("EditForm").reset();

  //

  // clickedmodal()
  // {
  //   modal.style.display = "none";
  // }

  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  };
  handleImageevents = async (event) => {
    event.preventDefault();

    let location = document.getElementById("profile_location").value;
    let Profile_IFSC = document.getElementById("prof_IFSC").value;
    let BankAccount = document.getElementById("prof_BankAccount").value;

    var UPI = document.getElementById("pro_UPIID").value;

    // var UPI = document.getElementById("upi").value;

    if (UPI != "") {
      //alert("Bank acc:"+prof_BankAccount)
      const reeUPI = new RegExp("[a-zA-Z0-9.-_]@[a-zA-Z]");
      const isOkkUPI = reeUPI.test(UPI);

      if (!isOkkUPI) {
        return alert("Enter a valid UPI ID");
      }
    }

    if (location === "" || location === null) {
      this.setState({
        ProfilepicPop: !this.state.ProfilepicPop,
      });
      return alert(
        this.state.providelocation.result
          ? this.state.providelocation.result
          : "Add your location"
      );
    } else if (Profile_IFSC === "" || BankAccount === "") {
      this.setState({
        ProfilepicPop: !this.state.ProfilepicPop,
      });
      return alert(
        this.state.providebankdetails.result
          ? this.state.providebankdetails.result
          : "Provide bank details"
      );
    }

    var prof_IFSC = document.getElementById("prof_IFSC").value;
    var prof_BankAccount = document.getElementById("prof_BankAccount").value;
    var pro_UPIID = document.getElementById("pro_UPIID").value;
    var profile_email = document.getElementById("profile_email").value;
    var profile_location = document.getElementById("profile_location").value;
    var profile_name = document.getElementById("profile_name").value;
    var profile_mobileNumber = document.getElementById(
      "profile_mobileNumber"
    ).value;
    var profile_dateOfBirth = document.getElementById(
      "profile_dateOfBirth"
    ).value;

    var prof_benID = document.getElementById("prof_benID").value;
    var profile_dateOfAnniversary = document.getElementById(
      "profile_dateOfAnniversary"
    ).value;
    console.log("this.state.ImageDisplay", this.state.ImageDisplay);
    console.log("this.state.file", this.state.file);

    var profile_imagePath = this.state.file;
    if (this.state.file !== null) {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      if (this.state.ImageDisplay !== undefined) {
        const data = new FormData();
        data.append("profile_mobileNumber", profile_mobileNumber);
        data.append("profile_name", profile_name);
        data.append("profile_location", profile_location);
        data.append("pro_UPIID", pro_UPIID);
        data.append("profile_email", profile_email);
        data.append("prof_BankAccount", prof_BankAccount);
        data.append("prof_IFSC", prof_IFSC);
        data.append("prof_benID", prof_benID);

        const formatDate = (dateStr) => {
          const [day, month, year] = dateStr.split("-");
          return `${year}-${month}-${day}`;
        };
        data.append(
          "profile_dateOfBirth",
          profile_dateOfBirth ? formatDate(profile_dateOfBirth) : "2005-04-30"
        );

        data.append(
          "profile_dateOfAnniversary",
          profile_dateOfAnniversary
            ? formatDate(profile_dateOfAnniversary)
            : "2005,04,30"
        );
        data.append("profile_imagePath", profile_imagePath);

        // Log FormData keys and values for debugging
        for (let [key, value] of data.entries()) {
        }

        var url = "https://apitest.paropakari.com/api/UploadFile/Post";

        var options = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            // 'Content-Type': 'application/json', // Don't set this header when sending FormData
            // 'Accept': 'application/json'
          },
          body: data,
        };

        try {
          const response = await fetch(url, options);

          if (response.ok) {
            var profile_name = document.getElementById("profile_name").value;
            alert(profile_name + " " + this.state.updatesuccess.result);
            this.submit();

            this.setState({
              ProfilepicPop: !this.state.ProfilepicPop,
            });
            // this.setState({
            //   ImageDisplay: null,
            // });
          } else {
            const errorResponse = await response.text();
            console.error(
              "Failed to upload:",
              response.statusText,
              errorResponse
            );
            alert(
              `Failed to upload: ${response.statusText} - ${errorResponse}`
            );
          }
        } catch (error) {
          // console.error("error", error);
          // alert(`Error: ${error.message}`);
        }
      }
    }
  };

  decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  handleSubmitevents = async (event) => {
    event.preventDefault();

    var prof_IFSC = document.getElementById("prof_IFSC").value;

    var prof_BankAccount = document.getElementById("prof_BankAccount").value;

    var pro_UPIID = document.getElementById("pro_UPIID").value;

    var profile_email = document.getElementById("profile_email").value;

    var profile_location = document.getElementById("profile_location").value;

    var lengthloc = document.getElementById("profile_location").value.length;

    var validemail = document.getElementById("profile_email").value;
    const emailTest = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/);
    var valimail = emailTest.test(validemail);

    var profile_name = document.getElementById("profile_name").value;

    var profile_mobileNumber = document.getElementById(
      "profile_mobileNumber"
    ).value;

    var profile_dateOfBirth = document.getElementById(
      "profile_dateOfBirth"
    ).value;

    // var prof_benID = document.getElementById("prof_benID").value;

    //

    // var prof_benID = "NULL";

    var prof_benID = document.getElementById("prof_benID").value;

    var profile_dateOfAnniversary = document.getElementById(
      "profile_dateOfAnniversary"
    ).value;

    // var profile_imagePath = document.getElementById("profile_imagePath").value;
    //

    if (profile_name == "") {
      alert(
        this.state.providename.result
          ? this.state.providename.result
          : "Provide name"
      );
      // alert(this.state.provideemail.result);
    }
    // if (profile_dateOfBirth == "") {
    //   alert(
    //     this.state.providedob.result
    //       ? this.state.providedob.result
    //       : "Provide date Of Birth"
    //   );
    // }

    // if (profile_imagePath == '') {
    //   alert(this.state.providimg.result ? this.state.provideimg.result : 'Provide Image');
    //   // alert(this.state.provideemail.result);
    // }

    if (profile_mobileNumber == "") {
      alert(
        this.state.providemobilenumber.result
          ? this.state.providemobilenumber.result
          : "Provide mobile number"
      );
      // alert(this.state.provideemail.result);
    }
    // if(profile_mobileNumber !== "")
    // {
    // //  alert("called");
    //           const mobileNumberPattern = /^[0-9]{10}$/;
    //           if (mobileNumberPattern.test(profile_mobileNumber)) {
    //             alert(
    //               this.state.valid_mob.result
    //                 ? this.state.valid_mob.result
    //                 : "Enter valid Mobile Number"
    //             )
    //           }
    //           }
    if (profile_email == "") {
      alert(
        this.state.provideemail.result
          ? this.state.provideemail.result
          : "Provide email address"
      );
      // alert(this.state.provideemail.result);
    } else if (profile_location == "") {
      alert(
        this.state.providelocation.result
          ? this.state.providelocation.result
          : "Add your location"
      );
      // alert(this.state.providelocation.result);
      // alert("Location");
    } else if (prof_IFSC == "" || prof_BankAccount == "") {
      alert(
        this.state.providebankdetails.result
          ? this.state.providebankdetails.result
          : "Provide bank details"
      );
      // alert(this.state.providebankdetails.result);
      // alert("Bank Details");
    }
    // else if (profile_dateOfAnniversary == "") {
    //   alert(
    //     this.state.provideDOA.result
    //       ? this.state.provideDOA.result
    //       : "Provide date Of Anniversary"
    //   );
    // }

    //  const { ifsc,Bank_acc } = this.state;
    else if (prof_IFSC != "" && prof_BankAccount != "") {
      // const re = new RegExp('^[A-Za-z]{4}0[A-Z0-9a-z]{6}$');
      // const isOk = re.test(prof_IFSC);
      //
      //

      // if (!isOk) {

      //   return alert(this.state.validifsc.result ? this.state.validifsc.result : 'Enter valid IFSC code');

      // }

      const ree = new RegExp("^[0-9]{9,18}$");
      const isOk = ree.test(prof_BankAccount);

      if (!isOk) {
        return alert(
          this.state.validbankacc.result
            ? this.state.validbankacc.result
            : "Enter Valid Bank Account"
        );
      } else {
        if (prof_IFSC != "") {
          //alert("Bank acc:"+prof_BankAccount)
          // const ree = new RegExp('^[0-9]{9,18}$');
          // const isOkk = ree.test(prof_BankAccount);
          //
          //
          // if (!isOkk) {

          //   return alert(this.state.validbankacc.result ? this.state.validbankacc.result : 'Enter Valid Bank Account');

          // }
          const re = new RegExp("^[A-Za-z]{4}0[A-Z0-9a-z]{6}$");
          const isOkk = re.test(prof_IFSC);

          if (!isOkk) {
            return alert(
              this.state.validifsc.result
                ? this.state.validifsc.result
                : "Enter valid IFSC code"
            );
          }
          // if (pro_UPIID) {
          //   alert("pro_UPIID:" + pro_UPIID)
          //   const reeUPI = new RegExp('[a-zA-Z0-9.\-_]@[a-zA-Z]');
          //   const isOkkUPI = reeUPI.test(pro_UPIID);
          //
          //
          //   if (!isOkkUPI) {

          //     return alert(this.state.validupiid.result ? this.state.validupiid.result : 'Enter valid UPI');

          //   }
          // }

          if (pro_UPIID.length !== 0) {
            // alert("pro_UPIID:" + pro_UPIID);

            const reeUPI = new RegExp("[a-zA-Z0-9.-_]@[a-zA-Z]");

            const isOkkUPI = reeUPI.test(pro_UPIID);

            if (!isOkkUPI) {
              return alert(
                this.state.validupiid.result
                  ? this.state.validupiid.result
                  : "Enter valid UPI"
              );
            }
          } else if (pro_UPIID.length === 0) {
            var pro_UPIID = "";
          }
          var default_mobileNumber = document.getElementById(
            "default_mobileNumber"
          ).value;
          if (isOk && isOkk) {
            //
            //
            let user = JSON.parse(localStorage.getItem("user"));
            const accessToken = user;

            const data = new FormData(event.target);
            var default_mobileNumber = document.getElementById(
              "default_mobileNumber"
            ).value;

            var profile_mobileNumber = document.getElementById(
              "profile_mobileNumber"
            ).value;

            if (default_mobileNumber !== profile_mobileNumber) {
              if (
                profile_mobileNumber.length > 10 ||
                profile_mobileNumber.length < 10
              ) {
                //alert("called123");
                alert(
                  this.state.valid_mob.result
                    ? this.state.valid_mob.result
                    : "Enter valid Mobile Numbervv"
                );
              }
              if (
                profile_mobileNumber.length !== "" &&
                profile_mobileNumber.length === 10
              ) {
                // this.toggleLoader();
                let url =
                  "https://apitest.paropakari.com/api/UploadFile/Profile_OTP?phonenumber=" +
                  profile_mobileNumber;

                // let url="https://apitest.paropakari.com/api/Authenticate/register";
                let data = this.state;
                fetch(url, {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-type": "application/json",
                    Accept: "application/json",
                  },
                  body: "body",
                }).then((result) => {
                  result.json().then((resp) => {
                    if (resp.message == "Registered number already exist") {
                      alert(
                        this.state.regnumexist.result
                          ? this.state.regnumexist.result
                          : "Registered number already exist"
                      );
                    } else {
                      let myCipher = this.cipher("mySecretSalt");

                      localStorage.setItem("Profile_OTP", myCipher(resp.otp));
                      // localStorage.setItem(
                      //   "Profile_OTP",
                      //   JSON.stringify(resp.otp)
                      // );
                      localStorage.setItem(
                        "profile_mobileNumber",
                        JSON.stringify(profile_mobileNumber)
                      );
                      window.location.href = "#/ProfilePageOTP";
                    }
                    // try {
                    // const response = await fetch(url, options);

                    //if (resp.Ok) {
                    //

                    //alert(this.state.updatesuccess.result);
                    //window.location.reload();

                    //}
                    //else{
                    //window.location.reload();

                    //}
                    //}
                    // catch (error) {
                    //     console.error("error",error);
                    //}
                  });
                });
              }
            } else {
              if (lengthloc < 3) {
                alert(
                  this.state.valid_location.result
                    ? this.state.valid_location.result
                    : "Please enter Valid Location"
                );
              } else if (valimail == false) {
                alert(
                  this.state.valid_Emailid.result
                    ? this.state.valid_Emailid.result
                    : "please enter valid Email Id"
                );
                //alert("please enter valid Email Id");
              } else {
                data.append("profile_mobileNumber", profile_mobileNumber);
                data.append("profile_name", profile_name);
                data.append("profile_location", profile_location);
                data.append("pro_UPIID", pro_UPIID);
                data.append("profile_email", profile_email);
                data.append("prof_BankAccount", prof_BankAccount);
                data.append("prof_IFSC", prof_IFSC);
                data.append("prof_benID", prof_benID);

                // data.append("profile_dateOfBirth", null);
                var con = document.getElementById("profile_dateOfBirth").value;

                const formatDate = (dateStr) => {
                  const [day, month, year] = dateStr.split("-");
                  return `${year}-${month}-${day}`;
                };

                var valueofDOB = "2005,04,30"; // Format the default date according to API expectations
                var valueofDOA = "2005,04,30";
                if (con === "") {
                  data.append("profile_dateOfBirth", valueofDOB); // Append default date to form data
                } else {
                  data.append("profile_dateOfBirth", formatDate(con)); // Append selected date to form data
                }

                var DateofA = document.getElementById(
                  "profile_dateOfAnniversary"
                ).value;
                var valueofDOB = "2005,04,30"; // Format the default date according to API expectations

                if (DateofA === "") {
                  data.append("profile_dateOfAnniversary", valueofDOA); // Append default date to form data
                } else {
                  data.append("profile_dateOfAnniversary", formatDate(DateofA)); // Append selected date to form data
                }

                for (let [key, value] of data.entries()) {
                }
                //data.append('profile_imagePath', profile_imagePath)
                //  this.toggleLoader();
                var url =
                  "https://apitest.paropakari.com/api/UploadFile/SubmitPost";

                var options = {
                  method: "POST",
                  headers: {
                    Authorization: "Bearer " + accessToken,
                    //  'Content-Type': 'application/json',
                    // 'Accept': 'application/json'
                  },
                  body: data,
                };

                try {
                  const response = await fetch(url, options);

                  if (response.ok) {
                    var profile_name =
                      document.getElementById("profile_name").value;
                    alert(profile_name + " " + this.state.updatesuccess.result);
                    window.location.reload();
                  } else {
                    // window.location.reload();
                  }
                } catch (error) {
                  console.error("error", error);
                }
              }
            }
          }
        }
      }
    }
  };

  handleDeletePic = async (event) => {
    event.preventDefault();
    let sam = document.getElementById("profile_imagePath").value;

    // if(sam == 'https://apitest.paropakari.com/Images/user.png')
    // {

    // }
    var x = window.confirm(this.state.wanttodelete.result);
    if (x) {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      // this.toggleLoader();
      // var profile_dateOfBirth = document.getElementById("profile_dateOfBirth").value;
      // var profile_dateOfAnniversary = document.getElementById("profile_dateOfAnniversary").value;
      const data = new FormData(event.target);
      const formatDate = (dateStr) => {
        const [day, month, year] = dateStr.split("-");
        return `${year}-${month}-${day}`;
      };

      var con = document.getElementById("profile_dateOfBirth").value;
      data.delete("profile_dateOfBirth");
      var valueofDOB = "2024-04-05"; // Format the default date according to API expectations
      var valueofDOA = "2024-04-05";
      if (con === "") {
        data.append("profile_dateOfBirth", valueofDOB); // Append default date to form data
      } else {
        data.append("profile_dateOfBirth", formatDate(con)); // Append selected date to form data
      }
      //

      var DateofA = document.getElementById("profile_dateOfAnniversary").value;
      // var valueofDOB = '2024-04-05'; // Format the default date according to API expectations
      data.delete("profile_dateOfAnniversary");
      if (DateofA === "") {
        data.append("profile_dateOfAnniversary", valueofDOA); // Append default date to form data
      } else {
        data.append("profile_dateOfAnniversary", formatDate(DateofA)); // Append selected date to form data
      }

      for (let [key, value] of data.entries()) {
      }
      var url = "https://apitest.paropakari.com/api/UploadFile/Post";

      var options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + accessToken,
          // 'Content-Type': 'application/json',
          // 'Accept': 'application/json'
        },
        body: data,
      };

      try {
        const response = await fetch(url, options);

        if (response.ok) {
          var profile_name = document.getElementById("profile_name").value;
          alert(profile_name + " " + this.state.updatesuccess.result);
          this.submit();

          this.setState({
            ProfilepicPop: !this.state.ProfilepicPop,
          });
        }
      } catch (error) {
        console.error("error", error);
      }
    } else {
      return false;
    }
  };

  onImageChange = (event) => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          profile_imagePath: e.target.result,
        });
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  };

  fileChangedHandler(event) {
    var fileInput = false;

    if (event.target.files[0]) {
      fileInput = true;
    }
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleSubmittrans = async (event) => {
    //event.preventDefault();

    var phone = document.getElementById("profile_mobileNumber").value;
    var address = document.getElementById("profile_location").value;
    var email = document.getElementById("email").value;
    var benId = document.getElementById("prof_benID").value;

    var benename = document.getElementById("profile_name").value;

    //
    var bankAccount = document.getElementById("prof_BankAccount").value;
    var ifsc = document.getElementById("prof_IFSC").value;

    let user = JSON.parse(localStorage.getItem("user"));
    var data = {
      phone: phone,
      benename: benename,
      address: address,
      email: email,
      bankAccount: bankAccount,
      ifsc: ifsc,
      benId: benId,
    };

    const accessToken = user;
    var url =
      "https://apitest.paropakari.com/api/UploadFile/Authorize?benename=" +
      benename +
      "&phone=" +
      phone +
      "&email=" +
      email +
      "&address1=" +
      address +
      "&bankAccount=" +
      bankAccount +
      "&ifsc=" +
      ifsc +
      "&benId=" +
      benId;

    //  var url = "https://apitest.paropakari.com/api/Create/Authorize?benename=tyryrty&phone=89879879879898798&email=vg@ynyu.bhn&address1=5657ghggj&bankAccount=56756756756756&ifsc=SBTgtrh5664"  ;

    var options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      localStorage.setItem("benefId", JSON.stringify(result.benefId));
      // localStorage.setItem('username', JSON.stringify(result.name));
      // localStorage.setItem('userid', JSON.stringify(result.userid));
      ///localStorage.setItem('create', JSON.stringify(result.id));

      if (response.ok) {
        // var page= {
        //   pathname: '#/HomePage',
        //   state: {
        //     data:{
        //      password: this.state.password,
        //      username: this.state.username,
        //     }
        //   }
        // }
        //window.alert("login succesful");
        //this.props.history.push(page)
        // alert("Login Successful...");
        //  window.location.href = "#/HomePage";
      } else {
        alert("Username and Password is incorrect!. Try Again");

        // window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // handleSubmitbeni = (event) => {
  //  // event.preventDefault();
  //  // let benname = JSON.parse(localStorage.getItem('benname'));
  //   const {profile_name,profile_email,profile_mobileNumber,profile_location,prof_BankAccount,prof_IFSC,pro_UPIID } = this.state;
  // // const data = new FormData(event.target);
  // const benid="vg111"
  // var phone = document.getElementById("profile_mobileNumber").value
  // var benename = document.getElementById("profile_name").value
  // var address= document.getElementById("profile_location").value
  // var email= document.getElementById("profile_email").value
  // var bankAccount= document.getElementById("prof_BankAccount").value
  // var ifsc= document.getElementById("prof_IFSC").value

  // var data={
  //   phone:phone,
  //   benename:benename,
  //   address:address,
  //   email:email,
  //   bankAccount:bankAccount,
  //   ifsc:ifsc
  // }
  //
  //  let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //  fetch('https://apitest.paropakari.com/api/Create/Authorize?benename='+ benename + '&phone=' + phone + '&email=' + email + '&address1=' + address +
  //  '&bankAccount=' + bankAccount +
  //  '&ifsc=' + ifsc + '&beneId=' + benid , {
  //    method: 'POST',
  //    headers:{
  //      Accept: 'application/json',
  //        Authorization: "Bearer " +accessToken
  //    },
  //    body: data,
  //  });
  //  alert("Added your Comment Successfully!");
  // }

  submit = () => {
    let url = "https://apitest.paropakari.com/api/GetProfile/get";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          emps: data,
        });

        localStorage.setItem("create", JSON.stringify(data));
      });
  };

  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        localStorage.setItem("noticount", JSON.stringify(data[0].noticount));
        //
      });
  };

  Footer = () => {
    let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  DefaultMenu() {
    //
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Edit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;

          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var FamilyandFriends =
            typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Bank Account~Submit~Cancel~Name~Date of Birth~Mobile Number~Date of Anniversary~IFSC Code~Email~Location~UPI ID&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Submit = typeof items[1] !== "undefined" ? items[1] : null;
          var Cancel = typeof items[2] !== "undefined" ? items[2] : null;
          var Name = typeof items[3] !== "undefined" ? items[3] : null;
          var DateOfBirth = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var DateofAnniversary =
            typeof items[6] !== "undefined" ? items[6] : null;
          var IFSCCode = typeof items[7] !== "undefined" ? items[7] : null;
          var Email = typeof items[8] !== "undefined" ? items[8] : null;
          var Location = typeof items[9] !== "undefined" ? items[9] : null;
          var UPIID = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            bankaccountt: BankAccount,
            submit: Submit,
            cancel: Cancel,
            name: Name,
            dateofbirth: DateOfBirth,
            mobilenumberr: MobileNumber,
            dateofaniversary: DateofAnniversary,
            ifsccode: IFSCCode,
            emaill: Email,
            location: Location,
            upiId: UPIID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Provide Email Address~Add your location~Provide Bank Details~Enter Valid IFSC Code~Enter Valid Bank Account~Want to Delete Your Profile Picture? Are You Sure?~Enter valid UPI~Provide Mobile Number~Provide Name~Provide Date of Birth~Provide Date of Anniversary&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ProvideEmail = typeof items[0] !== "undefined" ? items[0] : null;
          var ProvideLocation =
            typeof items[1] !== "undefined" ? items[1] : null;
          var ProvideBankDetails =
            typeof items[2] !== "undefined" ? items[2] : null;
          var ValifIFSCCode = typeof items[3] !== "undefined" ? items[3] : null;
          var ValidBankacc = typeof items[4] !== "undefined" ? items[4] : null;
          var WanttoDelete = typeof items[5] !== "undefined" ? items[5] : null;
          var ValidUPIID = typeof items[6] !== "undefined" ? items[6] : null;

          var ProvideMobileNumber =
            typeof items[7] !== "undefined" ? items[7] : null;
          var ProvideName = typeof items[8] !== "undefined" ? items[8] : null;
          var ProvideDOB = typeof items[9] !== "undefined" ? items[9] : null;
          var provideDOA = typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            provideemail: ProvideEmail,
            providename: ProvideName,
            providedob: ProvideDOB,
            providemobilenumber: ProvideMobileNumber,
            providelocation: ProvideLocation,
            providebankdetails: ProvideBankDetails,
            validifsc: ValifIFSCCode,
            validbankacc: ValidBankacc,
            wanttodelete: WanttoDelete,
            validupiid: ValidUPIID,
            provideDOA: provideDOA,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Profile Updated Successfully~Profile Update~Delete Profile Picture~Select Profile Picture~Friends~Back~Ok&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var UpdateSuccess = typeof items[1] !== "undefined" ? items[1] : null;
          var UpdateProfile = typeof items[2] !== "undefined" ? items[2] : null;
          var DeleteProfilePic =
            typeof items[3] !== "undefined" ? items[3] : null;
          var SelectProfilePic =
            typeof items[4] !== "undefined" ? items[4] : null;
          var Friends = typeof items[5] !== "undefined" ? items[5] : null;
          var Back = typeof items[6] !== "undefined" ? items[6] : null;
          var ok = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            privacypolicy: PrivacyPolicy,
            updatesuccess: UpdateSuccess,
            updateprofile: UpdateProfile,
            deleteprofilepic: DeleteProfilePic,
            friends: Friends,
            selectprofilepic: SelectProfilePic,
            Back: Back,
            ok: ok,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Please enter Valid Location~Please Enter valid Email Id~Enter a Valid Mobile Number~Registered number already exist&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var valid_location =
            typeof items[2] !== "undefined" ? items[2] : null;
          var valid_Emailid = typeof items[3] !== "undefined" ? items[3] : null;
          var valid_mob = typeof items[4] !== "undefined" ? items[4] : null;
          var regnumexist = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            valid_location: valid_location,
            valid_Emailid: valid_Emailid,
            valid_mob: valid_mob,
            regnumexist: regnumexist,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Bank Account Details~If you want to create a monetary cause for yourself, a bank account is mandatory, as it will be fetched automatically from your profile.~Close&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var bankaccdet = typeof items[2] !== "undefined" ? items[2] : null;
          var ifyouwanttocreate =
            typeof items[3] !== "undefined" ? items[3] : null;
          var close = typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            bankaccdet: bankaccdet,
            ifyouwanttocreate: ifyouwanttocreate,
            close: close,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Edit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;

          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var FamilyandFriends =
            typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=~Bank Account~Submit~Cancel~Name~Date of Birth~Mobile Number~Date of Anniversary~IFSC Code~Email~Location~UPI ID&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Submit = typeof items[1] !== "undefined" ? items[1] : null;
          var Cancel = typeof items[2] !== "undefined" ? items[2] : null;
          var Name = typeof items[3] !== "undefined" ? items[3] : null;
          var DateOfBirth = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var DateofAnniversary =
            typeof items[6] !== "undefined" ? items[6] : null;
          var IFSCCode = typeof items[7] !== "undefined" ? items[7] : null;
          var Email = typeof items[8] !== "undefined" ? items[8] : null;
          var Location = typeof items[9] !== "undefined" ? items[9] : null;
          var UPIID = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            bankaccountt: BankAccount,
            submit: Submit,
            cancel: Cancel,
            name: Name,
            dateofbirth: DateOfBirth,
            mobilenumberr: MobileNumber,
            dateofaniversary: DateofAnniversary,
            ifsccode: IFSCCode,
            emaill: Email,
            location: Location,
            upiId: UPIID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Profile Updated Successfully~Profile Update~Delete Profile Picture~Select Profile Picture~Friends~Back~Ok&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var UpdateSuccess = typeof items[1] !== "undefined" ? items[1] : null;
          var UpdateProfile = typeof items[2] !== "undefined" ? items[2] : null;
          var DeleteProfilePic =
            typeof items[3] !== "undefined" ? items[3] : null;
          var SelectProfilePic =
            typeof items[4] !== "undefined" ? items[4] : null;
          var Friends = typeof items[5] !== "undefined" ? items[5] : null;
          var Back = typeof items[6] !== "undefined" ? items[6] : null;
          var ok = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            privacypolicy: PrivacyPolicy,
            updatesuccess: UpdateSuccess,
            updateprofile: UpdateProfile,
            deleteprofilepic: DeleteProfilePic,
            friends: Friends,
            Back: Back,
            selectprofilepic: SelectProfilePic,
            ok: ok,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Provide Email Address~Add your location~Provide Bank Details~Enter Valid IFSC Code~Enter Valid Bank Account~Want to Delete Your Profile Picture? Are You Sure?~Enter valid UPI~Provide Mobile Number~Provide Name~Provide Date of Birth~Provide Date of Anniversary&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ProvideEmail = typeof items[0] !== "undefined" ? items[0] : null;
          var ProvideLocation =
            typeof items[1] !== "undefined" ? items[1] : null;
          var ProvideBankDetails =
            typeof items[2] !== "undefined" ? items[2] : null;
          var ValifIFSCCode = typeof items[3] !== "undefined" ? items[3] : null;
          var ValidBankacc = typeof items[4] !== "undefined" ? items[4] : null;
          var WanttoDelete = typeof items[5] !== "undefined" ? items[5] : null;
          var ValidUPIID = typeof items[6] !== "undefined" ? items[6] : null;
          var ProvideMobileNumber =
            typeof items[7] !== "undefined" ? items[7] : null;
          var ProvideName = typeof items[8] !== "undefined" ? items[8] : null;

          var ProvideDOB = typeof items[9] !== "undefined" ? items[9] : null;
          var provideDOA = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            provideemail: ProvideEmail,

            providelocation: ProvideLocation,
            providebankdetails: ProvideBankDetails,
            providename: ProvideName,
            providedob: ProvideDOB,
            providemobilenumber: ProvideMobileNumber,
            validifsc: ValifIFSCCode,
            validbankacc: ValidBankacc,
            wanttodelete: WanttoDelete,
            validupiid: ValidUPIID,
            provideDOA: provideDOA,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel~Please enter Valid Location~Please Enter valid Email Id~Enter a Valid Mobile Number~Registered number already exist&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;
          var valid_location =
            typeof items[1] !== "undefined" ? items[1] : null;
          var valid_Emailid = typeof items[2] !== "undefined" ? items[2] : null;
          var valid_mob = typeof items[3] !== "undefined" ? items[3] : null;
          var regnumexist = typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
            valid_location: valid_location,
            valid_Emailid: valid_location,
            valid_mob: valid_mob,
            regnumexist: regnumexist,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Bank Account Details~If you want to create a monetary cause for yourself, a bank account is mandatory, as it will be fetched automatically from your profile.~Close&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var bankaccdet = typeof items[2] !== "undefined" ? items[2] : null;
          var ifyouwanttocreate =
            typeof items[3] !== "undefined" ? items[3] : null;
          var close = typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            bankaccdet: bankaccdet,
            ifyouwanttocreate: ifyouwanttocreate,
            close: close,
          });
        });
    }
  }

  componentDidMount() {
    document.body.style.overflow = "auto";
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
    window.removeEventListener("load", this.submit);
    this.submit();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
  }
  getMaxDate() {
    const today1 = new Date();
    const maxDate = new Date(
      today1.getFullYear() - 18,
      today1.getMonth(),
      today1.getDate()
    );
    return maxDate.toISOString().split("T")[0]; // Date in yyyy-MM-dd format
  }

  render() {
    const { usernameErr, pnoErr, emailErr, LocErr, dobErr, doaErr } =
      this.state.formErrors;
    const { notify } = this.state;
    let { foo, Menu } = this.state;
    const {
      handleResponse,
      imageUrl,
      invalidImage,
      copyright_new,
      paropakari_new,
    } = this.state;
    var noticount = JSON.parse(localStorage.getItem("noticount"));
    const currentYear = new Date().getFullYear();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() + 1;
    var mm2 = today.getMonth() + 2; //January is 0 so need to add 1 to make it 1!

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    today = yyyy + "-" + mm + "-" + dd;
    var nextmonth = yyyy + "-" + mm2 + "-" + dd;
    var nextYear = yyyy2 + "-" + mm + "-" + dd;

    const {
      emps,
      profile_name,
      profile_email,
      profile_mobileNumber,
      profile_location,
      profile_dateOfAnniversary,
      profile_dateOfBirth,
      remove,
      prof_BankAccount,
      prof_IFSC,
      pro_UPIID,
      benId,
    } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      Back,
      logout,
      today1,
      maxDate,
      sam,
      others,
      requiredforr,
      familyfriends,
      provideemail,
      providelocation,
      providebankdetails,
      provideDOA,
      validifsc,
      validbankacc,
      wanttodelete,
      validupiid,
      self,
      submit,
      beneficiaryname,
      privacypolicy,
      updateprofile,
      deleteprofilepic,
      selectprofilepic,
      providename,
      providedob,
      providemobilenumber,
      friends,
      bankaccountt,
      name,
      mobilenumberr,
      emaill,
      dateofbirth,
      dateofaniversary,
      location,
      ifsccode,
      upiId,
      edit,
      cancel,
      close,
      updatesuccess,
      bankaccdet,
      ifyouwanttocreate,
      langID,
      ok,
    } = this.state;

    var username = JSON.parse(localStorage.username);
    if (this.state.loading) {
      return (
        <center>
          <img
            className="loading-img2"
            src="https://apitest.paropakari.com/images/Paropakari.gif"
          />
        </center>
      );
    } else {
      return (
        <div
          className="VIpgJd-ZVi9od-ORHb-OEVmcd"
          style={{ position: "absolute" }}
        >
          <div
            style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
            className="notranslate"
          >
            {/* <meta http-equiv="refresh" content="10"/>   */}
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            {/*[if lt IE 9]>
              
              
          <![endif]*/}
            <link rel="shortcut icon" href="images/ico/favicon.ico" />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="144x144"
              href="images/ico/apple-touch-icon-144-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="114x114"
              href="images/ico/apple-touch-icon-114-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="72x72"
              href="images/ico/apple-touch-icon-72-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              href="images/ico/apple-touch-icon-57-precomposed.png"
            />
            <select
              className="form-control"
              style={{
                display: "inline",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <div>
              <div className="mag notranslate">
                <div class="pro-home-nav">
                  <input
                    type="checkbox"
                    id="pro-home-nav-check"
                    onClick={this.checkfun}
                  />
                  <div class="pro-home-nav-header">
                    <div class="pro-home-nav-title notranslate">
                      <a className="navbar-brand" href="#/HomePage">
                        <h1 className="navbar-new-text">
                          <img
                            src="./assets/images/plogo.png"
                            alt="logo"
                            style={{
                              marginBottom: "17px",
                              width: "26%",
                              fontSize: "300px",
                            }}
                          />
                          AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-home-nav-btn">
                    <label for="pro-home-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Hindi" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a
                            className="new-design notranslate"
                            href="#/CauseFor"
                          >
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu notranslate">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Tamil" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p
                          class="pro-center-tamil"
                          style={{ marginRight: "10px" }}
                        >
                          <a className="new-tamil-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Kannada" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Spanish" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : langID == "Telugu" ? (
                    <>
                      <div class="pro-home-nav-links pro-side pro-top">
                        <p class="pro-center">
                          <a className="new-telugu-design" href="#/CauseFor">
                            {beaparopakari.result}
                          </a>
                        </p>
                        <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                          <li className="parop-menu">
                            <a href="#/Myactivity">{myjourney.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/CauseFor">{createacause.result}</a>
                          </li>
                          <li className="parop-menu">
                            <a href="#/InviteFriend">{invite.result}</a>
                          </li>
                          <li className="parop-menu parop-notify">
                            <a href="#/Notification">
                              <div
                                class="fa fa-bell"
                                style={{ fontSize: "24px" }}
                              >
                                {notify.map((notifyy) => (
                                  <span class="-count">
                                    {notifyy.noticount}
                                  </span>
                                ))}
                              </div>
                            </a>
                          </li>
                          <li className="parop-menu">
                            <a>
                              <b>{username}</b>{" "}
                              <i className="fa fa-angle-down"></i>
                            </a>
                            <ul class="dropdown">
                              <li className="hower-text">
                                <a className="text-parop-menu" href="#/Profile">
                                  <b>{profile.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/ChangePassword">
                                  <b>{changepassword.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Group">
                                  <b>{group.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <a href="#/Friends">
                                  <b>{friends.result}</b>
                                </a>
                              </li>
                              <li className="hower-text">
                                <LogoutLink logoutResult={logout.result} />
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/*/#page-breadcrumb*/}
              <section
                id="about-company"
                className={
                  this.state.movedown == true
                    ? "wow fadeInUp"
                    : "wow fadeInUp Tiptop"
                }
                data-wow-duration="400ms"
                data-wow-delay="400ms"
                scroll="no"
                style={{
                  backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
                  overflow: "hidden",
                }}
              >
                <div
                  className="container"
                  style={{
                    backgroundColor: "#aee6db",
                    width: "100%",
                    marginLeft: "0%",
                    // maxWidth: "1460px",
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  {/* Page Content */}
                  {/* <a href="#/HomePage" style={{ color: "#664712", fontSize: "20px" }}>
                <i class="fa fa-backward" aria-hidden="true"></i></a> */}
                  <hr />
                  <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
                  />
                  <center>
                    <h2>{profile.result}</h2>
                  </center>
                  <hr />
                  <div
                    ng-app="myApp"
                    ng-controller="sortCtrl"
                    ng-init="GetProfileData();"
                    role="form"
                    className="ng-scope"
                  >
                    <form
                      autoComplete="off"
                      onSubmit={this.handleSubmitevents}
                      className="ng-pristine ng-valid ng-valid-min ng-valid-max"
                    >
                      {emps.map((emp) => (
                        <div className="row ng-scope" ng-repeat="Post in posts">
                          <div className="col-lg-3">
                            {(() => {
                              if (langID == "Tamil") {
                                return (
                                  <a
                                    href="#/HomePage"
                                    className="btn btn-secondary "
                                    style={{
                                      textDecoration: "none",
                                      marginLeft: "34%",
                                      fontSize: "14px",
                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      width: "30%",
                                    }}
                                  >
                                    {Back.result}
                                  </a>
                                );
                              } else {
                                return (
                                  <a
                                    href="#/HomePage"
                                    className="btn btn-secondary "
                                    style={{
                                      textDecoration: "none",
                                      marginLeft: "37%",
                                      fontSize: "14px",
                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      width: "26%",
                                    }}
                                  >
                                    {Back.result}
                                  </a>
                                );
                              }
                            })()}
                            <br />
                            <br />
                            <a>
                              {/* {this.state.ImageDisplay  && (
                                <div
                                  style={{
                                    textAlign: "center",
                                    marginLeft: "254px",
                                  }}
                                >
                                  <button
                                    className="remove"
                                    id="closebut"
                                    onClick={this.resetFile}
                                  >
                                    <CancelIcon className="icon-x"></CancelIcon>
                                  </button>
                                </div>
                              )} */}
                              {/* <img alt="profile" src={emp.profile_imagePath} style={{marginLeft:"10px",borderRadius:"50%",width:"100%",height:'100%'}}></img> */}
                              {/*                                         
                                         <img className="card-img1" 
                                       id="target" 
                                       style={{width:"100%",height:"40%",borderRadius:'50%'}} 
                                       src={emp.profile_imagePath}
                                       alt={this.state.file}  />
                                       <img id="groupimage" className="card-img2" 
                                       style={{width:"23%",height:"45%",borderRadius:'50%',marginLeft:"-340px",position:"fixed",marginTop:"-5px"}} 
                                       src={this.state.ImageDisplay}  /> */}
                              <div className="center-block">
                                {/* <img class="circle-img"
                                style={{ borderRadius: '200px' }}
                                id="target"
                                src={emp.profile_imagePath}
                                name
                                alt={this.state.file} /> */}
                                {/* <img
                                class="circle-img"
                                style={{ borderRadius: "200px" }}
                                id="target"
                                src={emp.profile_imagePath}
                                alt={this.state.file}
                              /> */}
                                {/* {(() => {
                                  if (this.state.ImageDisplay == null) {
                                    return ( */}
                                <img
                                  class="circle-img"
                                  style={{
                                    borderRadius: "200px",
                                    width: "312px",
                                    height: "312px",
                                  }}
                                  id="target"
                                  src={emp.profile_imagePath}
                                  alt={this.state.file}
                                />
                                {/* );
                                  } else {
                                    return (
                                      <img
                                        style={{
                                          borderRadius: "200px",
                                          width: "312px",
                                          height: "312px",
                                          position: "initial",
                                        }}
                                        class="preview-circle-image"
                                        src={this.state.ImageDisplay}
                                      />
                                    );
                                  }
                                })()} */}
                              </div>

                              <p className="card-text" />
                            </a>

                            {/* <input type="file" id="profile_imagePath" accept="image/*" name="profile_imagePath"
                            onChange={this.ImagePreview} style={{ display: 'none' }} /> */}
                            {/* <label for="profile_imagePath" style={{fontFamily:'cuyabra'}}>
                                       {edit.result}&nbsp;<i style={{fontsize:'24px'}} class="fa"> &#xf067;</i></label> */}
                            {/* <img id="groupimage" style={{width:'17%',heifht:'30%',marginLeft:'-1%'}} src={this.state.ImageDisplay} alt /> */}
                            <p className="card-text" />
                            <br />
                            {/* Vijayaasri */}
                            {/* {emps.map(emp =>
                            <input type="hidden"
                              defaultValue="Picture" name="profile_imagePath"
                              onBlur={(data) => { this.setState({ profile_imagePath: data.target.value }) }}></input>)} */}
                          </div>
                          <br />
                          <center>
                            {" "}
                            <button
                              onClick={() => this.proPop()}
                              type="button"
                              class="btn btn-primary"
                              id="editing"
                              data-toggle="modal"
                              data-target="#basicModal"
                              style={{
                                backgroundColor: "rgb(102, 71, 18)",
                                marginLeft: "3px",
                                marginTop: "-10px",
                                width: "84px",
                              }}
                            >
                              {edit.result}
                            </button>{" "}
                          </center>
                          <br />

                          <div className="col-lg-9">
                            <form id="EditForm" style={{ marginLeft: "4%" }}>
                              <div className="row">
                                <div className="col-lg-4">
                                  <label style={{ paddingTop: 12 }}>
                                    {" "}
                                    {name.result}
                                  </label>
                                  {/* <b style={{color:'red'}}> *</b> */}
                                  {/* <a style={{ fontSize: 24, paddingLeft: 10, textDecoration: 'none', paddingTop: 7 }} onClick={this.NamedisableTxt} className="fa"></a> */}
                                  <input
                                    className="form-control "
                                    id="profile_name"
                                    name="profile_name"
                                    defaultValue={emp.profile_name}
                                    placeholder="Name"
                                    type="text"
                                    readOnly="readonly"
                                  />

                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="profile_name"
                                      defaultValue={emp.profile_name}
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_name: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                  {usernameErr && (
                                    <span style={{ color: "red" }}>
                                      {usernameErr}
                                    </span>
                                  )}

                                  <label>{dateofbirth.result}</label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      paddingTop: 10,
                                      textDecoration: "none",
                                    }}
                                    onClick={this.DOBundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    type="hidden"
                                    id="DOA_default"
                                    value={emp.profile_dateOfBirth}
                                  />
                                  <input
                                    defaultValue={emp.profile_dateOfBirth}
                                    type="text"
                                    name="profile_dateOfBirth"
                                    min="01-01-1920"
                                    onBlur={this.handleBlur.bind(this)}
                                    // onFocus={(e) => (e.target.type = "date")}
                                    onFocus={this.handleFocus.bind(this)}
                                    id="profile_dateOfBirth"
                                    // dateFormat="MMM d yyyy"
                                    readOnly="readonly"
                                    className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                                    max={maxDate}
                                    placeholder={dateofbirth.result}
                                  />

                                  <input
                                    type="hidden"
                                    id="remainsame"
                                    value={emp.profile_dateOfBirth}
                                  />
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      defaultValue={emp.profile_dateOfBirth}
                                      name="DOB"
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_dateOfBirth:
                                            data.target.value,
                                        });
                                      }}
                                    ></input>
                                  ))}
                                  {dobErr && (
                                    <span style={{ color: "red" }}>
                                      {dobErr}
                                    </span>
                                  )}
                                  <br />

                                  <label>
                                    {bankaccountt.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>

                                  <a
                                    href="#?id=collapseTwo"
                                    data-toggle="modal"
                                    data-target="#fileModal"
                                    style={{
                                      textDecoration: "none",
                                      lineHeight: "1.2",
                                      fontSize: "1.5rem",
                                      color: "black",
                                    }}
                                  >
                                    &nbsp;
                                    <b>&#9432;</b>
                                  </a>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      textDecoration: "none",
                                      paddingBottom: 5,
                                    }}
                                    onClick={this.BankAccundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                    data-ng-model="Post.profile_location"
                                    maxLength={18}
                                    defaultValue={emp.prof_BankAccount}
                                    onInput={(event) => {
                                      const value = event.target.value;
                                      if (!/^\d*$/.test(value)) {
                                        event.target.value = value.slice(0, -1); // Remove last character if not a digit
                                      }
                                    }}
                                    id="prof_BankAccount"
                                    name="prof_BankAccount"
                                    placeholder="Account Number"
                                    readOnly="readonly"
                                    type="text"
                                  />

                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="BankAccount"
                                      id="prof_BankAccount"
                                      defaultValue={emp.prof_BankAccount}
                                      onBlur={(data) => {
                                        this.setState({
                                          prof_BankAccount: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}

                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="ip_address"
                                      defaultValue={emp.ip_address}
                                      onBlur={(data) => {
                                        this.setState({
                                          ip_address: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="delete_status"
                                      defaultValue={emp.delete_status}
                                      onBlur={(data) => {
                                        this.setState({
                                          delete_status: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="user_id"
                                      defaultValue={emp.user_id}
                                      onBlur={(data) => {
                                        this.setState({
                                          user_id: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="created_date"
                                      defaultValue={emp.created_date}
                                      onBlur={(data) => {
                                        this.setState({
                                          created_date: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}

                                  <input
                                    className="form-control "
                                    id="prof_benID"
                                    name="prof_benID"
                                    Value="NoBenId"
                                    placeholder="Name"
                                    readOnly="readonly"
                                    type="hidden"
                                  />
                                </div>

                                <div className="col-lg-4">
                                  <label>
                                    {mobilenumberr.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      textDecoration: "none",
                                      paddingTop: 7,
                                    }}
                                    onClick={this.MobnodisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    className="form-control"
                                    defaultValue={emp.profile_mobileNumber}
                                    id="profile_mobileNumber"
                                    onInput={(event) => {
                                      const value = event.target.value;
                                      if (!/^\d*$/.test(value)) {
                                        event.target.value = value.slice(0, -1); // Remove last character if not a digit
                                      }
                                    }}
                                    name="profile_mobileNumber"
                                    placeholder="Mobile Number"
                                    readOnly="readonly"
                                    type="text"
                                    maxLength={10}
                                  />
                                  {pnoErr ? (
                                    <span style={{ color: "red" }}>
                                      {pnoErr}
                                    </span>
                                  ) : null}
                                  <span className="field-validation-valid text-danger" />

                                  {emps.map((emp) => (
                                    <input
                                      maxLength={10}
                                      onInput={(event) => {
                                        const value = event.target.value;
                                        if (!/^\d*$/.test(value)) {
                                          event.target.value = value.slice(
                                            0,
                                            -1
                                          ); // Remove last character if not a digit
                                        }
                                      }}
                                      type="hidden"
                                      name="profile_mobileNumber"
                                      id="mobileNumber"
                                      defaultValue={emp.profile_mobileNumber}
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_mobileNumber:
                                            data.target.value,
                                        });
                                      }}
                                    />
                                  ))}

                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      maxLength={10}
                                      onInput={(event) => {
                                        const value = event.target.value;
                                        if (!/^\d*$/.test(value)) {
                                          event.target.value = value.slice(
                                            0,
                                            -1
                                          ); // Remove last character if not a digit
                                        }
                                      }}
                                      name="default_mobileNumber"
                                      id="default_mobileNumber"
                                      defaultValue={emp.profile_mobileNumber}
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_mobileNumber:
                                            data.target.value,
                                        });
                                      }}
                                    />
                                  ))}

                                  <label>{dateofaniversary.result}</label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      paddingTop: 10,
                                      textDecoration: "none",
                                    }}
                                    onClick={this.DOAundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    type="hidden"
                                    id="DOB_default"
                                    value={emp.profile_dateOfAnniversary}
                                  />
                                  <input
                                    defaultValue={emp.profile_dateOfAnniversary}
                                    type="text"
                                    name="profile_dateOfAnniversary"
                                    min="1920-01-01"
                                    // onBlur={this.handleBlurDOA}
                                    // onBlur={this.handleBlurDOA.bind(this)}
                                    onBlur={this.handleBlurDOA.bind(this)}
                                    // onFocus={(e) => (e.target.type = "date")}
                                    // onFocus={this.handleFocusDOA}
                                    onFocus={this.handleFocusDOA.bind(this)}
                                    id="profile_dateOfAnniversary"
                                    dateFormat="yyyy-MM-dd"
                                    readOnly="readonly"
                                    className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                                    max={today}
                                    placeholder={dateofaniversary.result}
                                  />
                                  <input
                                    type="hidden"
                                    id="remainsameanni"
                                    value={emp.profile_dateOfAnniversary}
                                  />
                                  {doaErr && (
                                    <span style={{ color: "red" }}>
                                      {doaErr}
                                    </span>
                                  )}
                                  <br />

                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="DOA"
                                      defaultValue={
                                        emp.profile_dateOfAnniversary
                                      }
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_dateOfAnniversary:
                                            data.target.value,
                                        });
                                      }}
                                    />
                                  ))}

                                  <label>
                                    {ifsccode.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      textDecoration: "none",
                                      paddingBottom: 5,
                                    }}
                                    onClick={this.IFSCundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                    data-ng-model="Post.prof_IFSC"
                                    maxLength={11}
                                    id="prof_IFSC"
                                    name="prof_IFSC"
                                    placeholder="IFSC Code"
                                    readOnly="readonly"
                                    type="text"
                                    defaultValue={emp.prof_IFSC}
                                  />

                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="IFSC"
                                      id="prof_IFSC"
                                      maxLength={11}
                                      defaultValue={emp.prof_IFSC}
                                      onBlur={(data) => {
                                        this.setState({
                                          prof_IFSC: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}

                                  <br />
                                </div>

                                <div
                                  className="col-lg-4"
                                  style={{ width: "width:32.33333333%" }}
                                >
                                  <label>
                                    {emaill.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      textDecoration: "none",
                                      paddingTop: 7,
                                    }}
                                    onClick={this.emailundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  {emailErr && (
                                    <span style={{ color: "red" }}>
                                      {emailErr}
                                    </span>
                                  )}
                                  <input
                                    className="form-control inputPro"
                                    id="profile_email"
                                    name="profile_email"
                                    defaultValue={emp.profile_email}
                                    placeholder="Email"
                                    readOnly="readonly"
                                    type="email"
                                  />
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="email"
                                      id="email"
                                      defaultValue={emp.profile_email}
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_email: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                  <label>
                                    {location.result}
                                    <b style={{ color: "red" }}> *</b>
                                  </label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      textDecoration: "none",
                                      paddingTop: 10,
                                    }}
                                    onClick={this.locationundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                    data-ng-model="Post.profile_location"
                                    defaultValue={emp.profile_location}
                                    id="profile_location"
                                    minLength={3}
                                    name="profile_location"
                                    placeholder={location.result}
                                    readOnly="readonly"
                                    type="text"
                                  />
                                  {LocErr && (
                                    <div style={{ color: "red" }}>{LocErr}</div>
                                  )}{" "}
                                  <span className="field-validation-valid text-danger" />
                                  <br />
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="location"
                                      id="profile_location"
                                      defaultValue={emp.profile_location}
                                      onBlur={(data) => {
                                        this.setState({
                                          profile_location: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                  <label>{upiId.result}</label>
                                  <a
                                    style={{
                                      fontSize: 24,
                                      paddingLeft: 10,
                                      textDecoration: "none",
                                      paddingBottom: 5,
                                    }}
                                    onClick={this.UPIundisableTxt}
                                    className="fa"
                                  >
                                    
                                  </a>
                                  <input
                                    onKeyDown={(e) => {
                                      if (e.key === " ") {
                                        e.preventDefault();
                                      }
                                    }}
                                    className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                    data-ng-model="Post.profile_location"
                                    maxLength={30}
                                    id="pro_UPIID"
                                    name="pro_UPIID"
                                    defaultValue={emp.pro_UPIID}
                                    placeholder="UPI ID"
                                    readOnly="readonly"
                                    type="text"
                                  />
                                  {emps.map((emp) => (
                                    <input
                                      type="hidden"
                                      name="UPIID"
                                      id="UPIID"
                                      maxLength={30}
                                      defaultValue={emp.pro_UPIID}
                                      onBlur={(data) => {
                                        this.setState({
                                          pro_UPIID: data.target.value,
                                        });
                                      }}
                                    />
                                  ))}
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      ))}

                      <div className="row">
                        <div className="col-lg-4 newedit-btn">
                          {/* <label for="profile_imagePath" style={{fontFamily:'cuyabra'}}>
                                       {edit.result}&nbsp;<i style={{fontsize:'24px'}} class="fa"> &#xf067;</i></label> */}
                          <br />
                          <br />
                          {(() => {
                            if (langID == "Hindi") {
                              return (
                                <center>
                                  {" "}
                                  <button
                                    onClick={() => this.proPop()}
                                    type="button"
                                    class="btn btn-primary"
                                    id="editbtn"
                                    data-toggle="modal"
                                    data-target="#basicModal"
                                    style={{
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginLeft: "-115px",
                                      marginTop: "-10px",
                                      width: "18%",
                                    }}
                                  >
                                    {edit.result}
                                  </button>{" "}
                                </center>
                              );
                            } else if (langID == "English") {
                              return (
                                <center>
                                  {" "}
                                  <button
                                    onClick={() => this.proPop()}
                                    type="button"
                                    class="btn btn-primary"
                                    id="editbtn"
                                    data-toggle="modal"
                                    data-target="#basicModal"
                                    style={{
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginLeft: "-115px",
                                      marginTop: "-10px",
                                      width: "18%",
                                    }}
                                  >
                                    {edit.result}
                                  </button>{" "}
                                </center>
                              );
                            } else if (langID == "Spanish") {
                              return (
                                <center>
                                  {" "}
                                  <button
                                    onClick={() => this.proPop()}
                                    type="button"
                                    class="btn btn-primary"
                                    id="editbtn"
                                    data-toggle="modal"
                                    data-target="#basicModal"
                                    style={{
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginLeft: "-115px",
                                      marginTop: "-10px",
                                      width: "18%",
                                    }}
                                  >
                                    {edit.result}
                                  </button>{" "}
                                </center>
                              );
                            } else if (langID == "Telugu") {
                              return (
                                <center>
                                  {" "}
                                  <button
                                    onClick={() => this.proPop()}
                                    type="button"
                                    class="btn btn-primary"
                                    id="editbtn"
                                    data-toggle="modal"
                                    data-target="#basicModal"
                                    style={{
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginLeft: "-115px",
                                      marginTop: "-10px",
                                      width: "18%",
                                    }}
                                  >
                                    {edit.result}
                                  </button>{" "}
                                </center>
                              );
                            } else if (langID == "Kannada") {
                              return (
                                <center>
                                  {" "}
                                  <button
                                    onClick={() => this.proPop()}
                                    type="button"
                                    class="btn btn-primary"
                                    id="editbtn"
                                    data-toggle="modal"
                                    data-target="#basicModal"
                                    style={{
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginLeft: "-115px",
                                      marginTop: "-10px",
                                      width: "18%",
                                    }}
                                  >
                                    {edit.result}
                                  </button>{" "}
                                </center>
                              );
                            } else if (langID == "Tamil") {
                              return (
                                <center>
                                  {" "}
                                  <button
                                    onClick={() => this.proPop()}
                                    type="button"
                                    class="btn btn-primary"
                                    id="editbtn"
                                    data-toggle="modal"
                                    data-target="#basicModal"
                                    style={{
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginLeft: "-122px",
                                      marginTop: "-10px",
                                      width: "20%",
                                    }}
                                  >
                                    {edit.result}
                                  </button>{" "}
                                </center>
                              );
                            }
                          })()}
                        </div>
                        <div className="col-lg-6">
                          <br />
                          <br />
                          {(() => {
                            if (langID == "Hindi") {
                              return (
                                <center>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      width: "25%",
                                      height: "20%",

                                      padding: "8px 8px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      color: "white",
                                      fontSize: "14px",
                                      textDecoration: "none",
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {submit.result}
                                  </button>
                                  &nbsp;&nbsp;
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    Value={cancel.result}
                                    onClick={this.noneditableall}
                                    style={{
                                      width: "20%",
                                      height: "20%",
                                      padding: "8px 0px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      textDecoration: "none",

                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      fontSize: "14px",
                                      marginTop: "-10px",
                                    }}
                                  />
                                </center>
                              );
                            } else if (langID == "Kannada") {
                              return (
                                <center>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      width: "25%",
                                      height: "20%",

                                      padding: "8px 8px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      color: "white",
                                      fontSize: "14px",
                                      textDecoration: "none",
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {submit.result}
                                  </button>
                                  &nbsp;&nbsp;
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    Value={cancel.result}
                                    onClick={this.noneditableall}
                                    style={{
                                      width: "29%",
                                      height: "20%",
                                      padding: "8px 0px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      textDecoration: "none",

                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      fontSize: "14px",
                                      marginTop: "-10px",
                                    }}
                                  />
                                </center>
                              );
                            } else if (langID == "Tamil") {
                              return (
                                <center>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      width: "27%",
                                      height: "20%",
                                      color: "white",
                                      padding: "8px 1px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {submit.result}
                                  </button>
                                  &nbsp;&nbsp;
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    Value={cancel.result}
                                    onClick={this.noneditableall}
                                    style={{
                                      width: "24%",
                                      height: "20%",
                                      padding: "8px 8px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      marginTop: "-10px",
                                    }}
                                  />
                                </center>
                              );
                            } else if (langID == "English") {
                              return (
                                <center>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      width: "18%",
                                      height: "24%",

                                      padding: "8px 8px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      color: "white",
                                      fontSize: "14px",
                                      textDecoration: "none",
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {submit.result}
                                  </button>
                                  &nbsp;&nbsp;
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    Value={cancel.result}
                                    onClick={this.noneditableall}
                                    style={{
                                      width: "18%",
                                      height: "20%",
                                      padding: "8px 0px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      textDecoration: "none",

                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      fontSize: "14px",
                                      marginTop: "-10px",
                                    }}
                                  />
                                </center>
                              );
                            } else if (langID == "Spanish") {
                              return (
                                <center>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      width: "22%",
                                      height: "20%",

                                      padding: "8px 8px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      color: "white",
                                      fontSize: "14px",
                                      textDecoration: "none",
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {submit.result}
                                  </button>
                                  &nbsp;&nbsp;
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    Value={cancel.result}
                                    onClick={this.noneditableall}
                                    style={{
                                      width: "22%",
                                      height: "20%",
                                      padding: "8px 0px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      textDecoration: "none",

                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      fontSize: "14px",
                                      marginTop: "-10px",
                                    }}
                                  />
                                </center>
                              );
                            } else {
                              return (
                                <center>
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    style={{
                                      width: "25%",
                                      height: "20%",
                                      color: "white",
                                      padding: "8px 0px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      backgroundColor: "rgb(102, 71, 18)",
                                      marginTop: "-10px",
                                    }}
                                  >
                                    {submit.result}
                                  </button>
                                  &nbsp;&nbsp;
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    Value={cancel.result}
                                    onClick={this.noneditableall}
                                    style={{
                                      width: "28%",
                                      height: "20%",
                                      padding: "8px 0px",
                                      margin: "25px 0",
                                      border: "none",
                                      borderRadius: 4,
                                      backgroundColor: "rgb(102, 71, 18)",
                                      color: "white",
                                      marginTop: "-10px",
                                    }}
                                  />
                                </center>
                              );
                            }
                          })()}
                        </div>
                        <div className="col-lg-2"></div>
                      </div>
                    </form>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </section>

              {/* Dhinesh2   ///Profile pop          */}

              {/* {this.state.ProfilepicPop ? ( */}
                <div
                  class="modal fade"
                  id="basicModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div
                      class="modal-content1"
                      style={{ marginTop: "25% !important" }}
                    >
                      <div class="modal-header">
                        <h5 id="exampleModalLongTitle">
                          {updateprofile.result}
                        </h5>
                        <button
                          onClick={() => this.proPop()}
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form
                          autoComplete="off"
                          onSubmit={this.handleDeletePic}
                          //  onSubmit={(event) => this.handleDeletePic(event)}
                        >
                          <button
                            // type="button"
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              marginLeft: "-7px",
                            }}
                          >
                            <input
                              type="hidden"
                              name="remove"
                              id="remove"
                              defaultValue="1"
                            />
                            {emps.map((emp) => (
                              <div
                                style={{ display: "none" }}
                                className="circle-img"
                              >
                                {/* <input type="hidden" name="remove" id="remove"
                                             defaultValue="1"
                                             />
                                              <input type="hidden" name="remove" id="remove"
                                             defaultValue={emp.name}
                                             /> */}
                                <input
                                  name="profile_imagePath"
                                  placeholder="profile_imagePath"
                                  id="profile_imagePath"
                                  value={emp.profile_imagePath}
                                  type="text"
                                />

                                <input
                                  className="form-control"
                                  value={emp.profile_mobileNumber}
                                  id="profile_mobileNumber"
                                  onInput={(event) => {
                                    const value = event.target.value;
                                    if (!/^\d*$/.test(value)) {
                                      event.target.value = value.slice(0, -1); // Remove last character if not a digit
                                    }
                                  }}
                                  maxLength={10}
                                  name="profile_mobileNumber"
                                  placeholder="Mobile Number"
                                  readOnly="readonly"
                                  type="text"
                                />

                                <input
                                  className="form-control "
                                  id="profile_name"
                                  name="profile_name"
                                  defaultValue={emp.profile_name}
                                  placeholder="Name"
                                  type="text"
                                  readOnly="readonly"
                                />
                                <input
                                  className="form-control "
                                  id="prof_benID"
                                  name="prof_benID"
                                  value="NoBenId"
                                  placeholder="Name"
                                  readOnly="readonly"
                                  type="hidden"
                                />

                                <input
                                  defaultValue={emp.profile_dateOfBirth}
                                  type="hidden"
                                  name="profile_dateOfBirth"
                                  min="1920-01-01"
                                  onFocus={(e) => (e.target.type = "date")}
                                  id="profile_dateOfBirth"
                                  dateFormat="yyyy-MM-dd"
                                  readOnly="readonly"
                                  className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                                />
                                <input
                                  className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                  data-ng-model="Post.profile_location"
                                  id="prof_BankAccount"
                                  defaultValue={emp.prof_BankAccount}
                                  name="prof_BankAccount"
                                  placeholder="Account Number"
                                  readOnly="readonly"
                                  type="hidden"
                                />

                                <input
                                  defaultValue={emp.profile_dateOfAnniversary}
                                  type="hidden"
                                  name="profile_dateOfAnniversary"
                                  id="profile_dateOfAnniversary"
                                  dateFormat="yyyy-MM-dd"
                                  readOnly="readonly"
                                  className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                                />

                                <input
                                  className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                  data-ng-model="Post.prof_IFSC"
                                  maxLength={11}
                                  id="prof_IFSC"
                                  defaultValue={emp.prof_IFSC}
                                  name="prof_IFSC"
                                  placeholder="IFSC Code"
                                  readOnly="readonly"
                                  type="hidden"
                                />

                                <input
                                  className="form-control inputPro"
                                  style={{ marginTop: "-3px" }}
                                  id="profile_email"
                                  name="profile_email"
                                  defaultValue={emp.profile_email}
                                  placeholder="Email"
                                  readOnly="readonly"
                                  type="hidden"
                                />
                                <input
                                  className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                  data-ng-model="Post.profile_location"
                                  id="profile_location"
                                  minLength={3}
                                  defaultValue={emp.profile_location}
                                  name="profile_location"
                                  placeholder="Location"
                                  readOnly="readonly"
                                  type="text"
                                />

                                {/* https://apitest.paropakari.com/Images/user.png */}
                                <input
                                  className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                  data-ng-model="Post.profile_location"
                                  maxLength={30}
                                  id="pro_UPIID"
                                  defaultValue={emp.pro_UPIID}
                                  name="pro_UPIID"
                                  placeholder="UPI ID"
                                  readOnly="readonly"
                                  type="hidden"
                                />
                              </div>
                            ))}

                            <label style={{ fontSize: "11px" }}>
                              {" "}
                              {emps.map((emp) => (
                                <div>
                                  {(() => {
                                    if (
                                      emp.profile_imagePath ===
                                      "https://apitest.paropakari.com/Images/user.png"
                                    ) {
                                      return <center></center>;
                                    } else {
                                      return (
                                        <center>
                                          <b> {deleteprofilepic.result} </b>{" "}
                                        </center>
                                      );
                                    }
                                  })()}
                                </div>
                              ))}
                            </label>
                          </button>
                        </form>

                        <br />

                        {/* &nbsp;<i style={{fontsize:'24px'}} class="fa"> &#xf067;</i>*/}
                        <form
                          autoComplete="off"
                          // style={{ width: "52%" }}
                          onSubmit={this.handleImageevents}
                        >
                          {/*             
  Choose File
 <input type="file" class="hide_file"> */}
                          <label
                            style={{ fontFamily: "cuyabra", fontSize: "11px" }}
                          >
                            <b className="notranslate">
                              {selectprofilepic.result}
                            </b>
                          </label>

                          {emps.map((emp) => (
                            <div
                              style={
                                this.state.ScreenWidth < 400
                                  ? {
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }
                                  : this.state.ScreenWidth > 400 &&
                                    this.state.ScreenWidth < 600
                                  ? {
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }
                                  : {
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "100%",
                                    }
                              }

                              // >400 200 ! 60
                            >
                              <input
                                type="hidden"
                                name="remove"
                                id="remove"
                                defaultValue={emp.name}
                              />

                              <input
                                className="form-control"
                                value={emp.profile_mobileNumber}
                                id="profile_mobileNumber"
                                maxLength={10}
                                name="profile_mobileNumber"
                                placeholder="Mobile Number"
                                readOnly="readonly"
                                onInput={(event) => {
                                  const value = event.target.value;
                                  if (!/^\d*$/.test(value)) {
                                    event.target.value = value.slice(0, -1); // Remove last character if not a digit
                                  }
                                }}
                                type="hidden"
                              />

                              <input
                                className="form-control "
                                id="profile_name"
                                name="profile_name"
                                defaultValue={emp.profile_name}
                                placeholder="Name"
                                type="hidden"
                                readOnly="readonly"
                              />
                              <input
                                className="form-control "
                                id="prof_benID"
                                name="prof_benID"
                                value="NoBenId"
                                placeholder="Name"
                                readOnly="readonly"
                                type="hidden"
                              />

                              <input
                                className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                data-ng-model="Post.profile_location"
                                id="prof_BankAccount"
                                defaultValue={emp.prof_BankAccount}
                                name="prof_BankAccount"
                                placeholder="Account Number"
                                readOnly="readonly"
                                type="hidden"
                              />

                              <input
                                defaultValue={emp.profile_dateOfAnniversary}
                                type="hidden"
                                name="profile_dateOfAnniversary"
                                id="profile_dateOfAnniversary"
                                dateFormat="yyyy-MM-dd"
                                readOnly="readonly"
                                className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                              />

                              <input
                                defaultValue={emp.profile_dateOfBirth}
                                type="hidden"
                                name="profile_dateOfBirth"
                                min="1920-01-01"
                                onFocus={(e) => (e.target.type = "date")}
                                id="profile_dateOfBirth"
                                dateFormat="yyyy-MM-dd"
                                readOnly="readonly"
                                className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-min ng-valid-max"
                              />
                              <input
                                className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                data-ng-model="Post.prof_IFSC"
                                id="prof_IFSC"
                                defaultValue={emp.prof_IFSC}
                                name="prof_IFSC"
                                placeholder="IFSC Code"
                                readOnly="readonly"
                                type="hidden"
                              />

                              <input
                                className="form-control inputPro"
                                style={{ marginTop: "-3px" }}
                                id="profile_email"
                                name="profile_email"
                                defaultValue={emp.profile_email}
                                placeholder="Email"
                                readOnly="readonly"
                                type="hidden"
                              />

                              <input
                                className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                data-ng-model="Post.profile_location"
                                id="profile_location"
                                defaultValue={emp.profile_location}
                                name="profile_location"
                                placeholder="Location"
                                readOnly="readonly"
                                type="hidden"
                              />

                              <input
                                className="form-control ng-pristine ng-untouched ng-valid ng-not-empty"
                                data-ng-model="Post.profile_location"
                                maxLength={30}
                                id="pro_UPIID"
                                defaultValue={emp.pro_UPIID}
                                name="pro_UPIID"
                                placeholder="UPI ID"
                                readOnly="readonly"
                                type="hidden"
                              />

                              {this.state.ImageDisplay == null ? (
                                <img
                                  //800  70%
                                  className="card-img"
                                  id="target1"
                                  style={{
                                    width: "150px",
                                    height: "150px",
                                    borderRadius: "50%",
                                  }}
                                  src={emp.profile_imagePath}
                                  alt={this.state.file}
                                />
                              ) : (
                                <div
                                  className="small-pre"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  <img
                                    id="groupimage"
                                    style={{
                                      width: '150px',
                                      height: '150px',
                                      borderRadius: '50%'
                                    }}
                                    // className="small-circle-image"
                                    src={this.state.ImageDisplay}
                                  />
                                </div>
                              )}
                            </div>
                          ))}
                          <br />

                          <input
                            placeholder="Select"
                            type="file"
                            id="profile_imagePath"
                            accept="image/*"
                            name="profile_imagePath"
                            onChange={this.ImagePreview}
                            className="hide_file"
                            style={{
                              color: "rgb(165, 217, 207)",
                              position: "absolute",
                              top: "17%",
                              left: "0%",
                              width: "100%",
                              height: "159px",
                            }}
                          />

                          {/* <img className="small-circle-image"
                                       src={this.state.ImageDisplay}  /> */}

                          <br />
                          <br />
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              justifyContent: "end",
                            }}
                          >
                            <button
                              type="submit"
                              class="btn btn-primary"
                              id="profileokbtn"
                              data-toggle="modal"
                              data-target="#basicModal"
                              style={{
                                backgroundColor: "rgb(102, 71, 18)",
                                width: "35%",
                              }}
                            >
                              {ok.result}
                            </button>
                          </div>

                          {/* <br /> */}
                        </form>
                      </div>
                      {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
                    </div>
                  </div>
                </div>
              {/* // ) : null} */}

              <div className="modal fade" id="fileModal" role="dialog">
                <div className="modal-dialog">
                  <div
                    className="modal-content"
                    style={{ marginTop: "25% !important" }}
                  >
                    <div className="modal-header">
                      <h5 style={{ fontWeight: "bold" }}>
                        {bankaccdet.result}{" "}
                      </h5>
                    </div>
                    <div className="modal-body">{ifyouwanttocreate.result}</div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        className="btn btn-default"
                        data-dismiss="modal"
                      >
                        {close.result}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/*/#team*/}
              <div style={{ backgroundColor: "#664712" }}>
                <footer id="footer" style={{ color: "white" }}>
                  <br />
                  <div className="container">
                    <div className="copyright">
                      <div className="row col-lg-12">
                        <div className="col-lg-9 mb-6">
                          {/* {foo.map((fo) => (
                          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
                    <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
                      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
                      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
                      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
                      </p>
                      ))} */}

                          <p style={{ marginLeft: "45px" }}>
                            {copyright_new.result} &nbsp;{currentYear} ©{" "}
                            {paropakari_new.result}&nbsp; |&nbsp;
                            <a
                              href="#/Contactuslogin"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              {contactus.result}&nbsp;
                            </a>
                            |{" "}
                            <a
                              href="#/PrivacyPolicy"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              &nbsp;{privacypolicy.result}&nbsp;
                            </a>
                            |
                            <a
                              href="#/TermsandCondition"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              &nbsp;{termsandcondition.result}
                            </a>
                          </p>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <div
                            className="social-icons"
                            style={{ color: "white", marginLeft: "72px" }}
                          >
                            <ul className="nav nav-pills">
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.facebook.com/beoneparopakari"
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                                >
                                  <i className="fa fa-youtube-play" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/paropakari_beone/"
                                >
                                  <i className="fa fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.linkedin.com/company/paropakari/"
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerss"></div>
                </footer>
              </div>
              {/*/#footer*/}
            </div>
          </div>
        </div>
      );
    }
  }
}
