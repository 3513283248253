import React, { Component } from "react";
import { Link } from "react-router-dom";
import ImagePreview from "./ImagePreview";
import Likepopup from "./Likepopup";
import Comment from "./Comment";
import Cookies from "js-cookie";
import TaggedFriends from "./TaggedFriends";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from "../Constants/BaseURL";
import PostComponent from "./PostComponent";

const styles = {
  btnThird: {
    backgroundColor: "rgb(102, 71, 18)",
    color: "white",
    textDecoration: "none",
  },
  btnThirdText: {
    color: "white",
    lineHeight: "20px",
  },
  link: {
    backgroundColor: "rgb(102, 71, 18)",
    color: "white",
    textDecoration: "none",
    alignContent: "space-around",
    marginTop: "",
    fontSize: 10,
  },
  btnThirdResponsive: {
    backgroundColor: "rgb(102, 71, 18)",
    color: "white",
    textDecoration: "none",
    marginLeft: "0",
    cursor: "none",
    fontSize: "12px",
  },
  linkResponsive: {
    backgroundColor: "rgb(102, 71, 18)",
    color: "white",
    textDecoration: "none",
    alignContent: "space-around",
    marginTop: "10px",
    fontSize: "10px",
  },
};

const Selects = [
  {
    name: "firstSelect",
    options: [
      { value: "2024", label: "2024" },
      { value: "2023", label: "2023" },
      { value: "2022", label: "2022" },
      { value: "2021", label: "2021" },
      { value: "2020", label: "2020" },
      { value: "2019", label: "2019" },
      { value: "2018", label: "2018" },
      { value: "2017", label: "2017" },
      { value: "2016", label: "2016" },
    ],
  },

  {
    name: "secondSelect",
    options: [
      { value: "0", label: "Incomplete" },
      { value: "1", label: "80G" },
      { value: "2", label: "80G" },
      { value: "3", label: "Sports" },
      { value: "4", label: "Social welfare" },
      { value: "5", label: "Professional" },
      { value: "6", label: "Health" },
      { value: "7", label: "Food and Nutrition" },
      { value: "8", label: "Education" },
    ],
  },

  {
    name: "ThirdSelect",
    options: [
      { value: "0", label: "My Causes" },
      { value: "1", label: "Liked Causes" },
      { value: "2", label: "Commented Causes" },
      { value: "3", label: "Pledged Causes" },
    ],
  },
];

export default class MyActivity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id : this.props.match.params.id,
      years: {
        options: [
          { value: "2024", label: "2024" },
          { value: "2023", label: "2023" },
          { value: "2022", label: "2022" },
          { value: "2021", label: "2021" },
          { value: "2020", label: "2020" },
          { value: "2019", label: "2019" },
          { value: "2018", label: "2018" },
          { value: "2017", label: "2017" },
          { value: "2016", label: "2016" },
        ],
        value: null,
      },

      categories: {
        options: [
          { value2: "0", label: "Incomplete" },
          { value2: "1", label: "80G" },
          { value2: "2", label: "80G" },
          { value2: "3", label: "Sports" },
          { value2: "4", label: "Social welfare" },
          { value2: "5", label: "Professional" },
          { value2: "6", label: "Health" },
          { value2: "7", label: "Food and Nutrition" },
          { value2: "8", label: "Education" },
        ],
        value: null,
      },

      Activity: {
        options: [
          { value3: "0", label: "My Causes" },
          { value3: "1", label: "Liked Causes" },
          { value3: "2", label: "Commented Causes" },
          { value3: "3", label: "Pledged Causes" },
        ],
        value: null,
      },

      filter: "",
      emps: [],
      empsubmit: [],
      Dataa: [],
      CategoryData: [],
      Activitydata: [],
      notify: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      search: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      others: [],
      sort: [],
      loading: true,
      mostrecent: [],
      mostpopular: [],
      available80g: [],
      amountdesc: [],
      amountasc: [],
      complete: [],
      incomplete: [],
      education: [],
      enddate: [],
      causes: [],
      viewdetail: [],
      deletee: [],
      edit: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      CommentHere: [],
      Submit: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      wanttodelete: [],
      blog: [],
      tag: [],
      like: [],
      comment: [],
      donateasmile: [],
      year: [],
      activity: [],
      mycauses: [],
      likedcauses: [],
      commentedcauses: [],
      pledgedcauses: [],
      langID: [],
      privacypolicy: [],
      friends: [],
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      selectValue: "",
      year: "",
      selectValue1: "",
      selectValue2: "",
      selectedOption: null,
      selectedOption1: null,
      selectedOption2: null,
      movedown: true,
      yearval: "0",
      catval: "0",
      actval: "0",
      visibledata: false,
      visible: false,
      message: false,
      copyright_new: [],
      paropakari_new: [],
      PostLimit: 2,
      PostLoading: false,
      //cookie
      language: "",
      previousCookieLang: "",
      isMobile: window.innerWidth,
    };
    this.isMountedFlag = false; // Flag to track if component is mounted
    this.submit = this.submit.bind(this);
    this.handleResize = this.handleResize.bind(this);

    //this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.handlecategoriesChange = this.handlecategoriesChange.bind(this);
    this.handleactivityChange = this.handleactivityChange.bind(this);
    //this.handleChange=this.handleChange.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);

    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.handleChangesCategory = this.handleChangesCategory.bind(this);
    this.handleChangess = this.handleChangess.bind(this);
    this.refreshcomment = this.refreshcomment.bind(this);
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth  });
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
    // name = oldName == null ? "English" : name;
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "#/Login",
    };
    this.props.history.push(page);
  };

  submit = () => {
    let url = "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetail";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          emps: [],
        });
        this.setState({
          emps: this.AddImg(data),
        });

        if (data.length == 0) {
          this.setState({
            message: true,
          });
        }
      });
  };

  DeleteGroup = (upload_id) => {
    var x = window.confirm(this.state.wanttodelete.result);
    if (x) {
      fetch(
        "https://apitest.paropakari.com/api/Group/Postelete?upload_id=" +
          upload_id,
        { method: "POST" }
      ).then(() => this.setState({ status: "Delete successful" }));

      this.refreshcomment();
    } else {
      return false;
    }
  };

  notify = () => {
    try {
      let url =
        "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            notify: data,
          });

          //
        });
    } catch (error) {
      console.error(error);
    }
  };

  onSelectChange1(name, value) {
    this.setState(
      (prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            value: value.value,
          },
        };
      },
      () => {
        let url =
          "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=3&Year=" +
          this.state.years.value +
          "&CategoryID=" +
          this.state.categories.value;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        //
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              like: this.AddImg(data),
            });

            // localStorage.setItem('filterData', JSON.stringify(this.state.like));
            if (data.length == 0) {
              // this.setState({
              //   message: true
              // })
            }

            //
          });
      }
    );
  }

  onSelectChange(name, value) {
    let obj = {};

    obj[name] = value;

    this.setState(obj);

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=3&Year=" +
      obj[name].value +
      "&CategoryID=" +
      obj[name].value;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    //
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          like: this.AddImg(data),
        });

        if (data.length == 0) {
          this.setState({
            message: true,
          });
        }

        //
      });
  }

  AddLiking = async (event) => {
    event.preventDefault();

    // Create a FormData object from the form
    const data = new FormData(event.target);

    // Retrieve user and access token
    const user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    // Set up the URL and options for the POST request
    const postUrl =
      "https://apitest.paropakari.com/api/GetProfile/Add_LikeDetails";
    const postOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    };

    try {
      // Make the POST request and wrap it in a Promise
      const postResponse = await fetch(postUrl, postOptions);
      const postResult = await postResponse.json();

      // Create a Promise to resolve before checking the if condition
      const promise = new Promise((resolve, reject) => {
        if (postResponse.ok) {
          this.setState({
            emps: [],
            empsubmit: [],
          });

          resolve(true);
        } else {
          reject(postResult);
        }
      });

      // Execute the if condition once the Promise is resolved
      promise
        .then(async () => {
          // Retrieve filter values from the DOM
          const YearDrop = document.getElementById("YearDrop").value;
          const CategoryDrop = document.getElementById("CategoryDrop").value;
          const ActivityDrop = document.getElementById("ActivityDrop").value;

          // Construct the URL for the GET request
          const getUrl = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${ActivityDrop}&Year=${YearDrop}&CategoryID=${CategoryDrop}`;

          // Set up the options for the GET request
          const getOptions = {
            method: "GET",
            headers: {
              "Content-type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${accessToken}`,
              "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
            },
          };

          // Make the GET request
          const getResponse = await fetch(getUrl, getOptions);
          const data = await getResponse.json();

          // Filter the data based on the selected criteria
          let filteredData = data;
          if (CategoryDrop != 0 && YearDrop != 0) {
            filteredData = data.filter(
              (e) => e.year == YearDrop && e.category_id == CategoryDrop
            );
          } else if (ActivityDrop != 0) {
            filteredData = data;
          } else if (CategoryDrop != 0) {
            filteredData = data.filter((e) => e.category_id == CategoryDrop);
          } else if (YearDrop != 0) {
            filteredData = data.filter((e) => e.year == YearDrop);
          }
          // Update state with the filtered data
          this.setState({
            emps: this.AddImg(filteredData),
            empsubmit: this.AddImg(filteredData),
            loading: false,
          });
        })
        .catch((error) => {
          console.error("Failed to add like:", error);
          // Handle failure if needed
        });
    } catch (error) {
      console.error("Error during fetch:", error);
      // Handle error, such as showing a message to the user
    }
  };

  Footer = () => {
    let url =
      "https://apitest.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  AddImg = (data) => {
    function sortByCreatedDateDescending(data) {
      return data.sort((a, b) => {
        const dateA = new Date(a.created_date);
        const dateB = new Date(b.created_date);
        return dateB - dateA;
      });
    }

    const sortedData = sortByCreatedDateDescending(data);

    let final = sortedData.map((e) => {
      if (e) {
        let img = null;
        if (e.imageData !== null) {
          img = e.imageData.split(",").map((img) => {
            return {
              file_name: img.split("$$$")[0],
              file_type: img.split("$$$")[1],
            };
          });
        }
        return {
          ...e,
          data: e.imageData === null ? [] : img,
        };
      }
    });

    return final;
  };

  handleChange = async (event) => {
    // setTimeout(() => {
    //   this.setState({
    //     visible: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       visible: false,
    //     });
    //   }, 500);
    // }, 1000);
    if (this.state.PostLimit != 5) {
      this.setState({ PostLimit: 5 });
    }
    this.setState({ value: event.target.value });
    this.setState({ yearval: event.target.value });
    const promise = new Promise((resolve, reject) => {
      try {
        this.setState({
          emps: [],
          CategoryData: [],
        });
        resolve();
      } catch (error) {
        reject();
      }
    });
    promise.then(async () => {
      if (
        event.target.value == 0 &&
        this.state.actval == 0 &&
        this.state.catval == 0
      ) {
        // alert("handlechange is called");
        let url =
          "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetail";
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (
        event.target.value == 0 &&
        this.state.actval != 0 &&
        this.state.catval != 0
      ) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=5&Year=${event.target.value}&CategoryID=${this.state.catval}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (
        event.target.value == 0 &&
        this.state.actval == 0 &&
        this.state.catval != 0
      ) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=5&Year=${event.target.value}&CategoryID=${this.state.catval}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (
        event.target.value == 0 &&
        this.state.actval != 0 &&
        this.state.catval == 0
      ) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${event.target.value}&CategoryID=${this.state.catval}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else {
        this.setState({ value: event.target.value });

        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${
          this.state.actval != 0 ? this.state.actval : 6
        }&Year=${event.target.value}&CategoryID=${this.state.catval}`;
        // "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=6" +
        //-- "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=6" +
        //event.target.value+
        // this.state.Sort.value +
        // --"&Year=" + event.target.value +
        //  this.state.Years.value +
        // --"&CategoryID=0";

        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        //

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },

          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
              Dataa: this.AddImg(data),
            });

            //localStorage.setItem('filterData', JSON.stringify(this.state.Dataa));

            if (data.length == 0) {
              this.setState({
                visibledata: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }

            //
          });
      }
    });
  };

  handleChangesCategory = (event) => {
    if (this.state.PostLimit != 5) {
      this.setState({ PostLimit: 5 });
    }
    this.setState({ value2: event.target.value });
    this.setState({ catval: event.target.value });
    // this.setState({
    //   emps: [],
    // });
    const promise = new Promise((resolve, reject) => {
      try {
        this.setState({
          emps: [],
          CategoryData: [],
        });
        resolve();
      } catch (error) {
        reject();
      }
    });
    promise.then(async () => {
      if (
        event.target.value == 0 &&
        this.state.yearval == 0 &&
        this.state.actval == 0
      ) {
        // alert("handle change category called");
        let url =
          "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetail";
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (
        event.target.value == 0 &&
        this.state.actval != 0 &&
        this.state.catval != 0
      ) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${this.state.yearval}&CategoryID=${event.target.value}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (
        event.target.value == 0 &&
        this.state.actval == 0 &&
        this.state.catval != 0
      ) {
        // window.location.reload();
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=0&Year=${this.state.yearval}&CategoryID=${event.target.value}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (
        event.target.value == 0 &&
        this.state.actval != 0 &&
        this.state.catval == 0
      ) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${this.state.yearval}&CategoryID=${event.target.value}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else {
        this.setState({ value2: event.target.value });

        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${
          this.state.actval != 0 ? this.state.actval : 5
        }&Year=${this.state.yearval}&CategoryID=${event.target.value}`;

        // "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=5" +
        // --"https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=5" +
        //event.target.value+
        // this.state.Sort.value +
        // --"&Year=0" +
        //  this.state.Years.value +
        // --"&CategoryID=" + event.target.value;

        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        //
        // this.setState({ CategoryData: [] });
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },

          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
              CategoryData: this.AddImg(data),
            });

            // this.setState({
            //   // emps: data,
            //   emps: this.AddImg(data),

            //   CategoryData: this.AddImg(data),
            // });

            //
            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      }
    });
  };

  handleChangess = async (event) => {
    // setTimeout(() => {
    //   this.setState({
    //     visible: true,
    //   });
    //   setTimeout(() => {
    //     this.setState({
    //       visible: false,
    //     });
    //   }, 500);
    // }, 1000);
    if (this.state.PostLimit != 5) {
      this.setState({ PostLimit: 5 });
    }
    this.setState({ value3: event.target.value });
    this.setState({ actval: event.target.value });
    const promise = new Promise((resolve, reject) => {
      try {
        this.setState({
          emps: [],
          CategoryData: [],
        });
        resolve();
      } catch (error) {
        reject();
      }
    });
    promise.then(async () => {
      if (
        event.target.value == 0 &&
        this.state.yearval == 0 &&
        this.state.catval == 0
      ) {
        // alert("handlechangesss is called");
        let url =
          "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetail";
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (event.target.value == 0 && this.state.yearval != 0) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=6&Year=${this.state.yearval}&CategoryID=${this.state.catval}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else if (event.target.value == 0 && this.state.catval != 0) {
        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=5&Year=${this.state.yearval}&CategoryID=${this.state.catval}`;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }
          });
      } else {
        this.setState({ value3: event.target.value });

        let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${event.target.value}&Year=${this.state.yearval}&CategoryID=${this.state.catval}`;
        // "https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=" +
        // --"https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=" +
        // --event.target.value +
        // this.state.Sort.value +
        // --"&Year=0" +
        //  this.state.Years.value +
        // --"&CategoryID=0";

        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        //

        await fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },

          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              emps: this.AddImg(data),
              Activitydata: this.AddImg(data),
            });

            if (data.length == 0) {
              this.setState({
                visibledata: true,
                message: true,
              });
            } else if (data.length != 0) {
              this.setState({
                visibledata: false,
              });
            }

            //
          });
      }
    });
  };

  ReloadPage = () => {
    let reloadsmyactivity = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem("reloadsmyactivity") !== null) {
      reloadsmyactivity = sessionStorage.getItem("reloadsmyactivity");
      reloadsmyactivity++;
      window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    } else {
      reloadsmyactivity = 1;
      window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    }
    if (reloadsmyactivity == 1) {
      window.location.reload();
    }
  };

  DefaultMenu() {
    //
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);
    let reloadsmyactivity = 0;

    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("confirmpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("tagedit", reloadsmyactivity);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    let datatrans =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem("this.state.googtrans");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID = MID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    window.sessionStorage.setItem("translate", datatransID);

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Food and Nutrition&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var FoodandNutrtion =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available~Amount Ascending~Amount Descending&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Health = typeof items[0] !== "undefined" ? items[0] : null;
          var Professional = typeof items[1] !== "undefined" ? items[1] : null;
          var Sports = typeof items[2] !== "undefined" ? items[2] : null;
          var SocialWelfare = typeof items[3] !== "undefined" ? items[3] : null;
          var Others = typeof items[4] !== "undefined" ? items[4] : null;
          var Sort = typeof items[5] !== "undefined" ? items[5] : null;
          var MostRecent = typeof items[6] !== "undefined" ? items[6] : null;
          var MostPopular = typeof items[7] !== "undefined" ? items[7] : null;
          var Availabl80G = typeof items[8] !== "undefined" ? items[8] : null;
          var AmountAsc = typeof items[9] !== "undefined" ? items[9] : null;
          var AmountDesc = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Privacy Policy&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Complete = typeof items[0] !== "undefined" ? items[0] : null;
          var Incomplete = typeof items[1] !== "undefined" ? items[1] : null;
          var Howitwork = typeof items[2] !== "undefined" ? items[2] : null;
          var Blog = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[4] !== "undefined" ? items[4] : null;
          var Termsandcondition =
            typeof items[5] !== "undefined" ? items[5] : null;
          var CopyRight = typeof items[6] !== "undefined" ? items[6] : null;
          var Causes = typeof items[7] !== "undefined" ? items[7] : null;
          var Beneficiaries = typeof items[8] !== "undefined" ? items[8] : null;
          var PrivacyPolicy = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year~Activity&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Paropakaris = typeof items[0] !== "undefined" ? items[0] : null;
          var Amountraised = typeof items[1] !== "undefined" ? items[1] : null;
          var Educattion = typeof items[2] !== "undefined" ? items[2] : null;
          var Enddate = typeof items[3] !== "undefined" ? items[3] : null;
          var Tag = typeof items[4] !== "undefined" ? items[4] : null;
          var Like = typeof items[5] !== "undefined" ? items[5] : null;
          var Comment = typeof items[6] !== "undefined" ? items[6] : null;
          var Donateasmile = typeof items[7] !== "undefined" ? items[7] : null;
          var Year = typeof items[8] !== "undefined" ? items[8] : null;
          var Activity = typeof items[9] !== "undefined" ? items[9] : null;
          // Dhinesh Here
          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            like: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            activity: Activity,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=My Causes~Liked Causes~Commented Causes~Pledged Causes~View Details~Edit~Delete~Comment Here~Submit&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Mycauses = typeof items[0] !== "undefined" ? items[0] : null;
          var Likedcauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Commentedcauses =
            typeof items[2] !== "undefined" ? items[2] : null;
          var Pledgedcauses = typeof items[3] !== "undefined" ? items[3] : null;
          var ViewDetail = typeof items[4] !== "undefined" ? items[4] : null;
          var Edit = typeof items[5] !== "undefined" ? items[5] : null;
          var Delete = typeof items[6] !== "undefined" ? items[6] : null;
          var CommentHere = typeof items[7] !== "undefined" ? items[7] : null;
          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            langID: LangID,
            viewdetail: ViewDetail,
            edit: Edit,
            deletee: Delete,
            pledgedcauses: Pledgedcauses,
            CommentHere: CommentHere,
            Submit: Submit,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Want to Delete This Cause? Are You Sure?~Friends&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var WanttoDelete = typeof items[0] !== "undefined" ? items[0] : null;
          var Friends = typeof items[1] !== "undefined" ? items[1] : null;
          var LangID = datatransID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            friends: Friends,
            wanttodelete: WanttoDelete,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Food and Nutrition&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var FoodandNutrtion =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            foodandnutrition: FoodandNutrtion,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available~Amount Ascending~Amount Descending&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Health = typeof items[0] !== "undefined" ? items[0] : null;
          var Professional = typeof items[1] !== "undefined" ? items[1] : null;
          var Sports = typeof items[2] !== "undefined" ? items[2] : null;
          var SocialWelfare = typeof items[3] !== "undefined" ? items[3] : null;
          var Others = typeof items[4] !== "undefined" ? items[4] : null;
          var Sort = typeof items[5] !== "undefined" ? items[5] : null;
          var MostRecent = typeof items[6] !== "undefined" ? items[6] : null;
          var MostPopular = typeof items[7] !== "undefined" ? items[7] : null;
          var Availabl80G = typeof items[8] !== "undefined" ? items[8] : null;
          var AmountAsc = typeof items[9] !== "undefined" ? items[9] : null;
          var AmountDesc = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Complete = typeof items[0] !== "undefined" ? items[0] : null;
          var Incomplete = typeof items[1] !== "undefined" ? items[1] : null;
          var Howitwork = typeof items[2] !== "undefined" ? items[2] : null;
          var Blog = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[4] !== "undefined" ? items[4] : null;
          var Termsandcondition =
            typeof items[5] !== "undefined" ? items[5] : null;
          var CopyRight = typeof items[6] !== "undefined" ? items[6] : null;
          var Causes = typeof items[7] !== "undefined" ? items[7] : null;
          var Beneficiaries = typeof items[8] !== "undefined" ? items[8] : null;
          var PrivacyPolicy = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year~Activity&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Paropakaris = typeof items[0] !== "undefined" ? items[0] : null;
          var Amountraised = typeof items[1] !== "undefined" ? items[1] : null;
          var Educattion = typeof items[2] !== "undefined" ? items[2] : null;
          var Enddate = typeof items[3] !== "undefined" ? items[3] : null;
          var Tag = typeof items[4] !== "undefined" ? items[4] : null;
          var Like = typeof items[5] !== "undefined" ? items[5] : null;
          var Comment = typeof items[6] !== "undefined" ? items[6] : null;
          var Donateasmile = typeof items[7] !== "undefined" ? items[7] : null;
          var Year = typeof items[8] !== "undefined" ? items[8] : null;
          var Activity = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            like: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            activity: Activity,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=My Causes~Liked Causes~Commented Causes~Pledged Causes~View Details~Edit~Delete~Comment Here~Submit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Mycauses = typeof items[0] !== "undefined" ? items[0] : null;
          var Likedcauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Commentedcauses =
            typeof items[2] !== "undefined" ? items[2] : null;
          var Pledgedcauses = typeof items[3] !== "undefined" ? items[3] : null;
          var ViewDetail = typeof items[4] !== "undefined" ? items[4] : null;
          var Edit = typeof items[5] !== "undefined" ? items[5] : null;
          var Delete = typeof items[6] !== "undefined" ? items[6] : null;
          var CommentHere = typeof items[7] !== "undefined" ? items[7] : null;
          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            langID: LangID,
            viewdetail: ViewDetail,
            edit: Edit,
            deletee: Delete,
            pledgedcauses: Pledgedcauses,
            CommentHere: CommentHere,
            Submit: Submit,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Want to Delete This Cause? Are You Sure?~Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var WanttoDelete = typeof items[0] !== "undefined" ? items[0] : null;
          var Friends = typeof items[1] !== "undefined" ? items[1] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            friends: Friends,
            wanttodelete: WanttoDelete,
          });
        });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);

    this.isMountedFlag = true;
    this.submit();
    this.setState({ loading: false });

    window.addEventListener("load", this.notify);
    this.notify();
    setInterval(() => {
      this.notify();
    }, 5000);

    window.addEventListener("load", this.Footer);
    this.Footer();
    this.Footer();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const elements = document.getElementById("goog-gt-vt");
    if (elements) {
      elements.style.display = "none"; // Hide the element if it exists
    }
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "My Journey - paropakari";
    window.addEventListener("scroll", () => {
      const scrollTop = window.scrollY;
      const scrollHeight = document.documentElement.scrollHeight;
      const clientHeight = window.innerHeight;

      if (
        Math.round(scrollTop + clientHeight) >= Math.round(scrollHeight - 100)
      ) {
        this.setState((prevstate) => ({
          PostLimit: prevstate.PostLimit + 2,
        }));
      }
    });

    this.interval = setInterval(this.checkAndSetLanguage, 500); // Check every second
    this.checkAndSetLanguage(); // Initial check
  }
  checkAndSetLanguage = () => {
    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    const cookieLang =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    if (cookieLang !== this.state.previousCookieLang) {
      let newLanguage = "";
      let MID = "English";
      if (cookieLang === "en") {
        newLanguage = "English";
      } else if (cookieLang === "kn") {
        newLanguage = "Kannada";
      } else if (cookieLang === "ta") {
        newLanguage = "Tamil";
      } else if (cookieLang === "te") {
        newLanguage = "Telugu";
      } else if (cookieLang === "hi") {
        newLanguage = "Hindi";
      } else if (cookieLang === "es") {
        newLanguage = "Spanish";
      } else if (cookieLang === MID) {
        newLanguage = MID;
      }

      sessionStorage.setItem("translate", newLanguage);
      this.setState(
        { language: newLanguage, previousCookieLang: cookieLang },
        this.DefaultMenu()
      );
    }
  };
  componentWillUnmount() {
    // window.removeEventListener("resize", this.handleResize);

    clearTimeout(this.timer);
    // window.removeEventListener("load", this.submit);
    // this.submit();
    // window.removeEventListener("load", this.notify);
    // this.notify();
    // window.removeEventListener("load", this.Footer);
    // this.Footer();
    // window.addEventListener("load", this.ReloadPage);
    // this.ReloadPage();
    // window.removeEventListener("load", this.DefaultMenu);
    // this.DefaultMenu();
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleSubmit(event) {
    alert("Your favorite flavor is: " + this.state.selectValue);
    event.preventDefault();
  }

  handlecategoriesChange(e) {
    this.setState({ selectValue1: e.target.value });

    //
  }

  handleactivityChange(e) {
    this.setState({ selectValue2: e.target.value });

    //
  }

  searchTxt(e) {
    this.setState({ filter: e.target.value });
  }

  refreshcomment = () => {
    let YearDrop = document.getElementById("YearDrop").value;
    let CategoryDrop = document.getElementById("CategoryDrop").value;
    let ActivityDrop = document.getElementById("ActivityDrop").value;

    let url = `https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${ActivityDrop}&Year=${YearDrop}&CategoryID=${CategoryDrop}`;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())

      .then((data) => {
        this.setState({ loading: false });
        this.setState({
          emps: [],
          empsubmit: [],
        });
        if (CategoryDrop != 0 && YearDrop != 0) {
          let final = data.filter(
            (e) => e.year == YearDrop && e.category_id == CategoryDrop
          );

          this.setState({
            emps: this.AddImg(final),
            empsubmit: this.AddImg(final),
          });
        } else if (ActivityDrop != 0) {
          this.setState({
            emps: this.AddImg(data),
            empsubmit: this.AddImg(data),
          });
        } else if (CategoryDrop != 0) {
          let final = data.filter((e) => e.category_id == CategoryDrop);

          this.setState({
            emps: this.AddImg(final),
            empsubmit: this.AddImg(final),
          });
        } else if (YearDrop != 0) {
          let final = data.filter((e) => e.year == YearDrop);

          this.setState({
            emps: this.AddImg(final),
            empsubmit: this.AddImg(final),
          });
        } else {
          this.setState({
            emps: this.AddImg(data),
            empsubmit: this.AddImg(data),
          });
        }
      });
  };

  loadData = async (Id) => {
    let data = {};
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      let first_promise = new Promise((resolve, reject) => {
        if (user != null) {
          fetch(
            "https://apitest.paropakari.com/api/GetProfile/GetTop1ImagePreview?Id=" +
              Id,
            {
              headers: { Authorization: `Bearer ${accessToken}` },
            }
          )
            .then((response) => response.json())
            .then((receivedData) => {
              data["data1"] = receivedData;

              resolve(receivedData);
            });
        }
      });

      let second_promise = new Promise((resolve, reject) => {
        fetch(
          "https://apitest.paropakari.com/api/GetProfile/GetImagePreview?Id=" +
            Id,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
          .then((response) => response.json())
          .then((receivedData1) => {
            data["data"] = receivedData1;

            resolve(receivedData1);
            //setData(receivedData)
          });
      });
      try {
        let result = Promise.all([first_promise, second_promise]);
        result.then((data1) => {
          return data;
        });
      } catch (error) {}
    } catch (error) {}
  };

  render() {
    const {
      emps,
      empsubmit,
      message,
      Dataa,
      CategoryData,
      Activitydata,
      loading,
      PostLimit,
      PostLoading,
      isMobile
    } = this.state;

    let { foo, Menu } = this.state;
    const { notify } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      viewdetail,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      edit,
      deletee,
      search,
      categories,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      others,
      wanttodelete,
      friends,
      sort,
      mostrecent,
      mostpopular,
      available80g,
      amountdesc,
      privacypolicy,
      amountasc,
      complete,
      incomplete,
      enddate,
      tag,
      CommentHere,
      Submit,
      like,
      comment,
      donateasmile,
      year,
      activity,
      mycauses,
      likedcauses,
      commentedcauses,
      pledgedcauses,
      langID,
      copyright_new,
      paropakari_new,
    } = this.state;
    const currentYear = new Date().getFullYear();
    var username = JSON.parse(localStorage.username);
    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          {/*/head*/}
          <select
            className="form-control notranslate"
            style={{
              display: "none",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
            value={this.state.value}
            onChange={this.handleChanges}
          >
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          {/* <GoogleTranslate></GoogleTranslate>    */}
          {/* <form onSubmit={this.refreshPage} onChange={this.handleChanges}>  */}
          <div
            id="google_translate_element"
            onClick={this.refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          >
            {/* <button onClick={refreshPage}>Click to reload!</button> */}
          </div>
          {/* </form>   */}
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontSize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p
                        class="pro-center-tamil"
                        style={{ marginRight: "10px" }}
                      >
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Myactivity">
                            {myjourney.result}
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}} className="notranslate">    
<div class="navbar navbar-inverse" role="banner">
   <div class="container" id="containerid">
       <div class="navbar-header">
           <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
               <span class="sr-only">Toggle navigassstion</span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
           </button>
           <a class="navbar-brand" href="#/Homepage">
             <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontSize:'52px'}}  />AROPAKARI                   
               </h1>
           </a>           
       </div>
       <div class="collapse navbar-collapse">
           <ul class="nav navbar-nav navbar-right">
           <li id="paro" className="active ">                   
                   {(() => {if (langID == 'Tamil' ) {return (
                         <li id="paro" className="active tamilbtn "><a className="btn1 third" id="tamilbtn1"  href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
                  
                         {beaparopakari.result}
                         </a></li>
                  );
                  }
                  else if (langID == 'English' ) {return (
                    <li id="paro" className="active englishbtn"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
                  
                    {beaparopakari.result}
                    </a></li>
                  );
                  }
                  else if (langID == 'Hindi' ) {return (
                    <li id="paro" className="active hindibtn"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>                  
                    {beaparopakari.result}
                    </a></li>
                  );
                  }
                                   else {return(                  
                                    <li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>                 
                             {beaparopakari.result}
                             </a></li>                  
                  );}                  
                  })()}                  
                  </li>                
               <li className="active"><a href="#/Myactivity"><b>{myjourney.result}</b></a></li> 
               <li><a href="#/CauseFor"><b>{createacause.result}</b></a></li> 
               <li><a href="#/InviteFriend"><b>{invite.result}</b></a></li> 
               <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>                            
                          <div class="fa fa-bell" style={{fontSize:'24px'}}>{notify.map((notifyy) => (
                                               <span class="-count">                                                             
                                {notifyy.noticount} 
                                  </span> ))}</div>
                           </a></li> 
               <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                   <ul role="menu" class="sub-menu">
                       <li><a href="#/Profile"><b>{profile.result}</b></a></li>
                       <li><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                       <li><a href="#/Group"><b>{group.result}</b></a></li>
                       <li><a href="#/Login"><b>{logout.result}</b></a></li>
                   </ul>
               </li>                         
           </ul>
       </div>
   </div>
</div>
</header> */}
            <div>
              {/*/#page-breadcrumb*/}
              {/* <section id="blog" className="activitymy" style={{backgroundColor:'#aee6db'}}> */}
              <section
                id="blog"
                className={this.state.movedown == true ? null : "Tiptop"}
                style={{ backgroundColor: "#aee6db" }}
              >
                <div className="container">
                  {/* <Sampletest></Sampletest> */}
                  {/* <select
                      className="form-control notranslate "
                      id="Threebox"
                      style={{
                        display: "inline",
                        width: "15%",
                        marginTop: "1%",
                        marginLeft: "1%",
                      }}
                      value={this.state.value}
                      onChange={this.handleChange}
                    >
                      <option value="0">{year.result}</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                    </select>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    <select
                      className="form-control notranslate"
                      id="Threebox"
                      style={{ display: "inline", width: "15%" }}
                      value={this.state.value2}
                      onChange={this.handleChangesCategory}
                    >
                      <option value="0">{categories.result}</option>
                      <option value="8">{education.result}</option>
                      <option value="7">{foodandnutrition.result}</option>
                      <option value="6">{health.result}</option>
                      <option value="5">{professional.result}</option>
                      <option value="4">{socialwefare.result}</option>
                      <option value="3">{sports.result}</option>
                      <option value="2">{others.result}</option>
                    </select>
                    &nbsp;&nbsp; &nbsp;&nbsp;
                    <select
                      className="form-control notranslate"
                      id="Threebox"
                      style={{ display: "inline", width: "15%" }}
                      value={this.state.value3}
                      onChange={this.handleChangess}
                    >
                      <option value="0">{activity.result}</option>
                      <option value="11">{mycauses.result}</option>
                      <option value="12">{likedcauses.result}</option>
                      <option value="13">{commentedcauses.result}</option>
                      <option value="14">{pledgedcauses.result}</option>
                    </select> */}
                  <div class="filter-container notranslate">
                    <select
                      className="form-control notranslate "
                      id="YearDrop"
                      value={this.state.yearval}
                      onChange={this.handleChange}
                    >
                      <option value="0">{year.result}</option>
                      <option value="2024">2024</option>
                      <option value="2023">2023</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                    </select>

                    <select
                      className="form-control notranslate"
                      id="CategoryDrop"
                      value={this.state.catval}
                      onChange={(e) => this.handleChangesCategory(e)}
                    >
                      <option value="0">{categories.result}</option>
                      <option value="8">{education.result}</option>
                      <option value="7">{foodandnutrition.result}</option>
                      <option value="6">{health.result}</option>
                      <option value="5">{professional.result}</option>
                      <option value="4">{socialwefare.result}</option>
                      <option value="3">{sports.result}</option>
                      <option value="2">{others.result}</option>
                    </select>
                    <select
                      className="form-control notranslate"
                      id="ActivityDrop"
                      value={this.state.actval}
                      onChange={this.handleChangess}
                    >
                      <option value="0">{activity.result}</option>
                      <option value="11">{mycauses.result}</option>
                      <option value="12">{likedcauses.result}</option>
                      <option value="13">{commentedcauses.result}</option>
                      <option value="14">{pledgedcauses.result}</option>
                    </select>
                  </div>

                  {/* <select id="byYear" name="byYear" style={{width: '10%',marginLeft: '1%'}} required data-validation-required-message="required" className="col-lg-4 form-control ng-untouched ng-empty ng-dirty ng-valid-parse ng-invalid ng-invalid-required" ng-model="byYear">
  <option style={{display: 'none'}} disabled value selected="selected">By Year</option>
  <option value={2021}>2021</option>
  <option value={2020}>2020</option>
  <option value={2019}>2019</option>
  <option value={2018}>2018</option>
  <option value={2017}>2017</option>
</select>
<select id="categories" name="categories" style={{width: '18%',marginLeft: '1%'}} required data-validation-required-message="required" className="col-lg-4 form-control ng-untouched ng-empty ng-dirty ng-valid-parse ng-invalid ng-invalid-required" ng-model="categories">
  <option style={{display: 'none'}} disabled value selected="selected">By Purpose</option>
  <option value="Education">Education</option>
  <option value="Food and Nutrition">Food and Nutrition</option>
  <option value="Health">Health</option>
  <option value="Professional">Professional</option>
  <option value="Social welfare">Social welfare</option>
  <option value="Sports">Sports</option>
  <option value="80G">80G</option>
  <option value="Complete">Complete</option>
  <option value="Incomplete">Incomplete</option>
  <option value="Others">Others</option>
</select>
<select id="activity" name="activity" style={{width: '20%',marginLeft: '1%'}} required data-validation-required-message="required" className="col-lg-4 form-control ng-untouched ng-empty ng-dirty ng-valid-parse ng-invalid ng-invalid-required" ng-change="GetAllMyData(2);" ng-model="activity">
  <option style={{display: 'none'}} disabled value selected="selected">Activity</option>
  <option value={1}>My Causes</option>
  <option value={2}>Liked Causes</option>
  <option value={3}>Commented Causes</option>
  <option value={4}>Pledged Causes</option>
</select> */}

                  {/* /// */}

                  <div
                    className="inv-button notranslate"
                    id="notifysidebar"
                    style={{ marginLeft: "85.5%" }}
                  >
                    {(() => {
                      if (langID == "Tamil") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "English") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Hindi") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text notranslate">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Telugu") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Spanish") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Kannada") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/InviteFriend/" + langID}
                          >
                            <div className="inv-button1" id="button-pulse">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{invite.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Tamil") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "English") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Hindi") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text notranslate">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Telugu") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Kannada") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    {(() => {
                      if (langID == "Spanish") {
                        return (
                          <a
                            className="inv-text-link"
                            href={"#/CauseFor/" + langID}
                          >
                            <div className="inv-button1">
                              <p className="inv-text">
                                &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                              </p>
                            </div>
                          </a>
                        );
                      }
                    })()}
                    <div className="">
                      <a
                        onClick={this.topFunction}
                        id="myBtn"
                        title="Go to top"
                        style={{ display: "block", marginLeft: "75%" }}
                      >
                        <p
                          className="arrow-btn"
                          style={{
                            width: "100px",
                            color: "rgb(102, 71, 18)",
                          }}
                        >
                          <i
                            style={{
                              width: "100px",
                              color: "rgb(102, 71, 18)",
                            }}
                            class="fa1 fa-arrow-circle-up"
                          ></i>
                        </p>
                      </a>
                    </div>
                  </div>
                  {/* /// */}

                  {/* <p>{Dataa.length}</p> */}
                  {/* <p>{CategoryData.length}</p> */}

                  {loading ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "109%",
                        marginLeft: "-45%",
                        marginTop: "3%",
                      }}
                    >
                      {" "}
                      Loading...{" "}
                    </p>
                  ) : //      <div className="spinner" style={{height :'0%'}}>
                  //      <div style={{marginTop:'-3%'}}>
                  //      <MetroSpinner size={40} color="white"
                  //          loading={loading} />

                  //          </div>
                  //  </div>
                  Dataa.length == 0 &&
                    this.state.value &&
                    CategoryData.length == 0 &&
                    this.state.emps == 0 &&
                    this.state.value2 == 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          marginLeft: "25%",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        id="spanMsg"
                      >
                        No Results Found
                      </span>
                    </div>
                  ) : Dataa.length == 0 &&
                    this.state.value &&
                    CategoryData.length == 0 &&
                    this.state.emps == 0 &&
                    !this.state.value2 == 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          marginLeft: "25%",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        id="spanMsg"
                      >
                        No Results Found
                      </span>
                    </div>
                  ) : Dataa.length == 0 &&
                    this.state.value &&
                    this.state.emps == 0 &&
                    CategoryData.length != 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          marginLeft: "25%",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        id="spanMsg"
                      >
                        No Results Found
                      </span>
                    </div>
                  ) : CategoryData.length == 0 &&
                    this.state.value2 &&
                    this.state.emps == 0 &&
                    Dataa.length != 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          marginLeft: "25%",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        id="spanMsg"
                      >
                        No Results Found
                      </span>
                    </div>
                  ) : // this.state.emps.length == 0 ? (
                  //   <div style={{ marginTop: "5%" }}>
                  //     <span
                  //       style={{
                  //         marginLeft: "25%",
                  //         fontWeight: "bold",
                  //         color: "black",
                  //       }}
                  //       id="spanMsg"
                  //     >
                  //       No Results Found5
                  //     </span>
                  //   </div>
                  // )
                  // :
                  //  this.state.empsubmit.length !== 0 &&
                  this.state.emps.length == 0 &&
                    this.state.value2 !== 0 &&
                    CategoryData.length === 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          marginLeft: "25%",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        id="spanMsg"
                      >
                        No Results Found
                      </span>
                    </div>
                  ) : this.state.emps.length == 0 &&
                    this.state.value2 !== 0 &&
                    Activitydata.length === 0 ? (
                    <div style={{ marginTop: "5%" }}>
                      <span
                        style={{
                          marginLeft: "25%",
                          fontWeight: "bold",
                          color: "black",
                        }}
                        id="spanMsg"
                      >
                        No Results Found
                      </span>
                    </div>
                  ) : null}

                  {/* 
                        {
                      this.state.emps.length == 0  ? (
                        <div style={{ marginTop: "5%" }}>
                          <span
                            style={{
                              marginLeft: "25%",
                              fontWeight: "bold",
                              color: "black",
                            }}
                            id="spanMsg"
                          >
                            No Results Found5
                          </span>
                        </div>
                      ) 
                   :
                      null} */}

                  <br />
                  <div className="row">
                    {(() => {
                      if (this.state.visible == true) {
                        return null;
                      } else if (this.state.visibledata == true) {
                        return null;
                      } else if (this.state.emps.length == 0) {
                        return (
                          <div>
                            {/* 
                        {message && (<div style={{marginTop:'5%'}}>
  <span style={{marginLeft:'25%',fontWeight:'bold'}} id='Message'>No Results Found</span>
  </div>
  )}  */}

                            <div className="col-md-9 col-sm-7">
                              <div className="row">
                                {this.state.empsubmit
                                  .slice(0, PostLimit)
                                  .map((emp) => (
                                    //  <tr key={emp.user_id}>
                                    <div className="col-lg-12 col-md-12">
                                      {(() => {
                                        if (emp.profile_name == username) {
                                          return (
                                            <div>
                                              <h3 style={{ fontSize: "15px" }}>
                                                <a
                                                  href={
                                                    "#/Confirm/" + emp.upload_id
                                                  }
                                                  style={{
                                                    fontSize: "20px",
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{emp.upload_title}</b>
                                                </a>
                                              </h3>
                                              {(() => {
                                                if (emp.causefor_Id == "0") {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Required Amount{" "}
                                                      {emp.upload_amount}
                                                    </p>
                                                  );
                                                } else if (
                                                  emp.causefor_Id == "1"
                                                ) {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Number of Beneficiaries{" "}
                                                      {
                                                        emp.upload_num_of_beneficiaries
                                                      }
                                                    </p>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              <h3 style={{ fontSize: "15px" }}>
                                                <a
                                                  href={
                                                    "#/ResponsePage/" +
                                                    emp.upload_id
                                                  }
                                                  style={{
                                                    fontSize: "20px",
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{emp.upload_title}</b>
                                                </a>
                                              </h3>
                                              {(() => {
                                                if (emp.causefor_Id == "0") {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Required Amount{" "}
                                                      {emp.upload_amount}{" "}
                                                    </p>
                                                  );
                                                } else if (
                                                  emp.causefor_Id == "1"
                                                ) {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Number of Beneficiaries{" "}
                                                      {
                                                        emp.upload_num_of_beneficiaries
                                                      }
                                                    </p>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          );
                                        }
                                      })()}
                                      <div className="row">
                                        <div className="col-lg-4 col-sm-4">
                                          <p>{emp.category_name}</p>
                                        </div>
                                        <div className="col-lg-3 col-sm-3">
                                          <h4
                                            style={{
                                              fontSize: "15px",
                                              float: "right",
                                              color: "black",
                                            }}
                                          >
                                            <b>{emp.upload_place}</b>
                                          </h4>
                                        </div>
                                        <div className="col-lg-5 col-sm-5">
                                          <p></p>
                                        </div>
                                        <br />
                                      </div>
                                      <br />
                                      <div className="row" id="imagesss">
                                        {/* //////////////////////////////////// Using code////////////////////////////////  */}
                                        <br />
                                        {(() => {
                                          if (emp.profile_name == username) {
                                            return (
                                              <div className="col-lg-7 col-sm-7">
                                                {emp.file_type == ".png" ||
                                                emp.file_type == ".jpg" ||
                                                emp.file_type == ".jfi" ||
                                                emp.file_type == ".jfif" ||
                                                emp.file_type == ".JPG" ||
                                                emp.file_type == ".webp" ||
                                                emp.file_type == ".jpeg" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a
                                                      className="alttag"
                                                      href="#"
                                                      onClick={(e) =>
                                                        e.preventDefault()
                                                      }
                                                    >
                                                      {" "}
                                                      <embed
                                                        src={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        value={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        title=""
                                                        scrolling="no"
                                                        className="img-responsive"
                                                      ></embed>
                                                    </a>
                                                  </ImagePreview>
                                                ) : emp.file_type == ".docx" ||
                                                  emp.file_type == ".doc" ||
                                                  emp.file_type == ".xls" ||
                                                  emp.file_type === ".xlsx" ? (
                                                  // <a>
                                                  //   <iframe
                                                  //     className="img-responsive"
                                                  //     // src={emp.file_name + "#toolbar=0"}

                                                  //     // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                                                  //     src={
                                                  //       "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                                  //       emp.file_name //+  "&embedded=true"
                                                  //     }
                                                  //     // autoplay="0"
                                                  //     // width="100%"
                                                  //     height="300"
                                                  //   />
                                                  // </a>
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    {/* <a>
                                                  <video
                                                    width="100%"
                                                    height="300px"
                                                    controls
                                                    controlsList="nodownload"
                                                  >
                                                    <source
                                                      src={emp.file_name}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </a> */}
                                                  </ImagePreview>
                                                ) : emp.file_type == ".docx" ||
                                                  emp.file_type == ".doc" ? (
                                                  <a>
                                                    <iframe
                                                      className="img-responsive"
                                                      // src={emp.file_name + "#toolbar=0"}

                                                      // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                                                      src={
                                                        "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                                        emp.file_name //+  "&embedded=true"
                                                      }
                                                      // autoplay="0"
                                                      // width="100%"
                                                      height="300"
                                                    />
                                                  </a>
                                                ) : emp.file_type == ".mp4" ||
                                                  emp.file_type == ".webm" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a>
                                                      <video
                                                        width="100%"
                                                        height="300px"
                                                        controls
                                                        controlsList="nodownload"
                                                      >
                                                        <source
                                                          src={emp.file_name}
                                                          type="video/mp4"
                                                        />
                                                      </video>
                                                    </a>
                                                  </ImagePreview>
                                                ) : (
                                                  emp.file_type == ".pdf" && (
                                                    <ImagePreview
                                                      Id={emp.upload_id}
                                                      datavalue={emp.data}
                                                    ></ImagePreview>
                                                  )
                                                )}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="col-lg-7 col-sm-7">
                                                {emp.file_type == ".png" ||
                                                emp.file_type == ".jpg" ||
                                                emp.file_type == ".jfi" ||
                                                emp.file_type == ".jfif" ||
                                                emp.file_type == ".webp" ||
                                                emp.file_type == ".JPG" ||
                                                emp.file_type == ".jpeg" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a
                                                      className="alttag"
                                                      href="#"
                                                      onClick={(e) =>
                                                        e.preventDefault()
                                                      }
                                                    >
                                                      {" "}
                                                      <embed
                                                        src={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        value={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        title=""
                                                        scrolling="no"
                                                        className="img-responsive"
                                                      ></embed>
                                                    </a>
                                                  </ImagePreview>
                                                ) : emp.file_type == ".docx" ||
                                                  emp.file_type == ".doc" ||
                                                  emp.file_type == ".xls" ||
                                                  emp.file_type === ".xlsx" ? (
                                                  // <a>
                                                  //   <iframe
                                                  //     className="img-responsive"
                                                  //     // src={emp.file_name + "#toolbar=0"}

                                                  //     // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                                                  //     src={
                                                  //       "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                                  //       emp.file_name //+  "&embedded=true"
                                                  //     }
                                                  //     // autoplay="0"
                                                  //     // width="100%"
                                                  //     height="300"
                                                  //   />
                                                  // </a>
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  ></ImagePreview>
                                                ) : emp.file_type == ".mp4" ||
                                                  emp.file_type == ".webm" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a>
                                                      <video
                                                        width="100%"
                                                        height="300px"
                                                        controls
                                                        controlsList="nodownload"
                                                      >
                                                        <source
                                                          src={emp.file_name}
                                                          type="video/mp4"
                                                        />
                                                      </video>
                                                    </a>
                                                  </ImagePreview>
                                                ) : (
                                                  emp.file_type == ".pdf" && (
                                                    <ImagePreview
                                                      Id={emp.upload_id}
                                                      datavalue={emp.data}
                                                    ></ImagePreview>
                                                  )
                                                )}
                                              </div>
                                            );
                                          }
                                        })()}

                                        <br />
                                      </div>
                                      <br />

                                      <PostComponent
                                        emp={emp}
                                        enddate={enddate}
                                      />
                                      <div className="single-blog single-column">
                                        <div className="post-content overflow">
                                          {/* <p style={{width:'700px'}}>{emp.upload_description}</p>
<p><b className="notranslate">{enddate.result}</b> &nbsp;{emp.required_by}</p> */}
                                          {/* <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{display: 'block',borderColor: 'transparent',textAlign:'right',fontSize:'60px'}}>
<i class="fa fa-arrow-circle-up" style={{fontSize:'18px',color:'rgb(102, 71, 18)'}}></i>
</a> */}
                                          <div
                                            className="post-bottom overflow col-lg-7 col-sm-7 "
                                            id="tag-like-comment"
                                          >
                                            <div className="col-lg-12">
                                              <div className="col-lg-4">
                                                {(() => {
                                                  if (
                                                    emp.profile_name == username
                                                  ) {
                                                    return (
                                                      <a
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        <a
                                                          href={
                                                            "#/TagEdit/" +
                                                            emp.upload_id
                                                          }
                                                          style={{
                                                            textDecoration:
                                                              "none",
                                                            color: "black",
                                                          }}
                                                        >
                                                          <i className="fa fa-tag" />
                                                        </a>
                                                        <b className="">
                                                          &nbsp;{emp.tagcount}
                                                          &nbsp;
                                                          <TaggedFriends
                                                            // Translated={ tag.result ? tag.result : "Tags" }
                                                            trans={
                                                              tag.result
                                                                ? tag.result
                                                                : "Tags"
                                                            }
                                                            Id={emp.upload_id}
                                                            datavalue={emp.data}
                                                          >
                                                            {" "}
                                                          </TaggedFriends>
                                                        </b>
                                                      </a>
                                                    );
                                                  } else {
                                                    return (
                                                      <a
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        <i className="fa fa-tag" />
                                                        <b className="">
                                                          &nbsp;{emp.tagcount}{" "}
                                                          &nbsp;
                                                          <TaggedFriends
                                                            // Translated={ tag.result ? tag.result : "Tags" }
                                                            trans={
                                                              tag.result
                                                                ? tag.result
                                                                : "Tags"
                                                            }
                                                            Id={emp.upload_id}
                                                            datavalue={emp.data}
                                                          >
                                                            {" "}
                                                          </TaggedFriends>
                                                        </b>
                                                      </a>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                              <div className="col-lg-4">
                                                <a>
                                                  <form
                                                    onSubmit={(e) =>
                                                      this.AddLiking(e)
                                                    }
                                                  >
                                                    <input
                                                      type="hidden"
                                                      name="id"
                                                      onChange={
                                                        this.handlebenfChange
                                                      }
                                                      id="id"
                                                      defaultValue={
                                                        emp.upload_id
                                                      }
                                                    />
                                                    <button
                                                      type="submit"
                                                      className="fa "
                                                      style={{
                                                        display: "contents",
                                                        backgroundColor:
                                                          "rgb(174, 230, 219)",
                                                        fontSize: "inherit",
                                                        // textRendering: "auto",
                                                        WebkitFontSmoothing:
                                                          "antialiased",
                                                        transform:
                                                          "translate(0, 0)",
                                                        fontFamily: "cuyabra",
                                                      }}
                                                    >
                                                      {(() => {
                                                        if (emp.uid == "true") {
                                                          return (
                                                            <img
                                                              src="https://apitest.paropakari.com/Images/like.png"
                                                              alt="like"
                                                              className="fa"
                                                              style={{
                                                                display:
                                                                  "inline-block",
                                                                fontSize:
                                                                  "inherit",
                                                                textRendering:
                                                                  "auto",
                                                                WebkitFontSmoothing:
                                                                  "antialiased",
                                                                transform:
                                                                  "translate(0, 0)",
                                                                height: "16px",
                                                                width: "16px",
                                                                border: "none",
                                                              }}
                                                            />
                                                          );
                                                        } else {
                                                          return (
                                                            <img
                                                              src="https://apitest.paropakari.com/Images/liked.png"
                                                              alt="Unlike"
                                                              className="fa"
                                                              style={{
                                                                display:
                                                                  "inline-block",
                                                                fontSize:
                                                                  "inherit",
                                                                textRendering:
                                                                  "auto",
                                                                WebkitFontSmoothing:
                                                                  "antialiased",
                                                                transform:
                                                                  "translate(0, 0)",
                                                                height: "16px",
                                                                width: "16px",
                                                                border: "none",
                                                                outline: "none",
                                                              }}
                                                            />
                                                          );
                                                        }
                                                      })()}
                                                      &nbsp;
                                                      <b
                                                        className=""
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        {emp.likes} &nbsp;{" "}
                                                      </b>
                                                    </button>
                                                  </form>
                                                  <b style={{ color: "black" }}>
                                                    <Likepopup
                                                      Translated={
                                                        like.result
                                                          ? like.result
                                                          : "Like"
                                                      }
                                                      Id={emp.upload_id}
                                                      datavalue={emp.data}
                                                      refreshcomment={() =>
                                                        this.refreshcomment()
                                                      }
                                                    >
                                                      {" "}
                                                    </Likepopup>
                                                  </b>
                                                </a>
                                              </div>
                                              {(() => {
                                                if (
                                                  emp.profile_name == username
                                                ) {
                                                  return (
                                                    <div className="col-lg-4">
                                                      <a
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        <i className="fa fa-comments" />
                                                        <b className="">
                                                          &nbsp;{emp.comments}{" "}
                                                          &nbsp;
                                                          <Comment
                                                            Translated={
                                                              comment.result
                                                                ? comment.result
                                                                : "Comments"
                                                            }
                                                            Submit={
                                                              Submit.result
                                                                ? Submit.result
                                                                : "Submit"
                                                            }
                                                            CommentHere={
                                                              CommentHere.result
                                                                ? CommentHere.result
                                                                : "Comment Here"
                                                            }
                                                            Id={emp.upload_id}
                                                            datavalue={emp.data}
                                                            refreshcomment={() =>
                                                              this.refreshcomment()
                                                            }
                                                            Url={`https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${this.state.yearval}&CategoryID=${this.state.catval}`}
                                                            Data={(data) => {
                                                              return null;
                                                            }}
                                                          >
                                                            {" "}
                                                          </Comment>
                                                        </b>
                                                      </a>
                                                      {/* href={"#/Confirm/"+emp.upload_id}  */}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="col-lg-4">
                                                      <a
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        <i className="fa fa-comments" />
                                                        <b className="">
                                                          &nbsp;{emp.comments}
                                                          &nbsp;
                                                          <Comment
                                                            Translated={
                                                              comment.result
                                                                ? comment.result
                                                                : "Comments"
                                                            }
                                                            Submit={
                                                              Submit.result
                                                                ? Submit.result
                                                                : "Submit"
                                                            }
                                                            CommentHere={
                                                              CommentHere.result
                                                                ? CommentHere.result
                                                                : "Comment Here"
                                                            }
                                                            Id={emp.upload_id}
                                                            datavalue={emp.data}
                                                            refreshcomment={() =>
                                                              this.refreshcomment()
                                                            }
                                                            Url={`https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${this.state.yearval}&CategoryID=${this.state.catval}`}
                                                            Data={(data) => {
                                                              return null;
                                                            }}
                                                          >
                                                            {" "}
                                                          </Comment>
                                                        </b>
                                                      </a>
                                                      {/* href={"#/ResponsePage/"+emp.upload_id}  */}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          </div>
                                          <div className=" col-lg-7 col-sm-7  ">
                                            <div className="col-lg-12 notranslate">
                                              <div className="col-lg-4">
                                                {(() => {
                                                  if (
                                                    emp.profile_name ==
                                                      username &&
                                                    emp.confirmed == 0 &&
                                                    emp.pending == 0
                                                  ) {
                                                    return (
                                                      <a
                                                        href={
                                                          "#/EditPage/" +
                                                          emp.upload_id
                                                        }
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        <b>
                                                          <i
                                                            className="fa fa-edit"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                          &nbsp;&nbsp;
                                                          {edit.result}
                                                        </b>{" "}
                                                      </a>
                                                    );
                                                  } else {
                                                    return <a></a>;
                                                  }
                                                })()}
                                              </div>
                                              <div className="col-lg-4"></div>
                                              <div className="col-lg-4">
                                                {(() => {
                                                  if (
                                                    emp.profile_name ==
                                                      username &&
                                                    emp.confirmed == 0 &&
                                                    emp.pending == 0
                                                  ) {
                                                    return (
                                                      <b>
                                                        {" "}
                                                        <i
                                                          className="fa fa-trash-o"
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        />
                                                        <button
                                                          onClick={() => {
                                                            this.DeleteGroup(
                                                              emp.upload_id
                                                            );
                                                          }}
                                                          style={{
                                                            backgroundColor:
                                                              "transparent",
                                                            border: "none",
                                                            color: "black",
                                                          }}
                                                        >
                                                          &nbsp;{deletee.result}
                                                        </button>
                                                      </b>
                                                    );
                                                  } else {
                                                    return <a></a>;
                                                  }
                                                })()}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="col-lg-1"></div>
                                              <div className="col-lg-10 col-md-10">
                                                <ul className="nav navbar-nav post-nav">
                                                  {(() => {
                                                    if (
                                                      emp.confirmed >= "100" &&
                                                      emp.causefor_Id == 1
                                                    ) {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          style={
                                                            window.innerWidth <
                                                            768
                                                              ? styles.btnThirdResponsive
                                                              : styles.btnThird
                                                          }
                                                        >
                                                          <b
                                                            style={
                                                              window.innerWidth <
                                                              768
                                                                ? styles.btnThirdResponsive
                                                                : styles.btnThirdText
                                                            }
                                                          >
                                                            100% Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    } else if (
                                                      emp.confirmed1 >= "100" &&
                                                      emp.causefor_Id == 0
                                                    ) {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          style={
                                                            window.innerWidth <
                                                            768
                                                              ? styles.btnThirdResponsive
                                                              : styles.btnThird
                                                          }
                                                        >
                                                          <b
                                                            style={
                                                              window.innerWidth <
                                                              768
                                                                ? styles.btnThirdResponsive
                                                                : styles.btnThirdText
                                                            }
                                                          >
                                                            100% Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    } else if (
                                                      langID == "Tamil"
                                                    ) {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          style={
                                                            window.innerWidth <
                                                            768
                                                              ? styles.btnThirdResponsive
                                                              : styles.btnThird
                                                          }
                                                        >
                                                          {(() => {
                                                            if (
                                                              emp.profile_name ==
                                                              username
                                                            ) {
                                                              return (
                                                                <Link
                                                                  to={
                                                                    "/Confirm/" +
                                                                    emp.upload_id
                                                                  }
                                                                  style={
                                                                    window.innerWidth <
                                                                    768
                                                                      ? styles.linkResponsive
                                                                      : styles.link
                                                                  }
                                                                >
                                                                  &nbsp;
                                                                  <b
                                                                    style={
                                                                      window.innerWidth <
                                                                      768
                                                                        ? styles.btnThirdResponsive
                                                                        : styles.btnThirdText
                                                                    }
                                                                  >
                                                                    {
                                                                      viewdetail.result
                                                                    }
                                                                  </b>
                                                                </Link>
                                                              );
                                                            } else {
                                                              return (
                                                                <Link
                                                                  to={
                                                                    "/ResponsePage/" +
                                                                    emp.upload_id
                                                                  }
                                                                  style={
                                                                    window.innerWidth <
                                                                    768
                                                                      ? styles.linkResponsive
                                                                      : styles.link
                                                                  }
                                                                >
                                                                  &nbsp;
                                                                  <b
                                                                    className="notranslate"
                                                                    style={
                                                                      window.innerWidth <
                                                                      768
                                                                        ? styles.btnThirdResponsive
                                                                        : styles.btnThirdText
                                                                    }
                                                                  >
                                                                    {
                                                                      donateasmile.result
                                                                    }
                                                                  </b>
                                                                </Link>
                                                              );
                                                            }
                                                          })()}
                                                        </li>
                                                      );
                                                    } else {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          style={
                                                            window.innerWidth <
                                                            768
                                                              ? styles.btnThirdResponsive
                                                              : styles.btnThird
                                                          }
                                                        >
                                                          {(() => {
                                                            if (
                                                              emp.profile_name ==
                                                              username
                                                            ) {
                                                              return (
                                                                <Link
                                                                  to={
                                                                    "/Confirm/" +
                                                                    emp.upload_id
                                                                  }
                                                                  style={
                                                                    window.innerWidth <
                                                                    768
                                                                      ? styles.linkResponsive
                                                                      : styles.link
                                                                  }
                                                                >
                                                                  &nbsp;
                                                                  <b
                                                                    className="notranslate"
                                                                    style={
                                                                      window.innerWidth <
                                                                      768
                                                                        ? styles.btnThirdResponsive
                                                                        : styles.btnThirdText
                                                                    }
                                                                  >
                                                                    {
                                                                      viewdetail.result
                                                                    }
                                                                  </b>
                                                                </Link>
                                                              );
                                                            } else {
                                                              return (
                                                                <Link
                                                                  to={
                                                                    "/ResponsePage/" +
                                                                    emp.upload_id
                                                                  }
                                                                  style={
                                                                    window.innerWidth <
                                                                    768
                                                                      ? styles.linkResponsive
                                                                      : styles.link
                                                                  }
                                                                >
                                                                  &nbsp;
                                                                  <b
                                                                    className="notranslate"
                                                                    style={
                                                                      window.innerWidth <
                                                                      768
                                                                        ? styles.btnThirdResponsive
                                                                        : styles.btnThirdText
                                                                    }
                                                                  >
                                                                    {
                                                                      donateasmile.result
                                                                    }
                                                                  </b>
                                                                </Link>
                                                              );
                                                            }
                                                          })()}
                                                        </li>
                                                      );
                                                    }
                                                  })()}
                                                  {(() => {
                                                    if (
                                                      emp.confirmed <= "99" &&
                                                      emp.causefor_Id == 1
                                                    ) {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          style={
                                                            window.innerWidth <
                                                            768
                                                              ? styles.btnThirdResponsive
                                                              : styles.btnThird
                                                          }
                                                        >
                                                          <b
                                                            className=""
                                                            style={
                                                              window.innerWidth <
                                                              768
                                                                ? styles.btnThirdResponsive
                                                                : styles.btnThirdText
                                                            }
                                                          >
                                                            {emp.confirmed}%
                                                            Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    } else if (
                                                      emp.confirmed1 <= "99" &&
                                                      emp.causefor_Id == 0
                                                    ) {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          style={
                                                            window.innerWidth <
                                                            768
                                                              ? styles.btnThirdResponsive
                                                              : styles.btnThird
                                                          }
                                                        >
                                                          <b
                                                            className=""
                                                            style={
                                                              window.innerWidth <
                                                              768
                                                                ? styles.btnThirdResponsive
                                                                : styles.btnThirdText
                                                            }
                                                          >
                                                            {emp.confirmed1}%
                                                            Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    }
                                                  })()}
                                                </ul>
                                              </div>
                                              <div className="col-lg-1"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    // </tr>
                                  ))}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div id="allData">
                            <div className="col-md-9 col-sm-7">
                              <div className="row">
                                {this.state.emps
                                  .slice(0, PostLimit)
                                  .map((emp) => (
                                    //  <tr key={emp.user_id}>
                                    <div className="col-lg-12 col-md-12">
                                      {(() => {
                                        if (emp.profile_name == username) {
                                          return (
                                            <div>
                                              <h3 style={{ fontSize: "15px" }}>
                                                <a
                                                  href={
                                                    "#/Confirm/" + emp.upload_id
                                                  }
                                                  style={{
                                                    fontSize: "20px",
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{emp.upload_title}</b>
                                                </a>
                                              </h3>

                                              {(() => {
                                                if (emp.causefor_Id == "0") {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Required Amount{" "}
                                                      {emp.upload_amount}
                                                    </p>
                                                  );
                                                } else if (
                                                  emp.causefor_Id == "1"
                                                ) {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Number of Beneficiaries{" "}
                                                      {
                                                        emp.upload_num_of_beneficiaries
                                                      }
                                                    </p>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div>
                                              <h3 style={{ fontSize: "15px" }}>
                                                <a
                                                  href={
                                                    "#/ResponsePage/" +
                                                    emp.upload_id
                                                  }
                                                  style={{
                                                    fontSize: "20px",
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                >
                                                  <b>{emp.upload_title}</b>
                                                </a>
                                              </h3>
                                              {(() => {
                                                if (emp.causefor_Id == "0") {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Required Amount{" "}
                                                      {emp.upload_amount}
                                                    </p>
                                                  );
                                                } else if (
                                                  emp.causefor_Id == "1"
                                                ) {
                                                  return (
                                                    <p style={{ float: "" }}>
                                                      Number of Beneficiaries{" "}
                                                      {
                                                        emp.upload_num_of_beneficiaries
                                                      }
                                                    </p>
                                                  );
                                                }
                                              })()}
                                            </div>
                                          );
                                        }
                                      })()}
                                      <div className="row">
                                        <div className="col-lg-4 col-sm-4">
                                          <p>{emp.category_name}</p>
                                        </div>
                                        <div
                                          className="col-lg-3 col-sm-3"
                                          id="uploadplace"
                                        >
                                          <h4
                                            style={{
                                              fontSize: "15px",
                                              float: "right",
                                              color: "black",
                                            }}
                                          >
                                            <b>{emp.upload_place}</b>
                                          </h4>
                                        </div>
                                        <div className="col-lg-5 col-sm-5">
                                          <p></p>
                                        </div>
                                        <br />
                                      </div>
                                      <div className="row" id="imagessss">
                                        {(() => {
                                          if (emp.profile_name == username) {
                                            return (
                                              <div className="col-lg-7 col-sm-7">
                                                {emp.file_type == ".png" ||
                                                emp.file_type == ".jpg" ||
                                                emp.file_type == ".jfi" ||
                                                emp.file_type == ".webp" ||
                                                emp.file_type == ".jfif" ||
                                                emp.file_type == ".JPG" ||
                                                emp.file_type == ".jpeg" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a
                                                      className="alttag"
                                                      href="#"
                                                      onClick={(e) =>
                                                        e.preventDefault()
                                                      }
                                                    >
                                                      {" "}
                                                      <embed
                                                        src={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        value={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        title=""
                                                        scrolling="no"
                                                        className="img-responsive"
                                                      ></embed>
                                                    </a>
                                                  </ImagePreview>
                                                ) : emp.file_type == ".docx" ||
                                                  emp.file_type == ".doc" ||
                                                  emp.file_type == ".xls" ||
                                                  emp.file_type === ".xlsx" ? (
                                                  // <a>
                                                  //   <iframe
                                                  //     className="img-responsive"
                                                  //     // src={emp.file_name + "#toolbar=0"}

                                                  //     // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                                                  //     src={
                                                  //       "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                                  //       emp.file_name //+  "&embedded=true"
                                                  //     }
                                                  //     // autoplay="0"
                                                  //     // width="100%"
                                                  //     height="300"
                                                  //   />
                                                  // </a>
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    {/* <a>
                                                  <video
                                                    width="100%"
                                                    height="300px"
                                                    controls
                                                    controlsList="nodownload"
                                                  >
                                                    <source
                                                      src={emp.file_name}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </a> */}
                                                  </ImagePreview>
                                                ) : emp.file_type == ".mp4" ||
                                                  emp.file_type == ".webm" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a>
                                                      <video
                                                        width="100%"
                                                        height="300px"
                                                        controls
                                                        controlsList="nodownload"
                                                      >
                                                        <source
                                                          src={emp.file_name}
                                                          type="video/mp4"
                                                        />
                                                      </video>
                                                    </a>
                                                  </ImagePreview>
                                                ) : (
                                                  emp.file_type == ".pdf" && (
                                                    <ImagePreview
                                                      Id={emp.upload_id}
                                                      datavalue={emp.data}
                                                    >
                                                      {/* <a>
                                                      <embed
                                                        src={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        value={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        title=""
                                                        scrolling="no"
                                                        style={{
                                                          width: "100%",
                                                          height: "300px",
                                                          background: "white",
                                                        }}
                                                      ></embed>
                                                    </a> */}
                                                    </ImagePreview>
                                                  )
                                                )}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="col-lg-7 col-sm-7">
                                                {emp.file_type == ".png" ||
                                                emp.file_type == ".jpg" ||
                                                emp.file_type == ".jfi" ||
                                                emp.file_type == ".jfif" ||
                                                emp.file_type == ".JPG" ||
                                                emp.file_type == ".jpeg" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a
                                                      className="alttag"
                                                      href="#"
                                                      onClick={(e) =>
                                                        e.preventDefault()
                                                      }
                                                    >
                                                      {" "}
                                                      <embed
                                                        src={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        value={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        title=""
                                                        scrolling="no"
                                                        className="img-responsive"
                                                      ></embed>
                                                    </a>
                                                  </ImagePreview>
                                                ) : emp.file_type == ".docx" ||
                                                  emp.file_type == ".doc" ||
                                                  emp.file_type == ".xls" ||
                                                  emp.file_type === ".xlsx" ? (
                                                  // <a>
                                                  //   <iframe
                                                  //     className="img-responsive"
                                                  //     // src={emp.file_name + "#toolbar=0"}

                                                  //     // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                                                  //     src={
                                                  //       "https://view.officeapps.live.com/op/embed.aspx?src=" +
                                                  //       emp.file_name //+  "&embedded=true"
                                                  //     }
                                                  //     // autoplay="0"
                                                  //     // width="100%"
                                                  //     height="300"
                                                  //   />
                                                  // </a>
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    {/* <a>
                                                  <video
                                                    width="100%"
                                                    height="300px"
                                                    controls
                                                    controlsList="nodownload"
                                                  >
                                                    <source
                                                      src={emp.file_name}
                                                      type="video/mp4"
                                                    />
                                                  </video>
                                                </a> */}
                                                  </ImagePreview>
                                                ) : emp.file_type == ".mp4" ||
                                                  emp.file_type == ".webm" ? (
                                                  <ImagePreview
                                                    Id={emp.upload_id}
                                                    datavalue={emp.data}
                                                  >
                                                    <a>
                                                      <video
                                                        width="100%"
                                                        height="300px"
                                                        controls
                                                        controlsList="nodownload"
                                                      >
                                                        <source
                                                          src={emp.file_name}
                                                          type="video/mp4"
                                                        />
                                                      </video>
                                                    </a>
                                                  </ImagePreview>
                                                ) : (
                                                  emp.file_type == ".pdf" && (
                                                    <ImagePreview
                                                      Id={emp.upload_id}
                                                      datavalue={emp.data}
                                                    >
                                                      {/* <a>
                                                      <embed
                                                        src={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        value={
                                                          emp.file_name +
                                                          "#toolbar=0"
                                                        }
                                                        title=""
                                                        scrolling="no"
                                                        style={{
                                                          width: "100%",
                                                          height: "300px",
                                                          background: "white",
                                                        }}
                                                      ></embed>
                                                    </a> */}
                                                    </ImagePreview>
                                                  )
                                                )}
                                              </div>
                                            );
                                          }
                                        })()}

                                        <br />
                                      </div>
                                      <br />
                                      <PostComponent
                                        emp={emp}
                                        enddate={enddate}
                                      />
                                      {/* 
<div className="row">         
<div className="col-sm-6 col-md-6">
<p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black'}}>{emp.category_name}</a>
</p>
</div>
<div className="col-sm-6 col-md-6">
<p style={{width:'700px'}}><a style={{width:'50%',textDecoration:'none',color:'black', marginLeft:'2%'}}>{emp.upload_place}</a>
</p>
</div>
</div> */}
                                      <div className="single-blog single-column">
                                        <div className="post-content overflow">
                                          <div className="container-fluid">
                                            <div className="row">
                                              <div
                                                id="tag-like-comment"
                                                className="post-bottom overflow col-lg-7 col-sm-12 d-flex justify-content-between align-items-center"
                                              >
                                                {/* Tag Section */}
                                                <div className="d-flex align-items-center"
                                                id="homelike"
                                                style={
                                            
                                                  //   langID === 'Tamil' && isMobile < 500 ? 
                                                    
                                                  //   {
                                                  //     fontSize:'9.5px',
                                                  //   } 
                                                  //     :
                                                  //   {                                              
                                                  //     backgroundColor:''
                                                  // }
        
                                                  {
                                                    ...(langID === 'Tamil' && isMobile <= 500
                ? {
                    fontSize: '9.5px',
                    // Add more styles for this condition if needed
                }
                : {}),
            ...(isMobile >= 354 && isMobile <= 500
                ? {
                    // Styles for medium devices (e.g., tablets)
                    fontSize: '9.5px',
                    // Add more styles for this condition if needed
                }
                : {}),
            ...(isMobile < 354
                ? {
                    // Styles for large devices (e.g., desktops)
                    fontSize: '9.5px',
                    // Add more styles for this condition if needed
                }
                : {}),
                ...(isMobile > 314 && isMobile < 338
                  ? {
                      fontSize: '9.5px',
                      marginLeft:'0pc'
                  }
                  : {}),
        
                  ...(langID === 'Spanish' &&  isMobile > 314 && isMobile < 338
                    ? {
                        fontSize: '9px',
                        marginLeft:'-1pc'
                    }
                    : {}),
            // Other global styles can be added here
            backgroundColor: '', // Example: default background color
                                                  }
        
        
                                                }
                                                >
                                                  {(() => {
                                                    if (
                                                      emp.profile_name ==
                                                      username
                                                    ) {
                                                      return (
                                                        <a
                                                          style={{
                                                            color: "black",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <a
                                                            href={
                                                              "#/TagEdit/" +
                                                              emp.upload_id
                                                            }
                                                            style={{
                                                              textDecoration:
                                                                "none",
                                                              color: "black",
                                                            }}
                                                          >
                                                            <i className="fa fa-tag" />
                                                          </a>
                                                          <b className="">
                                                            &nbsp;{emp.tagcount}
                                                            &nbsp;
                                                            <TaggedFriends
                                                              // Translated={ tag.result ? tag.result : "Tags" }
                                                              trans={
                                                                tag.result
                                                                  ? tag.result
                                                                  : "Tags"
                                                              }
                                                              Id={emp.upload_id}
                                                              datavalue={
                                                                emp.data
                                                              }
                                                            >
                                                              {" "}
                                                            </TaggedFriends>
                                                          </b>
                                                        </a>
                                                      );
                                                    } else {
                                                      return (
                                                        <a
                                                          style={{
                                                            color: "black",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <i className="fa fa-tag" />
                                                          <b className="">
                                                            &nbsp;{emp.tagcount}{" "}
                                                            &nbsp;
                                                            <TaggedFriends
                                                              // Translated={ tag.result ? tag.result : "Tags" }
                                                              trans={
                                                                tag.result
                                                                  ? tag.result
                                                                  : "Tags"
                                                              }
                                                              Id={emp.upload_id}
                                                              datavalue={
                                                                emp.data
                                                              }
                                                            >
                                                              {" "}
                                                            </TaggedFriends>
                                                          </b>
                                                        </a>
                                                      );
                                                    }
                                                  })()}
                                                </div>

                                                {/* Like Section */}
                                                <div className="d-flex align-items-center"
                                                 style={
                                            
                                                  {
                                                    ...(langID === 'Tamil' && isMobile <= 500
                ? {
                    fontSize: '9.5px',
                }
                : {}),
            ...(isMobile >= 354 && isMobile <= 500
                ? {
                    fontSize: '9.5px',
                }
                : {}),
            ...(isMobile < 354
                ? {
                    fontSize: '9.5px',
                    marginLeft:'-1pc'
                }
                : {}),
      
                ...(langID === 'Spanish' &&  isMobile > 314 && isMobile < 338
                ? {
                    fontSize: '9px',
                    marginLeft:'-1pc'
                }
                : {}),
            backgroundColor: '',
            marginTop:'3px'
                                                  }
      
      
                                              }
                                                >
                                                  <a>
                                                    <form
                                                      onSubmit={(e) =>
                                                        this.AddLiking(e)
                                                      }
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      <input
                                                        type="hidden"
                                                        name="id"
                                                        onChange={
                                                          this.handlebenfChange
                                                        }
                                                        id="id"
                                                        defaultValue={
                                                          emp.upload_id
                                                        }
                                                      />
                                                      <button
                                                        type="submit"
                                                        className="fa "
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          background: "none",
                                                          fontSize: "inherit",
                                                          textRendering: "auto",
                                                          transform:
                                                            "translate(0px)",
                                                          fontFamily: "cuyabra",
                                                          border: "medium",
                                                          // marginLeft: "5px",
                                                          marginTop: "1px",
                                                        }}
                                                      >
                                                        {emp.uid == "true" ? (
                                                          <img
                                                            src="https://apitest.paropakari.com/Images/like.png"
                                                            alt="like"
                                                            className="fa"
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              fontSize:
                                                                "inherit",
                                                              textRendering:
                                                                "auto",
                                                              WebkitFontSmoothing:
                                                                "antialiased",
                                                              transform:
                                                                "translate(0, 0)",
                                                              height: "14px",
                                                              width: "14px",
                                                              // marginRight: "4px",
                                                              border: "none",
                                                              outline: "none",
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            src="https://apitest.paropakari.com/Images/liked.png"
                                                            alt="Unlike"
                                                            className="fa"
                                                            style={{
                                                              display:
                                                                "inline-block",
                                                              fontSize:
                                                                "inherit",
                                                              textRendering:
                                                                "auto",
                                                              WebkitFontSmoothing:
                                                                "antialiased",
                                                              transform:
                                                                "translate(0, 0)",
                                                              height: "14px",
                                                              width: "14px",
                                                              // marginRight: "4px",

                                                              border: "none",
                                                              outline: "none",
                                                            }}
                                                          />
                                                        )}
                                                        &nbsp;
                                                        <b
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        >
                                                          {emp.likes} &nbsp;{" "}
                                                        </b>
                                                      </button>
                                                    </form>
                                                    <b
                                                      style={{ color: "black" }}
                                                    >
                                                      <Likepopup
                                                        Translated={
                                                          like.result
                                                            ? like.result
                                                            : "Like"
                                                        }
                                                        Id={emp.upload_id}
                                                        datavalue={emp.data}
                                                        refreshcomment={() =>
                                                          this.refreshcomment()
                                                        }
                                                      >
                                                        {" "}
                                                      </Likepopup>
                                                    </b>
                                                  </a>
                                                </div>

                                                {/* Comment Section */}
                                                <div className="d-flex align-items-center"
                                                
                                                style={
                                            
                                                  {
                                                    ...(langID === 'Tamil' && isMobile <= 500
                ? {
                    fontSize: '9.5px',
                    // Add more styles for this condition if needed
                }
                : {}),
            ...(isMobile >= 354 && isMobile <= 500
                ? {
                    // Styles for medium devices (e.g., tablets)
                    fontSize: '9.5px',
                    // Add more styles for this condition if needed
                }
                : {}),
            ...(isMobile < 354
                ? {
                    // Styles for large devices (e.g., desktops)
                    fontSize: '9.5px',
                    // Add more styles for this condition if needed
                }
                : {}),
        
        
                ...(langID === 'Spanish' &&  isMobile > 314 && isMobile < 338
                ? {
                    fontSize: '9px',
                    marginLeft:'-1pc'
                }
                : {}),
            // Other global styles can be added here
            backgroundColor: '', // Example: default background color
                                                  }
        
        
                                              }
                                                >
                                                  {(() => {
                                                    if (
                                                      emp.profile_name ==
                                                      username
                                                    ) {
                                                      return (
                                                        <a
                                                          style={{
                                                            color: "black",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <i
                                                            className="fa fa-comments"
                                                            id="fa-comments"
                                                            style={{
                                                              marginRight:
                                                                "4px",
                                                            }}
                                                          />
                                                          <b id="cmtsty">
                                                            &nbsp;{emp.comments}
                                                            <Comment
                                                              Translated={
                                                                comment.result
                                                                  ? comment.result
                                                                  : "Comments"
                                                              }
                                                              Submit={
                                                                Submit.result
                                                                  ? Submit.result
                                                                  : "Submit"
                                                              }
                                                              CommentHere={
                                                                CommentHere.result
                                                                  ? CommentHere.result
                                                                  : "Comment Here"
                                                              }
                                                              Id={emp.upload_id}
                                                              datavalue={
                                                                emp.data
                                                              }
                                                              refreshcomment={() =>
                                                                this.refreshcomment()
                                                              }
                                                              Url={`https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${this.state.yearval}&CategoryID=${this.state.catval}`}
                                                              Data={(data) => {
                                                                return null;
                                                              }}
                                                            ></Comment>
                                                          </b>
                                                        </a>
                                                      );
                                                    } else {
                                                      return (
                                                        <a
                                                          style={{
                                                            color: "black",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <i className="fa fa-comments" />
                                                          <b className="">
                                                            &nbsp;{emp.comments}
                                                            &nbsp;
                                                            <Comment
                                                              Translated={
                                                                comment.result
                                                                  ? comment.result
                                                                  : "Comments"
                                                              }
                                                              Submit={
                                                                Submit.result
                                                                  ? Submit.result
                                                                  : "Submit"
                                                              }
                                                              CommentHere={
                                                                CommentHere.result
                                                                  ? CommentHere.result
                                                                  : "Comment Here"
                                                              }
                                                              Id={emp.upload_id}
                                                              datavalue={
                                                                emp.data
                                                              }
                                                              refreshcomment={() =>
                                                                this.refreshcomment()
                                                              }
                                                              Url={`https://apitest.paropakari.com/api/GetProfile/Get_MyPostDetails?id=${this.state.actval}&Year=${this.state.yearval}&CategoryID=${this.state.catval}`}
                                                              Data={(data) => {
                                                                return null;
                                                              }}
                                                            >
                                                              {" "}
                                                            </Comment>
                                                          </b>
                                                        </a>
                                                      );
                                                    }
                                                  })()}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className=" col-lg-7 col-sm-7  ">
                                            <div
                                              id="editdlt"
                                              className="col-lg-12 notranslate"
                                              style={
                                            
                                                //   langID === 'Tamil' && isMobile < 500 ? 
                                                  
                                                //   {
                                                //     fontSize:'9.5px',
                                                //   } 
                                                //     :
                                                //   {                                              
                                                //     backgroundColor:''
                                                // }
      
                                                {
                                                  ...(langID === 'Tamil' && isMobile <= 500
              ? {
                  fontSize: '10px',
                  // Add more styles for this condition if needed
              }
              : {}),
          ...(isMobile >= 354 && isMobile <= 500
              ? {
                  // Styles for medium devices (e.g., tablets)
                  fontSize: '9.5px',
                  // Add more styles for this condition if needed
              }
              : {}),
          ...(isMobile < 354
              ? {
                  // Styles for large devices (e.g., desktops)
                  fontSize: '9.5px',
                  // Add more styles for this condition if needed
              }
              : {}),
              ...(isMobile > 314 && isMobile < 338
                ? {
                    fontSize: '9.5px',
                    marginLeft:'0pc'
                }
                : {}),
      
                ...(langID === 'Spanish' &&  isMobile > 314 && isMobile < 338
                  ? {
                      fontSize: '10px',
                      marginLeft:'-1pc'
                  }
                  : {}),
          // Other global styles can be added here
          backgroundColor: '', // Example: default background color
                                                }
      
      
                                              }
                                            >
                                              <div className="col-lg-4">
                                                {(() => {
                                                  if (
                                                    emp.profile_name ==
                                                      username &&
                                                    emp.confirmed == 0 &&
                                                    emp.pending == 0
                                                  ) {
                                                    return (
                                                      <a
                                                        href={
                                                          "#/EditPage/" +
                                                          emp.upload_id
                                                        }
                                                        style={{
                                                          color: "black",
                                                        }}
                                                      >
                                                        <b>
                                                          <i
                                                            className="fa fa-edit"
                                                            style={{
                                                              color: "black",
                                                            }}
                                                          />
                                                          &nbsp;&nbsp;
                                                          {edit.result}
                                                        </b>{" "}
                                                      </a>
                                                    );
                                                  } else {
                                                    return <a></a>;
                                                  }
                                                })()}
                                              </div>
                                              <div className="col-lg-4"></div>
                                              <div className="col-lg-4">
                                                {(() => {
                                                  if (
                                                    emp.profile_name ==
                                                      username &&
                                                    emp.confirmed == 0 &&
                                                    emp.pending == 0
                                                  ) {
                                                    return (
                                                      <b>
                                                        {" "}
                                                        <i
                                                          className="fa fa-trash-o"
                                                          style={{
                                                            color: "black",
                                                          }}
                                                        />
                                                        <button
                                                          onClick={() => {
                                                            this.DeleteGroup(
                                                              emp.upload_id
                                                            );
                                                          }}
                                                          style={{
                                                            backgroundColor:
                                                              "transparent",
                                                            border: "none",
                                                            color: "black",
                                                          }}
                                                        >
                                                          &nbsp;{deletee.result}
                                                        </button>
                                                      </b>
                                                    );
                                                  } else {
                                                    return <a></a>;
                                                  }
                                                })()}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="col-lg-1"></div>
                                              <div className="col-lg-10 col-md-10">
                                                <ul
                                                  id="postnav"
                                                  className={`nav navbar-nav post-nav ${
                                                    langID === "Tamil"
                                                      ? "tamil"
                                                      : ""
                                                  }`}
                                                >
                                                  {(() => {
                                                    if (
                                                      emp.confirmed >= "100" &&
                                                      emp.causefor_Id == 1
                                                    ) {
                                                      return (
                                                        <li className="btn1 third">
                                                          <b
                                                            className=""
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            100% Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    } else if (
                                                      emp.confirmed1 >= "100" &&
                                                      emp.causefor_Id == 0
                                                    ) {
                                                      return (
                                                        <li className="btn1 third">
                                                          <b
                                                            className=""
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            100% Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    } else if (
                                                      langID == "Tamil"
                                                    ) {
                                                      return (
                                                        <>
                                                          {emp.profile_name ===
                                                          username ? (
                                                            <li className="btn1 third donate-btn">
                                                              <Link
                                                                to={
                                                                  "/Confirm/" +
                                                                  emp.upload_id
                                                                }
                                                              >
                                                                &nbsp;
                                                                <b
                                                                  className="notranslate"
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                  }}
                                                                >
                                                                  {
                                                                    viewdetail.result
                                                                  }
                                                                </b>
                                                              </Link>
                                                            </li>
                                                          ) : (
                                                            <li
                                                              // className="btn1 third" id="Donatesmilebutton" style={responsiveStyles}
                                                              className="btn1 third donate-btn left-align"
                                                            >
                                                              <Link
                                                                to={
                                                                  "/ResponsePage/" +
                                                                  emp.upload_id
                                                                }
                                                              >
                                                                &nbsp;
                                                                <b
                                                                  className="notranslate"
                                                                  style={{
                                                                    color:
                                                                      "white",
                                                                  }}
                                                                >
                                                                  {
                                                                    donateasmile.result
                                                                  }
                                                                </b>
                                                              </Link>
                                                            </li>
                                                          )}
                                                        </>
                                                      );
                                                    } else {
                                                      return (
                                                        <li
                                                          className="btn1 third"
                                                          id="donatesmileb"
                                                        >
                                                          {(() => {
                                                            if (
                                                              emp.profile_name ==
                                                              username
                                                            ) {
                                                              return (
                                                                <Link
                                                                  to={
                                                                    "/Confirm/" +
                                                                    emp.upload_id
                                                                  }
                                                                >
                                                                  &nbsp;
                                                                  <b
                                                                    className="notranslate"
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                    }}
                                                                  >
                                                                    {
                                                                      viewdetail.result
                                                                    }
                                                                  </b>
                                                                </Link>
                                                              );
                                                            } else {
                                                              return (
                                                                <Link
                                                                  to={
                                                                    "/ResponsePage/" +
                                                                    emp.upload_id
                                                                  }
                                                                >
                                                                  &nbsp;
                                                                  <b
                                                                    className="notranslate"
                                                                    style={{
                                                                      color:
                                                                        "white",
                                                                    }}
                                                                  >
                                                                    {
                                                                      donateasmile.result
                                                                    }
                                                                  </b>
                                                                </Link>
                                                              );
                                                            }
                                                          })()}
                                                        </li>
                                                      );
                                                    }
                                                  })()}

                                                  {(() => {
                                                    if (
                                                      emp.confirmed <= "99" &&
                                                      emp.causefor_Id == 1
                                                    ) {
                                                      return (
                                                        <li className="btn1 third">
                                                          <b
                                                            className=""
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {emp.confirmed}%
                                                            Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    } else if (
                                                      emp.confirmed1 <= "99" &&
                                                      emp.causefor_Id == 0
                                                    ) {
                                                      return (
                                                        <li className="btn1 third">
                                                          <b
                                                            className=""
                                                            style={{
                                                              color: "white",
                                                            }}
                                                          >
                                                            {emp.confirmed1}%
                                                            Achieved
                                                          </b>
                                                        </li>
                                                      );
                                                    }
                                                  })()}
                                                </ul>
                                                <style jsx>{`
                                                  .nav.navbar-nav.post-nav {
                                                    display: flex;
                                                    flex-wrap: nowrap; /* Single row on large screens */
                                                    justify-content: space-between;
                                                    padding: 0;
                                                    list-style-type: none;
                                                    margin-top: 2pc;
                                                  }

                                                  .nav.navbar-nav.post-nav.tamil
                                                    .btn1.third {
                                                    margin-left: -15px;
                                                  }

                                                  .nav.navbar-nav.post-nav
                                                    .btn1.third {
                                                    background-color: #664816;
                                                    padding: 10px;
                                                    border-radius: 15px;
                                                    display: flex;
                                                    align-items: center;
                                                    justify-content: center;
                                                    width: 100%; /* Full width in flex column mode */
                                                    height: 40px; /* Fixed height */
                                                    box-sizing: border-box;
                                                    margin: 5px 20px; /* Margin to create space between items in column mode */
                                                    white-space: nowrap; /* Prevent text wrapping */
                                                    left: 4pc;
                                                  }

                                                  .nav.navbar-nav.post-nav
                                                    .btn1.third.left-align {
                                                    margin-left: -15px;
                                                  }
                                                  @media (min-width: 250px) and (max-width: 500px) {
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      left: 1.5pc;
                                                      height: auto;
                                                    }

                                                    .nav.navbar-nav.post-nav {
                                                      flex-direction: column; /* Change to column layout on small screens */
                                                      align-items: flex-start; /* Align items to start */
                                                    }

                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      font-size: 10px;
                                                      width: 50%; /* Full width */
                                                      white-space: normal; /* Allow text wrapping */
                                                      word-wrap: break-word; /* Optional: break long words */
                                                      text-align: center;
                                                      margin-left: 0;
                                                    }

                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third
                                                      .donate-btn,
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third
                                                      .achieved {
                                                      font-size: 10px;
                                                      margin-top: 10px;
                                                    }
                                                  }

                                                  @media (min-width: 500px) and (max-width: 768px) {
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      width: 50%;
                                                      left: 0.4pc;
                                                      white-space: normal; /* Allow text wrapping */
                                                      word-wrap: break-word; /* Optional: break long words */
                                                      text-align: center;
                                                    }
                                                    .nav.navbar-nav.post-nav {
                                                      display: flex;
                                                      flex-direction: column;
                                                    }
                                                  }
                                                  @media (width: 500px) {
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      width: 100%;
                                                      left: 0.4pc;
                                                    }
                                                  }
                                                  @media (width: 768px) {
                                                    .nav.navbar-nav.post-nav {
                                                      display: flex;
                                                      flex-direction: row;
                                                    }
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      width: auto;
                                                    }
                                                  }
                                                  @media (min-width: 768px) and (max-width: 992px) {
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      left: 0.9pc;
                                                    }
                                                  }

                                                  @media (min-width: 992px) and (max-width: 1200px) {
                                                    .nav.navbar-nav.post-nav
                                                      .btn1.third {
                                                      left: 3.5pc;
                                                    }
                                                  }
                                                `}</style>
                                              </div>
                                              <div className="col-lg-1"></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    // </tr>
                                  ))}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })()}

                    {/* Dhinesh */}
                    <div
                      className="col-md-1 col-sm-1"
                      style={{ fontSize: "25px", marginTop: "30%" }}
                    >
                      <div
                        style={{ position: "fixed", fontSize: "25px" }}
                      ></div>
                    </div>
                  </div>
                </div>
                {PostLoading ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "109%",
                      paddingBottom: "3vh",
                      marginBottom: "0px",
                    }}
                  >
                    {" "}
                    Loading...{" "}
                  </p>
                ) : null}
              </section>
            </div>
            {/*/#blog*/}
            <footer id="footer" style={{ color: "white" }}>
              <br />
              <div className="container notranslate">
                <div className="copyright">
                  <div className="row col-lg-12">
                    <div className="col-lg-9 mb-6">
                      <p>
                        {copyright_new.result} &nbsp;{currentYear} ©{" "}
                        {paropakari_new.result} |&nbsp;
                        <a
                          href="#/ContactusLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {contactus.result}&nbsp;
                        </a>
                        |{" "}
                        <a
                          href="#/PrivacyPolicyLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{privacypolicy.result}&nbsp;
                        </a>
                        |
                        <a
                          href="#/TermsandConditionLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{termsandcondition.result}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div className="social-icons" style={{ color: "white" }}>
                        <ul className="nav nav-pills">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/beoneparopakari"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                            >
                              <i className="fa fa-youtube-play" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/paropakari_beone/"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/paropakari/"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss"></div>
            </footer>
            {/*/#footer*/}
          </div>
        </div>
      </div>
    );
  }
}
