import React, { Component } from "react";
import $ from "jquery";
import Dropdowns from "./Dropdowns";
import Taggroup from "./Taggroup";
import { BASE_URLs } from '../Constants/BaseURL';
import Select from "react-select";
import Cookies from "js-cookie";
import LogoutLink from "./LogoutLink";

$(document).ready(function () {
  $(".theme-loader1").fadeOut(1000);
});
// const refreshPage = (e) => {

//   e.preventDefault();
//   setTimeout(() => window.location.reload(), 2000);

// }
export default class TagEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: [],
      selectedGroup: [],
      formErrors: {},
      user_id: "",
      profile_imagePath: "",
      profile_name: "",
      upload_id: "",
      created_date: "",
      upload_description: "",
      upload_amount: "",
      upload_place: "",
      OneRequired: [],
      upload_title: "",
      status_80G: "",
      upload_num_of_beneficiaries: "",
      category_id: "",
      category_name: "",
      delete_status: false,
      file_name: "",
      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      response_pledge: "",
      response_message: "",
      tagfriend: "",
      GRequired: [],
      TagS: [],
      // TagSno:[],
      HRequired: [],
      emps: [],
      comm: [],
      like: [],
      paropakaris: [],
      Tagged: [],
      notify: [],
      Liked: [],
      privacypolicy: [],
      foo: [],
      clm: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      search: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      others: [],
      sort: [],
      mostrecent: [],
      mostpopular: [],
      available80g: [],
      amountdesc: [],
      amountasc: [],
      complete: [],
      incomplete: [],
      education: [],
      enddate: [],
      causes: [],
      share: [],
      Submit: [],
      CommentHere: [],
      beneficiaries: [],
      friends: [],
      paropakariss: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      tag: [],
      likess: [],
      comment: [],
      donateasmile: [],
      year: [],
      activity: [],
      mycauses: [],
      likedcauses: [],
      commentedcauses: [],
      pledgedcauses: [],
      langID: [],
      HappinessMeter: [],
      ihaveconfirm: [],
      Achieved: [],
      OnItsWay: [],
      Names: [],
      MobileNumbers: [],
      Email: [],
      Pledge: [],
      Message: [],
      Responsedate: [],
      confirmed: [],
      Action: [],
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      taggedOption: [],
      Dropdownlist: [],
      Friendlist: [],
      setSelectedValue: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      shouldReload: true,
      //cookie
      language: "",
      previousCookieLang: "",
      newlyAddedGroup: [],
      newlyAddedFriend:[],
    };
    this.handleChangessss = this.handleChangessss.bind(this);
    this.handleChangess = this.handleChangess.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleMsg = this.handleMsg.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
    // name = oldName == null ? "English" : name;

    // VIpgJd-ZVi9od-vH1Gmf-ibnC6b

    // if (banner) {
    //   banner.style.display = "none";
    // }
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login",
    };
    this.props.history.push(page);
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  handleFormValidation() {
    const { tagfriend } = this.state;
    let formErrors = {};
    var tgroup = document.getElementById("Tag Group").value;
    // var causeID = JSON.parse(localStorage.id);
    let formIsValid = true;

    if (!tgroup) {
      //  alert('Required')
      // formIsValid = false;
      // formErrors["gErr"] = "Tagged group Required.";
    }

    // this.setState({ formErrors: formErrors });
    //
    // return formIsValid;
  }

  submit = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" +
      window.location.href.split("/")[5];
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          emps: data,
        });

        //
      });
  };

  // tag= () => {
  //
  //   const urll = new URL(window.location.href);
  //   const Id = urll.searchParams.get("upload_id");
  //
  //

  //   let url = "https://apitest.paropakari.com/api/GetProfile/GetTaggedfnd?id=" +window.location.href.split("/")[5];
  //   let data = this.state;
  //   let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //
  //
  //   fetch(url, {
  //           method: 'GET',
  //           headers: {
  //               "Content-type": "application/json",
  //               "Accept": "application/json",
  //               Authorization: "Bearer " + accessToken,
  //               "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //           },
  //           //body:JSON.stringify(data)
  //       }).then(response => response.json())
  //       .then(data => {
  //           this.setState({
  //               emps: data
  //           });
  //
  //          //
  //       });
  // }

  handleChangessss(value, actionDetails) {
    let selectedItems = [...this.state.selectedOption];
    let newlyAddedItems = [...this.state.newlyAddedFriend];

    switch (actionDetails.action) {
      case "remove-value":
      case "pop-value":
        if (selectedItems) {
          const index = selectedItems.findIndex(
            (item) =>
              item.label === actionDetails.removedValue.label &&
              item.value === actionDetails.removedValue.value
          );
          if (index > -1) {
            selectedItems.splice(index, 1);
            // Also remove from newly added items
            newlyAddedItems = newlyAddedItems.filter(
              (item) => item.value !== actionDetails.removedValue.value
            );
          }
        }
        break;

      case "select-option":
        selectedItems.push(actionDetails.option);
        newlyAddedItems.push(actionDetails.option);
        break;

      case "clear":
        selectedItems = [];
        newlyAddedItems = [];
        break;

      default:
        break;
    }

    this.setState({ selectedOption: selectedItems, newlyAddedFriend: newlyAddedItems });
  }

  tag = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/GetTaggedfnd?id=" +
      window.location.href.split("/")[5];

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Details: data,
        });
        if (data[0].profile_name !== null) {
          this.getValues(data);
        }
      });
  };

  ////////////////////tga group

  handleChangess(value, actionDetails) {
    let selectedItems = [...this.state.selectedGroup];
    let newlyAddedItems = [...this.state.newlyAddedGroup];

    switch (actionDetails.action) {
      case "remove-value":
      case "pop-value":
        if (selectedItems) {
          const index = selectedItems.findIndex(
            (item) =>
              item.label === actionDetails.removedValue.label &&
              item.value === actionDetails.removedValue.value
          );
          if (index > -1) {
            selectedItems.splice(index, 1);
            // Also remove from newly added items
            newlyAddedItems = newlyAddedItems.filter(
              (item) => item.value !== actionDetails.removedValue.value
            );
          }
        }
        break;

      case "select-option":
        selectedItems.push(actionDetails.option);
        newlyAddedItems.push(actionDetails.option);
        break;

      case "clear":
        selectedItems = [];
        newlyAddedItems = [];
        break;

      default:
        break;
    }

    this.setState({ selectedGroup: selectedItems, newlyAddedGroup: newlyAddedItems });
  }

  getValues = (apiValues) => {
    let previousValue = [];
    let names = apiValues[0].profile_name.split(",");
    let imgs = apiValues[0].profile_imagepath.split(",");
    let ids = apiValues[0].user_id.split(",");
    let index = 0;

    for (let i = 0; i < names.length; i++) {
      let data = {
        value: ids[i].toString(),
        label: (
          <div class="tag-image-container">
            <img className="tag-image" width="30" src={imgs[i]} /> {names[i]}
          </div>
        ),
      };
      previousValue.push(data);
    }
    this.setState({ selectedOption: previousValue });
  };

  getValue = (apiValuess) => {
    let previousValue = [];
    // let names = apiValuess[0].group_Name.split(",");
    // let imgs = apiValuess[0].imagepath.split(",");
    // let ids = apiValuess[0].group_id.toString().split(",");
    // let index = 0;

    apiValuess.map((e) => {
      let data = {
        value: e.group_id,
        label: (
          <div class="tag-image-container">
            <img className="tag-image" width="30" src={e.imagePath} />{" "}
            {e.group_Name}
          </div>
        ),
      };
      previousValue.push(data);
    });

    // for(let i=0; i<names.length; i++){
    //   let data = { value: ids[i].toString(), label: <div><img className="tag-image" width="30" src={imgs[i]} /> {names[i]}</div>  };
    //   previousValue.push(data);
    // }

    this.setState({ selectedGroup: previousValue });

    if (!this.state.selectedGroup) {
      //  alert('Required')
    }
  };
  ReloadPage = () => {
    let reloadsmyactivity = 0;
    if (sessionStorage.getItem("responsepagereload") !== null) {
      reloadsmyactivity = sessionStorage.getItem("responsepagereload");
      reloadsmyactivity++;

      window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    } else {
      reloadsmyactivity = 1;
      window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    }

    alert(reloadsmyactivity);
    if (reloadsmyactivity == 1) {
      window.location.reload();
    }
  };
  taggrp = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/GetTaggedGrp?id=" +
      window.location.href.split("/")[5];

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // if (data[0].group_id !== null) {
        let previousValue = [];
        // let names = apiValuess[0].group_Name.split(",");
        // let imgs = apiValuess[0].imagepath.split(",");
        // let ids = apiValuess[0].group_id.toString().split(",");
        // let index = 0;

        data.map((e) => {
          let data1 = {
            value: e.group_id,
            label: (
              <div class="tag-image-container">
                <img className="tag-image" width="30" src={e.imagePath} />{" "}
                {e.group_Name}
              </div>
            ),
          };
          previousValue.push(data1);
        });

        // for(let i=0; i<names.length; i++){
        //   let data = { value: ids[i].toString(), label: <div><img className="tag-image" width="30" src={imgs[i]} /> {names[i]}</div>  };
        //   previousValue.push(data);
        // }

        this.setState({ selectedGroup: previousValue });
        // }
        this.setState({
          Details1: data,
        });
      });
  };

  notify = () => {
    const { emps } = this.state;
    {
      emps.map((emp) => <div>Akila {emp.user_id}</div>);
    }
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        //
      });
  };

  comment = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_Comment?id=" +
      window.location.href.split("/")[5];
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comm: data,
        });

        //
      });
  };
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    localStorage.clear();
    window.location.href = "#/Login";
  };

  Tagged = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_TagDetails?id=" +
      window.location.href.split("/")[5];
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Tagged: data,
        });

        //
      });
  };

  Liked = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_LikedUsers?id=" +
      window.location.href.split("/")[5];
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Liked: data,
        });

        //
      });
  };

  paropakaris = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisDetails?id=" +
      window.location.href.split("/")[5];
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          paropakaris: data,
        });

        //
      });
  };

  like = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisCount?id=" +
      window.location.href.split("/")[5];
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          like: data,
        });

        //
      });
  };

  handleMsg = (event) => {
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    const upload_id = urll.searchParams.get("upload_id");
    event.preventDefault();

    const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch(
      "https://apitest.paropakari.com/api/GetProfile/SupportCause?response_message=" +
      response_message +
      "&response_pledge=" +
      response_pledge +
      "&upload_id=" +
      upload_id,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      }
    );

    alert("Added your Comment Successfully!");

    window.location.reload();
  };
  handleClick = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch("https://apitest.paropakari.com/api/GetProfile/Add_Comment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    });

    alert("Added your Comment Successfully!");

    window.location.reload();
  };

  dropdown = () => {
    //
    let url = "https://apitest.paropakari.com/api/Group/Getgroup";

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Dropdownlist: data,
        });
      });
  };
  customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "white",
    }),
  };

  dropdownhandleChange = (e) => {
    this.setState({
      setSelectedValue: Array.isArray(e) ? e.map((x) => x.value) : null,
    });
  };

  friend = () => {
    //
    let url = "https://apitest.paropakari.com/api/Invite/GetInviteuser";

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Friendlist: data,
        });
      });
  };
  customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "white",
    }),
  };

  dropdownhandleChange = (e) => {
    this.setState({
      settaggedValue: Array.isArray(e) ? e.map((x) => x.value) : null,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    //  if (this.handleFormValidation()) {

    // if (
    //   this.state.selectedGroup.length !== 0 ||
    //   this.state.selectedOption.length !== 0
    // ) {
      // if (this.state.selectedOption == "" && this.state.selectedGroup == "") {
      //   alert(
      //     this.state.OneRequired.result
      //       ? this.state.OneRequired.result
      //       : "Tag atleast one Group or Friend"
      //   );
      // }
      // else if (this.state.selectedGroup == '') {
      //   alert(this.state.HRequired.result ? this.state.HRequired.result : 'Tag Groups Required');
      // }
      // else if (
      //   this.state.selectedGroup !== "" ||
      //   this.state.selectedOption !== ""
      // ) {
        const upload_id = window.location.href.split("/")[5];
        const data = new FormData(event.target);
        let user = JSON.parse(localStorage.getItem("user"));
        data.upload_id = window.location.href.split("/")[5];
        const accessToken = user;
        const url = "https://apitest.paropakari.com/api/Create/UpdateTagged";
        const options = {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: data,
        };
        // this.setState(this.initialState)

        const response = await fetch(url, options);
        const result = await response.json();
       
        if (response.ok) {
          alert(
            this.state.TagS.result
              ? this.state.TagS.result
              : "Tag Updated Successfully"
          );

          // window.location.reload();
          window.location.href = "#/HomePage";
        } else {
          // alert(
          //   this.state.OneRequired.result
          //     ? this.state.OneRequired.result
          //     : "Tag atleast one Group or Friend"
          // );
          window.location.reload();
        }

        this.setState(this.initialState);
      // } else {
      //   alert(
      //     this.state.OneRequired.result
      //       ? this.state.OneRequired.result
      //       : "Tag atleast one Group or Friend"
      //   );
      // }
    // } else {
    //   //alert('Please Select Tags');
    //   alert(
    //     this.state.OneRequired.result
    //       ? this.state.OneRequired.result
    //       : "Tag atleast one Group or Friend"
    //   );
    //   // alert("Tag atleast one Group or Friends Required")
    // }
  };

  Footer = () => {
    let url =
      "https://apitest.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  ReloadPage = () => {
    let reloadsmyactivity = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem("tagedit") !== null) {
      reloadsmyactivity = sessionStorage.getItem("tagedit");
      reloadsmyactivity++;
      window.sessionStorage.setItem("tagedit", reloadsmyactivity);
    } else {
      reloadsmyactivity = 1;
      window.sessionStorage.setItem("tagedit", reloadsmyactivity);
    }

    if (reloadsmyactivity == 1) {
      window.location.reload();
    }
  };

  DefaultMenu() {
    let reloads = 0;
    window.sessionStorage.setItem("reloadlogin", reloads);
    window.sessionStorage.setItem("reload", reloads);
    window.sessionStorage.setItem("reloadcreate", reloads);

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);

    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Tag Friends Required~Tag Groups Required~Tag Updated Successfully~Tag atleast one Group or Friend&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var GRequired = typeof items[1] !== "undefined" ? items[1] : null;
          var HRequired = typeof items[2] !== "undefined" ? items[2] : null;
          var TagS = typeof items[3] !== "undefined" ? items[3] : null;
          var OneRequired = typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            privacypolicy: PrivacyPolicy,
            GRequired: GRequired,
            HRequired: HRequired,
            TagS: TagS,
            OneRequired: OneRequired,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;
          var GRequired = typeof items[10] !== "undefined" ? items[10] : null;
          var HRequired = typeof items[11] !== "undefined" ? items[11] : null;
          // var TagSno = typeof items[12] !== "undefined" ? items[12] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            GRequired: GRequired,
            HRequired: HRequired,
            //  TagSno: TagSno
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;
          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;
          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var Termsandcondition =
            typeof items[6] !== "undefined" ? items[6] : null;
          var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
          var Causes = typeof items[8] !== "undefined" ? items[8] : null;
          var GRequired = typeof items[9] !== "undefined" ? items[9] : null;

          var HRequired = typeof items[10] !== "undefined" ? items[10] : null;
          //  var TagSno = typeof items[12] !== "undefined" ? items[12] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,

            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            GRequired: GRequired,
            HRequired: HRequired,
            //  TagSno: TagSno,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Education = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;

          var GRequired = typeof items[10] !== "undefined" ? items[10] : null;
          var HRequired = typeof items[11] !== "undefined" ? items[11] : null;
          // var TagSno = typeof items[12] !== "undefined" ? items[12] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Education,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            GRequired: GRequired,
            HRequired: HRequired,
            //TagSno: TagSno,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~Anticipated~Name~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Names = typeof items[8] !== "undefined" ? items[8] : null;
          var GRequired = typeof items[9] !== "undefined" ? items[9] : null;
          var HRequired = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Names: Names,
            GRequired: GRequired,
            HRequired: HRequired,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Email~Pledge~Message~Response date~Action~Comment Here~Share~Submit~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Email = typeof items[0] !== "undefined" ? items[0] : null;
          var Pledge = typeof items[1] !== "undefined" ? items[1] : null;
          var Message = typeof items[2] !== "undefined" ? items[2] : null;
          var Responsedate = typeof items[3] !== "undefined" ? items[3] : null;
          var Action = typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var Share = typeof items[6] !== "undefined" ? items[6] : null;
          var Submit = typeof items[7] !== "undefined" ? items[7] : null;
          var GRequired = typeof items[8] !== "undefined" ? items[8] : null;
          var HRequired = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);

          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            Email: Email,
            Pledge: Pledge,
            Message: Message,
            Responsedate: Responsedate,
            Action: Action,
            CommentHere: CommentHere,
            share: Share,
            Submit: Submit,
            GRequired: GRequired,
            HRequired: HRequired,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Confirmed~I have confirm that i recieved donation~Friends~Tag Friends Required~Tag Groups Required~Contact Us&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Confirmed = typeof items[0] !== "undefined" ? items[0] : null;
          var IhaveConfirm = typeof items[1] !== "undefined" ? items[1] : null;
          var Friends = typeof items[2] !== "undefined" ? items[2] : null;
          var GRequired = typeof items[3] !== "undefined" ? items[3] : null;
          var HRequired = typeof items[4] !== "undefined" ? items[4] : null;
          var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
          //  var TagSno = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            confirmed: Confirmed,
            ihaveconfirm: IhaveConfirm,
            friends: Friends,
            GRequired: GRequired,
            HRequired: HRequired,
            contactus: Contactus,
            // TagSno: TagSno,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Tag Friends Required~Tag Groups Required~Tag Updated Successfully~Tag atleast one Group or Friend~Contact Us&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var GRequired = typeof items[1] !== "undefined" ? items[1] : null;
          var HRequired = typeof items[2] !== "undefined" ? items[2] : null;
          var TagS = typeof items[3] !== "undefined" ? items[3] : null;
          var OneRequired = typeof items[4] !== "undefined" ? items[4] : null;
          var Contactus = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            privacypolicy: PrivacyPolicy,
            GRequired: GRequired,
            HRequired: HRequired,
            TagS: TagS,
            OneRequired: OneRequired,
            contactus: Contactus,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;
          var GRequired = typeof items[10] !== "undefined" ? items[10] : null;
          var HRequired = typeof items[11] !== "undefined" ? items[11] : null;
          // var TagSno = typeof items[12] !== "undefined" ? items[12] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            GRequired: GRequired,
            HRequired: HRequired,

            // TagSno: TagSno
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;
          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;
          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var Termsandcondition =
            typeof items[6] !== "undefined" ? items[6] : null;
          var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
          var Causes = typeof items[8] !== "undefined" ? items[8] : null;
          var GRequired = typeof items[9] !== "undefined" ? items[9] : null;
          var HRequired = typeof items[10] !== "undefined" ? items[10] : null;
          // var TagSno = typeof items[12] !== "undefined" ? items[12] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,

            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            GRequired: GRequired,
            HRequired: HRequired,
            //  TagSno: TagSno,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year~Tag Friends Required~Tag Groups Require&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Education = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;
          var GRequired = typeof items[10] !== "undefined" ? items[10] : null;
          var HRequired = typeof items[11] !== "undefined" ? items[11] : null;
          // var TagSno = typeof items[12] !== "undefined" ? items[12] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Education,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            GRequired: GRequired,
            HRequired: HRequired,
            //  TagSno: TagSno,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~Anticipated~Name~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Names = typeof items[8] !== "undefined" ? items[8] : null;
          var GRequired = typeof items[9] !== "undefined" ? items[9] : null;
          var HRequired = typeof items[10] !== "undefined" ? items[10] : null;
          //  var TagSno = typeof items[11] !== "undefined" ? items[11] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Names: Names,
            GRequired: GRequired,
            HRequired: HRequired,
            //  TagSno: TagSno,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Mobile~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var MobileNumbers = typeof items[0] !== "undefined" ? items[0] : null;
          var GRequired = typeof items[1] !== "undefined" ? items[1] : null;
          var HRequired = typeof items[2] !== "undefined" ? items[2] : null;
          // var TagSno = typeof items[3] !== "undefined" ? items[3] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            MobileNumbers: MobileNumbers,
            GRequired: GRequired,
            HRequired: HRequired,
            //  TagSno: TagSno,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Email~Pledge~Message~Response date~Action~Comment Here~Share~Submit~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Email = typeof items[0] !== "undefined" ? items[0] : null;
          var Pledge = typeof items[1] !== "undefined" ? items[1] : null;
          var Message = typeof items[2] !== "undefined" ? items[2] : null;
          var Responsedate = typeof items[3] !== "undefined" ? items[3] : null;
          var Action = typeof items[4] !== "undefined" ? items[4] : null;

          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var Share = typeof items[6] !== "undefined" ? items[6] : null;
          var Submit = typeof items[7] !== "undefined" ? items[7] : null;
          var GRequired = typeof items[8] !== "undefined" ? items[8] : null;
          var HRequired = typeof items[9] !== "undefined" ? items[9] : null;
          //  var TagSno = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            Email: Email,
            Pledge: Pledge,
            Message: Message,
            Responsedate: Responsedate,
            Action: Action,
            CommentHere: CommentHere,
            share: Share,
            Submit: Submit,
            GRequired: GRequired,
            HRequired: HRequired,
            // TagSno: TagSno,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Confirmed~I have confirm that i recieved donation~Friends~Tag Friends Required~Tag Groups Required&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Confirmed = typeof items[0] !== "undefined" ? items[0] : null;
          var IhaveConfirm = typeof items[1] !== "undefined" ? items[1] : null;
          var Friends = typeof items[2] !== "undefined" ? items[2] : null;
          var GRequired = typeof items[3] !== "undefined" ? items[3] : null;
          var HRequired = typeof items[4] !== "undefined" ? items[4] : null;
          //var TagSno = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            confirmed: Confirmed,
            ihaveconfirm: IhaveConfirm,
            friends: Friends,
            GRequired: GRequired,
            HRequired: HRequired,
            langID: LangID,
            // TagSno: TagSno
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  }

  componentDidMount() {

    this.interval = setInterval(this.checkAndSetLanguage, 500); // Check every second
    this.checkAndSetLanguage(); // Initial check
    if (!localStorage.getItem("reloaded")) {
      localStorage.setItem("reloaded", "true");
      window.location.reload();
    } else {
      // Reset the flag when navigating away from the component
      window.addEventListener("beforeunload", this.resetReloadFlag);
    }
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.tag);
    this.tag();
    window.addEventListener("load", this.taggrp);
    this.taggrp();
    window.addEventListener("load", this.comment);
    this.comment();
    window.addEventListener("load", this.like);
    this.like();
    window.addEventListener("load", this.paropakaris);
    this.paropakaris();
    window.addEventListener("load", this.Tagged);
    this.Tagged();
    window.addEventListener("load", this.Liked);
    this.Liked();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.dropdown);
    this.dropdown();
    window.addEventListener("load", this.friend);
    this.friend();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
  }

  checkAndSetLanguage = () => {
    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    const cookieLang =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    if (cookieLang !== this.state.previousCookieLang) {
      let newLanguage = "";
      let MID = "English";
      if (cookieLang === "en") {
        newLanguage = "English";
      } else if (cookieLang === "kn") {
        newLanguage = "Kannada";
      } else if (cookieLang === "ta") {
        newLanguage = "Tamil";
      } else if (cookieLang === "te") {
        newLanguage = "Telugu";
      } else if (cookieLang === "hi") {
        newLanguage = "Hindi";
      } else if (cookieLang === "es") {
        newLanguage = "Spanish";
      } else if (cookieLang === MID) {
        newLanguage = MID;
      }

      sessionStorage.setItem("translate", newLanguage);
      this.setState(
        { language: newLanguage, previousCookieLang: cookieLang },
        this.DefaultMenu()
      );
    }
  };
  resetReloadFlag = () => {
    localStorage.removeItem("reloaded");
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("beforeunload", this.resetReloadFlag);
    window.removeEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.tag);
    this.tag();
    window.addEventListener("load", this.taggrp);
    this.taggrp();
    window.removeEventListener("load", this.comment);
    this.comment();
    window.removeEventListener("load", this.like);
    this.like();
    window.removeEventListener("load", this.paropakaris);
    this.paropakaris();
    window.removeEventListener("load", this.Tagged);
    this.Tagged();
    window.removeEventListener("load", this.Liked);
    this.Liked();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.dropdown);
    this.dropdown();
    window.addEventListener("load", this.friend);
    this.friend();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // handleSubmitevents = (event) => {
  //   event.preventDefault();

  //   if (this.handleFormValidation()) {
  //     alert(' success')
  //     this.setState(this.initialState)
  //
  //   }
  //

  // };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  render() {
    const { paropakaris } = this.state;
    const { comm } = this.state;
    const { emps } = this.state;
    const { like } = this.state;
    const { Tagged } = this.state;
    const { Liked } = this.state;
    const { notify } = this.state;
    const { gErr } = this.state.formErrors;
    var noticount = JSON.parse(localStorage.getItem("noticount"));
    const currentYear = new Date().getFullYear();
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakariss,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      search,
      categories,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      others,
      friends,
      sort,
      mostrecent,
      mostpopular,
      available80g,
      amountdesc,
      amountasc,
      complete,
      incomplete,
      enddate,
      tag,
      likess,
      comment,
      donateasmile,
      year,
      activity,
      mycauses,
      likedcauses,
      commentedcauses,
      pledgedcauses,
      langID,
      HappinessMeter,
      Achieved,
      OnItsWay,
      Names,
      OneRequired,
      privacypolicy,
      share,
      submit,
      Submit,
      CommentHere,
      confirmed,
      ihaveconfirm,
      copyright_new,
      paropakari_new,
      MobileNumbers,
      Email,
      Pledge,
      Message,
      Responsedate,
      GRequired,
      HRequired,
      TagS,

      Action,
    } = this.state;

    let { foo } = this.state;
    var username = JSON.parse(localStorage.username);
    const {
      Details,
      Dropdownlist,
      setSelectedValue,
      Friendlist,
      settaggedvalue,
      Details1,
    } = this.state;

    let selectedValue = [];
    let selectedLabel = [];
    this.state.selectedOption &&
      this.state.selectedOption.forEach((item) => {
        selectedValue.push(item.value);
        selectedLabel.push(item.label);
      });

    let options = Dropdownlist.map(function (item) {
      return {
        value: item.group_id,
        label: item.group_Name,
        image: item.imagePath,
      };
    });

    let formatOptionLabel = ({ value, label, image }) => {
      const isSelected = this.state.selectedGroup.some(
        (item) => item.value === value
      );
  
      const isNewlyAdded = this.state.newlyAddedGroup.some(
        (item) => item.value === value
      );
  
      let style = { borderRadius: "50%", height: "30px", width: "30px" };
  
      if (isSelected) {
        style = { borderRadius: "50%", height: "30px" };
      }
  
      if (isNewlyAdded) {
        style = { borderRadius: "50%", height: "30px", width: "30px", border: "2px solid green" }; // Specific style for newly added items
      }
  
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={style} src={image} />
          <span style={{ marginLeft: "10px" }}>{label}</span>
        </div>
      );
    };

    let optionsfrnd = Friendlist.map(function (item) {
      return {
        value: item.id,
        label: item.name,
        image: item.profile_imagePath,
      };
    });

    let formatOptionLabelfrnd = ({ value, label, image }) => {
      const isSelected = this.state.selectedOption.some(
        (item) => item.value === value
      );
  
      const isNewlyAdded = this.state.newlyAddedFriend.some(
        (item) => item.value === value
      );
  
      let style = { borderRadius: "50%", height: "30px", width: "30px" };
  
      if (isSelected) {
        style = { borderRadius: "50%", height: "30px" };
      }
  
      if (isNewlyAdded) {
        style = { borderRadius: "50%", height: "30px", width: "30px", border: "2px solid green" }; // Specific style for newly added items
      }
  
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img style={style} src={image} />
          <span style={{ marginLeft: "10px" }}>{label}</span>
        </div>
      );
    };

    let unique = [...new Set(selectedLabel)];

    let unique2 = [...new Set(selectedValue)];

    let selectedValue1 = [];
    let selectedLabel1 = [];
    this.state.selectedGroup &&
      this.state.selectedGroup.forEach((item) => {
        selectedValue1.push(item.value);
        selectedLabel1.push(item.label);
      });

    let unique1 = [...new Set(selectedLabel1)];

    let unique21 = [...new Set(selectedValue1)];

    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          {/*[if lt IE 9]>
  <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          {/*/head*/}
          {/* <select className="form-control" style={{ display: 'none', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="English">English</option>
          </select> */}
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div
            id="google_translate_element"
            onClick={this.refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          >
            <select
              className="form-control"
              style={{
                display: "none",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>

            {/* <button onClick={refreshPage}>Click to reload!</button> */}
          </div>
          {/*/#header*/}
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontSize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p
                        class="pro-center-tamil"
                        style={{ marginRight: "10px" }}
                      >
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/*/#page-breadcrumb*/}
            <section
              id="blog"
              className={this.state.movedown == true ? null : "Tiptop"}
              style={{ backgroundColor: "#aee6db" }}
            >
              <br />
              <div className="container">
                {emps.map((emp) => (
                  <div className="" role="form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                      <div className="row">
                        <div class="form-group col-sm-4 col-md-4 col-lg-4"></div>
                        <div class="form-group col-sm-4 col-md-4 col-lg-4">
                          <label style={{ marginLeft: "13%" }}>
                            Tag Friends
                          </label>
                          <center>
                            <p id="upload_id1" />
                            <input
                              type="hidden"
                              className="form-control"
                              defaultValue={unique2}
                              placeholder="Title"
                              id="user_id"
                              name="user_id"
                            />
                            <input
                              type="hidden"
                              className="form-control"
                              defaultValue={emp.upload_id}
                              placeholder="Title"
                              id="upload_id"
                              name="upload_id"
                            />
                            <div style={{ width: "75%" }}>
                              {/* 
    {like.map((emp) => (
      <input type="text"  name="default_user_id"  defaultValue={emp.userId}  />
    ))} */}

                              <Select
                                isClearable={false}
                                placeholder={"Select Mates"}
                                styles={this.customStyles}
                                // menuPortalTarget={document.querySelector('body')}
                                value={this.state.selectedOption}
                                onChange={this.handleChangessss}
                                hideSelectedOptions={true}
                                selectedValue={true}
                                formatOptionLabel={formatOptionLabelfrnd}
                                options={optionsfrnd}
                                //options={Friendlist.map(e => ({ label: e.name, value: e.id}))}
                                isMulti
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    neutral50: "black",
                                  },
                                })}
                              />

                              {/* <Dropdowns ></Dropdowns> */}
                            </div>
                          </center>
                        </div>
                        <div class="form-group col-sm-4 col-md-4 col-lg-4"></div>
                      </div>
                      <div className="row">
                        <div class="form-group col-sm-4 col-md-4 col-lg-4"></div>
                        <div class="form-group col-sm-4 col-md-4 col-lg-4">
                          <input
                            type="hidden"
                            value={window.location.href.split("/")[5]}
                          ></input>
                          <br />
                          <label style={{ marginLeft: "13%" }}>
                            Tag Groups
                          </label>
                          <center>
                            <br />
                            <div style={{ width: "75%" }}>
                              <Select
                                id="Tag Group"
                                name="Tag Group"
                                isClearable={false}
                                placeholder={"Select Groups"}
                                styles={this.customStyles}
                                // menuPortalTarget={document.querySelector('body')}
                                onChange={this.handleChangess}
                                value={this.state.selectedGroup}
                                formatOptionLabel={formatOptionLabel}
                                options={options}
                                selectedValue1={true}
                                hideSelectedOptions={true}
                                //options={Dropdownlist.map(e => ({ label: e.group_Name, value: e.group_id}))}
                                isMulti
                                theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    neutral50: "black",
                                  },
                                })}
                              />

                              {/* <div style={{}}>
                                      {gErr && <span style={{ color: "red" }}>{GRequired.result ? GRequired.result : gErr}</span>}
                                    </div> */}

                              {/* <Taggroup style={{width:'50%'}}></Taggroup> */}
                            </div>

                            <input
                              type="hidden"
                              className="form-control"
                              defaultValue={unique21}
                              placeholder="Title"
                              id="group_id"
                              name="group_id"
                            />
                          </center>
                        </div>
                        <div class="form-group col-sm-4 col-md-4 col-lg-4"></div>
                      </div>
                      <div className="row">
                        <div class="form-group col-sm-4 col-md-4 col-lg-4"></div>
                        <div class="form-group col-sm-4 col-md-4 col-lg-4">
                          <center>
                            <button
                              type="submit"
                              className="btn btn-secondary "
                              style={{
                                backgroundColor: "rgb(102, 71, 18)",
                                color: "white",
                              }}
                            >
                              Submit
                            </button>
                            &nbsp;{" "}
                            <a
                              href={
                                "#/Confirm/" +
                                window.location.href.split("/")[5]
                              }
                              className="btn btn-secondary"
                              style={{
                                backgroundColor: "rgb(102, 71, 18)",
                                color: "white",
                              }}
                            >
                              Cancel
                            </a>
                          </center>
                        </div>
                        <div class="form-group col-sm-4 col-md-4 col-lg-4"></div>
                      </div>
                      <br />

                      {/* {like.map((emp) => (
      <input type="text"  name="default_group_id" id="default_group_id"  defaultValue={emp.groupId}  />
    ))} */}
                      <br />
                      <br />
                    </form>
                  </div>
                ))}
              </div>
            </section>
          </div>
          {/*/#blog*/}
          <footer
            id="footer"
            style={{ color: "white" }}
            className="notranslate"
          >
            <br />
            <div className="container">
              <div className="copyright">
                <div className="row col-lg-12">
                  <div className="col-lg-9 mb-6">
                    {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                    <p style={{ marginLeft: "45px" }}>
                      {copyright_new.result} &nbsp;{currentYear} ©{" "}
                      {paropakari_new.result} |&nbsp;
                      <a
                        href="#/Contactuslogin"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {contactus.result}&nbsp;
                      </a>
                      |{" "}
                      <a
                        href="#/PrivacyPolicyLogin"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        &nbsp;{privacypolicy.result}&nbsp;
                      </a>
                      |
                      <a
                        href="#/TermsandConditionLogin"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        &nbsp;{termsandcondition.result}
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div
                      className="social-icons"
                      style={{ color: "white", marginLeft: "72px" }}
                    >
                      <ul className="nav nav-pills">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/beoneparopakari"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                        <li>
                          <a
                            target="_blank"
                            href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                          >
                            <i className="fa fa-youtube-play" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/paropakari_beone/"
                          >
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/paropakari/"
                          >
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="containerss"></div>
          </footer>
          {/*/#footer*/}
        </div>
      </div>
    );
  }
}
