import React, { Component } from "react";
import CancelIcon from "@material-ui/icons/Cancel";
import Resizer from "react-image-file-resizer";
import GroupSeven from "./GroupSeven";
import Cookies from "js-cookie";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from '../Constants/BaseURL';

export default class Creategroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id,
      data: [],
      notify: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      selectedOption: [],
      SelectedValue: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      requiredforr: [],
      self: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      back: [],
      addmembers: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      friends: [],
      howitworks: [],
      privacypolicy: [],
      selectatleast1: [],
      blog: [],
      donateasmile: [],
      addnew: [],
      groupicon: [],
      name: [],
      noofmembers: [],
      action: [],
      search: [],
      edit: [],
      details: [],
      deletee: [],
      creategroup: [],
      langID: [],
      add: [],
      fileshouldbe: [],
      fields: {},
      errors: {},
      ImageDisplay: null,
      invalidImage: null,
      selectedFile: null,
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      addmin_mem: [],
      alreadyexist: [],
      createdsuccess: [],
      loading: false,
      isMobile: window.innerWidth <= 768,
      SaveImageFile: [],
      checking: true,
    };
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.reader = new FileReader();

    this.resetFile = this.resetFile.bind(this);
    this.ImagePreImagePreview = this.ImagePreview.bind(this);
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth <= 768 });
  }
  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };
  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // if(!fields["Group_Name"]){
    //    formIsValid = false;
    //    errors["Group_Name"] = "Group Name Cannot be empty";
    // }

    //   else if(!fields["user_id"]){
    //     formIsValid = false;
    //     errors["user_id"] = "Add mates Cannot be empty";

    //  }

    //  else{

    this.setState({ errors: errors });
    return formIsValid;
  }

  customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
    }),
  };

  resetFile(event) {
    event.preventDefault();

    const fileInput = document.getElementById('ImagePath');
    if (fileInput) {
      fileInput.value = '';
    }

    this.setState({ ImageDisplay: null });
    this.setState({ checking: false });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    // this.GetGroupNamesExist();
    const { addmin_mem, alreadyexist, createdsuccess, group,selectedFile,checking } = this.state;

    const data = new FormData(event.target);

    if (checking && selectedFile) {
      data.append("ImagePath", selectedFile);
    }

    let member = document.getElementById("memberselect").value;

    if (member == null || member == "") {
      // alert("Add minumum one member to create a group");
      alert(
        addmin_mem.result
          ? addmin_mem.result
          : "Add minimum one member to create a group"
      );
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      // this.toggleLoader();
      const url = "https://apitest.paropakari.com/api/Group/CreateGroup";
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      };
      // this.setState(this.initialState)

      const response = await fetch(url, options);
      const result = await response.json();

      if (result.message == "User created successfully!") {
        var Group_Name = document.getElementById("Group_Name").value;
        alert(
          group.result +
          " " +
          Group_Name +
          "" +
          " " +
          createdsuccess.result +
          "!"
        );
        window.location.href = "#/Group";
      } else if (result.message == "GroupName already Exists!") {
        var Group_Name = document.getElementById("Group_Name").value;
        alert(
          Group_Name + " " + alreadyexist.result
            ? alreadyexist.result
            : "already exists. Choose a different group name"
        );
        window.location.reload();
      } else {
        alert("Sorry, Try Again");
      }
      this.setState(this.initialState);
    }
  };

  ImageFileSave = (imageFile) => {
    if (imageFile !== null) {
      this.setState({ SaveImageFile: imageFile }, () => {
       
      });
    } else {
      return null;
    }
  };

  ChangeState = () => {
    this.setState({ checking: true });
  }

  ImagePreview = (event) => {
    const SelectFile = event.target.files[0];

    var imageFile = [];

    var ExistFile = [];

    if (SelectFile !== null) {
      this.ImageFileSave(SelectFile);
      this.setState({ checking: true })
    }

    if (!SelectFile) {
      if (this.state.selectedFile) {
       

        ExistFile = this.state.SaveImageFile;
      } else {
        
      }
    }

    if (!SelectFile) {
      imageFile = ExistFile;
    } else {
      imageFile = SelectFile;
    }



    const maxSize = 1024;
    const fsize = imageFile.size / 1024;

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({ invalidImage: "Please select a valid image." });
      return false;
    } else if (fsize > maxSize) {
      this.setState({ invalidImage: "Please select a smaller image." });
      return false;
    }

    var reader = new FileReader();
    reader.onloadend = (e) => {
      this.setState({
        ImageDisplay: reader.result,
        selectedFile: imageFile,
        invalidImage: null,
      });
    };

    reader.readAsDataURL(imageFile);
    Resizer.imageFileResizer(
      imageFile,
      30,
      30,
      "PNG",
      10,
      0,
      (uri) => { },
      "base64"
    );
  };

  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
    }
  };

  Footer = () => {
    let url =
      "https://apitest.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  DefaultMenu() {
    //
    let reloads = 0;
    window.sessionStorage.setItem("reloadlogin", reloads);
    window.sessionStorage.setItem("reload", reloads);
    window.sessionStorage.setItem("reloadcreate", reloads);
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);

    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright Â© 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Add New~Group Icon~Name~No. of Members~Action~Search~Edit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var AddNew = typeof items[3] !== "undefined" ? items[3] : null;
          var GroupIcon = typeof items[4] !== "undefined" ? items[4] : null;
          var Name = typeof items[5] !== "undefined" ? items[5] : null;
          var NoofMembers = typeof items[6] !== "undefined" ? items[6] : null;
          var Action = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            addnew: AddNew,
            groupicon: GroupIcon,
            name: Name,
            noofmambers: NoofMembers,
            action: Action,
            search: Search,
            edit: Edit,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Details~Delete~Create Group~Back~Add Members~Add~File Should be Less than 1 MB~Select Atleast One Member in Group~Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Details = typeof items[0] !== "undefined" ? items[0] : null;
          var Delete = typeof items[1] !== "undefined" ? items[1] : null;
          var CreateGroup = typeof items[2] !== "undefined" ? items[2] : null;
          var Back = typeof items[3] !== "undefined" ? items[3] : null;
          var AddMembers = typeof items[4] !== "undefined" ? items[4] : null;
          var Add = typeof items[5] !== "undefined" ? items[5] : null;
          var FileShouldBe = typeof items[6] !== "undefined" ? items[6] : null;
          var SelectAtleast1 =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Friends = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            details: Details,
            deletee: Delete,
            creategroup: CreateGroup,
            back: Back,
            addmembers: AddMembers,
            add: Add,
            fileshouldbe: FileShouldBe,
            selectatleast1: SelectAtleast1,
            friends: Friends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~created successfully~Add minimum one member to create a group~already exists. Choose a different group name&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var createdsuccess =
            typeof items[2] !== "undefined" ? items[2] : null;
          var addmin_mem = typeof items[3] !== "undefined" ? items[3] : null;
          var alreadyexist = typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            addmin_mem: addmin_mem,
            alreadyexist: alreadyexist,
            createdsuccess: createdsuccess,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            privacypolicy: PrivacyPolicy,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright Â© 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Add New~Group Icon~Name~No. of Members~Action~Search~Edit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var AddNew = typeof items[3] !== "undefined" ? items[3] : null;
          var GroupIcon = typeof items[4] !== "undefined" ? items[4] : null;
          var Name = typeof items[5] !== "undefined" ? items[5] : null;
          var NoofMembers = typeof items[6] !== "undefined" ? items[6] : null;
          var Action = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            addnew: AddNew,
            groupicon: GroupIcon,
            name: Name,
            noofmambers: NoofMembers,
            action: Action,
            search: Search,
            edit: Edit,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Details~Delete~Create Group~Back~Add Members~Add~File Should be Less than 1 MB~Select Atleast One Member in Group~Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Details = typeof items[0] !== "undefined" ? items[0] : null;
          var Delete = typeof items[1] !== "undefined" ? items[1] : null;
          var CreateGroup = typeof items[2] !== "undefined" ? items[2] : null;
          var Back = typeof items[3] !== "undefined" ? items[3] : null;
          var AddMembers = typeof items[4] !== "undefined" ? items[4] : null;
          var Add = typeof items[5] !== "undefined" ? items[5] : null;
          var FileShouldBe = typeof items[6] !== "undefined" ? items[6] : null;
          var SelectAtleast1 =
            typeof items[7] !== "undefined" ? items[7] : null;
          var Friends = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            details: Details,
            deletee: Delete,
            creategroup: CreateGroup,
            back: Back,
            addmembers: AddMembers,
            add: Add,
            fileshouldbe: FileShouldBe,
            selectatleast1: SelectAtleast1,
            friends: Friends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~created successfully~Add minimum one member to create a group~already exists. Choose a different group name&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var createdsuccess =
            typeof items[2] !== "undefined" ? items[2] : null;
          var addmin_mem = typeof items[3] !== "undefined" ? items[3] : null;
          var alreadyexist = typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            addmin_mem: addmin_mem,
            alreadyexist: alreadyexist,
            createdsuccess: createdsuccess,
          });
        });
    }
  }

  dropdown = () => {
    let url = "https://apitest.paropakari.com/api/Group/GetProfileLoggedUser";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
    })
      .then((results) => results.json())
      .then((data) => this.setState({ data: data }));
  };
  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        //
      });
  };

  componentDidMount() {
    window.addEventListener("load", this.dropdown);
    this.dropdown();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "India's only expenseless, direct crowdfunding platform";
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.addEventListener("load", this.dropdown);
    this.dropdown();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    window.removeEventListener("resize", this.handleResize);
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }
  render() {
    const { notify } = this.state;

    let { foo, Menu } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      others,
      requiredforr,
      familyfriends,
      self,
      submit,
      beneficiaryname,
      selectatleast1,
      friends,
      addnew,
      groupicon,
      name,
      noofmembers,
      action,
      search,
      edit,
      details,
      deletee,
      creategroup,
      back,
      addmembers,
      privacypolicy,
      add,
      fileshouldbe,
      langID,
      copyright_new,
      paropakari_new,
      alreadyexist,
      createdsuccess,
      checking,
    } = this.state;
    const currentYear = new Date().getFullYear();
    const { handleResponse, imageUrl, invalidImage, isMobile } = this.state;
    var username = JSON.parse(localStorage.username);
    // let {foo} = this.state;
    if (this.state.loading) {
      return (
        <center className="notranslate">
          <img
            className="loading-img2 notranslate"
            src="https://apitest.paropakari.com/images/Paropakari.gif"
          />
        </center>
      );
    } else {
      return (
        <div
          className="VIpgJd-ZVi9od-ORHb-OEVmcd"
          style={{ position: "absolute" }}
        >
          <div
            style={{
              overflowX: "hidden",
              backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
            }}
            className="notranslate"
          >
            {/* <meta http-equiv="refresh" content="10"/>   */}
            <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
            <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
            <link href="./assets/css/lightbox.css" rel="stylesheet" />
            <link href="./assets/css/animate.min.css" rel="stylesheet" />
            <link href="./assets/css/main.css" rel="stylesheet" />
            {/*[if lt IE 9]>
              
              
          <![endif]*/}
            <link rel="shortcut icon" href="images/ico/favicon.ico" />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="144x144"
              href="images/ico/apple-touch-icon-144-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="114x114"
              href="images/ico/apple-touch-icon-114-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="72x72"
              href="images/ico/apple-touch-icon-72-precomposed.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              href="images/ico/apple-touch-icon-57-precomposed.png"
            />
            {/*/head*/}
            <select
              className="form-control"
              style={{
                display: "inline",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
            &nbsp;&nbsp; &nbsp;&nbsp;
            <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontSize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p
                        class="pro-center-tamil"
                        style={{ marginRight: "10px" }}
                      >
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

              {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}}>      
  <div className="container">
              <div className="navbar navbar-inverse" role="banner">
                <div className="">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand" href="#/HomePage">
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                  {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
       {beaparopakari.result}
       </a></li>
);
}
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" target="_blank" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
           {beaparopakari.result}
           </a></li>

);}

})()}    
        
                                                 
                            
                                  
                                   <li><a href="#/Myactivity"><b>{myjourney.result}</b></a></li> 
               <li><a href="#/CauseFor"><b>{createacause.result}</b></a></li> 
               <li><a href="#/InviteFriend"><b>{invite.result}</b></a></li> 
               <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                            
                          <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                               <span class="-count"> 
                                
                              
                                {notifyy.noticount}
                               
                                  </span> ))}</div>
                           </a></li> 
               <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                   <ul role="menu" class="sub-menu">
                       <li><a href="#/Profile/"><b>{profile.result}</b></a></li>
                       <li><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                       <li className="active"><a href="#/Group/"><b>{group.result}</b></a></li>
                       <li><a href="#/Login/"><b>{logout.result}</b></a></li>
                   </ul>
               </li>        
           
                                         
                                 </ul>
                  </div>
                 
                </div>
              </div>
              </div>
            </header> */}

              {/*/#header*/}

              {/*/#page-breadcrumb*/}
              <section
                id="about-company"
                className={
                  this.state.movedown == true
                    ? "wow fadeInUp"
                    : "wow fadeInUp Tiptop"
                }
                data-wow-duration="400ms"
                data-wow-delay="400ms"
                style={{ backgroundColor: "#aee6db" }}
              >
                <div className="container">
                  {/* Page Content */}
                  <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                      <div className="main-body">
                        <div className="page-wrapper">
                          <div className="page-body">
                            <br />
                            <br />
                            <center>
                              {" "}
                              <h2 style={{ color: "black" }}>
                                <b>{creategroup.result}</b>
                              </h2>
                            </center>
                            <br />

                            <form
                              autoComplete="off"
                              className="form-horizontal1"
                              method="post"
                              onSubmit={this.handleSubmit.bind(this)}
                            >
                              {/* <table style={{ width: "100%" }}>
                              <tbody>
                                <tr>
                                  <td style={{ width: "60%" }}> */}

                              <div class="row">
                                <div class="form-group col-sm-4 col-lg-4"></div>
                                <div class="form-group col-sm-4 col-lg-4">
                                  {/* <label
                                            style={{ marginLeft: "-14%" }}
                                          >
                                            {name.result}
                                          </label> */}
                                  <label
                                    className
                                    htmlFor="name"
                                    style={{ float: "left" }}
                                  >
                                    {" "}
                                    {name.result}
                                  </label>
                                  <input
                                    className="form-control"
                                    id="Group_Name"
                                    name="Group_Name"
                                    autofocus
                                    required
                                    maxLength={30}
                                    style={{ paddingRight: "-10%" }}
                                  />
                                </div>
                                <div class="form-group col-sm-4 col-lg-4"></div>
                              </div>

                              <div class="row">
                                <div class="form-group col-sm-4 col-lg-4"></div>
                                <div class="form-group col-sm-4 col-lg-4">
                                  {/* {(() => {
                                          if (langID == "Tamil") {
                                            return (
                                              <label
                                                style={{
                                                  marginLeft: "-8px",
                                                }}
                                              >
                                                {" "}
                                                {groupicon.result}{" "}
                                                <br />
                                                <a
                                                  style={{
                                                    fontSize: 12,
                                                    color: "red",
                                                  }}
                                                >
                                                  ({fileshouldbe.result})
                                                </a>
                                              </label>
                                            );
                                          }
                                        })()}
                                        {(() => {
                                          if (langID == "Telugu") {
                                            return (
                                              <label
                                                style={{
                                                  marginLeft: "-30px",
                                                }}
                                              >
                                                {" "}
                                                {groupicon.result}{" "}
                                                <br />
                                                <a
                                                  style={{
                                                    fontSize: 12,
                                                    color: "red",
                                                  }}
                                                >
                                                  ({fileshouldbe.result})
                                                </a>
                                              </label>
                                            );
                                          }
                                        })()}
                                        {(() => {
                                          if (langID == "Kannada") {
                                            return (
                                              <label
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                {groupicon.result}{" "}
                                                <br />
                                                <a
                                                  style={{
                                                    fontSize: 12,
                                                    color: "red",
                                                  }}
                                                >
                                                  ({fileshouldbe.result})
                                                </a>
                                              </label>
                                            );
                                          }
                                        })()}
                                        {(() => {
                                          if (langID == "English") {
                                            return (
                                              <label
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                {groupicon.result}{" "}
                                                <a
                                                  style={{
                                                    fontSize: 12,
                                                    color: "red",
                                                  }}
                                                >
                                                  ({fileshouldbe.result})
                                                </a>
                                              </label>
                                            );
                                          }
                                        })()}
                                        {(() => {
                                          if (langID == "Spanish") {
                                            return (
                                              <label
                                                style={{
                                                  marginLeft: "60px",
                                                }}
                                              >
                                                {" "}
                                                {groupicon.result}{" "}
                                                <a
                                                  style={{
                                                    fontSize: 12,
                                                    color: "red",
                                                  }}
                                                >
                                                  ({fileshouldbe.result})
                                                </a>
                                              </label>
                                            );
                                          }
                                        })()}
                                        {(() => {
                                          if (langID == "Hindi") {
                                            return (
                                              <label
                                                style={{
                                                  marginLeft: "10px",
                                                }}
                                              >
                                                {" "}
                                                {groupicon.result}{" "}
                                                <a
                                                  style={{
                                                    fontSize: 12,
                                                    color: "red",
                                                  }}
                                                >
                                                  ({fileshouldbe.result})
                                                </a>
                                              </label>
                                            );
                                          }
                                        })()} */}
                                  <label
                                    style={{
                                      float: "left",
                                    }}
                                  >
                                    {groupicon.result}&nbsp;
                                    <a
                                      style={{
                                        fontSize: 12,
                                        color: "red",
                                      }}
                                    >
                                      ({fileshouldbe.result})
                                    </a>
                                  </label>
                                  <br />
                                  <input
                                    type="file"
                                    onMouseDown={this.ChangeState}

                                    // name={checking ? "ImagePath" : "imagePathNew"}
                                    // id={checking ? "ImagePath" : "imagePathNew"}

                                    name="ImagePath"
                                    id="ImagePath"

                                    className="form-control"
                                    accept="image/*"
                                    style={{
                                      width: "100%",
                                      display: "none",
                                    }}
                                    onChange={this.ImagePreview}
                                  />
                                  <br />{" "}
                                  <label
                                    for="ImagePath"
                                    className="form-control"
                                    style={{
                                      fontFamily: "cuyabra",
                                      // width: "17%",
                                    }}
                                  >
                                    {add.result}&nbsp;
                                    <i style={{ fontsize: "24px" }} class="fa">
                                      {" "}
                                      &#xf067;
                                    </i>
                                  </label>
                                  {invalidImage && (
                                    <p className="showerror">{invalidImage}</p>
                                  )}
                                  <br />
                                </div>
                                <div class="form-group col-sm-4 col-lg-4"></div>
                              </div>

                              {/* {(() => {
                                        if (langID == "Tamil") {
                                          return (
                                            <label
                                              id="Addmate"
                                              style={{ marginLeft: "0%" }}
                                            >
                                              {addmembers.result}
                                            </label>
                                          );
                                        }
                                      })()}
                                      {(() => {
                                        if (langID == "Telugu") {
                                          return (
                                            <label
                                              id="Addmate"
                                              style={{
                                                marginLeft: "-6%",
                                              }}
                                            >
                                              {addmembers.result}
                                            </label>
                                          );
                                        }
                                      })()}
                                      {(() => {
                                        if (langID == "Kannada") {
                                          return (
                                            <label
                                              id="Addmate"
                                              style={{
                                                marginLeft: "-8%",
                                              }}
                                            >
                                              {addmembers.result}
                                            </label>
                                          );
                                        }
                                      })()}
                                      {(() => {
                                        if (langID == "English" || langID == "Spanish") {
                                          return (
                                            <label
                                              id="Addmate"
                                              style={{
                                                marginLeft: "-10%",
                                              }}
                                            >
                                              {addmembers.result}
                                            </label>
                                          );
                                        }
                                      })()}
                                      {(() => {
                                        if (langID == "Hindi") {
                                          return (
                                            <label
                                              id="Addmate"
                                              style={{
                                                marginLeft: "-12%",
                                              }}
                                            >
                                              {addmembers.result}
                                            </label>
                                          );
                                        }
                                      })()} */}

                              <div class="row">
                                <div class="form-group col-sm-4 col-lg-4"></div>
                                <div class="form-group col-sm-4 col-lg-4">
                                  <label
                                    id="Addmate"
                                    style={{
                                      float: "left",
                                      textAlign: "left",
                                      width: "100%",
                                    }}
                                  >
                                    {addmembers.result}

                                    <br />
                                    <div style={{ marginBottom: "2%" }}></div>
                                    <GroupSeven></GroupSeven>
                                  </label>
                                  {this.state.ImageDisplay && (
                                    <div
                                      style={{
                                        textAlign: "center",
                                        marginTop: "-1%",
                                        marginLeft: "0%",
                                        marginBottom: "-2%",
                                      }}
                                    >
                                      <button
                                        className="remove"
                                        style={{
                                          marginLeft: isMobile ? "35%" : "36%",
                                        }}
                                        onClick={this.resetFile}
                                      >
                                        <CancelIcon className="icon-x"></CancelIcon>
                                      </button>
                                    </div>
                                  )}

                                  {this.state.ImageDisplay !== null && (
                                    <div
                                      style={{
                                        display: "block",
                                        borderRadius: "50%",
                                        height: "200px",
                                        width: "200px",
                                        overflow: "hidden",
                                        marginLeft: isMobile ? "22%" : "28%",
                                      }}
                                    >
                                      <img
                                        className="pic"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "cover",
                                        }}
                                        src={this.state.ImageDisplay}
                                        name="imagePath"
                                        alt="Group Icon"
                                      />
                                    </div>
                                  )}
                                </div>
                                <div class="form-group col-sm-4 col-lg-4"></div>
                                <br />
                              </div>
                              {/* {this.state.ImageDisplay !==
                                        null && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              marginLeft: "-19%",
                                              marginBottom: "-2%",
                                            }}
                                          >
                                            <button
                                              className="remove"
                                              onClick={this.resetFile}
                                            >
                                              <CancelIcon className="icon-x"></CancelIcon>
                                            </button>
                                          </div>
                                        )}
                                      {this.state.ImageDisplay !==
                                        null && (
                                          <img
                                            id="groupimage"
                                            style={{
                                              width: "17%",
                                              heifht: "30%",
                                              marginLeft: "-0%",
                                            }}
                                            alt="Group Icon"
                                            src={this.state.ImageDisplay}
                                          />
                                        )} */}

                              <div
                                className="row"
                                style={{ marginTop: isMobile ? "-15%" : "" }}
                              >
                                <div class="form-group col-sm-4 col-lg-4"></div>
                                <div class="form-group col-sm-4 col-lg-4">
                                  <center>
                                    <button
                                      type="submit"
                                      defaultValue="Create"
                                      className="btn btn-secondary "
                                      style={{
                                        backgroundColor: "rgb(102, 71, 18)",
                                        color: "white",
                                        marginTop: "10%",
                                      }}
                                    >
                                      {submit.result}
                                    </button>
                                    {/* <br /><br /> */}
                                    <a
                                      className="btn btn-secondary "
                                      style={{
                                        textDecoration: "none",
                                        fontSize: "14px",
                                        backgroundColor: "rgb(102, 71, 18)",
                                        color: "white",
                                        marginTop: "10%",
                                      }}
                                      href={"#/Group"}
                                    >
                                      {back.result}
                                    </a>
                                  </center>
                                </div>
                                <div class="form-group col-sm-4 col-lg-4"></div>
                              </div>

                              {/* </td>
                                </tr>
                              </tbody>
                            </table> */}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="styleSelector"></div>
                  </div>
                </div>
                <br />
                <br />
                <br />
                <br />
              </section>

              {/*/#team*/}
              <div style={{ backgroundColor: "#664712" }}>
                <footer id="footer" style={{ color: "white" }}>
                  <br />
                  <div className="container">
                    <div className="copyright">
                      <div className="row col-lg-12">
                        <div className="col-lg-9 mb-6">
                          {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                          <p style={{ marginLeft: "45px" }}>
                            {copyright_new.result} &nbsp;{currentYear} Â©{" "}
                            {paropakari_new.result} |&nbsp;
                            <a
                              href="#/Contactuslogin"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              {contactus.result}&nbsp;
                            </a>
                            |{" "}
                            <a
                              href="#/PrivacyPolicyLogin"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              &nbsp;{privacypolicy.result}&nbsp;
                            </a>
                            |
                            <a
                              href="#/TermsandConditionLogin"
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              &nbsp;{termsandcondition.result}
                            </a>
                          </p>
                        </div>
                        <div className="col-lg-3 mb-3">
                          <div
                            className="social-icons"
                            style={{ color: "white", marginLeft: "72px" }}
                          >
                            <ul className="nav nav-pills">
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.facebook.com/beoneparopakari"
                                >
                                  <i className="fa fa-facebook" />
                                </a>
                              </li>
                              {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                                >
                                  <i className="fa fa-youtube-play" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.instagram.com/paropakari_beone/"
                                >
                                  <i className="fa fa-instagram" />
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.linkedin.com/company/paropakari/"
                                >
                                  <i className="fa fa-linkedin" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerss"></div>
                </footer>
              </div>
              {/*/#footer*/}
            </div>
          </div>
        </div>
      );
    }
  }
}
