import React, { Component } from 'react'
import Select from 'react-select';
import CancelIcon from '@material-ui/icons/Cancel';
import Resizer from 'react-image-file-resizer';
import Cookies from 'js-cookie';
import { BASE_URLs } from '../Constants/BaseURL';
export default class EditTag extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      id: this.props.match.params.id,
      selectedOption: [],

      Dropdownlist: [],
      setSelectedValue: [],
      notify: [],
      Details: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [], changepassword: [], group: [], logout: [], privacypolicy: [],
      requiredforr: [], self: [], familyfriends: [], others: [], beneficiaryname: [], submit: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [], donateasmile: [], groupinfo: [], back: [], members: [], name: [], change: [], update: [], groupicon: [],

      langID: [],
      ImageDisplay: '',
      copyright_new: [],
      paropakari_new: [],

    }
    this.Logo = this.Logo.bind(this);
    this.ImagePreview = this.ImagePreview.bind(this);
    this.resetFile = this.resetFile.bind(this);
    this.submit = this.submit.bind(this);
    this.dropdown = this.dropdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageChange = this.onImageChange.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.reader = new FileReader();
  }
  getValues(apiValues) {
    let previousValue = [];
    let names = apiValues[0].profile_name.split(",");
    let ids = apiValues[0].user_id.split(",");
    let index = 0;

    if (names.length === ids.length) {
      names.forEach((item) => {
        let data = { value: ids[index].toString(), label: item };
        index++;
        previousValue.push(data);
      });
    }

    this.setState({ selectedOption: previousValue });
  }
  onImageChange = (event) => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    })
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          profile_imagePath: e.target.result
        });
      };
      
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  submit() {
    
    let url = 'https://apitest.paropakari.com/api/Group/GetGroupEdit?GroupId=' + this.props.match.params.id;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },

    }).then(response => response.json())
      .then(data => {
        this.getValues(data);
        this.setState({
          Details: data

        });
        
      })
  }
  ImagePreview = (event) => {
    var fileInput = false
    var maxSize = '1024';
    const imageFile = event.target.files[0];
    if (event.target.files[0]) {
      fileInput = true
    }

    
    var reader = new FileReader();
    var url = reader.readAsDataURL(imageFile);
    
    reader.onloadend = function (e) {
      this.setState({
        ImageDisplay: [reader.result],
      })
    }.bind(this);
    if (fileInput) {

      Resizer.imageFileResizer(
        imageFile,
        30,
        30,
        'PNG',
        10,
        0,
        uri => {
          
        },
        'base64'
      );
    }


    var fsize = event.target.files[0].size / 15;

    // if (!imageFile) {
    //   this.setState({ invalidImage: 'Please select image.' });
    //   return false;
    // }

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({ invalidImage: 'Please select valid image.' });
      return false;
    }

    else if (fsize < maxSize) {
      this.setState({ invalidImage: 'Please select Quality image.' });
      return false;
    }
    this.reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        this.setState({ selectedFile: imageFile, invalidImage: null });
      };
      img.onerror = () => {
        this.setState({ invalidImage: 'Invalid image content.' });
        return false;
      };

      img.src = e.target.result;
    };
    this.reader.readAsDataURL(imageFile);
  }
  resetFile(event) {
    event.preventDefault();

    this.setState({ ImageDisplay: null });
  }
  dropdown() {
    
    let url = 'https://apitest.paropakari.com/api/Group/EdittaggedUser?upload_id=' + this.props.match.params.id;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },

    }).then(response => response.json())
      .then(data => {
        this.setState({
          Dropdownlist: data
        });
        
      });

  }
  customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "white",

    })
  };

  dropdownhandleChange = (e) => {
    this.setState({ setSelectedValue: Array.isArray(e) ? e.map(x => x.value) : null });

  }
  handleSubmit = async event => {
    event.preventDefault();
    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem('user'));

    const accessToken = user;
    const url = "https://apitest.paropakari.com/api/Group/PostGroup";
    const options = {
      method: "POST",
      headers: {

        Accept: 'application/json',
        Authorization: "Bearer " + accessToken
      },
      body: data,
    };
    this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response;
    if (response.ok) {
      alert('Members tagged successfully')
      window.location.href = "#/Group";
    }
    else {
      window.location.reload();
    }
    this.setState(this.initialState)
    
  };

  handleChange(value, actionDetails) {
    let items = [...this.state.selectedOption];
    if (actionDetails.action === "remove-value") {
      if (items) {
        var index = -1;
        items.forEach((item, i) => {
          if (item.label === actionDetails.removedValue.label && item.value === actionDetails.removedValue.value) {
            index = i;
            return;
          }
        });
        if (index > -1) {
          items.splice(index, 1);
        }
      }
    }
    else {
      items.push(actionDetails.option);
    }

    this.setState({ selectedOption: items });
  }


  notify = () => {


    let url = "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        // 
      });
  }

  Footer() {

    


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  DefaultMenu() {
    
    let reloads = 0;
    window.sessionStorage.setItem('reload', (reloads));

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;


          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit
          });


        });
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition = typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });



        });
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Group Info~Back~Members~Name~Change~Update~Group Icon&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var FamilyandFriends = typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName = typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var GroupInfo = typeof items[3] !== "undefined" ? items[3] : null;
          var Back = typeof items[4] !== "undefined" ? items[4] : null;
          var Members = typeof items[5] !== "undefined" ? items[5] : null;
          var Name = typeof items[6] !== "undefined" ? items[6] : null;
          var Change = typeof items[7] !== "undefined" ? items[7] : null;
          var Update = typeof items[8] !== "undefined" ? items[8] : null;
          var GroupIcon = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            groupinfo: GroupInfo,
            back: Back,
            members: Members,
            name: Name,
            change: Change,
            update: Update,
            groupicon: GroupIcon,
            langID: LangID
          });


        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    // localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let l_id = JSON.parse(localStorage.getItem('MenuID'));
    
    let MID = event.target.value;
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;


          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit
          });


        });
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;
          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition = typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            privacypolicy: PrivacyPolicy,
            langID: LangID
          });



        });
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Group Info~Back~Members~Name~Change~Update~Group Icon&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Detailss: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var FamilyandFriends = typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName = typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var GroupInfo = typeof items[3] !== "undefined" ? items[3] : null;
          var Back = typeof items[4] !== "undefined" ? items[4] : null;
          var Members = typeof items[5] !== "undefined" ? items[5] : null;
          var Name = typeof items[6] !== "undefined" ? items[6] : null;
          var Change = typeof items[7] !== "undefined" ? items[7] : null;
          var Update = typeof items[8] !== "undefined" ? items[8] : null;
          var GroupIcon = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            submit: Submit,
            groupinfo: GroupInfo,
            back: Back,
            members: Members,
            name: Name,
            change: Change,
            update: Update,
            groupicon: GroupIcon,
            langID: LangID
          });


        });
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }
  }




  componentDidMount() {
    window.addEventListener('load', this.submit);
    this.submit();
    window.addEventListener('load', this.dropdown);
    this.dropdown();
    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }

    document.title = "India's only expenseless, direct crowdfunding platform"
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.submit);
    this.submit();
    window.addEventListener('load', this.dropdown);
    this.dropdown();
    window.removeEventListener('load', this.notify);
    this.notify();
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }
  Logo() {
    var page = {
      pathname: '/Home',

    }

    this.props.history.push(page)
  }


  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }





  render() {
    const { Details, Dropdownlist, setSelectedValue } = this.state;
    const { howitworks, blog, contactus, termsandcondition, causes, beneficiaries, paropakaris, amountraised, copyright, beaparopakari, myjourney, createacause, invite,
      profile, changepassword, group, logout, others, requiredforr, familyfriends, self, submit, beneficiaryname, name, groupinfo, members, back, groupicon,
      change, update, privacypolicy, copyright_new, paropakari_new,
      langID } = this.state;

    let selectedValue = [];
    let selectedLabel = [];
    this.state.selectedOption && this.state.selectedOption.forEach((item) => {
      selectedValue.push(item.value);
      selectedLabel.push(item.label);
    });

    const currentYear = new Date().getFullYear();
    let unique = [...new Set(selectedLabel)]
    
    let unique2 = [...new Set(selectedValue)]
    
    const { notify } = this.state;
    var username = JSON.parse(localStorage.username);
    let { foo, Menu } = this.state;

    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />

          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;


          <div>

            <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>

              <div class="navbar navbar-inverse" role="banner">
                <div class="container">
                  <div class="navbar-header">
                    <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span class="sr-only">Toggle navigassstion</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>

                    <a class="navbar-brand" href="#/Homepage">
                      <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                      </h1>
                    </a>

                  </div>
                  <div class="collapse navbar-collapse">
                    <ul class="nav navbar-nav navbar-right">


                      <li><a href="#/Myactivity"><b>{myjourney.result}</b></a></li>
                      <li><a href="#/CauseFor"><b>{createacause.result}</b></a></li>
                      <li><a href="#/InviteFriend"><b>{invite.result}</b></a></li>
                      <li className=""><a href="#/Notification" style={{ fontFamily: 'cuyabra', fontWeight: 'bold' }}>

                        <div class="fa fa-bell" style={{ fontsize: '24px' }}>{notify.map((notifyy) => (
                          <span class="-count">


                            {notifyy.noticount}

                          </span>))}</div>
                      </a></li>
                      {/* <li><a href="shortcodes.html ">Login</a></li>  */}
                      <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                        <ul role="menu" class="sub-menu">
                          <li><a href="#/Profile"><b>{profile.result}</b></a></li>
                          <li><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                          <li className="active"><a href="#/Group"><b>{group.result}</b></a></li>
                          <li><a href="#/Login"><b>{logout.result}</b></a></li>
                        </ul>
                      </li>
                      {/* <li><a href="shortcodes.html ">Shortcodes</a></li>                     */}
                    </ul>
                  </div>
                  {/* <div className="search">
           <form role="form">
               <i className="fa fa-search"></i>
               <div className="field-toggle">
                   <input type="text" className="search-form" autocomplete="off" placeholder="Search"/>
               </div>
           </form>
       </div> */}
                </div>
              </div>
            </header>

            {/*/#header*/}

            {/*/#page-breadcrumb*/}
            <section id="about-company" className=" wow fadeInUp" data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundColor: '#aee6db' }}>

              <div className="containerss">
                {/* Page Content */}

                <div className="pcoded-content">
                  <div className="pcoded-inner-content">
                    <div className="main-body">
                      <div className="page-wrapper">

                        <div className="page-body">





                          <div>

                            <form action="" onSubmit={this.handleSubmit.bind(this)}  >
                              <div className="row" style={{ marginLeft: 3 }}>
                                <center>
                                  <h3><b>{groupinfo.result}</b></h3>

                                  <div className="container">
                                    <div className="form-horizontal" style={{ border: 'inset dotted black' }}>
                                      <div className="form-group">

                                        <label style={{ marginLeft: '-21%' }}>{name.result}</label>

                                        {Details.map(Det =>
                                          <input type="text" className="form-control" style={{ width: '25%' }} readOnly="true"
                                            defaultValue={Det.group_Name} name="group_Name"
                                          ></input>)}
                                        <div style={{ color: 'red' }}>
                                          <span className="field-validation-valid" data-valmsg-for="group_Name" data-valmsg-replace="true" />
                                        </div>
                                      </div>

                                      <div className="form-group">
                                        {(() => {
                                          if (langID == 'Tamil') {
                                            return (
                                              <label style={{ marginLeft: '-15%' }}>{members.result}</label>
                                            );
                                          }


                                        })()}
                                        {(() => {
                                          if (langID == 'Hindi') {
                                            return (
                                              <label style={{ marginLeft: '-21%' }}>{members.result}</label>
                                            );
                                          }


                                        })()}
                                        {(() => {
                                          if (langID == 'Kannada') {
                                            return (
                                              <label style={{ marginLeft: '-20%' }}>{members.result}</label>
                                            );
                                          }


                                        })()}
                                        {(() => {
                                          if (langID == 'English' || langID == 'Telugu') {
                                            return (
                                              <label style={{ marginLeft: '-19%' }}>{members.result}</label>
                                            );
                                          }


                                        })()}


                                        <br />
                                       
                                        <div style={{ width: '25%', backgroundColor: 'white' }}>

                                          <Select
                                            isClearable={false}
                                            placeholder={"Select Mates"}
                                            styles={this.customStyles}
                                            menuPortalTarget={document.querySelector('body')}
                                            value={this.state.selectedOption}
                                            onChange={this.handleChange}
                                            selectedValue={true}
                                            hideSelectedOptions={false}
                                            options={Dropdownlist.map(e => ({ label: e.name, value: e.id }))}
                                            isMulti
                                            theme={theme => ({
                                              ...theme,
                                              colors: {
                                                ...theme.colors,
                                                neutral50: 'white',
                                              },
                                            })}
                                          />
                                        </div>

                                      </div>

                                      <div className="form-group">
                                        {(() => {
                                          if (langID == 'Tamil') {
                                            return (
                                              <label htmlFor="GroupName" style={{ marginLeft: '-17%' }}>{groupicon.result} </label>
                                            );
                                          }


                                        })()}

                                        {(() => {
                                          if (langID == 'Kannada') {
                                            return (
                                              <label htmlFor="GroupName" style={{ marginLeft: '-16%' }}>{groupicon.result} </label>
                                            );
                                          }


                                        })()}
                                        {(() => {
                                          if (langID == 'Telugu') {
                                            return (
                                              <label htmlFor="GroupName" style={{ marginLeft: '-13%' }}>{groupicon.result} </label>
                                            );
                                          }


                                        })()}
                                        {(() => {
                                          if (langID == 'Hindi') {
                                            return (
                                              <label htmlFor="GroupName" style={{ marginLeft: '-19%' }}>{groupicon.result} </label>
                                            );
                                          }


                                        })()}
                                        {(() => {
                                          if (langID == 'English') {
                                            return (
                                              <label htmlFor="GroupName" style={{ marginLeft: '-18%' }}>{groupicon.result} </label>
                                            );
                                          }


                                        })()}
                                        <input type="hidden" name="user_id" defaultValue={unique2} />

                                        {Details.map(Det =>
                                          <input type="hidden" name="default_user_id" defaultValue={Det.user_id} />)}

                                        <input type="file" className="image-upload" id="ImagePath" accept="image/*"
                                          name='ImagePath' style={{ width: '25%', display: 'none' }} onChange={this.ImagePreview} />

                                        <br />  <label for="ImagePath" className="form-control" style={{ fontFamily: 'cuyabra', width: '25%' }}>{change.result}&nbsp;<i style={{ fontsize: '24px' }} class="fa"> &#xf067;</i></label>

                                        {Details.map(Det =>
                                          <input type="hidden"
                                            defaultValue={Det.imagePath} name="default_ImagePath"
                                          ></input>)}
                                        {this.state.ImageDisplay && (
                                          <div style={{ textAlign: "center" }}>
                                            <button className="remove" onClick={this.resetFile}><CancelIcon className="icon-x"></CancelIcon></button>
                                          </div>
                                        )}
                                        <img id="groupimage" style={{ width: '25%', heifht: '30%', marginLeft: '-1%' }} src={this.state.ImageDisplay} alt />
                                        <br />
                                        {Details.map(Det =>
                                          this.state.file ?
                                            <img id="target" style={{ width: "10%", height: "10%" }} src={this.state.file} alt /> :

                                            <img className="upload" style={{ width: "25%", height: "10%" }} alt src={Det.imagePath} name="imagePath" />)}
                                      </div>
                                      <div className="form-group">
                                        <div className="col-sm-12" style={{ marginLeft: '-15px' }}>
                                          <button type="submit" defaultValue="Create" className="btn btn-secondary " style={{ backgroundColor: 'rgb(102, 71, 18)', marginLeft: '2.5%', color: 'white' }}>{update.result}</button>
                                          <br /><br />
                                          <a className="btn btn-secondary " style={{ textDecoration: 'none', marginLeft: '2.5%', fontSize: '14px', backgroundColor: 'rgb(102, 71, 18)', color: 'white' }} href={"#/Group/" + langID}>
                                            {back.result}        </a>

                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </center>
                              </div>
                            </form>

                          </div>

























                        </div>

                      </div>
                    </div>
                  </div>
                  <div id="styleSelector">
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
            </section>

            {/*/#team*/}
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p style={{ marginLeft: '45px' }}>{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white', marginLeft: '72px' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>
            {/*/#footer*/}

          </div>

        </div>
      </div>
    )
  }
}