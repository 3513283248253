import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import loading from "../assets/images/Paropakari.gif";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import { BASE_URLs } from "../Constants/BaseURL";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        marginTop: "200px",
        marginRight: "42%",
        zIndex: "+1",
      }}
      onClick={onClick}
    >
      <img
        src="https://apitest.paropakari.com/Images/Cleft.png"
        height="30px"
        width="30px"
        style={{ borderRadius: "25px" }}
      ></img>
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        zIndex: "+1",
        marginTop: "200px",
        marginLeft: "30%",
      }}
      onClick={onClick}
    >
      <img
        src="https://apitest.paropakari.com/Images/Cright.png"
        height="30px"
        width="30px"
        style={{ borderRadius: "25px", marginLeft: "51px" }}
      ></img>
    </div>
  );
}

function SampleNext(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    >
      <img
        src="../assets/images/home/slider/back5.png"
        height="30px"
        width="30px"
        style={{ borderRadius: "25px", marginLeft: "-50px" }}
      ></img>
    </div>
  );
}
function SamplePrev(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "transparent",
        zIndex: "+1",
      }}
      onClick={onClick}
    >
      <img
        src="../assets/images/home/slider/back3.png"
        height="30px"
        width="30px"
        style={{ borderRadius: "25px", marginLeft: "50px" }}
      ></img>
    </div>
  );
}
export default function ImagePreview({ Id, datavalue }) {
  const config = {
    speed: 3000,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SampleNext />,
    prevArrow: <SamplePrev />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          speed: 3000,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        },
      },
    ],
  };
  const confirguration = {
    speed: 300,

    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
        },
      },
    ],
  };

  const customStyles = {
    top: "50%",
    left: "51%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: "40%",
    borderRadius: "20px",
    // backgroundColor:'rgb(174, 230, 219)',
    backgroundColor: "rgb(165, 217, 207)",
    border: "none",
    // position:"fixed",
    position: "absolute",
    overflow: "hidden",
    zIndex: "-1",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [UpdatePop, setUpdatePop] = useState(false);
  const [data, setData] = useState([]);
  const [data1, setData1] = useState([]);
  // const [data, setData] = useState(datavalue.data===undefined? []:datavalue.data);
  // const [data1, setData1] = useState(datavalue.data1===undefined? []:datavalue.data1);
  const [selectedValue, setSelectedValue] = useState([]);
  const [settings, setSettings] = useState(config);
  const [set, setset] = useState(confirguration);

  const [modal, setModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [imageValue, setimageValue] = useState([]);
  const [Key, setKey] = useState("PreviewImage0");
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  function toggleModal() {
    setIsOpen(!isOpen);
    setModal(false);
  }
  useEffect(() => {
    //  loadData();

    // setTimeout(()=>{
    //   document.getElementById('loader').style.display = "block"
    // },1000)
    // document.getElementById('loader').style.display = "none"

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadData = async () => {
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      if (user != null) {
        await fetch(
          "https://apitest.paropakari.com/api/GetProfile/GetTop1ImagePreview?Id=" +
            Id,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
          .then((response) => response.json())
          .then((receivedData) => setData1(receivedData));
      }

      await fetch(
        "https://apitest.paropakari.com/api/GetProfile/GetImagePreview?Id=" +
          Id,
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
        .then((response) => response.json())
        .then((receivedData) => {
          setData(receivedData);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };
  const update = () => {
    //  loadData();

    // setIsOpen(true);
    setModal(true);
    //document.getElementById("modalRegisterForm").style.display = "block";
  };

  const SetImagePreview = (imagevalue, key) => {
    // setimageValue([]);
    setimageValue(imagevalue);
    setKey(key);
    // if (imageValue.length === 0) {
    setTimeout(() => {
      var image = document.getElementById(key);
      if (image) {
        image.click();
      }
    }, 200);
    // } else {
    //   var image = document.getElementById(key);
    //   if (image) {
    //     image.click();
    //   }
    //}
  };

  return (
    <div>
      <div className="App">
        {datavalue.length == 0 ? (
          <img
            className="loading-img"
            src={loading}
            id="loader"
            style={{
              borderRadius: "30px",
              textAlign: "center",
              display: "block",
              margin: "150px auto",
              display: "flex",
              justifyContent: "center",
            }}
          />
        ) : (
          <Slider {...set}>
            {datavalue.map((emp, i) => (
              <div
                className="containersss"
                // onClick={
                //   emp.file_type == ".png" ||
                //   emp.file_type == ".JPG" ||
                //   emp.file_type == ".jpg" ||
                //   emp.file_type == ".jfi" ||
                //   emp.file_type == ".jfif" ||
                //   emp.file_type == ".JPG" ||
                //   emp.file_type == ".jpeg"
                //     ? null
                //     : () => update()
                // }
              >
                <div key={i} className="">
                  {(() => {
                    if (
                      emp.file_type == ".png" ||
                      emp.file_type == ".JPG" ||
                      emp.file_type == ".jpg" ||
                      emp.file_type == ".jfi" ||
                      emp.file_type == ".jfif" ||
                      emp.file_type == ".JPG" ||
                      emp.file_type == ".jpeg" ||
                      emp.file_type == ".JPG" ||
                      emp.file_type == ".webp"
                    ) {
                      return (
                        // <a>
                        //   {" "}
                        //   <img
                        //     className="img-responsive"
                        //     alt="Causes"
                        //     src={emp.file_name}
                        //   />
                        //   {/* <embed src={(emp.file_name + "#toolbar=0")} value={emp.file_name + "#toolbar=0"} title="" scrolling="no" className="img-responsive" ></embed> */}
                        // </a>

                        <img
                          // onClick={() => SetImagePreviewClick()}
                          onClick={() =>
                            SetImagePreview(
                              datavalue,
                              "PreviewImage" + emp.file_name + ""
                            )
                          }
                          className="img-responsive"
                          height={200}
                          src={emp.file_name}
                          // style={{ objectFit: "cover" }}
                          alt="Causes"
                        />
                      );
                    } else if (
                      emp.file_type == ".pdf"
                      //|| emp.file_type == ".docx" ||
                      // emp.file_type == ".doc" ||
                      // emp.file_type == ".xlsx"
                    ) {
                      return (
                        <a>
                          {/* <div
                            id="Iframe-Master-CC-and-Rs"
                            class="set-margin set-padding set-border set-box-shadow center-block-horiz"
                          >
                            <div
                              class="responsive-wrapper
                                         responsive-wrapper-wxh-572x612"
                              style={{
                                WebkitOverflowScrolling: "touch",
                                // overflow: "auto",
                              }}
                            > */}
                          <iframe
                            className="img-responsive"
                            style={{ height: "300" }}
                            // src={emp.file_name + "#toolbar=0"}
                            src={
                              "https://docs.google.com/gview?url=" +
                              emp.file_name +
                              "&embedded=true"
                            }
                            autoplay="0"
                            width="100%"
                            height="300"
                          />

                          {/* <embed
                            src={emp.file_name + "#toolbar=0"}
                            value={emp.file_name + "#toolbar=0"}
                            title=""
                            scrolling="no"
                            className="img-responsive"
                            style={{ height: "300" }}
                          ></embed> */}
                          {/* </div>
                          </div> */}
                        </a>
                      );
                    } else if (
                      emp.file_type == ".docx" ||
                      emp.file_type == ".doc"

                      // || emp.file_type == ".xlsx"
                    ) {
                      return (
                        <a>
                          {/* <div
                            id="Iframe-Master-CC-and-Rs"
                            class="set-margin set-padding set-border set-box-shadow center-block-horiz"
                          >
                            <div
                              class="responsive-wrapper
                                         responsive-wrapper-wxh-572x612"
                              style={{
                                WebkitOverflowScrolling: "touch",
                                // overflow: "auto",
                              }}
                            > */}
                          <iframe
                            className="img-responsive"
                            // src={emp.file_name + "#toolbar=0"}
                            src={
                              "https://docs.google.com/gview?url=" +
                              emp.file_name +
                              "&embedded=true"
                            }
                            // autoplay="0"
                            // width="100%"
                            height="300"
                          />

                          {/* <embed
                                src={emp.file_name + "#toolbar=0"}
                                value={emp.file_name + "#toolbar=0"}
                                title=""
                                scrolling="no"
                                className="img-responsive"
                                style={{ width: "100%", height: "300" }}
                              ></embed> */}
                          {/* </div> */}
                          {/* </div> */}
                        </a>
                      );
                    } else if (
                      emp.file_type == ".xlsx" ||
                      emp.file_type == ".xls"
                    ) {
                      return (
                        <a>
                          {/* <div
                            id="Iframe-Master-CC-and-Rs"
                            class="set-margin set-padding set-border set-box-shadow center-block-horiz"
                          >
                            <div
                              class="responsive-wrapper
                                         responsive-wrapper-wxh-572x612"
                              style={{
                                WebkitOverflowScrolling: "touch",
                                // overflow: "auto",
                              }}
                            > */}
                          <iframe
                            className="img-responsive"
                            // src={emp.file_name + "#toolbar=0"}

                            // <iframe src="https://view.officeapps.live.com/op/embed.aspx?src=your_excel_file_url" width="100%" height="600px" frameborder="0">

                            src={
                              "https://view.officeapps.live.com/op/embed.aspx?src=" +
                              emp.file_name //+  "&embedded=true"
                            }
                            // autoplay="0"
                            // width="100%"
                            height="300"
                          />

                          {/* <embed
                                src={emp.file_name + "#toolbar=0"}
                                value={emp.file_name + "#toolbar=0"}
                                title=""
                                scrolling="no"
                                className="img-responsive"
                                style={{ width: "100%", height: "300" }}
                              ></embed> */}
                          {/* </div> */}
                          {/* </div> */}
                        </a>
                      );
                    } else if (
                      emp.file_type == ".mp4" ||
                      emp.file_type == ".webm"
                    ) {
                      return (
                        <a>
                          <video
                            controls
                            controlsList="nodownload"
                            className="img-responsive"
                            style={{
                              top: "0",
                              left: "0",
                              width: "100%",
                              backgroundSize: "cover",
                              backgroundColor: "black",
                            }}
                          >
                            <source src={emp.file_name} type="video/mp4" />
                          </video>
                          {/* <embed src={(emp.file_name + "#toolbar=0")} value={emp.file_name + "#toolbar=0"} title="" scrolling="no" className="img-responsive" style={{ top: "0", left: "0", width: "100%", backgroundSize: "cover" }}></embed> */}
                        </a>
                      );
                    } else {
                      return (
                        <a>
                          {/* <iframe
                            src={"https://docs.google.com/gview?url=" + emp.file_name + "&embedded=true"}
                            autoplay="0"
                            className="img-responsive"
                          /> */}
                          <embed
                            src={emp.file_name + "#toolbar=0"}
                            value={emp.file_name + "#toolbar=0"}
                            title=""
                            scrolling="no"
                            className="img-responsive"
                          ></embed>
                        </a>
                      );
                    }
                  })()}
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>

      {/* </Modal>    no using */}

      {modal && (
        <div
          id="modalRegisterForm"
          tabindex="-1"
          role="dialog"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1000,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 0.75)",
            outline: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              inset: "50% auto auto 51%",
              border: "none",
              background: "rgb(165, 217, 207)",
              overflow: "hidden",
              borderRadius: "20px",
              outline: "none",
              padding: "10px",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              height: isMobile ? "400px" : "421px",
              paddingRight: isMobile ? "5%" : "2%",
              width: isMobile ? "80%" : "40%",
              zIndex: -1,
            }}
          >
            <div className="modal-header">
              <button
                type="button"
                className="close"
                onClick={toggleModal}
                style={{ marginTop: "-10px" }}
              >
                ×
              </button>
            </div>

            <div className="App">
              {datavalue.length == 0 ? (
                <img className="loading-img" src={loading} />
              ) : (
                <Slider {...settings}>
                  {datavalue
                    .filter((x) => {
                      if (
                        x.file_type != ".png" ||
                        x.file_type != ".jpg" ||
                        x.file_type != ".jfi" ||
                        x.file_type != ".jfif" ||
                        x.file_type != ".JPG" ||
                        x.file_type != ".jpeg"
                      ) {
                        return x;
                      }
                    })
                    .map((x, i) => {
                      return (
                        <div className="container">
                          <div key={i} className="img-card">
                            {(() => {
                              if (
                                x.file_type == ".pdf" ||
                                x.file_type == ".docx" ||
                                x.file_type == ".doc" ||
                                x.file_type == ".xlsx"
                              ) {
                                return (
                                  <a>
                                    {" "}
                                    <embed
                                      src={x.file_name + "#toolbar=0"}
                                      value={x.file_name + "#toolbar=0"}
                                      title=""
                                      scrolling="no"
                                      className="responsive1"
                                      style={{ width: "89%", height: "290px" }}
                                    ></embed>
                                  </a>
                                );
                              } else if (x.file_type == ".mp4") {
                                return (
                                  <a>
                                    {" "}
                                    <video
                                      className="responsive1"
                                      width="89%"
                                      height="290px"
                                      controls
                                      controlsList="nodownload"
                                    >
                                      <source
                                        src={x.file_name}
                                        type="video/mp4"
                                      />
                                    </video>
                                    {/* <embed src={(x.file_name + "#toolbar=0")} value={x.file_name + "#toolbar=0"} title="" scrolling="no" className="responsive1" style={{ width: '89%', height: '290px' }}></embed> */}
                                  </a>
                                );
                              } else {
                                return (
                                  <a>
                                    {/* <iframe
                                    src={"https://docs.google.com/gview?url=" + x.file_name + "&embedded=true"}
                                    autoplay="0"
                                    style={{ width: "89%", height: "290px" }}
                                    className="responsive1"
                                  /> */}
                                    <embed
                                      src={x.file_name + "#toolbar=0"}
                                      value={x.file_name + "#toolbar=0"}
                                      title=""
                                      scrolling="no"
                                      style={{ width: "89%", height: "290px" }}
                                      className="responsive1"
                                    ></embed>
                                  </a>
                                );
                              }
                            })()}
                          </div>
                        </div>
                      );
                    })}
                </Slider>
              )}
            </div>
          </div>
        </div>
      )}

      <PhotoProvider
      // speed={() => 800}
      // easing={(type) =>
      //   type === 2
      //     ? "cubic-bezier(0.36, 0, 0.66, -0.56)"
      //     : "cubic-bezier(0.34, 1.56, 0.64, 1)"
      // }
      >
        {imageValue
          .filter((x) => {
            if (
              x.file_type == ".png" ||
              x.file_type == ".jpg" ||
              x.file_type == ".jfi" ||
              x.file_type == ".jfif" ||
              x.file_type == ".JPG" ||
              x.file_type == ".webp" ||
              x.file_type == ".jpeg"
            ) {
              return x;
            }
          })
          .map((file, index) => (
            <PhotoView onClose={() => {}} key={index} src={file.file_name}>
              <button
                id={"PreviewImage" + file.file_name + ""}
                style={{ display: "none" }}
              />
            </PhotoView>
          ))}
      </PhotoProvider>
      {/* </Modal> */}
    </div>
  );
}
