import React, { Component } from "react";
import ImagePreview from "./ImagePreview";
import $ from "jquery";
import Progress from "./Progress";
// import "../assets/css/Duplicate.css";
import Popup from "./Popup";
import Likepopup from "./Likepopup";
import Comment from "./Comment";
import Cookies from "js-cookie";
import TaggedFriends from "./TaggedFriends";
import twitterIcon from "../assets/images/home/slider/TwitterIcon.png";
import { DocumentViewer } from "react-documents";
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  WhatsappIcon,
  TwitterIcon,
  XIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookIcon,
} from "react-share";
import { Facebook } from "@material-ui/icons";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from "../Constants/BaseURL";

$(document).ready(function () {
  $(".theme-loader1").fadeOut(1000);
});

$(document).ready(function () {
  //custom button for homepage
  $(".share-btn").click(function (e) {
    $(".networks-5")
      .not($(this).next(".networks-5"))
      .each(function () {
        $(this).removeClass("active");
      });
    $(this).next(".networks-5").toggleClass("active");
  });
});
export default class ResponsePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      user_id: "",
      profile_imagePath: "",
      profile_name: "",
      upload_id: "",
      created_date: "",
      upload_description: "",
      upload_amount: "",
      upload_place: "",
      upload_title: "",
      status_80G: "",
      upload_num_of_beneficiaries: "",
      category_id: "",
      category_name: "",
      delete_status: false,
      file_name: "",
      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      response_pledge: "",
      response_message: "",
      emps: [],
      comm: [],
      like: [],
      share: [],
      notify: [],
      paropakaris: [],
      Tagged: [],
      Liked: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      Causefor_Id: [],
      detailsss: [],
      invite: [],
      friends: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      search: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      yoursuprt: [],
      sentsuccess: [],
      others: [],
      sort: [],
      mostrecent: [],
      mostpopular: [],
      available80g: [],
      amountdesc: [],
      amountasc: [],
      complete: [],
      incomplete: [],
      education: [],
      enddate: [],
      causes: [],
      privacypolicy: [],
      beneficiaries: [],
      paropakariss: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      tag: [],
      likess: [],
      comment: [],
      donateasmile: [],
      year: [],
      activity: [],
      mycauses: [],
      likedcauses: [],
      commentedcauses: [],
      pledgedcauses: [],
      langID: [],
      foo: [],
      HappinessMeter: [],
      Achieved: [],
      OnItsWay: [],
      Makeadifferencewithpledgeof: [],
      Writemessageto: [],
      Thecreatorcause: [],
      isresponsibleforproviding: [],
      clm: [],
      Paropakaritakesnoresponsibility: [],
      DonateNow: [],
      ScanandDonatewithAPP: [],
      CommentHere: [],
      YourMessagetotheNeedy: [],
      YourofSupport: [],
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      Submit: [],
      EndDate: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      //cookie
      language: "",
      previousCookieLang: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.initialState = this.state;
    this.handleMsg = this.handleMsg.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.handleOrder = this.handleOrder.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    this.handleSubmitcollect = this.handleSubmitcollect.bind(this);
    this.handleSubmitcollecttt = this.handleSubmitcollecttt.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login",
    };
    this.props.history.push(page);
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  handleSubmitcollect = async (event) => {
    event.preventDefault();
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    const upload_id = urll.searchParams.get("upload_id");

    var percent = document.getElementById("response_Pledge").value;

    if (percent == "") {
      let lang1 = localStorage.getItem("GoogleTranslate123");

      //ta  te   hi  kn  en
      let ConLang1;

      if (lang1 === "ta") {
        // alert("ta");
        ConLang1 =
          "தொடர்வதற்கு முன், நீங்கள் அடமானம் வைக்க விரும்பும் தொகையை உள்ளிடவும்";
      } else if (lang1 === "te") {
        //  alert("te");
        ConLang1 =
          "దయచేసి మీరు కొనసాగే ముందు మీరు తాకట్టు పెట్టాలనుకుంటున్న మొత్తాన్ని నమోదు చేయండి";
      } else if (lang1 === "hi") {
        //  alert("hi");
        ConLang1 =
          "कृपया आगे बढ़ने से पहले वह राशि दर्ज करें जिसे आप गिरवी रखना चाहते हैं";
      } else if (lang1 === "es") {
        //  alert("hi");
        ConLang1 =
          "Por favor, introduzca la cantidad que desea donar antes de continuar";
      } else if (lang1 === "kn") {
        //  alert("karan");
        ConLang1 =
          "ನೀವು ಮುಂದುವರಿಯುವ ಮೊದಲು ನೀವು ವಾಗ್ದಾನ ಮಾಡಲು ಬಯಸುವ ಮೊತ್ತವನ್ನು ನಮೂದಿಸಿ";
      } else if (lang1 === "English") {
        // alert("English");
        ConLang1 =
          "Please enter the amount you want to pledge before you proceed";
      } else if (lang1 === "en") {
        // alert("English");
        ConLang1 =
          "Please enter the amount you want to pledge before you proceed";
      }
      alert(ConLang1);
    } else {
      var num = document.getElementById("amount").value;
      // var total = (num / 100) * percent;
      const total = (num / 100) * percent;
      var out = Math.ceil(total);

      localStorage.setItem("Amount", JSON.stringify(out));

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      const phone = document.getElementById("profile_mobileNumber").value;
      const address = document.getElementById("profile_location").value;
      const benename = document.getElementById("profile_name").value;
      const bankAccount = document.getElementById("upload_BankAccount").value;
      const ifsc = document.getElementById("upload_IFSC").value;
      const upload_UPIIDd = document.getElementById("upload_UPIID").value;
      const email = document.getElementById("profile_email").value;
      //const upload_id=document.getElementById("upload_id").value

      //const data = new FormData(event.target);
      let Amount = JSON.parse(localStorage.getItem("Amount"));

      const datas = {
        customer_email: email,
        customer_name: benename,
        customer_phone: phone,
        customer_bank_account_number: bankAccount,
        customer_bank_ifsc: ifsc,
      };
      //https://apitest.paropakari.com/api/GetProfile/SupportCause?response_message=' + response_message + '&response_pledge=' + response_pledge + '&upload_id=' + this.props.match.params.id
      const url =
        "https://apitest.paropakari.com/api/Payment/Collectmoney?benename=" +
        benename +
        "&phone=" +
        phone +
        "&email=" +
        email +
        "&order_amount=" +
        Amount +
        "&order_currency=INR";

      const options = {
        method: "POST",
        headers: {
          // 'Content-Type': 'application/json; charset=utf-8',
          "Access-Control-Allow-Origin": "*",
          // 'Access-Control-Allow-Headers':'Access-Control-Request-Headers',
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: datas,
      };

      // this.setState(this.initialState)
      try {
        const response = await fetch(url, options);
        const result = await response.json();
        // localStorage.setItem('benefId', JSON.stringify(result.benefId));
        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        localStorage.setItem("order_token", JSON.stringify(result.order_token));
        localStorage.setItem("order_id", JSON.stringify(result.order_id));
        if (response.ok) {
          var profile_location =
            document.getElementById("profile_location").value;
          var profile_mobileNumber = document.getElementById(
            "profile_mobileNumber"
          ).value;
          var profile_name = document.getElementById("profile_name").value;
          var profile_email = document.getElementById("profile_email").value;

          var upload_IFSC = document.getElementById("upload_IFSC").value;
          var upload_UPIID = document.getElementById("upload_UPIID").value;
          var upload_BankAccount =
            document.getElementById("upload_BankAccount").value;
          // var upload_id=document.getElementById("upload_id").value

          this.props.history.push({
            pathname: "/SamplePayment",
            state: {
              data: {
                profile_email: profile_email,
                profile_location: profile_location,
                profile_mobileNumber: profile_mobileNumber,
                profile_name: profile_name,
                upload_IFSC: upload_IFSC,
                upload_UPIID: upload_UPIID,
                upload_BankAccount: upload_BankAccount,
                upload_id: this.props.match.params.id,
                response_message: response_message,

                // upload_id:upload_id
              },
            },
          });
        } else {
          alert("Something went wrong");

          //
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  getuser = () => {
    let url = "https://apitest.paropakari.com/api/GetProfile/Get";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          detailsss: data,
        });

        //
      });
  };

  submit = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        let final = data.map((e) => {
          if (e) {
            let img = null;
            if (e.imageData !== null) {
              img = e.imageData.split(",").map((img) => {
                return {
                  file_name: img.split("$$$")[0],
                  file_type: img.split("$$$")[1],
                };
              });
            }
            return {
              ...e,
              data: e.imageData === null ? [] : img,
            };
          }
        });
        this.setState({
          emps: [],
        });
        this.setState({
          emps: final,
        });
      });
  };
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    localStorage.clear();
    window.location.href = "#/Login";
  };
  AddLiking = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    const url = "https://apitest.paropakari.com/api/GetProfile/Add_LikeDetails";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();

      if (response.ok) {
        // Assuming the response has a 'success' field

        let url =
          "https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" +
          this.props.match.params.id;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            let final = data.map((e) => {
              if (e) {
                let img = null;
                if (e.imageData !== null) {
                  img = e.imageData.split(",").map((img) => {
                    return {
                      file_name: img.split("$$$")[0],
                      file_type: img.split("$$$")[1],
                    };
                  });
                }
                return {
                  ...e,
                  data: e.imageData === null ? [] : img,
                };
              }
            });

            this.setState({
              emps: final,
            });
          });
      } else {
        console.error("Failed to update like count");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if necessary
    }
  };

  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        localStorage.setItem("noticount", JSON.stringify(data[0].noticount));
        //
      });
  };

  comment = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_Comment?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          comm: data,
        });

        //
      });
  };

  Tagged = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_TagDetails?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Tagged: data,
        });

        //
      });
  };

  Liked = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_LikedUsers?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Liked: data,
        });

        //
      });
  };

  paropakaris = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisDetails?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          paropakaris: data,
        });

        //
      });
  };

  like = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisCount?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          like: data,
        });
        //
      });
  };

  ClaimAmountDetail = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_AmountDetail?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          clm: data,
        });

        if (document.getElementById("claimamount")) {
          var ClaimAmount = document.getElementById("claimamount").value;
          //           alert('Test1');
          // alert('Test'+ JSON.stringify(data[0].claimAmount));

          window.sessionStorage.setItem("ClaimAmount", ClaimAmount);
        }
      });
  };

  handleMsg = (event) => {
    // alert("called");
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    const upload_id = urll.searchParams.get("upload_id");
    event.preventDefault();

    var percent = document.getElementById("response_Pledge").value;

    var num = document.getElementById("amount").value;
    var upload_title = document.getElementById("upload_title").value;

    var out = (num / 100) * percent;

    localStorage.setItem("Amount", JSON.stringify(out));

    const data = new FormData(event.target);

    let lang = localStorage.getItem("GoogleTranslate123");
    if(response_pledge >= 1){

    //ta  te   hi  kn  en
    let ConLang;

    if (lang === "ta") {
      // alert("ta");
      ConLang =
        "நீங்கள் " + response_pledge + "% ஆதரவளிக்கப் போகிறீர்களா? சரியா?";
    } else if (lang === "te") {
      //  alert("te");
      ConLang =
        "మీరు " + response_pledge + "% మద్దతు ఇవ్వబోతున్నారా? సరియైనదా?";
    } else if (lang === "hi") {
      //  alert("hi");
      ConLang =
        "क्या आप " + response_pledge + "% का समर्थन करने जा रहे हैं? सही?";
    } else if (lang === "es") {
      //  alert("hi");
      ConLang = "Estás a punto de apoyar " + response_pledge + "%? ¿Correcto?";
    } else if (lang === "kn") {
      //  alert("karan");
      ConLang = "ನೀವು " + response_pledge + "% ಅನ್ನು ಬೆಂಬಲಿಸುತ್ತೀರಾ? ಸರಿ?";
    } else if (lang === "English") {
      // alert("English");
      ConLang = "You are about to support " + response_pledge + "%? Correct?";
    } else if (lang === "en") {
      // alert("English");
      ConLang = "You are about to support " + response_pledge + "%? Correct?";
    }
    var x = window.confirm(ConLang);

    if (x) {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(
        "https://apitest.paropakari.com/api/GetProfile/SupportCauses?upload_id=" +
          this.props.match.params.id,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: data,
        }
      );

      alert(
        this.state.yoursuprt.result +
          " " +
          upload_title +
          "" +
          " " +
          this.state.sentsuccess.result +
          "!"
      );

      window.location.href = "#/HomePage";
    }
  }
  else
  {
    let langs = localStorage.getItem("GoogleTranslate123");

    //ta  te   hi  kn  en
    let ConLangs;

    if (langs === "ta") {
      // alert("ta");
      ConLangs = "குறைந்தபட்ச ஆதரவு குறைந்தபட்சம் 1% ஆக இருக்க வேண்டும்";
    } else if (lang === "te") {
      //  alert("te");
      ConLangs = "కనీస మద్దతు కనీసం 1% ఉండాలి";
    } else if (lang === "hi") {
      //  alert("hi");
      ConLangs = "न्यूनतम समर्थन कम से कम 1% होना चाहिए";
    } else if (langs === "es") {
      //  alert("hi");
      ConLangs = "El apoyo mínimo debe ser al menos del 1%.";
    } else if (langs === "kn") {
      //  alert("karan");
      ConLangs = "ಕನಿಷ್ಠ ಬೆಂಬಲವು ಕನಿಷ್ಠ 1% ಆಗಿರಬೇಕು";
    } else if (lang === "English") {
      // alert("English");
      ConLangs = "Minimum support should be atleast 1%";
    } else if (langs === "en") {
      // alert("English");
      ConLangs = "Minimum support should be atleast 1%";
    }
    alert(ConLangs);
  }
  };

  handlePayment = (event) => {
    const { response_message, response_pledge } = this.state;
    const urll = new URL(window.location.href);
    // const upload_id = urll.searchParams.get("upload_id");
    event.preventDefault();

    var percent = document.getElementById("response_Pledge").value;

    var num = document.getElementById("amount").value;

    var out = (num / 100) * percent;

    var upload_id = document.getElementById("upload_id").value;
    localStorage.setItem("Amount", JSON.stringify(out));
    localStorage.setItem("upload_id", JSON.stringify(upload_id));

    const data = new FormData(event.target);

    // alert("called1");

    let lang = localStorage.getItem("GoogleTranslate123");

    //ta  te   hi  kn  en
    let ConLang;

    if (lang === "ta") {
      // alert("ta");
      ConLang =
        "நீங்கள் " + response_pledge + "% ஆதரவளிக்கப் போகிறீர்களா? சரியா?";
    } else if (lang === "te") {
      //  alert("te");
      ConLang =
        "మీరు " + response_pledge + "% మద్దతు ఇవ్వబోతున్నారా? సరియైనదా?";
    } else if (lang === "hi") {
      //  alert("hi");
      ConLang =
        "क्या आप " + response_pledge + "% का समर्थन करने जा रहे हैं? सही?";
    } else if (lang === "es") {
      //  alert("hi");
      ConLang = "Estás a punto de apoyar " + response_pledge + "%? ¿Correcto?";
    } else if (lang === "kn") {
      //  alert("karan");
      ConLang = "ನೀವು " + response_pledge + "% ಅನ್ನು ಬೆಂಬಲಿಸುತ್ತೀರಾ? ಸರಿ?";
    } else if (lang === "English") {
      // alert("English");
      ConLang = "You are about to support " + response_pledge + "%? Correct?";
    } else if (lang === "en") {
      // alert("English");
      ConLang = "You are about to support " + response_pledge + "%? Correct?";
    }
    var x = window.confirm(ConLang);
    if (x) {
      //ta  te   hi  kn  en

      if (out < 10) {
        let langs = localStorage.getItem("GoogleTranslate123");

        //ta  te   hi  kn  en
        let ConLangs;

        if (langs === "ta") {
          // alert("ta");
          ConLangs = "குறைந்தபட்ச அடமானத் தொகை ரூ 10/- ஆக இருக்க வேண்டும்";
        } else if (lang === "te") {
          //  alert("te");
          ConLangs = "కనిష్ట తాకట్టు మొత్తం రూ. 10/- ఉండాలి";
        } else if (lang === "hi") {
          //  alert("hi");
          ConLangs = "न्यूनतम प्रतिज्ञा राशि 10/- रुपये होनी चाहिए";
        } else if (langs === "es") {
          //  alert("hi");
          ConLangs = "El importe mínimo de la promesa debe ser de 10 rupias/-";
        } else if (langs === "kn") {
          //  alert("karan");
          ConLangs = "ಕನಿಷ್ಠ ವಾಗ್ದಾನ ಮೊತ್ತವು ರೂ 10/- ಆಗಿರಬೇಕು";
        } else if (lang === "English") {
          // alert("English");
          ConLangs = "Minimum pledge amount should be Rs 10/-";
        } else if (langs === "en") {
          // alert("English");
          ConLangs = "Minimum pledge amount should be Rs 10/-";
        }
        alert(ConLangs);
      } else {
        //alert(response_message);

        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        fetch(
          "https://apitest.paropakari.com/api/GetProfile/SupportCauses?upload_id=" +
            this.props.match.params.id,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + accessToken,
            },
            body: data,
          }
        );

        //alert("Your Suuport sent successfully");

        this.handleOrder(event);
      }
    }
    //
    //  var Paymentt= {

    //   pathname: '#/SamplePayment',
    //   state: {
    //     data:{

    //      name: this.state.BeneficiaryName,
    //      id:1
    //     }
    //     }
    //   }

    //   this.props.history.push(Paymentt)
    // window.location.href = "#/SamplePayment";

    //   var profile_location= document.getElementById("profile_location").value
    //   var profile_mobileNumber= document.getElementById("profile_mobileNumber").value
    //   var profile_name= document.getElementById("profile_name").value
    //   var profile_email= document.getElementById("profile_email").value

    //   var upload_IFSC= document.getElementById("upload_IFSC").value
    //   var upload_UPIID= document.getElementById("upload_UPIID").value
    //   var upload_BankAccount= document.getElementById("upload_BankAccount").value
    // var upload_id=this.props.match.params.id;
    //

    //   this.props.history.push( {pathname: "/SamplePayment",
    //   state: { data:{
    //     profile_email:profile_email,
    //     profile_location: profile_location,
    //     profile_mobileNumber:profile_mobileNumber,
    //     profile_name:profile_name,
    //     upload_IFSC:upload_IFSC,
    //     upload_UPIID:upload_UPIID,
    //     upload_BankAccount:upload_BankAccount,
    //     upload_id:upload_id
    //         } }});
    //
  };

  handleOrder = async (event) => {
    event.preventDefault();

    var percent = document.getElementById("response_Pledge").value;

    var num = document.getElementById("amount").value;
    const rawValue1 = (num / 100) * percent;
    var out = Math.ceil(rawValue1);

    localStorage.setItem("Amount", JSON.stringify(out));
    const rawValue = (num / 100) * percent;
    const newPercent = Math.ceil(rawValue);
    this.setState({ downPaymentPercent: newPercent });

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    const url =
      "https://apitest.paropakari.com/api/GetProfile/CreateOrder?amount=" +
      newPercent;
    const options = {
      method: "POST",

      headers: {
        Accept: "application/json",
        // "Access-Control-Allow-Headers" : "Content-Type",
        // "Access-Control-Allow-Headers" : "Content-Type,DNT,X-CustomHeader,Keep-Alive,User-Agent,X-Requested-With,If-Modified-Since,Cache-Control,Content-Type,Authorization",
        //   "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
        // 'Access-Control-Allow-Origin': '*',
        "Content-type": "application/json",
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        Authorization: "Bearer " + accessToken,
      },
      body: "data",
    };

    const response = await fetch(url, options);
    const result = await response.json();
    //  alert(result);
    localStorage.setItem("order_id", JSON.stringify(result.id));
    window.sessionStorage.setItem("order_id", result.id);
    localStorage.setItem("razorpay_amount", JSON.stringify(result.amount));
    localStorage.setItem("status", JSON.stringify(result.status));
    if (response.ok) {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      //alert(result.id);
      var percent = document.getElementById("response_Pledge").value;

      var num = document.getElementById("amount").value;
      const rawValue1 = (num / 100) * percent;
      var out = Math.ceil(rawValue1);

      localStorage.setItem("Amount", JSON.stringify(out));
      const rawValue = (num / 100) * percent;
      const newPercent = Math.ceil(rawValue);
      this.setState({ downPaymentPercent: newPercent });

      var profile_name = document.getElementById("profile_name").value;

      var profile_location = document.getElementById("profile_location").value;
      var upload_BankAccount =
        document.getElementById("upload_BankAccount").value;
      var upload_IFSC = document.getElementById("upload_IFSC").value;
      var upload_UPIID = document.getElementById("upload_UPIID").value;
      var profile_email = document.getElementById("profile_email").value;
      //
      var profile_mobileNumber = document.getElementById(
        "profile_mobileNumber"
      ).value;
      //
      var user_id = document.getElementById("user_id").value;
      //

      var user_name = document.getElementById("user_name").value;
      //
      var user_number = document.getElementById("user_number").value;
      //
      var user_Bankac = document.getElementById("user_Bankac").value;
      //
      var user_IFSC = document.getElementById("user_IFSC").value;
      //
      var user_UPIID = document.getElementById("user_UPIID").value;
      //
      var user_email = document.getElementById("user_email").value;
      //
      var user_location = document.getElementById("user_location").value;
      //
      let order_id = JSON.parse(localStorage.getItem("order_id"));
      //
      window.sessionStorage.setItem("order_id", order_id);
      //
      window.sessionStorage.setItem("name", user_name);
      //
      window.sessionStorage.setItem("Email", user_email);
      //
      window.sessionStorage.setItem("MobileNumber", user_number);
      //
      window.sessionStorage.setItem("Amount", out);
      //
      window.sessionStorage.setItem("Location", user_location);
      //
      let razorpay_amount = out;
      //
      let status = JSON.parse(localStorage.getItem("status"));

      var datas = {
        upload_id: this.props.match.params.id,
        Donor_contribution: newPercent,
        Donor_AccountNumber: user_Bankac,
        Donor_IFSC: user_IFSC,
        Donor_UPI: user_UPIID,
        Donor_email: user_email,
        Donor_mobilenumber: user_number,
        Donor_Address: user_location,
        Beneficiary_userid: user_id,
        Beneficiary_name: profile_name,
        Donor_name: user_name,
        Beneficiary_email: profile_email,
        Beneficiary_mobilenumber: profile_mobileNumber,
        Beneficiary_AccountNumber: upload_BankAccount,
        Beneficiary_IFSC: upload_IFSC,
        Beneficiary_UPI: upload_UPIID,
        Order_id: order_id,
        Order_amount: razorpay_amount,
        Order_created_date: "21",
        Order_status: status,
        Dpercentageofsupport: percent,
        Payment_id: "23",
        Paymenta_amount: "24",
        Payment_created_date: "25",
        Payment_status: "26",
      };

      var urll = "https://apitest.paropakari.com/api/GetProfile/Add_Order";

      var optionss = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",

          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: JSON.stringify(datas),
      };

      try {
        const response = await fetch(urll, optionss);
        const result = await response.json();

        // localStorage.setItem('username', JSON.stringify(result.name));
        // localStorage.setItem('userid', JSON.stringify(result.userid));
        ///localStorage.setItem('create', JSON.stringify(result.id));

        if (response.ok) {
          this.handleSubmitcollecttt();

          // this.props.history.push( {pathname: "/Loader",
          // state: { data:{
          //   profile_email:this.props.location.state.data.profile_email,
          //   profile_location: this.props.location.state.data.profile_location,
          //   profile_mobileNumber:this.props.location.state.data.profile_mobileNumber,
          //   profile_name:this.props.location.state.data.profile_name,
          //   upload_IFSC:this.props.location.state.data.upload_IFSC,
          //   upload_UPIID:this.props.location.state.data.upload_UPIID,
          //   upload_BankAccount:this.props.location.state.data.upload_BankAccount,
          //   upload_id:this.props.location.state.data.upload_id,
          //   cf_payment_id:result.cf_payment_id
          //       } }});
        } else {
          alert("Failed!. Try Again");

          //
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  handleSubmitcollecttt = () => {
    var percent = document.getElementById("response_Pledge").value;

    //Suppose u want 3 number of character
    if (percent <= 100) {
      var num = document.getElementById("amount").value;
      const rawValue1 = (num / 100) * percent;
      var out = Math.ceil(rawValue1);

      localStorage.setItem("Amount", JSON.stringify(out));
      const rawValue = (num / 100) * percent;
      const newPercent = Math.ceil(rawValue);
      this.setState({ downPaymentPercent: newPercent });

      var profile_name = document.getElementById("profile_name").value;
      //
      var profile_email = document.getElementById("profile_email").value;
      //
      var profile_mobileNumber = document.getElementById(
        "profile_mobileNumber"
      ).value;
      //
      var status_80G = document.getElementById("status_80G").value;
      //
      var upload_BankAccount =
        document.getElementById("upload_BankAccount").value;
      //
      var upload_IFSC = document.getElementById("upload_IFSC").value;
      //
      var upload_UPIID = document.getElementById("upload_UPIID").value;
      //
      let order_id = JSON.parse(localStorage.getItem("order_id"));
      //
      let razorpay_amount = JSON.parse(localStorage.getItem("razorpay_amount"));
      //
      let status = JSON.parse(localStorage.getItem("status"));
      //
      if (status_80G == 0) {
        this.props.history.push({
          pathname: "/DonateSmileWithout80G",

          state: {
            data: {
              amount: newPercent,
              name: profile_name,
              status_80g: status_80G,
              mobilenumber: profile_mobileNumber,
              email: profile_email,
              bankaccount: upload_BankAccount,
              ifsc: upload_IFSC,
              order_id: order_id,
              status: status,
              upload_id: this.props.match.params.id,
            },
          },
        });
      } else {
        this.props.history.push({
          pathname: "/DonateaSmile",

          state: {
            data: {
              amount: newPercent,
              name: profile_name,
              status_80g: status_80G,
              mobilenumber: profile_mobileNumber,
              email: profile_email,
              bankaccount: upload_BankAccount,
              ifsc: upload_IFSC,
              order_id: order_id,
              status: status,
              upload_id: this.props.match.params.id,
            },
          },
        });
      }

      // window.location.href = 'http://localhost:51827/Paropakari/Donate_a_Smile/?amount='+ newPercent + '&name=' + profile_name + '&status_80g=' + status80G +'&mobilenumber=' +profile_mobileNumber + '&email=' + profile_email + '&bankaccount=' +upload_BankAccount +'&ifsc=' + upload_IFSC ;
      var id = 9;
      //https://apitest.paropakari.com/api/GetProfile/SupportCause?response_message=' + response_message + '&response_pledge=' + response_pledge + '&upload_id=' + this.props.match.params.id
      // var url = 'http://localhost:51827/Paropakari/Donate_a_Smile/?amount=1000&name=Sush&status_80g=yes&mobilenumber=8548820436&email=sushmamt@gmail.com&bankaccount=sd5515151&ifsc=SBI56545455'
      // $.ajax({
      //         type: "GET",
      //         url: url,
      //         data: {},
      //         dataType: "html",
      //         header:{
      //           'Access-Control-Allow-Origin': '*',
      //         "Access-Control-Allow-Credentials" : "true",
      //         "Access-Control-Allow-Headers":"*",
      //         "Access-Control-Allow-Methods": "*"
      //       },
      //         success: function(data) { alert('got here with data');
      //         // window.location.href = 'http://localhost:51827/Event/Donation_Razorpay'
      //       },
      //         error: function() { alert('something bad happened');

      //       }
      //       });
    }
  };

  handleClick = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch("https://apitest.paropakari.com/api/GetProfile/Add_Comment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    });

    //alert("Added your Comment Successfully!");
  };
  // Footer() {

  //

  // let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
  //   let data = this.state;

  //
  //
  //   fetch(url, {
  //       method: 'GET',
  //       headers: {
  //         "Content-type": "application/json",
  //         "Accept": "application/json",

  //         "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //       },
  //       //body:JSON.stringify(data)
  //     }).then(response => response.json())
  //     .then(data => {
  //       this.setState({
  //         foo: data
  //       });
  //
  //        //
  //     });
  //}

  ReloadPage = () => {
    let reloadsmyactivity = 0;
    if (sessionStorage.getItem("responsepagereload") !== null) {
      reloadsmyactivity = sessionStorage.getItem("responsepagereload");
      reloadsmyactivity++;
      window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    } else {
      reloadsmyactivity = 1;
      window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    }

    if (reloadsmyactivity == 1) {
      window.location.reload();
    }
  };

  DefaultMenu() {
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("donateasmile", reloadsmyactivity);
    window.sessionStorage.setItem("donatesmile", reloadsmyactivity);
    window.sessionStorage.setItem("donateasmilewithout80g", reloadsmyactivity);
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    let datatrans =
      Language_value === undefined ? "en" : Language_value.split("/")[2];
    localStorage.setItem("GoogleTranslate123", datatrans);

    let GID = sessionStorage.getItem("this.state.googtrans");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;

          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;

          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var ContactUs = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition =
            typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: ContactUs,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Educattion = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~Anticipated~Make a difference with pledge of~Write a message to&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Makeadifferencewithpledgeof =
            typeof items[8] !== "undefined" ? items[8] : null;
          var Writemessageto =
            typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=The creator of the cause~is responsible for providing any documents~Paropakari.com takes no responsibility for these documents~Donate a Smile~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~End Date~Share&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Thecreatorcause =
            typeof items[0] !== "undefined" ? items[0] : null;
          var isresponsibleforproviding =
            typeof items[1] !== "undefined" ? items[1] : null;

          var Paropakaritakesnoresponsibility =
            typeof items[2] !== "undefined" ? items[2] : null;
          var DonateNow = typeof items[3] !== "undefined" ? items[3] : null;
          var ScanandDonatewithAPP =
            typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var YourMessagetotheNeedy =
            typeof items[6] !== "undefined" ? items[6] : null;
          var YourofSupport = typeof items[7] !== "undefined" ? items[7] : null;

          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var EndDate = typeof items[9] !== "undefined" ? items[9] : null;
          var Share = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,
            Submit: Submit,
            EndDate: EndDate,
            share: Share,
            langID: LangID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Friends&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Friends = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = datatransID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            friends: Friends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Your support for~is sent successfully&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var yoursuprt = typeof items[2] !== "undefined" ? items[2] : null;
          var sentsuccess = typeof items[3] !== "undefined" ? items[3] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            yoursuprt: yoursuprt,
            sentsuccess: sentsuccess,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;

          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;

          var Complete = typeof items[1] !== "undefined" ? items[1] : null;
          var Incomplete = typeof items[2] !== "undefined" ? items[2] : null;

          var Howitwork = typeof items[3] !== "undefined" ? items[3] : null;
          var Blog = typeof items[4] !== "undefined" ? items[4] : null;

          var Contactus = typeof items[5] !== "undefined" ? items[5] : null;
          var Termsandcondition =
            typeof items[6] !== "undefined" ? items[6] : null;
          var CopyRight = typeof items[7] !== "undefined" ? items[7] : null;
          var Causes = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;
          var Educattion = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Educattion,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~Anticipated~Make a difference with pledge of~Write a message to&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Makeadifferencewithpledgeof =
            typeof items[8] !== "undefined" ? items[8] : null;
          var Writemessageto =
            typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Makeadifferencewithpledgeof: Makeadifferencewithpledgeof,
            Writemessageto: Writemessageto,
            langID: LangID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Friends = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            friends: Friends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~Your support for~is sent successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var yoursuprt = typeof items[2] !== "undefined" ? items[2] : null;
          var sentsuccess = typeof items[3] !== "undefined" ? items[3] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            yoursuprt: yoursuprt,
            sentsuccess: sentsuccess,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=The creator of the cause~is responsible for providing any documents~Paropakari.com takes no responsibility for these documents~Donate a Smile~Scan and Donate with APP~Comment Here~Your Message to the Needy~Your of Support~Submit~End Date~Share&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Thecreatorcause =
            typeof items[0] !== "undefined" ? items[0] : null;
          var isresponsibleforproviding =
            typeof items[1] !== "undefined" ? items[1] : null;

          var Paropakaritakesnoresponsibility =
            typeof items[2] !== "undefined" ? items[2] : null;
          var DonateNow = typeof items[3] !== "undefined" ? items[3] : null;
          var ScanandDonatewithAPP =
            typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var YourMessagetotheNeedy =
            typeof items[6] !== "undefined" ? items[6] : null;
          var YourofSupport = typeof items[7] !== "undefined" ? items[7] : null;

          var Submit = typeof items[8] !== "undefined" ? items[8] : null;
          var EndDate = typeof items[9] !== "undefined" ? items[9] : null;
          var Share = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            Thecreatorcause: Thecreatorcause,
            isresponsibleforproviding: isresponsibleforproviding,
            Paropakaritakesnoresponsibility: Paropakaritakesnoresponsibility,
            DonateNow: DonateNow,
            ScanandDonatewithAPP: ScanandDonatewithAPP,
            CommentHere: CommentHere,
            YourMessagetotheNeedy: YourMessagetotheNeedy,
            YourofSupport: YourofSupport,
            Submit: Submit,
            EndDate: EndDate,
            share: Share,
            langID: LangID,
          });
        });
    }
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.comment);
    this.comment();
    window.addEventListener("load", this.like);
    this.like();
    window.addEventListener("load", this.paropakaris);
    this.paropakaris();
    window.addEventListener("load", this.Tagged);
    this.Tagged();
    window.addEventListener("load", this.Liked);
    this.Liked();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.ClaimAmountDetail);
    this.ClaimAmountDetail();
    window.addEventListener("load", this.getuser);
    this.getuser();
    // window.addEventListener('load', this.Footer);
    // this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const elements = document.getElementById("goog-gt-vt");
    if (elements) {
      elements.style.display = "none"; // Hide the element if it exists
    }
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    // const Script = document.createElement("script");
    // //id should be same as given to form element
    // const Form = document.getElementById('donateForm');
    // Script.setAttribute('src','https://checkout.razorpay.com/v1/payment-button.js')
    // Script.setAttribute('data-payment_button_id','pl_J4vFND8HkysKSE')
    // Form.appendChild(Script);

    document.title = "India's only expenseless, direct crowdfunding platform";
    this.interval = setInterval(this.checkAndSetLanguage, 500); // Check every second
    this.checkAndSetLanguage(); // Initial check
  }
  checkAndSetLanguage = () => {
    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    const cookieLang =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    if (cookieLang !== this.state.previousCookieLang) {
      let newLanguage = "";
      let MID = "English";
      if (cookieLang === "en") {
        newLanguage = "English";
      } else if (cookieLang === "kn") {
        newLanguage = "Kannada";
      } else if (cookieLang === "ta") {
        newLanguage = "Tamil";
      } else if (cookieLang === "te") {
        newLanguage = "Telugu";
      } else if (cookieLang === "hi") {
        newLanguage = "Hindi";
      } else if (cookieLang === "es") {
        newLanguage = "Spanish";
      } else if (cookieLang === MID) {
        newLanguage = MID;
      }

      sessionStorage.setItem("translate", newLanguage);
      this.setState(
        { language: newLanguage, previousCookieLang: cookieLang },
        this.DefaultMenu()
      );
    }
  };
  componentWillUnmount() {
    clearTimeout(this.timer);

    window.removeEventListener("load", this.submit);
    this.submit();
    window.removeEventListener("load", this.comment);
    this.comment();
    window.removeEventListener("load", this.like);
    this.like();
    window.removeEventListener("load", this.paropakaris);
    this.paropakaris();
    window.removeEventListener("load", this.Tagged);
    this.Tagged();
    window.removeEventListener("load", this.Liked);
    this.Liked();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    // window.removeEventListener('load', this.Footer);

    // this.Footer();
    window.removeEventListener("load", this.ClaimAmountDetail);
    this.ClaimAmountDetail();
    window.removeEventListener("load", this.getuser);
    this.getuser();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.getElementById("goog-gt-vt");
    if (element) {
      element.style.display = "none"; // Hide the element if it exists
    }
  }

  // handleInputChange = (e) => {
  //   const { target: { name, value } } = e
  //   this.setState({ [name]: value })

  //   switch(name) {

  //     case 'downPaymentPercent' :
  //
  //       const newAmount = parseInt(value/100 * this.state.fullPrice) // Assuming fullPrice set in state
  //       this.setState({ response_pledge: newAmount })
  //
  //       break
  //     case 'response_pledge' :
  //     var percent = document.getElementById("response_Pledge").value;
  //   var num = document.getElementById("amount").value;
  //   var out = num / 100 * percent;
  //
  //
  //

  //   localStorage.setItem('Amount', JSON.stringify(out));
  //
  //       const newPercent =num / 100 * percent ;
  //       this.setState({ downPaymentPercent: newPercent })
  //
  //
  //       break
  //     default:
  //       break
  //   }
  // }
  handleInputChange = (e) => {
    const {
      target: { name, value },
    } = e;
    this.setState({ [name]: value });

    switch (name) {
      case "downPaymentPercent":
        const newAmount = parseInt((value / 100) * this.state.fullPrice); // Assuming fullPrice set in state
        this.setState({ response_pledge: newAmount });

        break;
      case "response_pledge":
        var percent = document.getElementById("response_Pledge")
          ? document.getElementById("response_Pledge").value
          : "";

        //Suppose u want 3 number of character
        if (percent <= 100) {
          var num = document.getElementById("amount").value;
          const rawValue1 = (num / 100) * percent;
          var out = Math.ceil(rawValue1);

          localStorage.setItem("Amount", JSON.stringify(out));
          const rawValue = (num / 100) * percent;
          const newPercent = Math.ceil(rawValue);
          this.setState({ downPaymentPercent: newPercent });

          break;
        } else {
          var str = document.getElementById("response_Pledge").value;
          str = str.substring(0, str.length - (1, 2));
          document.getElementById("response_Pledge").value = str;
        }
    }
  };

  // The 2 inputs

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // handleSubmitevents = (event) => {
  //   event.preventDefault();

  //   if (this.handleFormValidation()) {
  //     alert(' success')
  //     this.setState(this.initialState)
  //
  //   }
  //

  // };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  render() {
    const filecount = 0;
    const { paropakaris } = this.state;
    const { comm } = this.state;
    const { emps, detailsss, clm } = this.state;
    const { like } = this.state;
    const { Tagged } = this.state;
    const { Liked } = this.state;
    const { notify } = this.state;
    var noticount = JSON.parse(localStorage.getItem("noticount"));
    var username = JSON.parse(localStorage.username);
    let { foo } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakariss,
      amountraised,
      copyright,
      yoursuprt,
      sentsuccess,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      search,
      categories,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      others,
      friends,
      sort,
      mostrecent,
      mostpopular,
      available80g,
      amountdesc,
      amountasc,
      complete,
      incomplete,
      enddate,
      tag,
      likess,
      Causefor_Id,
      comment,
      donateasmile,
      year,
      activity,
      mycauses,
      likedcauses,
      commentedcauses,
      pledgedcauses,
      HappinessMeter,
      share,
      Achieved,
      privacypolicy,
      OnItsWay,
      Makeadifferencewithpledgeof,
      Writemessageto,
      Thecreatorcause,
      isresponsibleforproviding,
      YourMessagetotheNeedy,
      YourofSupport,
      Paropakaritakesnoresponsibility,
      DonateNow,
      ScanandDonatewithAPP,
      CommentHere,
      Submit,
      EndDate,
      langID,
      copyright_new,
      paropakari_new,
    } = this.state;
    const currentYear = new Date().getFullYear();

    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          {/*/head*/}
          <div onClick={this.refreshPage}>
            <select
              className="form-control"
              style={{
                display: "none",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
          </div>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div
            id="google_translate_element"
            onClick={this.refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          >
            {/* <button onClick={refreshPage}>Click to reload!</button> */}
          </div>
          {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}} className="notranslate">      
              <div className="container">
                <div className="row">
                
                </div>
              </div>
              <div className="navbar navbar-inverse" role="banner">
                <div className="container">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand"  href="#/HomePage" >
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
          </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                   
                  {(() => {if (langID == 'Tamil' ) {return (
       <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
       {beaparopakari.result}
       </a></li>
);
}
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
           {beaparopakari.result}
           </a></li>

);}

})()}    
        
                                                 
                            
                                   <li className=""><a href="#/Myactivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{myjourney.result}</a></li>     
                                   <li className=""><a href="#/CauseFor" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{createacause.result}</a></li>       
                                   <li className=""><a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{invite.result}</a></li>         
                                   <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                   <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                        <span class="-count"> 
                                         
                                       
                                         {noticount}
                                        
                                           </span> ))}</div>
                                    </a></li>           
                                    
                                   <li className="dropdown"><a href="#/Profile" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{username} <i className="fa fa-angle-down" /></a>
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">{profile.result}</a></li>
                                       <li><a href="#/ChangePassword">{changepassword.result}</a></li>
                                       <li><a href="#/Group">{group.result}</a></li>
                                       <li><a href="#/Login">{logout.result}</a></li>
                                      
                                     </ul>
                                   </li>    
           
                                         
                                 </ul>
                  </div>
                 
                </div>
              </div>
            </header> */}
          <div className="mag notranslate">
            <div class="pro-home-nav">
              <input
                type="checkbox"
                id="pro-home-nav-check"
                onClick={this.checkfun}
              />
              <div class="pro-home-nav-header">
                <div class="pro-home-nav-title notranslate">
                  <a className="navbar-brand" href="#/HomePage">
                    <h1 className="navbar-new-text">
                      <img
                        src="./assets/images/plogo.png"
                        alt="logo"
                        style={{
                          marginBottom: "17px",
                          width: "26%",
                          fontsize: "300px",
                        }}
                      />
                      AROPAKARI
                    </h1>
                  </a>
                </div>
              </div>

              <div class="pro-home-nav-btn">
                <label for="pro-home-nav-check">
                  <div className="pro-naav">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </label>
              </div>

              {langID == "English" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-boxx parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Hindi" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-boxx parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Spanish" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-tamil-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown" style={{ height: "145px" }}>
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Tamil" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center-tamil" style={{ marginRight: "10px" }}>
                      <a className="new-tamil-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown" style={{ height: "145px" }}>
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Kannada" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-telugu-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown" style={{ height: "127px" }}>
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Telugu" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-telugu-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown" style={{ height: "127px" }}>
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {/*/#header*/}
          <div>
            {/*/#page-breadcrumb*/}
            <section
              id="blog"
              className={this.state.movedown == true ? null : "Tiptop"}
              style={{ backgroundColor: "rgb(174, 230, 219)" }}
            >
              <br />
              <div className="container">
                <div className="row">
                  <div className="col-md-7 col-sm-7">
                    <div className="row">
                      {emps.map((emp) => (
                        // <tr key={emp.user_id}>
                        <div className="col-sm-12 col-md-12">
                          {/* <h3 style={{fontsize:'15px'}}><a href={"#/ResponsePage/"+emp.upload_id} style={{fontsize:'20px',textDecoration:'none',color:'black'}}><b>{emp.upload_title} </b></a></h3> */}
                          {(() => {
                            if (emp.profile_name == username) {
                              return (
                                <div>
                                  <h3 style={{ fontsize: "15px" }}>
                                    <a
                                      href={"#/ResponsePage/" + emp.upload_id}
                                      style={{
                                        fontsize: "20px",
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <b>{emp.upload_title}</b>
                                    </a>

                                    {(() => {
                                      if (emp.status_80G == "1") {
                                        return (
                                          <a
                                            style={{
                                              marginLeft: "10px",
                                              fontSize: "15px",
                                              color: "gray",
                                            }}
                                          >
                                            <blink>
                                              <b className="">
                                                {available80g.result}
                                              </b>
                                            </blink>
                                          </a>
                                        );
                                      } else {
                                        return <a></a>;
                                      }
                                    })()}
                                  </h3>
                                  {(() => {
                                    if (emp.causefor_Id == "0") {
                                      return (
                                        <p style={{ float: "" }}>
                                          Required Amount {emp.upload_amount}
                                        </p>
                                      );
                                    } else if (emp.causefor_Id == "1") {
                                      return (
                                        <p style={{ float: "" }}>
                                          Number of Beneficiaries{" "}
                                          {emp.upload_num_of_beneficiaries}
                                        </p>
                                      );
                                    }
                                  })()}
                                </div>
                              );
                            } else {
                              return (
                                <div>
                                  <h3 style={{ fontsize: "15px" }}>
                                    <a
                                      href={"#/ResponsePage/" + emp.upload_id}
                                      style={{
                                        fontsize: "20px",
                                        textDecoration: "none",
                                        color: "black",
                                      }}
                                    >
                                      <b>{emp.upload_title}</b>
                                    </a>
                                    {(() => {
                                      if (emp.status_80G == "1") {
                                        return (
                                          <a
                                            style={{
                                              marginLeft: "10px",
                                              fontSize: "15px",
                                              color: "gray",
                                            }}
                                          >
                                            <blink>
                                              <b className="">
                                                {available80g.result}
                                              </b>
                                            </blink>
                                          </a>
                                        );
                                      } else {
                                        return <a></a>;
                                      }
                                    })()}
                                  </h3>
                                  {(() => {
                                    if (emp.causefor_Id == "0") {
                                      return (
                                        <p style={{ float: "" }}>
                                          Required Amount {emp.upload_amount}
                                        </p>
                                      );
                                    } else if (emp.causefor_Id == "1") {
                                      return (
                                        <p style={{ float: "" }}>
                                          Number of Beneficiaries{" "}
                                          {emp.upload_num_of_beneficiaries}
                                        </p>
                                      );
                                    }
                                  })()}
                                </div>
                              );
                            }
                          })()}
                          <div className="row">
                            <div className="col-lg-4 col-sm-4">
                              <p>{emp.category_name}</p>
                              <input
                                type="hidden"
                                name="upload_title"
                                onChange={this.handlebenfChange}
                                id="upload_title"
                                defaultValue={emp.upload_title}
                              />
                            </div>

                            <div className="col-lg-7 col-sm-7">
                              <h4
                                style={{
                                  fontSize: "15px",
                                  float: "right",
                                  color: "black",
                                }}
                              >
                                <b>{emp.upload_place}</b>
                              </h4>
                            </div>
                            <div className="col-lg-1 col-sm-1">
                              <p></p>
                            </div>
                          </div>
                          <div className="row" id="imagesssss">
                            <div className="col-lg-11 col-sm-8">
                              {emp.file_type == ".png" ||
                              emp.file_type == ".jpg" ||
                              emp.file_type == ".jfi" ||
                              emp.file_type == ".jfif" ||
                              emp.file_type == ".JPG" ||
                              emp.file_type == ".jpeg" ? (
                                <ImagePreview
                                  Id={emp.upload_id}
                                  datavalue={emp.data}
                                >
                                  <a
                                    className="alttag"
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    {" "}
                                    <embed
                                      src={emp.file_name + "#toolbar=0"}
                                      value={emp.file_name + "#toolbar=0"}
                                      title=""
                                      scrolling="no"
                                      className="img-responsive"
                                    ></embed>
                                  </a>
                                </ImagePreview>
                              ) : emp.file_type == ".docx" ||
                                emp.file_type == ".doc" ||
                                emp.file_type == ".xls" ||
                                emp.file_type === ".xlsx" ? (
                                <ImagePreview
                                  Id={emp.upload_id}
                                  datavalue={emp.data}
                                ></ImagePreview>
                              ) : emp.file_type == ".mp4" ||
                                emp.file_type == ".webm" ? (
                                <ImagePreview
                                  Id={emp.upload_id}
                                  datavalue={emp.data}
                                >
                                  <a>
                                    <video
                                      width="100%"
                                      height="300px"
                                      controls
                                      controlsList="nodownload"
                                    >
                                      <source
                                        src={emp.file_name}
                                        type="video/mp4"
                                      />
                                    </video>
                                  </a>
                                </ImagePreview>
                              ) : (
                                emp.file_type == ".pdf" && (
                                  <ImagePreview
                                    Id={emp.upload_id}
                                    datavalue={emp.data}
                                  >
                                    <a>
                                      <embed
                                        src={emp.file_name + "#toolbar=0"}
                                        value={emp.file_name + "#toolbar=0"}
                                        title=""
                                        scrolling="no"
                                        style={{
                                          width: "100%",
                                          height: "300px",
                                          background: "white",
                                        }}
                                      ></embed>
                                    </a>
                                  </ImagePreview>
                                )
                              )}
                            </div>

                            <div className="col-lg-4">
                              <p></p>
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-lg-4 col-sm-4">
                              <p>
                                <b>{emp.profile_name} 's Initiative</b>
                              </p>
                            </div>

                            <div className="col-lg-7">
                              <p style={{ float: "right" }} id="postedon">
                                Posted on &nbsp;{emp.created_date}
                              </p>
                            </div>
                            <div className="col-lg-1">
                              <p></p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-4">
                              <p>
                                Required for {emp.relationship}(
                                {emp.required_for})
                              </p>
                              <p>
                                <b>Description</b>&nbsp;"
                                {emp.upload_description}"
                              </p>
                            </div>

                            {/* <div className="col-lg-7">
           {(() => {if (emp.causefor_Id == '0') {return (
                   <p style={{float:'right'}}>Required Amount {emp.upload_amount}</p>
                  );
                }
                else if (emp.causefor_Id == '1'){return(
               
                  <p style={{float:'right'}}>Number of Beneficiaries {emp.upload_num_of_beneficiaries}</p>
                )}
              })()}
           </div> */}
                            <div className="col-lg-7">
                              <p style={{ float: "right" }}>
                                {enddate.result} &nbsp;{emp.required_by}
                              </p>
                            </div>
                            <div className="col-lg-1">
                              <p></p>
                            </div>
                          </div>

                          <div className="post-content overflow">
                            <div className="single-blog single-column">
                              <div
                                className="container-fluid"
                                style={{ width: "92%" }}
                              >
                                <div className="row">
                                  <div
                                    className="post-bottom overflow col-12 d-flex justify-content-between align-items-center flex-nowrap"
                                    style={{ marginLeft: "-1pc" }}
                                  >
                                    {/* Tag Section */}
                                    <div className="d-flex align-items-center">
                                      {(() => {
                                        if (emp.profile_name == username) {
                                          return (
                                            <a
                                              style={{
                                                color: "black",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <a
                                                href={
                                                  "#/TagEdit/" + emp.upload_id
                                                }
                                                id="taganch"
                                                style={{
                                                  textDecoration: "none",
                                                  color: "black",
                                                }}
                                              >
                                                <i className="fa fa-tag" />
                                              </a>
                                              <b className="">
                                                &nbsp;{emp.tagcount}&nbsp;
                                                <TaggedFriends
                                                  Translated={
                                                    tag.result
                                                      ? tag.result
                                                      : "Tag"
                                                  }
                                                  Id={
                                                    this.props.match.params.id
                                                  }
                                                >
                                                  {" "}
                                                </TaggedFriends>
                                              </b>
                                            </a>
                                          );
                                        } else {
                                          return (
                                            <a
                                              style={{
                                                color: "black",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i className="fa fa-tag" />
                                              <b className="">
                                                &nbsp;{emp.tagcount} &nbsp;
                                                <TaggedFriends
                                                  Translated={
                                                    tag.result
                                                      ? tag.result
                                                      : "Tag"
                                                  }
                                                  Id={
                                                    this.props.match.params.id
                                                  }
                                                >
                                                  {" "}
                                                </TaggedFriends>
                                              </b>
                                            </a>
                                          );
                                        }
                                      })()}
                                    </div>

                                    {/* Like Section */}
                                    <div className="d-flex align-items-center">
                                      <a>
                                        <form
                                          onSubmit={this.AddLiking.bind(this)}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <input
                                            type="hidden"
                                            name="id"
                                            onChange={this.handlebenfChange}
                                            id="id"
                                            defaultValue={emp.upload_id}
                                          />
                                          <button
                                            type="submit"
                                            className="fa notranslate"
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              backgroundColor: "transparent",
                                              fontSize: "inherit",
                                              textRendering: "auto",
                                              WebkitFontSmoothing:
                                                "antialiased",
                                              transform: "translate(0, 0)",
                                              fontFamily: "cuyabra",
                                              border: "none",
                                              marginLeft: "-7px",
                                            }}
                                          >
                                            {(() => {
                                              if (emp.uid == "true") {
                                                return (
                                                  <img
                                                    src="https://apitest.paropakari.com/Images/like.png"
                                                    alt="like"
                                                    className="fa"
                                                    style={{
                                                      height: "14px",
                                                      width: "14px",
                                                      marginRight: "4px",
                                                    }}
                                                  />
                                                );
                                              } else {
                                                return (
                                                  <img
                                                    src="https://paropakari.com/Images/liked.png"
                                                    alt="Unlike"
                                                    className="fa"
                                                    style={{
                                                      height: "14px",
                                                      width: "14px",
                                                      marginRight: "4px",
                                                      border: "none",
                                                      outline: "none",
                                                    }}
                                                  />
                                                );
                                              }
                                            })()}
                                            <b
                                              className="notranslate"
                                              style={{ color: "black" }}
                                            >
                                              {emp.likes} &nbsp;{" "}
                                            </b>
                                          </button>
                                        </form>
                                        <b
                                          style={{ color: "black" }}
                                          id="likebut"
                                        >
                                          <Likepopup
                                            Translated={
                                              likess.result
                                                ? likess.result
                                                : "Like"
                                            }
                                            Id={this.props.match.params.id}
                                            refreshcomment={() => this.submit()}
                                          >
                                            {" "}
                                          </Likepopup>
                                        </b>
                                      </a>
                                    </div>

                                    {/* Comment Section */}
                                    <div className="d-flex align-items-center">
                                      {(() => {
                                        if (emp.profile_name == username) {
                                          return (
                                            <a
                                              style={{
                                                color: "black",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i
                                                className="fa fa-comments"
                                                id="fa-comments"
                                                style={{ marginRight: "4px" }}
                                              />
                                              <b className="" id="cmtsty">
                                                &nbsp;{emp.comments}
                                                <Comment
                                                  Translated={
                                                    comment.result
                                                      ? comment.result
                                                      : "Comments"
                                                  }
                                                  Submit={
                                                    Submit.result
                                                      ? Submit.result
                                                      : "Submit"
                                                  }
                                                  CommentHere={
                                                    CommentHere.result
                                                      ? CommentHere.result
                                                      : "Comment Here"
                                                  }
                                                  Id={
                                                    this.props.match.params.id
                                                  }
                                                  refreshcomment={() =>
                                                    this.submit()
                                                  }
                                                  Url={`https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=${this.props.match.params.id}`}
                                                  Data={
                                                    (data) =>{
                                                      return null
                                                    }
                                                  }
                                                >
                                                  {" "}
                                                </Comment>
                                              </b>
                                            </a>
                                          );
                                        } else {
                                          return (
                                            <a
                                              style={{
                                                color: "black",
                                                display: "flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <i
                                                className="fa fa-comments"
                                                style={{ marginRight: "4px" }}
                                              />
                                              <b className="">
                                                &nbsp;{emp.comments}
                                                <Comment
                                                  Translated={
                                                    comment.result
                                                      ? comment.result
                                                      : "Comments"
                                                  }
                                                  Submit={
                                                    Submit.result
                                                      ? Submit.result
                                                      : "Submit"
                                                  }
                                                  CommentHere={
                                                    CommentHere.result
                                                      ? CommentHere.result
                                                      : "Comment Here"
                                                  }
                                                  Id={
                                                    this.props.match.params.id
                                                  }
                                                  refreshcomment={() =>
                                                    this.submit()
                                                  }
                                                  Url={`https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=${this.props.match.params.id}`}
                                                  Data={(data) =>
                                                    console.log("")
                                                  }
                                                >
                                                  {" "}
                                                </Comment>
                                              </b>
                                            </a>
                                          );
                                        }
                                      })()}
                                    </div>

                                    <div class="d-flex align-items-center">
                                      <i
                                        class="fa fa-share"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      <a
                                        href="#"
                                        style={{
                                          color: "black",
                                          marginLeft: "10px",
                                        }}
                                        class="sharebtn"
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        <b> {share.result}</b>
                                      </a>
                                    </div>
                                    <div
                                      id="myModal"
                                      class="modal fade"
                                      role="dialog"
                                    >
                                      <div class="modal-dialog">
                                        {/* <!-- Modal content--> */}
                                        <div class="modal-content">
                                          <div
                                            class="modal-header"
                                            id="Headerback"
                                          >
                                            <button
                                              type="button"
                                              class="close"
                                              data-dismiss="modal"
                                              style={{ marginTop: "-12px" }}
                                            >
                                              &times;
                                            </button>
                                          </div>
                                          <div class="modal-body text-center">
                                            <h5>
                                              Do you Share with your friends!
                                            </h5>
                                            {/* <div class="mt-5">
        <ul class="share_links">
          <li class="bg_fb"><a href="#" class="share_icon"  rel="tooltip" title="Facebook"><i class="fa fa-facebook"></i></a></li>

             <li class="bg_insta"><a href="#" class="share_icon" rel="tooltip" title="Instagram"><i class=" fa fa-instagram"></i></a></li>

        <li class="bg_whatsapp"><a href="#" class="share_icon" rel="tooltip" title="Whatsapp"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li></ul>
       </div> */}

                                            <FacebookShareButton
                                              url={
                                                "https://paropakari.com/#/ResponsePage/" +
                                                this.props.match.params.id
                                              }
                                              quote="SS"
                                              hashtag="#Be a Paropakari"
                                            >
                                              <FacebookIcon
                                                logoFillColor="white"
                                                style={{ width: "32px" }}
                                              />
                                            </FacebookShareButton>
                                            <WhatsappShareButton
                                              url={
                                                "https://paropakari.com/#/ResponsePage/" +
                                                this.props.match.params.id
                                              }
                                              quote="SS"
                                              hashtag="#Be a Paropakari"
                                            >
                                              <WhatsappIcon
                                                logoFillColor="white"
                                                style={{ width: "32px" }}
                                              />
                                            </WhatsappShareButton>
                                            <LinkedinShareButton
                                              url={
                                                "https://paropakari.com/#/ResponsePage/" +
                                                this.props.match.params.id
                                              }
                                              quote="SS"
                                              hashtag="#Be a Paropakari"
                                            >
                                              <LinkedinIcon
                                                logoFillColor="white"
                                                style={{ width: "32px" }}
                                              />
                                            </LinkedinShareButton>
                                            <TwitterShareButton
                                              url={
                                                "https://paropakari.com/#/ResponsePage/" +
                                                this.props.match.params.id
                                              }
                                              quote="SS"
                                              hashtag="#Be a Paropakari"
                                            >
                                              <XIcon
                                                logoFillColor="white"
                                                style={{ width: "32px" }}
                                              />
                                              {/* <img src={twitterIcon} style={{
                                                  width: '33.5px',
                                                  paddingBottom: '58.0px', marginLeft: '-0.2px', marginRight: '0px'
                                                }} /> */}
                                            </TwitterShareButton>
                                            <EmailShareButton
                                              url={
                                                "https://paropakari.com/#/ResponsePage/" +
                                                this.props.match.params.id
                                              }
                                              quote="SS"
                                              hashtag="#Be a Paropakari"
                                            >
                                              <EmailIcon
                                                logoFillColor="white"
                                                style={{ width: "32px" }}
                                              />
                                            </EmailShareButton>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <ul className="nav navbar-nav post-nav">
                    
                      <li><a>
                        
                        
                        
                        
                         <form >
     
   
 
      <input type="hidden" name= "id" onChange={this.handlebenfChange} id="id" defaultValue={emp.upload_id}/>   
 
      <button type="submit" className="fa" style={{display:'contents',backgroundColor:'rgb(174, 230, 219)',fontsize:'inherit',
      textRendering:'auto',WebkitFontSmoothing:'antialiased',transform:'translate(0, 0)',fontFamily:'cuyabra'}}>  </button>
   
 
 {(() => {if (emp.likes == 0) {return (
 
 <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/like.png"  className="fa" style={{display:'inline-block',fontsize:'inherit',textRendering:'auto',WebkitFontSmoothing:'antialiased',transform:'translate(0, 0)',height:"20px",width:"25px",border:'none'}}/>
 );
    }
                     else {return(
 
 <img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/liked.png"  className="fa" style={{display:'inline-block',fontsize:'inherit',textRendering:'auto',WebkitFontSmoothing:'antialiased',transform:'translate(0, 0)',height:"20px",width:"25px",border:'none',outline:'none'}}/>     );}
 
 
                     
                     })()}
                      
   {like.map((likes) => 
   (
                
    <h5 className="popup"><b> 
    <div className="spacee">
  {likes.likes}
  </div>  
     </b>

     <div>  
        <Likepopup Translated={likess.result? likess.result:'Like'} Id={this.props.match.params.id}> </Likepopup>
     </div> 
 
  </h5>         
 
 ))}
    
 
    
    </form>
    </a></li>
    
                      <li><i className="fa fa-comments" /><a style={{marginTop: '-18%',marginLeft: '22px'}}>{emp.comments}<Comment Translated={comment.result?comment.result:'Comments'}></Comment> </a></li>
        
     
                    
                    </ul> */}
                          </div>
                        </div>

                        // </tr>
                      ))}
                    </div>
                    {/* <h3 className="notranslate">{comment.result}</h3> */}
                    {/* {comm.map((comment) => (
               <div>

<h5><a style={{textDecoration:'none',color:'black'}}>{comment.comment_description}</a></h5>
                  <p style={{color:'black'}}><img src={comment.profile_imagePath} alt style={{width:'5%'}} /> {comment.profile_name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  {comment.created_date} <hr/></p>
              
               </div>
               ))} */}
                    {/* <form ng-submit="submitComment()" ng-controller="responseCtrl" onSubmit={this.handleClick} className="ng-pristine ng-scope ng-invalid ng-invalid-required">
                                        <div className="input-group" style={{ alignContent: 'center', marginBottom: 0 }} role="form">
                                          <input type="text" placeholder={CommentHere.result?CommentHere.result:'Comment Here'} autoComplete="off" className="form-control notranslate" style={{ width:'135%'}} onChange={this.handleChange} 
                                          id="comment_description" name="comment_description" ng-model="comment_description"
                                           />
                                            {emps.map((emp) => (
                                          <input type="hidden" id="upload_id" name="upload_id" defaultValue={emp.upload_id} autoComplete="off" />
                                         
                                          ))}
                                         <br/>
                                         <br/>
                                         <input type="submit" value={Submit.result?Submit.result:'Submit'} className="btn-primary notranslate" 
                                         style={{backgroundColor:'rgb(102, 71, 18)',color:'white', marginTop: 1,width:'50%',borderRadius:'30px' }} id="submitComment" /><br/><br/><br/>


                                        </div>
                                       
                                      </form> */}
                  </div>
                  <div className="col-md-5 col-sm-5">
                    <div className="sidebar blog-sidebar">
                      <div
                        className="sidebar-item  recent"
                        style={{ color: "black" }}
                      >
                        {/* <h3></h3> */}

                        <div className="media">
                          {emps.map((emp) => (
                            <div className="">
                              {/* ngRepeat: par in Paropakaries */}
                              <div
                                ng-repeat="par in Paropakaries"
                                className="ng-scope"
                                style={{ marginLeft: "2%" }}
                              >
                                {(() => {
                                  if (emp.causefor_Id == "0") {
                                    return (
                                      <p className="notranslate"
                                        style={{
                                          paddingBottom: 5,
                                          fontSize: 15,
                                          paddingTop: 5,
                                          color: "black",
                                        }}
                                      >
                                        {HappinessMeter.result
                                          ? HappinessMeter.result
                                          : "Happiness Meter"}
                                        &nbsp;&nbsp;&nbsp;
                                        {like.map((likes) => (
                                          <a
                                            style={{
                                              fontSize: 12,
                                              color: "black",
                                            }}
                                            className="ng-binding"
                                          >
                                            <img
                                              src="./assets/images/Achieved.png"
                                              className="beone"
                                              style={{
                                                width: "1.5%",
                                                height: "5%",
                                              }}
                                            />
                                            &nbsp;
                                            {Achieved.result
                                              ? Achieved.result
                                              : "Achieved"}
                                            &nbsp;
                                            {likes.confirmed1}%
                                          </a>
                                        ))}
                                        {/* &nbsp;&nbsp;
                                    {like.map((likes) => (
                                    <a style={{ fontSize: 12, color: 'black' }} className="ng-binding">
                                                                      <img src="https://paropakari.com/Images/gray.png" className="beone" style={{ width: '1.5%', height: '5%' }} />&nbsp;
                                                                     {OnItsWay.result?OnItsWay.result:'Anticipated'} &nbsp;
                                                                    
                                         {likes.pending1}%
                                            
                                         
                                    </a>
                                    ))} */}
                                      </p>
                                    );
                                  } else if (emp.causefor_Id == "1") {
                                    return (
                                      <p
                                      className="notranslate"
                                        style={{
                                          paddingBottom: 5,
                                          fontSize: 15,
                                          paddingTop: 5,
                                          color: "black",
                                        }}
                                      >
                                        {HappinessMeter.result
                                          ? HappinessMeter.result
                                          : "Happiness Meter"}
                                        &nbsp;&nbsp;&nbsp;
                                        {like.map((likes) => (
                                          <a
                                            style={{
                                              fontSize: 12,
                                              color: "black",
                                            }}
                                            className="ng-binding"
                                          >
                                            <img
                                              src="./assets/images/Achieved.png"
                                              className="beone"
                                              style={{
                                                width: "1.5%",
                                                height: "5%",
                                              }}
                                            />
                                            &nbsp;
                                            {Achieved.result
                                              ? Achieved.result
                                              : "Achieved"}
                                            &nbsp;
                                            {likes.confirmed}%
                                          </a>
                                        ))}
                                        &nbsp;&nbsp;
                                        {like.map((likes) => (
                                          <a
                                            style={{
                                              fontSize: 12,
                                              color: "black",
                                            }}
                                            className="ng-binding"
                                          >
                                            <img
                                              src="https://paropakari.com/Images/gray.png"
                                              className="beone"
                                              style={{
                                                width: "1.5%",
                                                height: "5%",
                                              }}
                                            />
                                            &nbsp;
                                            {OnItsWay.result
                                              ? OnItsWay.result
                                              : "Anticipated"}{" "}
                                            &nbsp;
                                            {likes.pending}%
                                          </a>
                                        ))}
                                      </p>
                                    );
                                  }
                                })()}

                                {/* ngIf: par.confirmed < 100 */}
                                <div
                                  ng-if="par.confirmed < 100"
                                  style={{ marginTop: 24 }}
                                  className="ng-scope"
                                ></div>

                                {/* Monetary */}

                                {(() => {
                                  if (emp.causefor_Id == "0") {
                                    return (
                                      <div className="notranslate">
                                        {like.map((likes) => (
                                          <div>
                                            {(() => {
                                              if (likes.confirmed1 < 100) {
                                                return (
                                                  <>
                                                    <div
                                                      className="progress1"
                                                      style={{
                                                        width: "100%",
                                                        marginBottom: 0,
                                                        backgroundColor:
                                                          "white",
                                                      }}
                                                    >
                                                      <div
                                                        className="progress1-done"
                                                        role="progressbar"
                                                        style={{
                                                          width:
                                                            likes.confirmed1 +
                                                            "%",
                                                          marginLeft: "-2px",
                                                          backgroundColor:
                                                            "white",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 14,
                                                            marginLeft: "97%",
                                                            color: "purple",
                                                          }}
                                                          className="far blink_me"
                                                        >
                                                          🙂
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              } else if (
                                                likes.pending1 == 100 &&
                                                likes.confirmed1 == 0
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <div
                                                      className="progress1-done"
                                                      role="progressbar"
                                                      style={{
                                                        width:
                                                          likes.confirmed1 +
                                                          "%",
                                                        marginLeft: "-2px",
                                                        backgroundColor:
                                                          "#66666",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 14,
                                                          marginLeft: "97%",
                                                          color: "purple",
                                                        }}
                                                        className="far blink_me"
                                                      >
                                                        🙂
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              } else if (
                                                likes.pending1 == 0 &&
                                                likes.confirmed1 == 0
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <div
                                                      className="progress1-done"
                                                      role="progressbar"
                                                      style={{
                                                        width:
                                                          likes.confirmed1 +
                                                          "%",
                                                        marginLeft: "-2px",
                                                        backgroundColor:
                                                          "white",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 14,
                                                          marginLeft: "97%",
                                                          color: "purple",
                                                        }}
                                                        className="far blink_me"
                                                      >
                                                        🙂
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              } else if (
                                                likes.pending1 == 0 &&
                                                likes.confirmed1 == 100
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <div
                                                      className="progress1-done"
                                                      role="progressbar"
                                                      style={{
                                                        width:
                                                          likes.confirmed1 +
                                                          "%",
                                                        marginLeft: "-2px",
                                                        backgroundColor: "pink",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          fontSize: 14,
                                                          marginLeft: "97%",
                                                          color: "purple",
                                                        }}
                                                        className="far blink_me"
                                                      >
                                                        🙂
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              } else if (
                                                likes.pending1 == 10 &&
                                                likes.confirmed1 == 10
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <div
                                                      className="progress1-done"
                                                      role="progressbar"
                                                      style={{
                                                        width:
                                                          likes.confirmed1 +
                                                          "%",
                                                        marginLeft: "-2px",
                                                        backgroundColor: "pink",
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          backgroundColor:
                                                            "grey",
                                                        }}
                                                        class="last"
                                                      ></span>
                                                      <span
                                                        style={{
                                                          backgroundColor:
                                                            "grey",
                                                        }}
                                                        class="last"
                                                      ></span>
                                                      <span
                                                        style={{
                                                          fontSize: 14,
                                                          marginLeft: "97%",
                                                          color: "purple",
                                                        }}
                                                        className="far blink_me"
                                                      >
                                                        🙂
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                })()}

                                {/* Non- Mondetary */}
                                {(() => {
                                  if (emp.causefor_Id == "1") {
                                    return (
                                      <div className="notranslate">
                                        {like.map((likes) => (
                                          <div>
                                            {(() => {
                                              if (
                                                likes.confirmed < 100 &&
                                                likes.pending < 100 &&
                                                likes.confirmed +
                                                  likes.pending <
                                                  100
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    {(() => {
                                                      if (
                                                        likes.confirmed < 100
                                                      ) {
                                                        return (
                                                          <div>
                                                            {likes.pending <=
                                                              100 &&
                                                            likes.confirmed <
                                                              100 ? (
                                                              <div
                                                                className="progress1-done"
                                                                role="progressbar"
                                                                style={{
                                                                  width:
                                                                    (likes.pending >=
                                                                    1
                                                                      ? likes.pending +
                                                                        3
                                                                      : likes.pending) +
                                                                    "%",
                                                                  background:
                                                                    "#6a6666",
                                                                  marginLeft:
                                                                    (likes.pending >=
                                                                    1
                                                                      ? likes.confirmed -
                                                                        2
                                                                      : likes.confirmed) +
                                                                    "%",

                                                                  position:
                                                                    "absolute",
                                                                  boxShadow:
                                                                    "0 3px 3px -5px gray, 0 2px 5px gray",
                                                                  borderTopRightRadius:
                                                                    "29px",
                                                                  borderBottomRightRadius:
                                                                    "29px",
                                                                  borderTopLeftRadius:
                                                                    "12px",
                                                                  borderBottomLeftRadius:
                                                                    "-1px",
                                                                }}
                                                              ></div>
                                                            ) : null}
                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.confirmed +
                                                                  "%",
                                                                backgroundColor:
                                                                  "#6a6666",
                                                                position:
                                                                  "absolute",
                                                                borderTopRightRadius:
                                                                  "0px",
                                                                borderBottomRightRadius:
                                                                  "0px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "100%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                            {/* <div className="progress1" role="progressbar" style={{ width: likes.pending+'%', marginLeft:"-2px" }}>

                                                          </div> */}
                                                            <br />
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "100%",
                                                              marginLeft:
                                                                "-2px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      }
                                                    })()}

                                                    {(() => {
                                                      if (
                                                        likes.pending >= 100 &&
                                                        likes.confirmed < 100
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending +
                                                                "%",
                                                              background:
                                                                "#6a6666",
                                                            }}
                                                          ></div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending +
                                                                "%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })()}
                                                    {(() => {
                                                      if (
                                                        likes.pending != 0 &&
                                                        likes.confirmed == 0
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "100%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          >
                                                            <div
                                                              className="progress-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.pending +
                                                                  "%",
                                                                backgroundColor:
                                                                  "gray",
                                                              }}
                                                            ></div>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending +
                                                                "%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })()}
                                                    {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
                                                  <b className="ng-binding"></b>
                                                  </div> */}
                                                  </div>
                                                );
                                              }
                                              //                                             else if (likes.confirmed >= 100 || likes.pending >= 100) {
                                              //                                               return (

                                              //                                                 <div className="progress1" style={{ width: '100%', marginBottom: 0, backgroundColor: 'White'}}>

                                              //                                                   {(() => {
                                              //                                                     if (likes.pending >= 100) {
                                              //                                                       return (

                                              //                                                         <div>

                                              // {(likes.pending >= 100 && likes.confirmed < 100)?  <div  className="progress1-done" role="progressbar"
                                              //                                                             style={{ width: 100-likes.confirmed +'%', background: '#6a6666',marginLeft:likes.confirmed+ '%' ,position:"absolute", boxShadow:"0 3px 3px -5px gray, 0 2px 5px gray"}}>
                                              //                                                             </div>:null}
                                              //                                                         <div className="progress1-done" role="progressbar" style={{ width: likes.confirmed +'%', marginLeft: "-2px"}}>
                                              //                                                           <span style={{ fontSize: 14, marginLeft: '97%', color: 'purple' }} className="far blink_me">🙂</span>
                                              //                                                         </div>
                                              //                                                         </div>
                                              //                                                       );
                                              //                                                     }
                                              //                                                     else {
                                              //                                                       return (
                                              //                                                         <div className="progress1-done" role="progressbar" style={{ width: likes.confirmed + '%', marginLeft: "-2px" }}>
                                              //                                                           <span style={{ fontSize: 14, marginLeft: '97%', color: 'purple' }} className="far blink_me">🙂</span>
                                              //                                                         </div>
                                              //                                                       );
                                              //                                                     }

                                              //                                                   })()}

                                              //                                                   {/* {(() => {if (likes.confirmed < 100) {return (
                                              // <div className="progress1-done" role="progressbar" style={{ width: '0%' }}>
                                              // </div>

                                              // );
                                              // }
                                              // else {return(

                                              // <div className="progress-done" role="progressbar" style={{ width: likes.pending +'%',backgroundColor:'#6a6666' }}>
                                              // </div>

                                              // );}

                                              // })()} */}
                                              //                                                   {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
                                              // <b className="ng-binding"></b>
                                              // </div> */}

                                              //                                                 </div>
                                              //                                               );
                                              //                                             }
                                              else if (
                                                likes.confirmed +
                                                  likes.pending >=
                                                100
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "White",
                                                      borderRadius: "20px",
                                                    }}
                                                  >
                                                    {(() => {
                                                      if (
                                                        likes.confirmed >= 100
                                                      ) {
                                                        return (
                                                          <div>
                                                            {/* 
                                                    <div  className="progress1-done" role="progressbar" 
                                                            style={{ width: 100-likes.confirmed +'%', background: '#6a6666',marginLeft:likes.confirmed+ '%' ,position:"absolute", boxShadow:"0 3px 3px -5px gray, 0 2px 5px gray"}}> 
                                                            </div> */}

                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width: "100%",
                                                                marginLeft:
                                                                  "-2px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "97%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      } else if (
                                                        likes.confirmed +
                                                          likes.pending >=
                                                        100
                                                      ) {
                                                        return (
                                                          <div>
                                                            {likes.confirmed +
                                                              likes.pending >=
                                                              100 &&
                                                            likes.confirmed ==
                                                              0 ? (
                                                              <div
                                                                className="progress1-done"
                                                                role="progressbar"
                                                                style={{
                                                                  width:
                                                                    100 -
                                                                    likes.confirmed +
                                                                    "%",
                                                                  background:
                                                                    "#6a6666",
                                                                  marginLeft:
                                                                    likes.confirmed +
                                                                    "%",
                                                                  position:
                                                                    "absolute",
                                                                  boxShadow:
                                                                    "0 3px 3px -5px gray, 0 2px 5px gray",
                                                                  borderTopLeftRadius:
                                                                    "20px",
                                                                  borderBottomLeftRadius:
                                                                    "20px",
                                                                }}
                                                              ></div>
                                                            ) : likes.confirmed +
                                                                likes.pending >=
                                                                100 &&
                                                              likes.confirmed >=
                                                                0 ? (
                                                              <div
                                                                className="progress1-done"
                                                                role="progressbar"
                                                                style={{
                                                                  width:
                                                                    100 -
                                                                    likes.confirmed +
                                                                    "%",
                                                                  background:
                                                                    "#6a6666",
                                                                  marginLeft:
                                                                    likes.confirmed +
                                                                    "%",
                                                                  position:
                                                                    "absolute",
                                                                  boxShadow:
                                                                    "0 3px 3px -5px gray, 0 2px 5px gray",
                                                                  borderTopLeftRadius:
                                                                    "0px",
                                                                  borderBottomLeftRadius:
                                                                    "0px",
                                                                }}
                                                              ></div>
                                                            ) : null}

                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.confirmed +
                                                                  "%",
                                                                marginLeft:
                                                                  "-2px",
                                                                borderTopRightRadius:
                                                                  "0px",
                                                                borderBottomRightRadius:
                                                                  "0px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "97%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.confirmed +
                                                                "%",
                                                              marginLeft:
                                                                "-2px",
                                                              borderTopRightRadius:
                                                                "0px",
                                                              borderBottomRightRadius:
                                                                "0px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      }
                                                    })()}

                                                    {/* {(() => {if (likes.confirmed < 100) {return (
                                                                <div className="progress1-done" role="progressbar" style={{ width: '0%' }}>
                                                                </div>

                                                                );
                                                                }
                                                                else {return(

                                                                <div className="progress-done" role="progressbar" style={{ width: likes.pending +'%',backgroundColor:'#6a6666' }}>
                                                                </div>

                                                                );}

                                                                })()} */}
                                                    {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
                                                    <b className="ng-binding"></b>
                                                    </div> */}
                                                  </div>
                                                );
                                              }
                                            })()}

                                            {/* <div ng-if="par.confirmed >= 100" class="progress" style="width:100%;margin-bottom:5px;">
                                                <div class="w3-blue" role="progressbar" width="{{par.confirmed}}%" ng-if="par.confirmed >= 100" style="width:100%;">
                                                <i style='font-size:14px;margin-left:96.5%;color:purple' class='far blink_me'>&#x1F642;</i>
                                                @*{{par.confirmed}}%*@
                                                </div>
                                                <div class="w3-gray" role="progressbar" width="{{par.pending}}%" ng-if="par.confirmed >= 100" style="width:0%;">
                                                </div>
                                                <div class="w3-light-gray" role="progressbar" width="{{100 - (par.confirmed + par.pending)}}%" ng-if="par.confirmed >= 100" style="width:0%">
                                                </div>
                                                </div> */}
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                })()}

                                {/* end ngIf: par.confirmed < 100 */}
                                {/* ngIf: par.confirmed >= 100 */}

                                {like.map((likes) =>
                                  (() => {
                                    if (langID === "Tamil") {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "65%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    } else if (langID === "Kannada") {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "60%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    } else if (langID == "English") {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "67%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    } else if (langID == "Spanish") {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "67%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    } else if (langID === "Hindi") {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "60%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    } else if (langID === "Telugu") {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "73%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    } else {
                                      return (
                                        <h5
                                          className=""
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "73%",
                                          }}
                                        >
                                          <b>
                                            {(() => {
                                              if (emp.causefor_Id == "1") {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris}
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className="space">
                                                    {likes.paropakaris1}
                                                  </div>
                                                );
                                              }
                                            })()}
                                          </b>

                                          <Popup
                                            Translatedword={
                                              paropakariss.result
                                                ? paropakariss.result
                                                : "Paropakaris"
                                            }
                                            Id={this.props.match.params.id}
                                            style={{ marginTop: "-8px" }}
                                          >
                                            {" "}
                                          </Popup>
                                        </h5>
                                      );
                                    }
                                  })()
                                )}
                              </div>
                              {/* end ngRepeat: par in Paropakaries */}

                              {/* ngIf: Post.user_id  == 'f9c77357-f20c-4714-894b-93a20a72fb40' && Post.relationship =='Self' */}
                              {/* ngIf: Post.user_id  != 'f9c77357-f20c-4714-894b-93a20a72fb40' || Post.relationship !='Self' */}
                              <div
                                ng-if="Post.user_id  != 'f9c77357-f20c-4714-894b-93a20a72fb40' || Post.relationship !='Self'"
                                className="ng-scope"
                              >
                                {emps.map((emp) => (
                                  <div>
                                    {(() => {
                                      if (emp.causefor_Id == "1") {
                                        return (
                                          <div>
                                            {like.map((likes) => (
                                              <form onSubmit={this.handleMsg}>
                                                {(() => {
                                                  if (
                                                    emp.relationship_id ==
                                                      "3" &&
                                                    emp.profile_name == username
                                                  ) {
                                                    return <div></div>;
                                                  } else if (
                                                    likes.confirmed >= 100
                                                  ) {
                                                    return (
                                                      <div>
                                                        <br />
                                                        <center>
                                                          <li
                                                            class="btn1 third"
                                                            style={{
                                                              backgroundColor:
                                                                "rgb(102, 71, 18)",
                                                              color: "white",
                                                              textDecoration:
                                                                "none",
                                                              width:
                                                                "fit-content",
                                                            }}
                                                          >
                                                            <blink>
                                                              &nbsp;&nbsp;&nbsp;&nbsp;
                                                              Cause Closed
                                                            </blink>
                                                          </li>
                                                        </center>
                                                      </div>
                                                    );
                                                  } else if (
                                                    likes.pending >= 100
                                                  ) {
                                                    return <div></div>;
                                                  } else {
                                                    return (
                                                      <div>
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "row",
                                                          }}
                                                        >
                                                          <h5 className="notranslate">
                                                            {Makeadifferencewithpledgeof.result
                                                              ? Makeadifferencewithpledgeof.result
                                                              : "Make a difference with pledge of"}
                                                          </h5>
                                                          &nbsp;
                                                          <input
                                                            type="hidden"
                                                            name="downPaymentPercent"
                                                            id="downPaymentPercent"
                                                            min="1"
                                                            value={
                                                              this.state
                                                                .downPaymentPercent
                                                            }
                                                            style={{
                                                              backgroundColor:
                                                                "transparent",
                                                              border: "none",
                                                            }}
                                                            onChange={
                                                              this
                                                                .handleInputChange
                                                            }
                                                          />
                                                        </div>

                                                        <div
                                                          className="slidecontainer"
                                                          style={{
                                                            width: "100%",
                                                          }}
                                                        >
                                                          <input
                                                            type="hidden"
                                                            name="amount"
                                                            id="amount"
                                                            defaultValue={
                                                              emp.amount
                                                            }
                                                          />

                                                          {/* <input type="number" min={1} max={100} required
                  onChange={this.handleChange} 
                 className="form-control" id="response_Pledge" placeholder={YourofSupport.result?YourofSupport.result:'Your % of Support'} 
                 value={ this.state.response_pledge }  onChange={this.handleInputChange}
                 id="response_pledge" name="response_pledge" /> */}
                                                          <input
                                                            type="text"
                                                            min={1}
                                                            max={100}
                                                            required
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            autoComplete="off"
                                                            onKeyPress={(
                                                              event
                                                            ) => {
                                                              if (
                                                                !/[0-9]/.test(
                                                                  event.key
                                                                )
                                                              ) {
                                                                event.preventDefault();
                                                              }
                                                            }}
                                                            className="form-control notranslate"
                                                            id="response_Pledge"
                                                            placeholder={
                                                              YourofSupport.result
                                                                ? YourofSupport.result
                                                                : "Your % of Support"
                                                            }
                                                            value={
                                                              this.state
                                                                .response_pledge
                                                            }
                                                            onInput={
                                                              this
                                                                .handleInputChange
                                                            }
                                                            name="response_pledge"
                                                          />

                                                          {/* <input type="hidden"  name="profile_location" id="profile_location"
                  onChange={this.handleChange} value={emp.profile_location}
                 className="form-control" defaultValue placeholder="Your % of Support"  />


           <input type="hidden"  name="upload_id" id="upload_id"
                  onChange={this.handleChange} value={this.props.match.params.id}
                 className="form-control" defaultValue placeholder="Your % of Support"  />

                      <input type="hidden"  name="profile_mobileNumber" id="profile_mobileNumber"
                  onChange={this.handleChange} value={emp.profile_mobileNumber}
                 className="form-control" defaultValue placeholder="Your % of Support"  />

                <input type="hidden"  name="profile_name" id="profile_name"
                  onChange={this.handleChange} value={emp.profile_name}
                 className="form-control" defaultValue placeholder="Your % of Support"  />


            <input type="hidden"  name="upload_BankAccount" id="upload_BankAccount"
                  onChange={this.handleChange} value={emp.upload_BankAccount}
                 className="form-control" defaultValue placeholder="Your % of Support"  />

                      <input type="hidden"  name="upload_IFSC" id="upload_IFSC"
                  onChange={this.handleChange} value={emp.upload_IFSC}
                 className="form-control" defaultValue placeholder="Your % of Support"  />

                <input type="hidden"  name="upload_UPIID" id="upload_UPIID"
                  onChange={this.handleChange} value={emp.upload_UPIID}
                 className="form-control" defaultValue placeholder="Your % of Support"  />
            
            <input type="hidden"  name="profile_email" id="profile_email"
                  onChange={this.handleChange} value={emp.profile_email}
                 className="form-control" defaultValue placeholder="Your % of Support"  />
            
 */}

                                                          <h5 className="ng-binding notranslate">
                                                            {Writemessageto.result
                                                              ? Writemessageto.result
                                                              : "Write a message to"}{" "}
                                                            {emp.profile_name}
                                                          </h5>
                                                          <textarea
                                                            rows={3}
                                                            cols={50}
                                                            id="response_message"
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            placeholder={
                                                              YourMessagetotheNeedy.result
                                                                ? YourMessagetotheNeedy.result
                                                                : "Your Message to the Needy"
                                                            }
                                                            value={
                                                              this.state
                                                                .response_message
                                                            }
                                                            name="response_message"
                                                            className="form-control"
                                                          />

                                                          {/* ngIf: Post.status_80G == 0 */}

                                                          <p
                                                            ng-if="Post.status_80G == 0"
                                                            className="ng-binding ng-scope"
                                                          >
                                                            <input
                                                              type="hidden"
                                                              id="upload_id"
                                                              name="upload_id"
                                                              defaultValue={
                                                                emp.upload_id
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                            />

                                                            <span class="blink notranslate">
                                                              {Thecreatorcause.result
                                                                ? Thecreatorcause.result
                                                                : "The creator of the cause"}{" "}
                                                              {emp.profile_name}{" "}
                                                              {isresponsibleforproviding.result
                                                                ? isresponsibleforproviding.result
                                                                : " is responsible for providing any documents "}
                                                              .{" "}
                                                              {Paropakaritakesnoresponsibility.result
                                                                ? Paropakaritakesnoresponsibility.result
                                                                : "Paropakari.com takes no responsibility for these documents"}
                                                            </span>
                                                          </p>
                                                          {/* <form>
<script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_J4vFND8HkysKSE" async></script> </form> */}

                                                          {(() => {
                                                            if (
                                                              langID == "Telugu"
                                                            ) {
                                                              return (
                                                                <center>
                                                                  <input
                                                                    type="submit"
                                                                    defaultValue={
                                                                      DonateNow.result
                                                                        ? DonateNow.result
                                                                        : "Donate Now"
                                                                    }
                                                                    value={
                                                                      DonateNow.result
                                                                        ? DonateNow.result
                                                                        : "Donate Now"
                                                                    }
                                                                    className="btn-primary notranslate"
                                                                    style={{
                                                                      width: 215,
                                                                      color:
                                                                        "white",
                                                                      backgroundColor:
                                                                        "rgb(102, 71, 18)",
                                                                      borderRadius:
                                                                        "30px",
                                                                      fontsize:
                                                                        "20px",
                                                                      padding:
                                                                        "6px",
                                                                    }}
                                                                    id="submitPost"
                                                                  />
                                                                  {/* <Paymentt>     
<input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
</Paymentt>  */}
                                                                </center>
                                                              );
                                                            } else if (
                                                              langID == "Tamil"
                                                            ) {
                                                              return (
                                                                <center>
                                                                  <input
                                                                    type="submit"
                                                                    defaultValue={
                                                                      DonateNow.result
                                                                        ? DonateNow.result
                                                                        : "Donate Now"
                                                                    }
                                                                    value={
                                                                      DonateNow.result
                                                                        ? DonateNow.result
                                                                        : "Donate Now"
                                                                    }
                                                                    className="btn-primary notranslate"
                                                                    style={{
                                                                      width: 280,
                                                                      color:
                                                                        "white",
                                                                      backgroundColor:
                                                                        "rgb(102, 71, 18)",
                                                                      borderRadius:
                                                                        "30px",
                                                                      fontsize:
                                                                        "20px",
                                                                      padding:
                                                                        "6px",
                                                                    }}
                                                                    id="submitPost"
                                                                  />
                                                                  {/* <Paymentt>     
<input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
</Paymentt>  */}
                                                                </center>
                                                              );
                                                            } else {
                                                              return (
                                                                <center>
                                                                  <input
                                                                    type="submit"
                                                                    defaultValue={
                                                                      DonateNow.result
                                                                        ? DonateNow.result
                                                                        : "Donate Now"
                                                                    }
                                                                    value={
                                                                      DonateNow.result
                                                                        ? DonateNow.result
                                                                        : "Donate Now"
                                                                    }
                                                                    className="btn-primary notranslate"
                                                                    style={{
                                                                      width: 135,
                                                                      color:
                                                                        "white",
                                                                      backgroundColor:
                                                                        "rgb(102, 71, 18)",
                                                                      borderRadius:
                                                                        "30px",
                                                                      fontsize:
                                                                        "20px",
                                                                      padding:
                                                                        "6px",
                                                                    }}
                                                                    id="submitPost"
                                                                  />
                                                                  {/* <Paymentt>     
                   <input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
                  </Paymentt>  */}
                                                                </center>
                                                              );
                                                            }
                                                          })()}

                                                          {/* end ngIf: Post.status_80G == 0 */}
                                                          {/* ngIf: Post.status_80G == 1 */}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                                <div>
                                                  {/* <center>
               <a href="#/SamplePayment" style={{ width: '100%',textDecoration:'none', color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'10px' }} >&nbsp;&nbsp;&nbsp;&nbsp;Donate Now&nbsp;&nbsp;&nbsp;&nbsp;</a>

               </center> */}
                                                  <br />
                                                </div>

                                                <div></div>

                                                <br />
                                              </form>
                                            ))}
                                          </div>
                                        );
                                      } else if (emp.causefor_Id == "0") {
                                        return (
                                          <form onSubmit={this.handlePayment}>
                                            {(() => {
                                              if (
                                                emp.relationship_id == "3" &&
                                                emp.profile_name == username
                                              ) {
                                                return <div></div>;
                                              } else {
                                                return (
                                                  <div>
                                                    {clm.map((emp1) => (
                                                      <div>
                                                        {/* <p>{emp1.claimAmount}{emp1.payOutStatus}</p> */}
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "row",
                                                          }}
                                                        >
                                                          <p>
                                                            {(() => {
                                                              if (
                                                                emp1.payOutStatus ===
                                                                "processed"
                                                              ) {
                                                                return (
                                                                  <div></div>
                                                                );
                                                              } else if (
                                                                emp1.payOutStatus ===
                                                                "processing"
                                                              ) {
                                                                return (
                                                                  <div></div>
                                                                );
                                                              } else if (
                                                                emp1.payOutStatus ===
                                                                "NoPayment"
                                                              ) {
                                                                return (
                                                                  <div>
                                                                    <h5 className="notranslate">
                                                                      {Makeadifferencewithpledgeof.result
                                                                        ? Makeadifferencewithpledgeof.result
                                                                        : "Make a difference with pledge of"}{" "}
                                                                      <input
                                                                        type="text"
                                                                        name="downPaymentPercent"
                                                                        id="downPaymentPercent"
                                                                        readOnly="readonly"
                                                                        value={
                                                                          this
                                                                            .state
                                                                            .downPaymentPercent
                                                                        }
                                                                        style={{
                                                                          backgroundColor:
                                                                            "transparent",
                                                                          border:
                                                                            "none",
                                                                        }}
                                                                        onChange={
                                                                          this
                                                                            .handleInputChange
                                                                        }
                                                                      />
                                                                    </h5>
                                                                  </div>
                                                                );
                                                              } else {
                                                                return (
                                                                  <div>
                                                                    <h5 className="notranslate">
                                                                      {Makeadifferencewithpledgeof.result
                                                                        ? Makeadifferencewithpledgeof.result
                                                                        : "Make a difference with pledge of"}
                                                                    </h5>
                                                                    &nbsp;
                                                                    <input
                                                                      type="text"
                                                                      name="downPaymentPercent"
                                                                      id="downPaymentPercent"
                                                                      value={
                                                                        this
                                                                          .state
                                                                          .downPaymentPercent
                                                                      }
                                                                      min="1"
                                                                      style={{
                                                                        backgroundColor:
                                                                          "transparent",
                                                                        border:
                                                                          "none",
                                                                      }}
                                                                      readOnly="readonly"
                                                                      onChange={
                                                                        this
                                                                          .handleInputChange
                                                                      }
                                                                    />
                                                                  </div>
                                                                );
                                                              }
                                                            })()}
                                                          </p>
                                                        </div>
                                                        {emps.map((emp) => (
                                                          <div
                                                            className="slidecontainer"
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <p>
                                                              {(() => {
                                                                if (
                                                                  emp1.payOutStatus ===
                                                                  "processed"
                                                                ) {
                                                                  return (
                                                                    <div>
                                                                      This Cause
                                                                      is Closed
                                                                    </div>
                                                                  );
                                                                } else if (
                                                                  emp1.payOutStatus ===
                                                                  "processing"
                                                                ) {
                                                                  return (
                                                                    <div>
                                                                      This Cause
                                                                      is Closed
                                                                    </div>
                                                                  );
                                                                } else if (
                                                                  emp1.payOutStatus ===
                                                                  "NoPayment"
                                                                ) {
                                                                  return (
                                                                    <div>
                                                                      <input
                                                                        type="hidden"
                                                                        name="amount"
                                                                        id="amount"
                                                                        defaultValue={
                                                                          emp.amount
                                                                        }
                                                                      />

                                                                      {/* <input type="number" min={1} max={100} required
                                  onChange={this.handleChange} 
                                 className="form-control" id="response_Pledge" placeholder={YourofSupport.result?YourofSupport.result:'Your % of Support'} 
                                 value={ this.state.response_pledge }  onChange={this.handleInputChange}
                                 id="response_pledge" name="response_pledge" /> */}
                                                                      <input
                                                                        type="text"
                                                                        min={1}
                                                                        max={
                                                                          100
                                                                        }
                                                                        onKeyPress={(
                                                                          event
                                                                        ) => {
                                                                          if (
                                                                            !/[0-9]/.test(
                                                                              event.key
                                                                            )
                                                                          ) {
                                                                            event.preventDefault();
                                                                          }
                                                                        }}
                                                                        required
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        autoComplete="off"
                                                                        className="form-control notranslate"
                                                                        id="response_Pledge"
                                                                        placeholder={
                                                                          YourofSupport.result
                                                                            ? YourofSupport.result
                                                                            : "Your % of Support"
                                                                        }
                                                                        value={
                                                                          this
                                                                            .state
                                                                            .response_pledge
                                                                        }
                                                                        onInput={
                                                                          this
                                                                            .handleInputChange
                                                                        }
                                                                        name="response_pledge"
                                                                      />

                                                                      {detailsss.map(
                                                                        (
                                                                          detail
                                                                        ) => (
                                                                          <div>
                                                                            <input
                                                                              type="hidden"
                                                                              name="user_name"
                                                                              id="user_name"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_name
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_number"
                                                                              id="user_number"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_mobileNumber
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_Bankac"
                                                                              id="user_Bankac"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.prof_BankAccount
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_IFSC"
                                                                              id="user_IFSC"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.prof_IFSC
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_UPIID"
                                                                              id="user_UPIID"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.pro_UPIID
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_email"
                                                                              id="user_email"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_email
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name=""
                                                                              id="user_location"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_location
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />
                                                                          </div>
                                                                        )
                                                                      )}
                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_location"
                                                                        id="profile_location"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_location
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_id"
                                                                        id="upload_id"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          this
                                                                            .props
                                                                            .match
                                                                            .params
                                                                            .id
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_mobileNumber"
                                                                        id="profile_mobileNumber"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_mobileNumber
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_name"
                                                                        id="profile_name"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_name
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_BankAccount"
                                                                        id="upload_BankAccount"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.upload_BankAccount
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="status_80G"
                                                                        id="status_80G"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.status_80G
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="user_id"
                                                                        id="user_id"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.user_id
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_IFSC"
                                                                        id="upload_IFSC"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.upload_IFSC
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_UPIID"
                                                                        id="upload_UPIID"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.upload_UPIID
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_email"
                                                                        id="profile_email"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_email
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <h5 className="ng-binding notranslate">
                                                                        {Writemessageto.result
                                                                          ? Writemessageto.result
                                                                          : "Write a message to"}{" "}
                                                                        {
                                                                          emp.profile_name
                                                                        }
                                                                      </h5>
                                                                      <textarea
                                                                        rows={3}
                                                                        cols={
                                                                          50
                                                                        }
                                                                        id="response_message"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        placeholder={
                                                                          YourMessagetotheNeedy.result
                                                                            ? YourMessagetotheNeedy.result
                                                                            : "Your Message to the Needy"
                                                                        }
                                                                        name="response_message"
                                                                        className="form-control notranslate"
                                                                      />

                                                                      {/* ngIf: Post.status_80G == 0 */}

                                                                      <p
                                                                        ng-if="Post.status_80G == 0"
                                                                        className="ng-binding ng-scope"
                                                                      >
                                                                        <input
                                                                          type="hidden"
                                                                          id="upload_id"
                                                                          name="upload_id"
                                                                          defaultValue={
                                                                            emp.upload_id
                                                                          }
                                                                          onChange={
                                                                            this
                                                                              .handleChange
                                                                          }
                                                                        />
                                                                        <span class="blink notranslate">
                                                                          {Thecreatorcause.result
                                                                            ? Thecreatorcause.result
                                                                            : "The creator of the cause"}{" "}
                                                                          {
                                                                            emp.profile_name
                                                                          }{" "}
                                                                          {isresponsibleforproviding.result
                                                                            ? isresponsibleforproviding.result
                                                                            : " is responsible for providing any documents "}
                                                                          .{" "}
                                                                          {Paropakaritakesnoresponsibility.result
                                                                            ? Paropakaritakesnoresponsibility.result
                                                                            : "Paropakari.com takes no responsibility for these documents"}
                                                                        </span>
                                                                      </p>
                                                                      {/* <form>
        <script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_J4vFND8HkysKSE" async></script> </form> */}

                                                                      {(() => {
                                                                        if (
                                                                          langID ==
                                                                          "Telugu"
                                                                        ) {
                                                                          return (
                                                                            <center>
                                                                              <input
                                                                                type="submit"
                                                                                defaultValue={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                value={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                className="btn-primary notranslate"
                                                                                style={{
                                                                                  width: 215,
                                                                                  color:
                                                                                    "white",
                                                                                  backgroundColor:
                                                                                    "rgb(102, 71, 18)",
                                                                                  borderRadius:
                                                                                    "30px",
                                                                                  fontsize:
                                                                                    "20px",
                                                                                  padding:
                                                                                    "6px",
                                                                                }}
                                                                                id="submitPost"
                                                                              />
                                                                              {/* <Paymentt>     
<input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
</Paymentt>  */}
                                                                            </center>
                                                                          );
                                                                        } else if (
                                                                          langID ==
                                                                          "Tamil"
                                                                        ) {
                                                                          return (
                                                                            <center>
                                                                              <input
                                                                                type="submit"
                                                                                defaultValue={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                value={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                className="btn-primary notranslate"
                                                                                style={{
                                                                                  width: 280,
                                                                                  color:
                                                                                    "white",
                                                                                  backgroundColor:
                                                                                    "rgb(102, 71, 18)",
                                                                                  borderRadius:
                                                                                    "30px",
                                                                                  fontsize:
                                                                                    "20px",
                                                                                  padding:
                                                                                    "6px",
                                                                                }}
                                                                                id="submitPost"
                                                                              />
                                                                              {/* <Paymentt>     
<input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
</Paymentt>  */}
                                                                            </center>
                                                                          );
                                                                        } else {
                                                                          return (
                                                                            <center>
                                                                              <input
                                                                                type="submit"
                                                                                defaultValue={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                value={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                className="btn-primary notranslate"
                                                                                style={{
                                                                                  width: 135,
                                                                                  color:
                                                                                    "white",
                                                                                  backgroundColor:
                                                                                    "rgb(102, 71, 18)",
                                                                                  borderRadius:
                                                                                    "30px",
                                                                                  fontsize:
                                                                                    "20px",
                                                                                  padding:
                                                                                    "6px",
                                                                                }}
                                                                                id="submitPost"
                                                                              />
                                                                              {/* <Paymentt>     
                                   <input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
                                  </Paymentt>  */}
                                                                            </center>
                                                                          );
                                                                        }
                                                                      })()}
                                                                    </div>
                                                                  );
                                                                } else {
                                                                  return (
                                                                    <div>
                                                                      <input
                                                                        type="hidden"
                                                                        name="amount"
                                                                        id="amount"
                                                                        defaultValue={
                                                                          emp.amount
                                                                        }
                                                                      />

                                                                      {/* <input type="number" min={1} max={100} required
                                         onChange={this.handleChange} 
                                        className="form-control" id="response_Pledge" placeholder={YourofSupport.result?YourofSupport.result:'Your % of Support'} 
                                        value={ this.state.response_pledge }  onChange={this.handleInputChange}
                                        id="response_pledge" name="response_pledge" /> */}
                                                                      <input
                                                                        type="text"
                                                                        min={1}
                                                                        max={
                                                                          100
                                                                        }
                                                                        required
                                                                        onKeyPress={(
                                                                          event
                                                                        ) => {
                                                                          if (
                                                                            !/[0-9]/.test(
                                                                              event.key
                                                                            )
                                                                          ) {
                                                                            event.preventDefault();
                                                                          }
                                                                        }}
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        className="form-control notranslate"
                                                                        id="response_Pledge"
                                                                        placeholder={
                                                                          YourofSupport.result
                                                                            ? YourofSupport.result
                                                                            : "Your % of Support"
                                                                        }
                                                                        value={
                                                                          this
                                                                            .state
                                                                            .response_pledge
                                                                        }
                                                                        onInput={
                                                                          this
                                                                            .handleInputChange
                                                                        }
                                                                        name="response_pledge"
                                                                      />

                                                                      {detailsss.map(
                                                                        (
                                                                          detail
                                                                        ) => (
                                                                          <div>
                                                                            <input
                                                                              type="hidden"
                                                                              name="user_name"
                                                                              id="user_name"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_name
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_number"
                                                                              id="user_number"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_mobileNumber
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_Bankac"
                                                                              id="user_Bankac"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.prof_BankAccount
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_IFSC"
                                                                              id="user_IFSC"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.prof_IFSC
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_UPIID"
                                                                              id="user_UPIID"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.pro_UPIID
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name="user_email"
                                                                              id="user_email"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_email
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />

                                                                            <input
                                                                              type="hidden"
                                                                              name=""
                                                                              id="user_location"
                                                                              onChange={
                                                                                this
                                                                                  .handleChange
                                                                              }
                                                                              value={
                                                                                detail.profile_location
                                                                              }
                                                                              className="form-control"
                                                                              defaultValue
                                                                              placeholder="Your % of Support"
                                                                            />
                                                                          </div>
                                                                        )
                                                                      )}
                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_location"
                                                                        id="profile_location"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_location
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_id"
                                                                        id="upload_id"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          this
                                                                            .props
                                                                            .match
                                                                            .params
                                                                            .id
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_mobileNumber"
                                                                        id="profile_mobileNumber"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_mobileNumber
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_name"
                                                                        id="profile_name"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_name
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_BankAccount"
                                                                        id="upload_BankAccount"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.upload_BankAccount
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="status_80G"
                                                                        id="status_80G"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.status_80G
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="user_id"
                                                                        id="user_id"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.user_id
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_IFSC"
                                                                        id="upload_IFSC"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.upload_IFSC
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="upload_UPIID"
                                                                        id="upload_UPIID"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.upload_UPIID
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <input
                                                                        type="hidden"
                                                                        name="profile_email"
                                                                        id="profile_email"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        value={
                                                                          emp.profile_email
                                                                        }
                                                                        className="form-control"
                                                                        defaultValue
                                                                        placeholder="Your % of Support"
                                                                      />

                                                                      <h5 className="ng-binding notranslate">
                                                                        {Writemessageto.result
                                                                          ? Writemessageto.result
                                                                          : "Write a message to"}{" "}
                                                                        {
                                                                          emp.profile_name
                                                                        }
                                                                      </h5>
                                                                      <textarea
                                                                        rows={3}
                                                                        cols={
                                                                          50
                                                                        }
                                                                        id="response_message"
                                                                        onChange={
                                                                          this
                                                                            .handleChange
                                                                        }
                                                                        placeholder={
                                                                          YourMessagetotheNeedy.result
                                                                            ? YourMessagetotheNeedy.result
                                                                            : "Your Message to the Needy"
                                                                        }
                                                                        name="response_message"
                                                                        className="form-control notranslate"
                                                                      />

                                                                      {/* ngIf: Post.status_80G == 0 */}

                                                                      <p
                                                                        ng-if="Post.status_80G == 0"
                                                                        className="ng-binding ng-scope"
                                                                      >
                                                                        <input
                                                                          type="hidden"
                                                                          id="upload_id"
                                                                          name="upload_id"
                                                                          defaultValue={
                                                                            emp.upload_id
                                                                          }
                                                                          onChange={
                                                                            this
                                                                              .handleChange
                                                                          }
                                                                        />
                                                                        <span class="blink notranslate">
                                                                          {Thecreatorcause.result
                                                                            ? Thecreatorcause.result
                                                                            : "The creator of the cause"}{" "}
                                                                          {
                                                                            emp.profile_name
                                                                          }{" "}
                                                                          {isresponsibleforproviding.result
                                                                            ? isresponsibleforproviding.result
                                                                            : " is responsible for providing any documents "}
                                                                          .{" "}
                                                                          {Paropakaritakesnoresponsibility.result
                                                                            ? Paropakaritakesnoresponsibility.result
                                                                            : "Paropakari.com takes no responsibility for these documents"}
                                                                        </span>
                                                                      </p>
                                                                      {/* <form>
               <script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_J4vFND8HkysKSE" async></script> </form> */}

                                                                      {(() => {
                                                                        if (
                                                                          langID ==
                                                                            "Tamil" ||
                                                                          langID ==
                                                                            "Telugu"
                                                                        ) {
                                                                          return (
                                                                            <center>
                                                                              <input
                                                                                type="submit"
                                                                                defaultValue={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                value={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                className="btn-primary notranslate"
                                                                                style={{
                                                                                  width: 320,
                                                                                  color:
                                                                                    "white",
                                                                                  backgroundColor:
                                                                                    "rgb(102, 71, 18)",
                                                                                  borderRadius:
                                                                                    "30px",
                                                                                  fontsize:
                                                                                    "20px",
                                                                                  padding:
                                                                                    "6px",
                                                                                }}
                                                                                id="submitPost"
                                                                              />
                                                                              {/* <Paymentt>     
<input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
</Paymentt>  */}
                                                                            </center>
                                                                          );
                                                                        } else {
                                                                          return (
                                                                            <center>
                                                                              <input
                                                                                type="submit"
                                                                                defaultValue={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                value={
                                                                                  DonateNow.result
                                                                                    ? DonateNow.result
                                                                                    : "Donate Now"
                                                                                }
                                                                                className="btn-primary notranslate"
                                                                                style={{
                                                                                  width: 135,
                                                                                  color:
                                                                                    "white",
                                                                                  backgroundColor:
                                                                                    "rgb(102, 71, 18)",
                                                                                  borderRadius:
                                                                                    "30px",
                                                                                  fontsize:
                                                                                    "20px",
                                                                                  padding:
                                                                                    "6px",
                                                                                }}
                                                                                id="submitPost"
                                                                              />
                                                                              {/* <Paymentt>     
                                          <input type="submit" defaultValue="Donate Now" value=" Donate Now " className="btn-primary" style={{ width: 100, color:'white',backgroundColor:'rgb(102, 71, 18)',borderRadius:'30px',fontsize:'20px',padding:'6px' }} id="submitPost" />
                                         </Paymentt>  */}
                                                                            </center>
                                                                          );
                                                                        }
                                                                      })()}
                                                                    </div>
                                                                  );
                                                                }
                                                              })()}
                                                            </p>

                                                            {/* end ngIf: Post.status_80G == 0 */}
                                                            {/* ngIf: Post.status_80G == 1 */}
                                                          </div>
                                                        ))}
                                                      </div>
                                                    ))}
                                                  </div>
                                                );
                                              }
                                            })()}
                                            <div>
                                              <br />
                                            </div>

                                            <div></div>

                                            <br />
                                          </form>
                                        );
                                      }
                                    })()}
                                  </div>
                                ))}
                                <br />
                              </div>

                              <div></div>
                            </div>
                          ))}
                          <div className="media-body"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/*/#blog*/}
          <div style={{ backgroundColor: "#664712" }}>
            <footer id="footer" style={{ color: "white" }}>
              <br />
              <div className="container notranslate">
                <div className="copyright">
                  <div className="row col-lg-12">
                    <div className="col-lg-9 mb-6">
                      <p style={{ marginLeft: "45px" }}>
                        {copyright_new.result} &nbsp;{currentYear} ©{" "}
                        {paropakari_new.result} |&nbsp;
                        <a
                          href="#/Contactuslogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {contactus.result}&nbsp;
                        </a>
                        |{" "}
                        <a
                          href="#/PrivacyPolicyLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{privacypolicy.result}&nbsp;
                        </a>
                        |
                        <a
                          href="#/TermsandConditionLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{termsandcondition.result}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div
                        className="social-icons"
                        style={{ color: "white", marginLeft: "72px" }}
                      >
                        <ul className="nav nav-pills">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/beoneparopakari"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                            >
                              <i className="fa fa-youtube-play" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/paropakari_beone/"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/paropakari/"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss"></div>
            </footer>
          </div>
          {/*/#footer*/}
        </div>
      </div>
    );
  }
}
