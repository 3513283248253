
import zIndex from '@material-ui/core/styles/zIndex';
import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { BASE_URLs } from '../Constants/BaseURL';


function Taggroup({ Translated }) {
  const [data, setData] = useState([]);

  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    await fetch('https://apitest.paropakari.com/api/Group/Getgroup', { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then(response => response.json())
      .then(receivedData => setData(receivedData));

  }
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);
  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "transparent",
      color: "black",
    })
  };

  let options = data.map(function (item) {
    return { value: item.group_id, label: item.group_Name, image: item.imagePath };
  })

  let formatOptionLabel = ({ value, label, image }) => (
    <div className="grp-flex" style={{ display: 'flex' }}>
      <img className="grp-edit-img2" src={image} />
      <span style={{ marginTop: '4px' }}>&nbsp;&nbsp;{label}</span>
    </div>
  );


  return (

    <div style={{ position: 'relative', zIndex: 1 }}>
      <Select
        placeholder={Translated ? Translated : 'Select'}
        onChange={handleChange}
        options={options}
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
          }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: '180px', 
          }),
        }}
        isMulti
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: 'black',
          },
        })}
      />


      {selectedValue && (
        <div style={{ marginTop: 20, lineHeight: '25px' }}>
          <input type="hidden" id="tag_grps" name="tag_grps" defaultValue={selectedValue} required />
        </div>
      )}
    </div>
  );
}
export default Taggroup;