import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { BASE_URLs } from '../Constants/BaseURL';

function GroupSeven() {
  const [data, setData] = useState([]);

  const [selectedValue, setSelectedValue] = useState([]);

  useEffect(() => {
    loadData();

  }, []);

  const loadData = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    await fetch('https://apitest.paropakari.com/api/Group/getprofileloggedUser',
     { headers: { "Authorization": `Bearer ${accessToken}` } })
      .then(response => response.json())
      .then(receivedData => setData(receivedData));
      
    
   
  }
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);

  }
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "white",

    })
  };

  // let options = data.map(function (item) {
  //   return { value: item.id, label: item.name, image: item.profile_imagePath };
  // })

  // const options = [
  //   { 
  //     value: 1,
  //     label: "Leanne Graham"
  //   },
  //   {
  //     value:  2,
  //     label: "Ervin Howell"
  //   }
  // ];


  const options = data.map(item => {

 

    return {

      label: item.name,

      value: item.id,
      image: item.profile_imagePath

    }



  })
  let formatOptionLabel = ({ value, label, image }) => (
    <div className="grp-flex" style={{ display: 'flex' }}>
      <img className="grp-edit-img2" src={image} />
      <span style={{marginTop:'4px'}}>&nbsp;&nbsp;{label}</span>
    </div>
  );

  return (

    <div>
{/* 
      <Select



        placeholder={"Select"}
        styles={customStyles}
        menuPortalTarget={document.querySelector('body')}
        onChange={handleChange}
        options={options}
        formatOptionLabel={formatOptionLabel}
        //  options={data.map(e => ({
        //   label: <div><img className="grp-edit-img" src={e.profile_imagePath} /> {e.name} </div>, value: e.id}))}
        isMulti
        required="true"
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: 'black',
          },
        })}
      /> */}

<Select options={options}  onChange={handleChange} 
  placeholder={"Select"}
  styles={customStyles}
 // menuPortalTarget={document.querySelector('body')}
 isMulti
 required="true"
 theme={theme => ({
   ...theme,
   colors: {
     ...theme.colors,
     neutral50: 'black',
   },
 })}
 formatOptionLabel={formatOptionLabel}
/>

      {selectedValue && <div style={{ marginTop: 20, lineHeight: '25px' }}>

        <input type="hidden" id="memberselect" name="user_id" defaultValue={selectedValue}/>

      </div>}
    </div>



  );
}
export default GroupSeven;