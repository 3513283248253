import React, { Component } from "react";
import Moment from "react-moment";
import Script from "react-load-script";
//import SideBars from './SideBars.js';
import { Link } from "react-router-dom";
import GoogleTranslate from "./GoogleTranslate";
import Cookies from "js-cookie";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from '../Constants/BaseURL';
// const refreshPage = ()=>{

//   setTimeout(() => window.location.reload(), 2000);

// }
// const refreshPage = (e) => {
//   setTimeout(() => window.location.reload(true), 2000);
//   e.preventDefault();
//   var banner = document.getElementById('#google_translate_element');
//   if (banner) {
//     banner.style.display = 'none';
//   }
// }

export default class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id,
      user_id: "",
      profile_imagePath: "",
      profile_name: "",

      Created_Date: "",
      upload_description: "",
      Created_Date: "",
      upload_amount: "",
      upload_place: "",
      upload_title: "",
      status_80G: "",
      upload_num_of_beneficiaries: "",
      category_id: "",
      category_name: "",
      delete_status: false,
      file_name: "",
      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      notify: [],
      emps: [],
      upload_id: "",
      foo: [],
      Menu: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      privacypolicy: [],
      requiredforr: [],
      self: [],
      familyfriends: [],
      others: [],
      beneficiaryname: [],
      submit: [],
      causes: [],
      friends: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      donateasmile: [],
      notification: [],

      langID: [],

      itemsToShow: 10,
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),

      expanded: false,
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      //cookie
      language: "",
      previousCookieLang: "",
    };
    //   this.handleEvent = this.handleEvent.bind(this);

    this.showMore = this.showMore.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);

    this.handleChanges = this.handleChanges.bind(this);
  }
  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
    // name = oldName == null ? "English" : name;

    // VIpgJd-ZVi9od-vH1Gmf-ibnC6b

    // if (banner) {
    //   banner.style.display = "none";
    // }
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login",
    };
    this.props.history.push(page);
  };

  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        localStorage.setItem("noticount", JSON.stringify(data[0].noticount));
        //
      });
  };

  //   handleClick = (uploadid) async event => {
  //     alert(uploadid);
  //     const data = new FormData(event.target);

  //     let user = JSON.parse(localStorage.getItem('user'));

  //     var datas = {
  //       upload_id: uploadid,
  //       Donor_contribution:newPercent,
  //       Donor_AccountNumber:user_Bankac,
  //       Donor_IFSC:user_IFSC,
  //       Donor_UPI:user_UPIID,
  //       Donor_email:user_email,
  //        Donor_mobilenumber:user_number,
  //        Donor_Address:user_location,
  //        Beneficiary_userid:user_id,
  //        Beneficiary_name:profile_name,
  //        Donor_name:user_name,
  //        Beneficiary_email:profile_email,
  //        Beneficiary_mobilenumber:profile_mobileNumber,
  //       Beneficiary_AccountNumber:upload_BankAccount,
  //       Beneficiary_IFSC:upload_IFSC,
  //       Beneficiary_UPI:upload_UPIID,
  //        Order_id:order_id,
  //        Order_amount:razorpay_amount,
  //        Order_created_date:"21",
  //        Order_status:status,
  //        Dpercentageofsupport:percent,
  //        Payment_id:"23",
  //        Paymenta_amount:"24",
  //        Payment_created_date:"25",
  //        Payment_status:"26",

  //       };
  //
  //     const accessToken=user;
  //     const url ="https://apitest.paropakari.com/api/GetProfile/InsertNotification";
  //     const options = {
  //         method: "POST",
  // headers:{

  // Accept: 'application/json',
  // "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
  // "Access-Control-Allow-Origin": "*",
  // Authorization: "Bearer " +accessToken
  // },
  // body: data,
  // };

  // var dist = document.getElementById('upload_id');
  //
  //       this.setState(this.initialState)
  //       const response = await fetch(url, options);
  //       const result = await response.json();

  //         //alert(' success');
  //         window.location.href = `#/ResponsePage/${letter}`;

  //     this.setState(this.initialState)
  //

  //
  //   }

  handleNotify = async (uploadid, tableid, statustype, event) => {
    const { upload_id } = this.state;

    event.preventDefault();
    //   var uid = document.getElementById('upload_id');
    // alert("uploadId"+uploadid+tableid+statustype)
    //

    //  const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    const url =
      "https://apitest.paropakari.com/api/GetProfile/InsertNotification?upload_id=" +
      uploadid +
      "&table_id=" +
      tableid +
      "&status_type=" +
      statustype;
    const options = {
      method: "POST",
      headers: {
        // Accept: 'application/json',
        "Content-Type": "application/json",
        // "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + accessToken,
      },
      body: "data",
    };

    var dist = document.getElementById("upload_id");
    //
    // this.setState(this.initialState)
    const response = await fetch(url, options);
    const result = await response.json();

    if (statustype == "F") {
      window.location.href = `#/ResponsePage/${uploadid}`;
    } else if (statustype == "N") {
      window.location.href = `#/ResponsePage/${uploadid}`;
    } else if (statustype == "T") {
      window.location.href = `#/ResponsePage/${uploadid}`;
    } 
    else {
 
      window.location.href = `#/Confirm/${uploadid}`;
    }

    //  this.setState(this.initialState)
  };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  submit = () => {
    let url = "https://apitest.paropakari.com/api/GetProfile/Get_Notification";
    let data = this.state;

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        const result = data.sort(function (a, b) {
          return new Date(b.created_Date) - new Date(a.created_Date);
        });
        this.setState({
          emps: result,
        });
      });
  };

  showMore() {
    this.state.itemsToShow === 10
      ? this.setState({ itemsToShow: this.state.emps.length, expanded: true })
      : this.setState({ itemsToShow: 10, expanded: false });
  }
  Footer = () => {
    let url =
      "https://apitest.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  ReloadPage = () => {
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem("notificationpagereload") !== null) {
      reloads = sessionStorage.getItem("notificationpagereload");
      reloads++;
      window.sessionStorage.setItem("notificationpagereload", reloads);
    } else {
      reloads = 1;
      window.sessionStorage.setItem("reload", reloads);
    }

    if (reloads == 1) {
      window.location.reload();
    }
  };

  DefaultMenu() {
    //
    let reloads = 0;
    window.sessionStorage.setItem("reload", reloads);
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    // window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));

    // let l_id = JSON.parse(localStorage.getItem('menuid'));
    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    let datatrans =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem("this.state.googtrans");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID = MID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    window.sessionStorage.setItem("translate", datatransID);

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Notification~Privacy Policy~Friends&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var Notification = typeof items[3] !== "undefined" ? items[3] : null;
          var PrivacyPolicy = typeof items[4] !== "undefined" ? items[4] : null;
          var Friends = typeof items[5] !== "undefined" ? items[5] : null;
          var LangID = datatransID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            notification: Notification,
            langID: LangID,

            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            privacypolicy: PrivacyPolicy,
            friends: Friends,
            submit: Submit,
          });

          //
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = datatransID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            myjourney: Myjourney,
            howitworks: Howitwork,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,

            langID: LangID,

            others: Others,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;

          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = datatransID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,

            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,

            langID: LangID,

            requiredforr: RequiredFor,
            self: Self,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
    
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Family/Friends~Beneficiary Name~Submit~Notification~Privacy Policy~Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FamilyandFriends =
            typeof items[0] !== "undefined" ? items[0] : null;
          var BeneficiaryName =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Submit = typeof items[2] !== "undefined" ? items[2] : null;
          var Notification = typeof items[3] !== "undefined" ? items[3] : null;
          var PrivacyPolicy = typeof items[4] !== "undefined" ? items[4] : null;
          var Friends = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            notification: Notification,
            langID: LangID,
            privacypolicy: PrivacyPolicy,
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
            friends: Friends,
            submit: Submit,
          });

          //
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            myjourney: Myjourney,
            howitworks: Howitwork,

            beaparopakari: Beaparopakari,

            createacause: Createacause,
            invite: Invite,

            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,

            langID: LangID,

            others: Others,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;

          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            blog: Blog,

            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,

            langID: LangID,

            requiredforr: RequiredFor,
            self: Self,
          });

          //
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
          });
        });
    }
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // handleChange = (e) => {
  //     const { name, value } = e.target;
  //     this.setState({ [name]: value });
  //
  //   }

  routeChange = () => {
    window.location.href = "./ResponsePage";
  };

  showMore() {
    this.state.itemsToShow === 10
      ? this.setState({ itemsToShow: this.state.emps.length, expanded: true })
      : this.setState({ itemsToShow: 10, expanded: false });
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();
    setInterval(() => {
      this.submit();
    }, 5000);

    window.addEventListener("load", this.notify);
    this.notify();
    setInterval(() => {
      this.notify();
    }, 5000);
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const elements = document.getElementById("goog-gt-vt");
    if (elements) {
      elements.style.display = "none"; // Hide the element if it exists
    }
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "View your notifications";
    this.interval = setInterval(this.checkAndSetLanguage, 500); // Check every second
    this.checkAndSetLanguage(); // Initial check
  }
  checkAndSetLanguage = () => {
    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    const cookieLang =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    if (cookieLang !== this.state.previousCookieLang) {
      let newLanguage = "";
      let MID = "English";
      if (cookieLang === "en") {
        newLanguage = "English";
      } else if (cookieLang === "kn") {
        newLanguage = "Kannada";
      } else if (cookieLang === "ta") {
        newLanguage = "Tamil";
      } else if (cookieLang === "te") {
        newLanguage = "Telugu";
      } else if (cookieLang === "hi") {
        newLanguage = "Hindi";
      } else if (cookieLang === "es") {
        newLanguage = "Spanish";
      } else if (cookieLang === MID) {
        newLanguage = MID;
      }

      sessionStorage.setItem("translate", newLanguage);
      this.setState(
        { language: newLanguage, previousCookieLang: cookieLang },
        this.DefaultMenu()
      );
    }
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("load", this.submit);
    this.submit();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    const element = document.getElementById("goog-gt-vt");
    if (element) {
      element.style.display = "none"; // Hide the element if it exists
    }
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }
  render() {
    const { emps } = this.state;
    const { notify } = this.state;
    var noticount = JSON.parse(localStorage.getItem("noticount"));
    let { foo, Menu } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      others,
      requiredforr,
      familyfriends,
      self,
      submit,
      beneficiaryname,
      notification,
      privacypolicy,
      friends,
      langID,
      copyright_new,
      paropakari_new,
    } = this.state;
    var username = JSON.parse(localStorage.username);
    const currentYear = new Date().getFullYear();
    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
          // className="notranslate"
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          {/*/head*/}
          <select
            className="form-control notranslate"
            style={{
              display: "none",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
            value={this.state.value}
            onChange={this.handleChanges}
          >
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          <div
            id="google_translate_element"
            onClick={this.refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          ></div>
          {/* </form>   */}
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title ">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text notranslate">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontsize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a className="text-parop-menu" href="#/Notification/">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a className="text-parop-menu" href="#/Notification/">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top notranslate">
                        <li className="parop-menu notranslate">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a className="text-parop-menu" href="#/Notification/">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p
                        class="pro-center-tamil"
                        style={{ marginRight: "10px" }}
                      >
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a className="text-parop-menu" href="#/Notification/">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a className="text-parop-menu" href="#/Notification/">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a className="text-parop-menu" href="#/Notification/">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}} className="notranslate">    

<div class="navbar navbar-inverse" role="banner">
   <div class="container" id="containerid">
       <div class="navbar-header">
           <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
               <span class="sr-only">Toggle navigassstion</span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
               <span class="icon-bar"></span>
           </button>

           <a class="navbar-brand" href="#/Homepage">
             <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo"style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI
                   
               </h1>
           </a>
           
       </div>
       <div class="collapse navbar-collapse">
           <ul class="nav navbar-nav navbar-right">
           
               
               <li><a href="#/Myactivity"><b>{myjourney.result}</b></a></li> 
               <li><a href="#/CauseFor"><b>{createacause.result}</b></a></li> 
               <li><a href="#/InviteFriend"><b>{invite.result}</b></a></li> 
               <li className="active"><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                            
                          <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                               <span class="-count"> 
                                
                              
                                {noticount}
                               
                                  </span> ))}</div>
                           </a></li> 
             
               <li class="dropdown"><a><b>{username}</b> <i class="fa fa-angle-down"></i></a>
                   <ul role="menu" class="sub-menu">
                       <li><a href="#/Profile"><b>{profile.result}</b></a></li>
                       <li><a href="#/ChangePassword"><b>{changepassword.result}</b></a></li>
                       <li><a href="#/Group"><b>{group.result}</b></a></li>
                       <li><a href="#/Login"><b>{logout.result}</b></a></li>
                   </ul>
               </li>                         
            
           </ul>
       </div>
       
   </div>
</div>
</header> */}

            <div>
              <br style={{ backgroundColor: "#aee6db" }} />
              <section
                id="about-company"
                className={
                  this.state.movedown == true
                    ? "wow fadeInUp"
                    : "wow fadeInUp Tiptop"
                }
                data-wow-duration="400ms"
                data-wow-delay="400ms"
                style={{ backgroundColor: "#aee6db" }}
              >
                <div className="noti-inv-button notranslate" id="notifysidebar">
                  {(() => {
                    if (langID == "Tamil") {
                      return (
                        <div className="inv-button1" id="button-pulse">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/InviteFriend/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{invite.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "English") {
                      return (
                        <div className="inv-button1" id="button-pulse">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/InviteFriend/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{invite.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "Spanish") {
                      return (
                        <div className="inv-button1" id="button-pulse">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/InviteFriend/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{invite.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "Hindi") {
                      return (
                        <div className="inv-button1" id="button-pulse">
                          <p className="inv-text notranslate">
                            <a
                              className="inv-text-link"
                              href={"#/InviteFriend/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{invite.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "Telugu") {
                      return (
                        <div className="inv-button1" id="button-pulse">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/InviteFriend/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{invite.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "Kannada") {
                      return (
                        <div className="inv-button1" id="button-pulse">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/InviteFriend/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{invite.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (langID == "Tamil") {
                      return (
                        <div className="inv-button1">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/CauseFor/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (langID == "English") {
                      return (
                        <div className="inv-button1">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/CauseFor/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "Spanish") {
                      return (
                        <div className="inv-button1">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/CauseFor/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (langID == "Hindi") {
                      return (
                        <div className="inv-button1">
                          <p className="inv-text notranslate">
                            <a
                              className="inv-text-link"
                              href={"#/CauseFor/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}

                  {(() => {
                    if (langID == "Telugu") {
                      return (
                        <div className="inv-button1">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/CauseFor/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {(() => {
                    if (langID == "Kannada") {
                      return (
                        <div className="inv-button1">
                          <p className="inv-text">
                            <a
                              className="inv-text-link"
                              href={"#/CauseFor/" + langID}
                            >
                              &nbsp;&nbsp;&nbsp;{beaparopakari.result}
                            </a>
                          </p>
                        </div>
                      );
                    }
                  })()}

                  <a
                    onClick={this.topFunction}
                    id="myBtn"
                    title="Go to top"
                    style={{
                      display: "block",
                      borderColor: "transparent",
                      textAlign: "left",
                      marginLeft: "80%",
                      fontsize: "60px",
                    }}
                  >
                    <p
                      className="arrow-btn"
                      style={{
                        fontsize: "25px",
                        sizes: "25",
                        width: "100px",
                        color: "rgb(102, 71, 18)",
                      }}
                    >
                      <i
                        style={{
                          fontsize: "25px",
                          sizes: "25",
                          width: "100px",
                          color: "rgb(102, 71, 18)",
                        }}
                        class="fa1 fa-arrow-circle-up"
                      ></i>
                    </p>
                  </a>
                </div>

                <div
                  className="container "
                  id="containerid"
                  style={{ marginTop: "-9%" }}
                >
                  <center>
                    <h1 className="title notranslate">
                      {notification.result}{" "}
                    </h1>
                  </center>
                  <br />
                  <div className="row">
                    <form>
                      <div className="col-md-10 col-sm-8">
                        {this.state.emps
                          .slice(0, this.state.itemsToShow)
                          .map((emp) => (
                            <tr key={emp.user_id}>
                              {(() => {
                                if (emp.flag == 0) {
                                  return (
                                    <div className="col-sm-12">
                                      <a
                                        onClick={async (event) => {
                                          await this.handleNotify(
                                            emp.upload_id,
                                            emp.id,
                                            emp.status,
                                            event
                                          );
                                        }}
                                      >
                                        <button
                                          type="submit"
                                          style={{
                                            background: "transparent",
                                            color: "black",
                                            border: "none",
                                          }}
                                        >
                                          {/* <input type="hidden" name="status_type" id="status_type" defaultValue={emp.status} />
                                        <input type="hidden" name="table_id" id="table_id" defaultValue={emp.id} />
                                        <input type="hidden" name="upload_id" id="upload_id" value={emp.upload_id} /> */}
                                          &nbsp;&nbsp;<b>{emp.profile_name}</b>{" "}
                                          {emp.notification_Description}{" "}
                                          <b>{emp.upload_title}</b> {emp.post}
                                        </button>
                                      </a>
                                      &nbsp;&nbsp;
                                      <b>
                                        {" "}
                                        <p
                                          style={{
                                            display: "inline",
                                            float: "right",
                                            fontSize: "9px",
                                          }}
                                        >
                                          <Moment fromNow>
                                            {emp.created_Date}
                                          </Moment>
                                        </p>
                                      </b>
                                      <hr
                                        style={{
                                          color: "#50d5c0",
                                          borderColor: "#50d5c0",
                                          backgroundColor: "#50d5c0",
                                        }}
                                      />
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="col-sm-12">
                                      <a
                                        onClick={async (event) => {
                                          await this.handleNotify(
                                            emp.upload_id,
                                            emp.id,
                                            emp.status,
                                            event
                                          );
                                        }}
                                      >
                                        <button
                                          type="submit"
                                          class="boxes move-up"
                                          style={{
                                            background: "transparent",
                                            color: "gray",
                                            border: "none",
                                          }}
                                        >
                                          {/* <input type="hidden" name="status_type" id="status_type" defaultValue={emp.status} />
                                        <input type="hidden" name="table_id" id="table_id" defaultValue={emp.id} />
                                        <input type="hidden" name="upload_id" id="upload_id" value={emp.upload_id} /> */}
                                          {/* Dhinesh */}
                                          &nbsp;&nbsp; <b>
                                            {emp.profile_name}
                                          </b>{" "}
                                          {emp.notification_Description}{" "}
                                          <b>{emp.upload_title}</b> {emp.post}
                                          &nbsp;&nbsp; {emp.Created_Date}
                                        </button>
                                      </a>
                                      <p
                                        style={{
                                          display: "inline",
                                          float: "right",
                                          fontSize: "9px",
                                        }}
                                      >
                                        <Moment fromNow>
                                          {emp.created_Date}
                                        </Moment>
                                      </p>
                                      <hr
                                        style={{
                                          color: "#50d5c0",
                                          borderColor: "#50d5c0",
                                          backgroundColor: "#50d5c0",
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              })()}
                            </tr>
                          ))}
                        {(() => {
                          if (this.state.emps.length === 0) {
                            return <div></div>;
                          } else if (this.state.emps.length >= 10) {
                            return (
                              <div className="col-sm-12">
                                <a
                                  className="btn btn-link"
                                  style={{
                                    marginLeft: "34%",
                                    marginBottom: "20px",
                                    color: "Black",
                                  }}
                                  onClick={this.showMore}
                                >
                                  {this.state.expanded ? (
                                    <span>Show Less</span>
                                  ) : (
                                    <span>Show More</span>
                                  )}
                                </a>
                              </div>
                            );
                          }
                        })()}
                      </div>

                      {/* <div className="col-md-2 col-sm-2" style={{marginTop:'15%'}}>
  <div className="sidebar1 blog-sidebar" id="notifysidebar" style={{position:'fixed'}}>
  {(() => {if (langID == 'Tamil' ) {return (
         <div className="sidebar-item  recent" id="button-pulse tamilbtn"style={{marginLeft:'2%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
         <div className="media" >
           
           
           <div className="pull-left">
             <a href={"#/InviteFriend/"+langID}  style={{textDecoration:'none',color:'white',marginLeft:'43%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
           </div>
           <div className="media-body">
           
           </div>
         </div>
         
        
       </div>
);
}


})()}   

{(() => {if (langID == 'English' ) {return (
         <div className="sidebar-item  recent " id="button-pulse" style={{marginLeft:'36%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
               <div className="media" >
                 
                 
                  <div className="pull-left">
                   <a href={"#/InviteFriend/"+langID}   style={{textDecoration:'none',color:'white',marginLeft:'80%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
                  </div>
                  <div className="media-body">
                 
                  </div>
                </div>
               
              
              </div>
);
}
              
})()} 
{(() => {if ( langID == 'Hindi' ) {return (
         <div className="sidebar-item  recent" id="button-pulse"style={{marginLeft:'38%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
               <div className="media" >
                 
                 
                  <div className="pull-left">
                   <a href={"#/InviteFriend/"+langID}   style={{textDecoration:'none',color:'white',marginLeft:'55%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
                  </div>
                  <div className="media-body">
                 
                  </div>
                </div>
               
              
              </div>
);
}
              
})()} 

  {(() => {if (langID == 'Telugu' ) {return (
         <div className="sidebar-item  recent" id="button-pulse" style={{marginLeft:'22%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
         <div className="media" >
           
           
           <div className="pull-left">
             <a href={"#/InviteFriend/"+langID}  style={{textDecoration:'none',color:'white',marginLeft:'25%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
           </div>
           <div className="media-body">
           
           </div>
         </div>
         
        
       </div>
);
}
              
})()}   
 {(() => {if (langID == 'Kannada' ) {return (
         <div className="sidebar-item  recent" id="button-pulse"style={{marginLeft:'35%',marginRight:'5%',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
         <div className="media" >
           
           
           <div className="pull-left">
             <a href={"#/InviteFriend/"+langID}  style={{textDecoration:'none',color:'white',marginLeft:'37%'}}>&nbsp;&nbsp;&nbsp;{invite.result}</a>
           </div>
           <div className="media-body">
           
           </div>
         </div>
         
        
       </div>
);
}
              
})()} 

{(() => {if (langID == 'Tamil' ) {return (
            <div className="sidebar-item  recent" id="tamilbtn" style={{marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
      
            <div className="media">
              <div className="pull-left"  style={{paddingRight:"7px"}}>
              <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;{beaparopakari.result}</a>
              </div>
              <div className="media-body">
               
              </div>
            </div>
           
          </div>
);
}


})()}   
{(() => {if (langID == 'English' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'36%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
      
                               <div className="media">
                                 <div className="pull-left">
                                 <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
                               </div>
                               <div className="media-body">
                                 
                                </div>
                               </div>
                             
                             </div>
);
}
                

})()} 
{(() => {if ( langID == 'Hindi' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'38%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
      
                               <div className="media">
                                 <div className="pull-left">
                                 <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
                               </div>
                               <div className="media-body">
                                 
                                </div>
                               </div>
                             
                             </div>
);
}
                

})()} 
{(() => {if (langID == 'Telugu' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'22%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
      
            <div className="media">
              <div className="pull-left">
              <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
              </div>
              <div className="media-body">
               
              </div>
            </div>
           
          </div>
);
}
                

})()} 
{(() => {if (langID == 'Kannada' ) {return (
            <div className="sidebar-item  recent" style={{marginLeft:'35%',marginRight:'5%',marginBottom:'5px',color:'white',backgroundColor:'rgb(102, 71, 18)',borderColor:'transparent',border:'2px solid #63501e94',borderRadius:'15px'}}>
      
      
            <div className="media">
              <div className="pull-left">
              <a href={"#/CauseFor/"+langID} style={{textDecoration:'none',color:'white'}}>&nbsp;&nbsp;&nbsp;{beaparopakari.result}</a>
              </div>
              <div className="media-body">
               
              </div>
            </div>
           
          </div>
);
}
                

})()}    
    
 
    <a onClick={this.topFunction} id="myBtn" title="Go to top" style={{display: 'block',borderColor: 'transparent',textAlign:'left',marginLeft:'80%',fontsize:'60px'}}>
<p style={{fontsize:'25px',sizes:'25',width:'100px',color:'rgb(102, 71, 18)'}}><i style={{fontsize:'25px',sizes:'25',width:'100px',color:'rgb(102, 71, 18)'}} class="fa1 fa-arrow-circle-up" ></i></p>

</a>
    
    
  </div>
</div> */}
                    </form>
                    <br></br>
                  </div>
                </div>
              </section>
            </div>
            {/*/#team*/}
            <footer id="footer" style={{ color: "white" }}>
              <br />
              <div className="container">
                <div className="copyright">
                  <div className="row col-lg-12">
                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                      <p className="notranslate">
                        {copyright_new.result} &nbsp;{currentYear} ©{" "}
                        {paropakari_new.result} |&nbsp;
                        <a
                          href="#/Contactuslogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {contactus.result}&nbsp;
                        </a>
                        |{" "}
                        <a
                          href="#/PrivacyPolicyLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{privacypolicy.result}&nbsp;
                        </a>
                        |
                        <a
                          href="#/TermsandConditionLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{termsandcondition.result}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div className="social-icons" style={{ color: "white" }}>
                        <ul className="nav nav-pills">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/beoneparopakari"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                            >
                              <i className="fa fa-youtube-play" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/paropakari_beone/"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/paropakari/"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss"></div>
            </footer>
            {/*/#footer*/}
          </div>
        </div>
      </div>
    );
  }
}
