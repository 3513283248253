import React, { useState, useEffect } from "react";
import { BASE_URLs } from '../Constants/BaseURL';

import Modal from "react-modal";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '480px',
    width: '405px',
    borderRadius: "20px",
    backgroundcolor: "green",
    border: "none",
  },
};


export default function Popup({ Id, Translatedword }) {
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);

  const[modal,setModal] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
    setModal(false)
  }

  useEffect(() => {
   
    loadData();
   

  }, []);

  const loadData = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    //   const urll = new URL(window.location.href);
    //   const Id = urll.searchParams.get("upload_id");
    //   
    //   
    await fetch("https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisDetails?id=" + Id
      , {
        headers: { "Authorization": `Bearer ${accessToken}` }

      })
      .then(response => response.json())
      .then(receivedData => setData(receivedData));
    

  }
  const handleChange = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : []);

  }

  const update = () => {

    // setIsOpen(true);
     setModal(true);
    //document.getElementById("modalRegisterForm").style.display = "block";
  
  }

  return (
    <div className="modally">

      <button className="toggle" onClick={update}> &nbsp;&nbsp;{Translatedword ? Translatedword : 'Paropakaris'}</button>


      {modal&&(
      <div  id="modalRegisterForm" tabindex="-1" role="dialog" style={{position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor:"rgba(255, 255, 255, 0.75)",
      outline: 0}}>
"

        <div style={{ 
    position:"absolute",
    inset:"50% auto auto 50%",
    border:"none",
    background: "rgb(255, 255, 255)",
    overflow: "auto",
    borderRadius:"20px",
    outline:"none",
    padding: "20px",
    marginRight:"-50%",
    transform: "translate(-50%, -50%)",
    height: "480px",
    width: "345px"}}>
           

           <div>  <div className="modal-header" style={{
          height: "100%",
          width: "100%",
          background: "linear-gradient(rgb(2, 170, 176) 0%, rgb(0, 205, 172) 100%)",
          display: "block",
          borderRadius:"10px",
          marginTop: "-20px"
        }}>
         <h4 className="modal-title" id="head-style" style={{
          textAlign: "center",
          fontWeight: "bold",
          }}>{Translatedword ? Translatedword : 'Paropakaris'}</h4>
          <button type="button" className="close" onClick={toggleModal}>×</button>
        </div> <table style={{ marginLeft: "10px" }}>
            <br />
            {data.map((paropakaris) => (

              <tr style={{ fontSize: '16px' }}>


                <td>{paropakaris.profile_name}:&nbsp;{paropakaris.response_pledge}%</td><br /><br />
                {/* <img src={paropakaris.profile_imagePath} alt style={{width:'10%',borderRadius:"30px"}} />  */}

                <td></td>

              </tr>
            ))}
          </table></div>
         
          
    </div>

        </div>)}


    </div>
  );
}