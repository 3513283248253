import React, { Component } from "react";
import "../assets/css/imagePopup.css";
import deleteicon from "../assets/images/deleteImg.png";
import Axios from "axios";
import { BASE_URLs } from '../Constants/BaseURL';

export default class DisplayingPopup extends Component {
  constructor(props) {
    super(props);
    this.prevCountRef = React.createRef();
    this.deleteimg = this.deleteimg.bind(this);

    this.state = {
      displayUsers: [],
      visible: false,
      NewdisplayUsers: [],
      File_Type: [],
    };
  }

  displayAlert() {
    this.props.onClose();
    // window.location.reload();
  }



  deleteimg() {
    // var x =  window.confirm(this.props.Delete + " " +this.props.File + "?");
    var x =  window.confirm(this.props.Delete + "?");
    if (x) {
   
    let url = "https://apitest.paropakari.com/api/Create/deleteimageedit?file_id=" + this.props.passid;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error('Failed to delete image');
        }
      })
      .then((data) => {
        
        this.props.onDeleteImage(this.props.passid); // Call the callback to update state in parent
      })
      .catch((error) => {
        console.error("Error deleting image:", error);
      });
    }else{}
  }
  

  getFileType = () => {
    const parts = this.props.passVal.split(".");
    if (parts.length > 1) {
      return parts[parts.length - 1];
    } else {
      return "Unknown";
    }
  };
  componentDidMount() {
    const fileType = this.getFileType();
    this.setState(
      {
        File_Type: fileType,
      },
      () => { }
    );

    
  }
  render() {
    const { File_Type } = this.state;
    return (
      <div className="modal__backdrop">
        <div className="modal__container">
          <div class="pic-ctn">
            <div className="head-sec">
              <p align="right">
                <img
                  src="./assets/images/delete.png"
                  height="15px"
                  onClick={() => this.displayAlert()}
                  width="15px"
                />
              </p>
              <>
                {File_Type == "docx" ||
                  File_Type == "doc" ||
                  File_Type === "xlsx" ||
                  File_Type === "xls" ||
                  File_Type == "pdf" ||
                  File_Type == "mp4" ||
                  File_Type == "webm" ? (
                  <div style={{ paddingLeft: "10%", paddingTop: "10%" }}>
                    <p>
                      {this.props.Delete} <strong>{File_Type}</strong> {this.props.File}?
                    </p>
                  </div>
                ) : (
                  <img
                    src={this.props.passVal}
                    className="img-algn"
                    alt="Cause Attachment"
                    height="150"
                    width="200px"
                  />
                )}
                {File_Type == "docx" ||
                  File_Type == "doc" ||
                  File_Type === "xlsx" ||
                  File_Type === "xls" ||
                  File_Type == "pdf" ||
                  File_Type == "mp4" ||
                  File_Type == "webm" ? (
                  <div style={{ textAlign: "center" }}>
                    <img
                      src={deleteicon}
                      height="15px"
                      width="20px"
                      onClick={() => this.deleteimg()}
                    ></img>
                  </div>
                ) : (
                  <img
                    src={deleteicon}
                    height="15px"
                    width="20px"
                    onClick={() => this.deleteimg()}
                  ></img>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
