import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
 ReactDOM.render(
  <React.StrictMode>
    <App />
    {/* <Script src='./assets/js/jquery.js'></Script>
<Script src='./assets/js/bootstrap.min.js'></Script>
<Script src='./assets/js/lightbox.min.js'></Script>
<Script src='./assets/js/wow.min.js'></Script>
<Script src='./assets/js/main.js'></Script> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
