import React, { Component } from 'react';
// import '../assets/css/bootstrap.min.css';
import Cookies from 'js-cookie';
import { BASE_URLs } from '../Constants/BaseURL';

export default class Ourstory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id, 
      value: null,
      foo: [],
      Details: [],
      OurStory: [],
      HowItWorks: [],
      Blog: [],
      Login: [],
      Paropakari: [],
      TermsandConditions: [],

      passwordd: [],
      ForgetPassword: [],
      contactus: [],
      termsandcondition: [],
      signup: [],
      signin: [],
      Asoneof: [],
      Whiletravelling: [],
      Theneedfor: [],
      Adilemma: [],
      Wedidnt: [],
      Thisled: [],
      Mostcrowd: [],
      Wethought: [],
      Ifwewant: [],
      whyshould: [],
      Sowedecided: [],
      Weneedyour: [],
      providessupport: [],
      privacypolicy: [],
      Paropakariisabeautiful: [],
      tobringless: [],
      Itisthe: [],
      Unlikeother: [],
      wehave: [],
      Allyouhave: [],
      Ifyouwant: [],
      OnParopakari: [],
      Youcanmanage: [],
      Aboutus: [],
      Disclaimer: [],
      OurStory: [],
      Causes: [],
      Beneficiaries: [],
      Paropakaris: [],
      copyright_new: [],
      paropakari_new: [],
      AmountRaised: [],
      Copyright: [],
      langID: [],
      beaparopakari: [],
      movedown: true,
    };
    this.initialState = this.state;
    this.handleChanges = this.handleChanges.bind(this);
    //this.handleClick = this.handleClick.bind(this);

    this.handleChanged = this.handleChanged.bind(this);
  }

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  Footer = () => {

    

  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChanges = () => {
    let reloads = 0;
    window.sessionStorage.setItem('reloadlogin', (reloads));
    window.sessionStorage.setItem('reload', (reloads));
    window.sessionStorage.setItem('reloadcreate', (reloads));
    let MID;
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    
    if (MID !== null) {
      // this.setState({value: event.target.value}); 
      let user2 = sessionStorage.getItem('translate');
      // let reloadlogin=0;
      // window.sessionStorage.setItem('reloadlogin', (reloadlogin));
      // 
      var someVarName = "value";
      localStorage.setItem("someVarKey", someVarName);
      var someVarName = "value";
      localStorage.setItem("someVarKey", someVarName);
      
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~ContactUs~TermsandConditions~As one of its charitable services,~providessupporttopoorchildrenseducationinruralandremoteareas.~Whiletravellingfromonevillagetoanotherandlookingatunderprivilegedfamiliesandtheirproblems,arealizationdawneduponus.&lang=" + MID;
      // let user = JSON.parse(localStorage.getItem("user"));
      // const accessToken = user;
      // 
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          // Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;

          // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
          //     .map(function() { return this.splice(0, size) }, longArray.slice());

          var items = data;
          var firstItem = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem1 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem2 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem3 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem4 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem5 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem6 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem7 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem8 = typeof items[8] !== "undefined" ? items[8] : null;
          var firstItem9 = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (MID));
          
          this.setState({
            OurStory: firstItem,
            HowItWorks: firstItem1,
            Blog: firstItem2,
            Login: firstItem3,
            beaparopakari: firstItem4,
            contactus: firstItem5,
            TermsandConditions: firstItem6,
            Asoneof: firstItem7,
            providessupport: firstItem8,
            Whiletravelling: firstItem9,

            langID: LangID

          });

          //  
          const trans = "Vijayaasri";
          // this.setState({ newArray: newArray });
        });

      if (MID !== null) {

        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Theneedforsupportisfargreaterthanwhatwecandothroughourfoundation.~Adilemmawasuponus.Dowespendallourresourcestoprovidesupportoristherealimittowhatwecando?~Wedidntlikeboththeseoptionsandsetouttospreadthewordtotrytoinspireandencouragepeopletojoinourvisionandhelptheneedy.~Thisledustothepreexistingideaofcrowdfunding.Followinguponthisideawewereleftastoundedwithwhatwefound.~Mostcrowdfundingwebsiteschargeafeetopostortakeacommissionfromthemoneyyouraise.~Wethoughttoourself~Ifwewanttoperformaselflessactofhelpingsomeone~whyshouldwepayforit~Sowedecidedtocreateaplatformconnectingtheneedywiththemorefortunatedirectlywithoutthirdpartyinvolvementorfees.~Weneedyouraffectionandsupportinthedevelopmentofourgenuinehumblealtruisticendeavortoreachasignificantscaleandmakeadifference&lang=" + MID;
        //  let user = JSON.parse(localStorage.getItem("user"));
        // const accessToken = user;
        // 
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            //  Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            
            const longArray = data;
            const size = 1;

            // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            //     .map(function() { return this.splice(0, size) }, longArray.slice());

            var items = data;

            var firstItem10 = typeof items[0] !== "undefined" ? items[0] : null;
            var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem12 = typeof items[2] !== "undefined" ? items[2] : null;

            var firstItem13 = typeof items[3] !== "undefined" ? items[3] : null;
            var firstItem14 = typeof items[4] !== "undefined" ? items[4] : null;
            var firstItem15 = typeof items[5] !== "undefined" ? items[5] : null;
            var firstItem16 = typeof items[6] !== "undefined" ? items[6] : null;
            var firstItem17 = typeof items[7] !== "undefined" ? items[7] : null;
            var firstItem18 = typeof items[8] !== "undefined" ? items[8] : null;
            var firstItem19 = typeof items[9] !== "undefined" ? items[9] : null;

            var LangID = MID;
            window.sessionStorage.setItem('translate', (MID));
            
            this.setState({

              Theneedfor: firstItem10,
              Adilemma: firstItem11,
              Wedidnt: firstItem12,
              Thisled: firstItem13,
              Mostcrowd: firstItem14,
              Wethought: firstItem15,
              Ifwewant: firstItem16,
              whyshould: firstItem17,
              Sowedecided: firstItem18,
              Weneedyour: firstItem19,

              langID: LangID

            });

            //  
            const trans = "Vijayaasri";
            // this.setState({ newArray: newArray });
          });

      }

      if (MID !== null) {

        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Paropakariisabeautifulinitiativeby~tobringlessfortunateandmorefortunatepeopletogethertoformajoyfulworld.~Itisthemosttrustedonlinefundraisingplatformbecauseitiscompletelyfreeofcost.~Unlikeothercrowdfundingwebsiteswhereyouhavetopayasignificantamountbeforeraisingmoneyforyourcause~wehaveeliminatedalltheselfishmiddlemenandfeestohelpouttheneedypeople.~Allyouhavetodoisregisteronourplatformwiththehelpofyourmobilenumberoremailandupdateyourprofile.~Ifyouwantyoucanalsoinviteyourfriends,ormakeagroupthroughwhichyoucancontributetoaparticularcause.~OnParopakariwevalueyouremotionsthuswegiveyouafreeplatformtoraisefundsforyourfavouritecauseororganizationwithoutanyhassle.~YoucanmanageyourcauseandinviteyourfriendstobecomeaParopakaritosupportyourcauseandraisemoreandmoremoney~Aboutus&lang=" + MID;
        // let user = JSON.parse(localStorage.getItem("user"));
        // const accessToken = user;
        // 
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            // Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            
            const longArray = data;
            const size = 1;

            // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            //     .map(function() { return this.splice(0, size) }, longArray.slice());

            var items = data;

            var firstItem20 = typeof items[0] !== "undefined" ? items[0] : null;
            var firstItem21 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem22 = typeof items[2] !== "undefined" ? items[2] : null;
            var firstItem23 = typeof items[3] !== "undefined" ? items[3] : null;
            var firstItem24 = typeof items[4] !== "undefined" ? items[4] : null;
            var firstItem25 = typeof items[5] !== "undefined" ? items[5] : null;
            var firstItem26 = typeof items[6] !== "undefined" ? items[6] : null;
            var firstItem27 = typeof items[7] !== "undefined" ? items[7] : null;
            var firstItem28 = typeof items[8] !== "undefined" ? items[8] : null;
            var firstItem29 = typeof items[9] !== "undefined" ? items[9] : null;

            var LangID = MID;
            window.sessionStorage.setItem('translate', (MID));
            
            this.setState({

              Paropakariisabeautiful: firstItem20,
              tobringless: firstItem21,
              Itisthe: firstItem22,
              Unlikeother: firstItem23,
              wehave: firstItem24,
              Allyouhave: firstItem25,
              Ifyouwant: firstItem26,
              OnParopakari: firstItem27,
              langID: LangID,
              Youcanmanage: firstItem28,
              Aboutus: firstItem29

            });


          });

      }
      if (MID != null) {
        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            const longArray = data;
            const size = 1;
            var ary = data;
            // localStorage.setItem('menuid', 'English');
            const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
              .map(function () { return this.splice(0, size) }, longArray.slice());
            var items = data;
            var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
            var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;

            var LangID = MID;
            window.sessionStorage.setItem('translate', (LangID));
            localStorage.setItem('MenuID', JSON.stringify(LangID));
            this.setState({
              copyright_new: copyright_new,
              paropakari_new: paropakari_new,
            });
          });
      }
      if (MID !== null) {

        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Disclaimer~Causes~Beneficiaries~Paropakaris~Amount Raised~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
        // let user = JSON.parse(localStorage.getItem("user"));
        // const accessToken = user;
        // 
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            // Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            
            const longArray = data;
            const size = 1;

            // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            //     .map(function() { return this.splice(0, size) }, longArray.slice());

            var items = data;


            var firstItem30 = typeof items[0] !== "undefined" ? items[0] : null;
            var firstItem31 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem32 = typeof items[2] !== "undefined" ? items[2] : null;
            var firstItem33 = typeof items[3] !== "undefined" ? items[3] : null;
            var firstItem34 = typeof items[4] !== "undefined" ? items[4] : null;
            var firstItem35 = typeof items[5] !== "undefined" ? items[5] : null;
            var firstItem36 = typeof items[6] !== "undefined" ? items[6] : null;
            var firstItem37 = typeof items[3] !== "undefined" ? items[3] : null;
            var LangID = MID;
            window.sessionStorage.setItem('translate', (MID));
            
            this.setState({

              langID: LangID,


              Disclaimer: firstItem30,
              Causes: firstItem31,
              Beneficiaries: firstItem32,
              Paropakaris: firstItem33,
              AmountRaised: firstItem34,
              Copyright: firstItem35,
              // beaparopakari:firstItem37,
              privacypolicy: firstItem36
            });


          });
      }
    }


  }

  handleChanged(event) {
    this.setState({ value: event.target.value });
    localStorage.setItem('myData', event.target.value);
    
    localStorage.getItem('myData');
    window.sessionStorage.setItem('translate', (event.target.value));
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari'];
    

    var someVarName = "value";
    localStorage.setItem("someVarKey", someVarName);
    var someVarName = "value";
    localStorage.setItem("someVarKey", someVarName);
    let MID = event.target.value;
    if (MID !== null) {
      // this.setState({value: event.target.value}); 
      let user2 = sessionStorage.getItem('translate');
      // let reloadlogin=0;
      // window.sessionStorage.setItem('reloadlogin', (reloadlogin));
      // 
      var someVarName = "value";
      localStorage.setItem("someVarKey", someVarName);
      var someVarName = "value";
      localStorage.setItem("someVarKey", someVarName);
      
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~ContactUs~TermsandConditions~As one of its charitable services,~providessupporttopoorchildrenseducationinruralandremoteareas.~Whiletravellingfromonevillagetoanotherandlookingatunderprivilegedfamiliesandtheirproblems,arealizationdawneduponus.&lang=" + MID;
      //  let user = JSON.parse(localStorage.getItem("user"));
      //  const accessToken = user;
      //  
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          //  Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;

          // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
          //     .map(function() { return this.splice(0, size) }, longArray.slice());

          var items = data;
          var firstItem = typeof items[0] !== "undefined" ? items[0] : null;
          var firstItem1 = typeof items[1] !== "undefined" ? items[1] : null;
          var firstItem2 = typeof items[2] !== "undefined" ? items[2] : null;
          var firstItem3 = typeof items[3] !== "undefined" ? items[3] : null;
          var firstItem4 = typeof items[4] !== "undefined" ? items[4] : null;
          var firstItem5 = typeof items[5] !== "undefined" ? items[5] : null;
          var firstItem6 = typeof items[6] !== "undefined" ? items[6] : null;
          var firstItem7 = typeof items[7] !== "undefined" ? items[7] : null;
          var firstItem8 = typeof items[8] !== "undefined" ? items[8] : null;
          var firstItem9 = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (MID));
          
          this.setState({
            OurStory: firstItem,
            HowItWorks: firstItem1,
            Blog: firstItem2,
            Login: firstItem3,
            beaparopakari: firstItem4,
            contactus: firstItem5,
            TermsandConditions: firstItem6,
            Asoneof: firstItem7,
            providessupport: firstItem8,
            Whiletravelling: firstItem9,

            langID: LangID

          });

          //  
          const trans = "Vijayaasri";
          // this.setState({ newArray: newArray });
        });

      if (MID !== null) {

        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Theneedforsupportisfargreaterthanwhatwecandothroughourfoundation.~Adilemmawasuponus.Dowespendallourresourcestoprovidesupportoristherealimittowhatwecando?~Wedidntlikeboththeseoptionsandsetouttospreadthewordtotrytoinspireandencouragepeopletojoinourvisionandhelptheneedy.~Thisledustothepreexistingideaofcrowdfunding.Followinguponthisideawewereleftastoundedwithwhatwefound.~Mostcrowdfundingwebsiteschargeafeetopostortakeacommissionfromthemoneyyouraise.~Wethoughttoourself~Ifwewanttoperformaselflessactofhelpingsomeone~whyshouldwepayforit~Sowedecidedtocreateaplatformconnectingtheneedywiththemorefortunatedirectlywithoutthirdpartyinvolvementorfees.~Weneedyouraffectionandsupportinthedevelopmentofourgenuinehumblealtruisticendeavortoreachasignificantscaleandmakeadifference&lang=" + MID;
        //  let user = JSON.parse(localStorage.getItem("user"));
        //  const accessToken = user;
        //  
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            //  Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            
            const longArray = data;
            const size = 1;

            // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            //     .map(function() { return this.splice(0, size) }, longArray.slice());

            var items = data;

            var firstItem10 = typeof items[0] !== "undefined" ? items[0] : null;
            var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem11 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem12 = typeof items[2] !== "undefined" ? items[2] : null;

            var firstItem13 = typeof items[3] !== "undefined" ? items[3] : null;
            var firstItem14 = typeof items[4] !== "undefined" ? items[4] : null;
            var firstItem15 = typeof items[5] !== "undefined" ? items[5] : null;
            var firstItem16 = typeof items[6] !== "undefined" ? items[6] : null;
            var firstItem17 = typeof items[7] !== "undefined" ? items[7] : null;
            var firstItem18 = typeof items[8] !== "undefined" ? items[8] : null;
            var firstItem19 = typeof items[9] !== "undefined" ? items[9] : null;

            var LangID = MID;
            window.sessionStorage.setItem('translate', (MID));
            
            this.setState({

              Theneedfor: firstItem10,
              Adilemma: firstItem11,
              Wedidnt: firstItem12,
              Thisled: firstItem13,
              Mostcrowd: firstItem14,
              Wethought: firstItem15,
              Ifwewant: firstItem16,
              whyshould: firstItem17,
              Sowedecided: firstItem18,
              Weneedyour: firstItem19,

              langID: LangID

            });

            //  
            const trans = "Vijayaasri";
            // this.setState({ newArray: newArray });
          });

      }

      if (MID !== null) {

        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Paropakariisabeautifulinitiativeby~tobringlessfortunateandmorefortunatepeopletogethertoformajoyfulworld.~Itisthemosttrustedonlinefundraisingplatformbecauseitiscompletelyfreeofcost.~Unlikeothercrowdfundingwebsiteswhereyouhavetopayasignificantamountbeforeraisingmoneyforyourcause~wehaveeliminatedalltheselfishmiddlemenandfeestohelpouttheneedypeople.~Allyouhavetodoisregisteronourplatformwiththehelpofyourmobilenumberoremailandupdateyourprofile.~Ifyouwantyoucanalsoinviteyourfriends,ormakeagroupthroughwhichyoucancontributetoaparticularcause.~OnParopakariwevalueyouremotionsthuswegiveyouafreeplatformtoraisefundsforyourfavouritecauseororganizationwithoutanyhassle.~YoucanmanageyourcauseandinviteyourfriendstobecomeaParopakaritosupportyourcauseandraisemoreandmoremoney~Aboutus&lang=" + MID;
        //  let user = JSON.parse(localStorage.getItem("user"));
        //  const accessToken = user;
        //  
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            //  Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            
            const longArray = data;
            const size = 1;

            // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            //     .map(function() { return this.splice(0, size) }, longArray.slice());

            var items = data;

            var firstItem20 = typeof items[0] !== "undefined" ? items[0] : null;
            var firstItem21 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem22 = typeof items[2] !== "undefined" ? items[2] : null;
            var firstItem23 = typeof items[3] !== "undefined" ? items[3] : null;
            var firstItem24 = typeof items[4] !== "undefined" ? items[4] : null;
            var firstItem25 = typeof items[5] !== "undefined" ? items[5] : null;
            var firstItem26 = typeof items[6] !== "undefined" ? items[6] : null;
            var firstItem27 = typeof items[7] !== "undefined" ? items[7] : null;
            var firstItem28 = typeof items[8] !== "undefined" ? items[8] : null;
            var firstItem29 = typeof items[9] !== "undefined" ? items[9] : null;

            var LangID = MID;
            window.sessionStorage.setItem('translate', (MID));
            
            this.setState({

              Paropakariisabeautiful: firstItem20,
              tobringless: firstItem21,
              Itisthe: firstItem22,
              Unlikeother: firstItem23,
              wehave: firstItem24,
              Allyouhave: firstItem25,
              Ifyouwant: firstItem26,
              OnParopakari: firstItem27,
              langID: LangID,
              Youcanmanage: firstItem28,
              Aboutus: firstItem29

            });

            //  
            const trans = "Vijayaasri";
            // this.setState({ newArray: newArray });
          });

      }
      if (MID !== null) {

        let url =
          "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Disclaimer~Causes~Beneficiaries~Paropakaris~Amount Raised~Copyright © 2022 Paropakari~Privacy Policy&lang=" + MID;
        // let user = JSON.parse(localStorage.getItem("user"));
        // const accessToken = user;
        // 
        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            //  Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
          }
        })
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              Details: data
            });
            
            const longArray = data;
            const size = 1;

            // const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            //     .map(function() { return this.splice(0, size) }, longArray.slice());

            var items = data;


            var firstItem30 = typeof items[0] !== "undefined" ? items[0] : null;
            var firstItem31 = typeof items[1] !== "undefined" ? items[1] : null;
            var firstItem32 = typeof items[2] !== "undefined" ? items[2] : null;
            var firstItem33 = typeof items[3] !== "undefined" ? items[3] : null;
            var firstItem34 = typeof items[4] !== "undefined" ? items[4] : null;
            var firstItem35 = typeof items[5] !== "undefined" ? items[5] : null;
            var firstItem36 = typeof items[6] !== "undefined" ? items[6] : null;
            var firstItem37 = typeof items[3] !== "undefined" ? items[3] : null;
            var LangID = MID;
            window.sessionStorage.setItem('translate', (MID));
            
            this.setState({

              langID: LangID,


              Disclaimer: firstItem30,
              Causes: firstItem31,
              Beneficiaries: firstItem32,
              Paropakaris: firstItem33,
              AmountRaised: firstItem34,
              Copyright: firstItem35,
              // beaparopakari:firstItem37,
              privacypolicy: firstItem36
            });


          });
      }
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      //  let user = JSON.parse(localStorage.getItem("user"));
      //  const accessToken = user;
      //  
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          //  Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
  }
  //  handleClick = () => {
  //     window.open("http://twitter.com/saigowthamr");
  //   };
  componentDidMount() {
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.handleChanges);
    this.handleChanges();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
  }
  componentWillUnmount() {

    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.handleChanges);
    this.handleChanges();
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  render() {
    const currentYear = new Date().getFullYear();
    const { OurStory, HowItWorks, Blog, Login, Paropakari, contactus, TermsandConditions,
      Asoneof, providessupport, Whiletravelling, Theneedfor, Adilemma, Wedidnt, Thisled, Mostcrowd, Wethought, Ifwewant, whyshould,
      Sowedecided, copyright_new,
      paropakari_new,
      Weneedyour, passwordd, ForgetPassword, termsandcondition, signup, value, Paropakariisabeautiful,
      tobringless,
      Itisthe,
      Unlikeother,
      wehave,
      Allyouhave,
      Ifyouwant, OnParopakari,
      Youcanmanage, Aboutus, Disclaimer, Causes, privacypolicy,
      Beneficiaries,
      Paropakaris, langID,
      AmountRaised, Copyright, beaparopakari } = this.state;
    let { foo } = this.state;
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />


          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanged}>
            <option value="English">Languages</option>

            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             <option value="Spanish">Spanish</option>
            <option value="English">English</option>



          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;


          <div>


            <div className="mag notranslate">
              <div class="pro-nav">
                <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                <div class="pro-nav-header">
                  <div class="pro-nav-title notranslate">
                    <a className="navbar-brand" href="#/Index1">
                      <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-nav-btn">
                  <label for="pro-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-design " href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx">
                      <a href="#/Ourstory" className="parop-menu text-parop-menu">{OurStory.result}</a>
                      <a href="#/Howitworks" className="parop-menu">{HowItWorks.result}</a>
                      <a href="#/Blog" className="parop-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : langID == "Hindi" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-hindi-menu text-parop-menu">{OurStory.result}</a>
                      <a href="#/Howitworks" className="parop-hindi-menu">{HowItWorks.result}</a>
                      <a href="#/Blog" className="parop-hindi-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-hindi-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : langID == "Kannada" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-kannada-menu text-parop-menu">{OurStory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{HowItWorks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{Login.result}</a>
                    </div>
                  </div>
                  </> : langID == "Spanish" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-kannada-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-kannada-menu text-parop-menu">{OurStory.result}</a>
                      <a href="#/Howitworks" className="parop-kannada-menu">{HowItWorks.result}</a>
                      <a href="#/Blog" className="parop-kannada-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-kannada-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : langID == "Tamil" ? <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-tamil-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-tamil-menu text-parop-menu">{OurStory.result}</a>
                      <a href="#/Howitworks" className="parop-tamil-menu">{HowItWorks.result}</a>
                      <a href="#/Blog" className="parop-tamil-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-tamil-menu">{Login.result}</a>
                    </div>
                  </div>
                </> : <>
                  <div class="pro-nav-links pro-side">
                    <p class='pro-center'>
                      <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                    </p>
                    <div className="parop-boxx parop-boxx-top">
                      <a href="#/Ourstory" className="parop-telugu-menu text-parop-menu">{OurStory.result}</a>
                      <a href="#/Howitworks" className="parop-telugu-menu">{HowItWorks.result}</a>
                      <a href="#/Blog" className="parop-telugu-menu">{Blog.result}</a>
                      <a href="#/Login" className="parop-telugu-menu">{Login.result}</a>
                    </div>
                  </div>
                </>}
              </div>
            </div>


            {/* <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <nav class="navbar navbar-expand-xxl bg-light">
            <div className="navbar  navbar-inverse" role="banner">
              <div className="container" id="containerid" >
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigassstion</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>

                  <a className="navbar-brand" href="#/Index1">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>

                </div>


                

                <div className="collapse navbar-collapse">

                <ul class="nav navbar-nav navbar-right">

<li id="paro" className="active">

{(() => {if (langID == 'Tamil' ) {return (
<li id="paro" className="active tamilbtn"><a className="btn1 third" id="tamilbtn1"  href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'English' ) {return (
<li id="paro" className="active englishbtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontsize:'2vw', fontWeight: 'bold',padding:'13px',marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'Hindi' ) {return (
<li id="paro" className="active hindibtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>

{beaparopakari.result}
</a></li>
);
}
else {return(

<li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>

);}

})()}

</li>

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{OurStory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{OurStory.result}</a>

);}

})()} 


</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{HowItWorks.result}

</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{HowItWorks.result}

</a>

);}

})()} 
</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{Blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{Blog.result}</a>

);}

})()} 


</li>         

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{Login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{Login.result}</a>

);}

})()} 



</li>                    
</ul>

                </div>

              </div>
            </div>
            </nav>
          </header> */}


            <section
              className={this.state.movedown == true ? null : "home-Tiptop"}

              id="services"


              style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>

              <div class="container" id="containerid">


                <div className="col-sm-12 text-center">

                  <h2 className="margin-bottom" ><b>{OurStory.result ? OurStory.result : 'Our Story'}</b></h2>
                  <p>
                    {Asoneof.result ? Asoneof.result : 'As one of it`s charitable services,'}   <a href="https://www.kdpfoundation.org/" target="_blank" rel="noreferrer noopener">
                      KDP Foundation
                    </a> {providessupport.result ? providessupport.result : 'provides support to poor children’s education in rural and remote areas.'} {Whiletravelling.result ? Whiletravelling.result : 'While travelling from one village to another and looking at underprivileged families and their problems, a realization dawned upon us.'}     <br />
                    <br />   {Theneedfor.result ? Theneedfor.result : 'The need for support is far greater than what we can do through our foundation.'}    {Adilemma.result ? Adilemma.result : 'A dilemma was upon us. Do we spend all our resources to provide support or is there a limit to what we can do?'}  {Wedidnt.result ? Wedidnt.result : ' We didnt like both these options and set out to spread the word to try to inspire and encourage people to join our vision and help the needy.'} {Thisled.result ? Thisled.result : 'This led us to the preexisting idea of crowd funding. Following up on this idea we were left astounded with what we found.'}{Mostcrowd.result ? Mostcrowd.result : 'Most crowd funding websites charge a fee to post or take a commission from the money you raise.'} {Wethought.result ? Wethought.result : 'We thought to ourself'}, "{Ifwewant.result ? Ifwewant.result : 'If we want to perform a selfless act of helping someone'}, {whyshould.result ? whyshould.result : 'why should we pay for it'}?"{Sowedecided.result ? Sowedecided.result : ' So we decided to create a platform connecting the needy with the more fortunate directly without third party involvement or fees.'}
                  </p><p>
                    <br />
                    {Weneedyour.result ? Weneedyour.result : 'We need your affection and support in the development of our genuine humble altruistic endeavor to reach a significant scale and make a difference'}
                  </p><br />
                </div>

                <div className="col-sm-12 text-center">

                  <h2 className="margin-bottom"><b>{Aboutus.result ? Aboutus.result : 'About Us'}</b></h2>
                  <p>
                    <br />


                    {Paropakariisabeautiful.result ? Paropakariisabeautiful.result : 'Paropakari is a beautiful initiative by'}  <a href="https://www.kdpfoundation.org/" target="_blank"  >
                      KDP Foundation
                    </a>            {tobringless.result ? tobringless.result : 'to bring less fortunate and more fortunate people together to form a joyful world.'} {Itisthe.result ? Itisthe.result : 'It is the most trusted online fundraising platform because it is completely free of cost.'}  {Unlikeother.result ? Unlikeother.result : 'Unlike other crowdfunding websites where you have to pay a significant amount before raising money for your cause'} - {wehave.result ? wehave.result : 'we have eliminated all the selfish middlemen and fees to help out the needy people.'}
                    {Allyouhave.result ? Allyouhave.result : 'All you have to do is register on our platform with the help of your mobile number or email and update your profile.'} {Ifyouwant.result ? Ifyouwant.result : 'If you want you can also invite your friends, or make a group through which you can contribute to a particular cause.'}
                    <a href={"#/Login"} target="_blank" >

                      Join Now
                    </a>

                  </p>
                </div>

                <div className="col-sm-12 text-center">

                  <h2 className="margin-bottom"><b>{Disclaimer.result ? Disclaimer.result : 'Disclaimer'}</b></h2>
                  <p>
                    <br />

                    {OnParopakari.result ? OnParopakari.result : 'On Paropakari, we value your emotions, thus we give you a free platform to raise funds for your favourite cause or organization without any hassle.'} {Youcanmanage.result ? Youcanmanage.result : 'You can manage your cause and invite your friends to become a Paropakari to support your cause and raise more and more money'}
                  </p>

                  <br />
                  <br />
                </div>

              </div>

            </section>

            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                      <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{TermsandConditions.result}</a></p>


                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>

            {/* <script type="text/javascript" src="js/jquery.js"></script> */}

            {/* <script type='text/javascript' src='./assets/js/jquery.js'/>
    <script type="text/javascript" src='./assets/js/bootstrap.min.js'></script>
    <script type="text/javascript" src="./assets/js/lightbox.min.js"></script>
    <script type="text/javascript" src='./assets/js/wow.min.js'></script>
    <script type="text/javascript" src='./assets/js/main.js'></script>    */}

          </div>
        </div>
      </div>

    )
  }
}
