import React, { useEffect } from "react";

const PostComponent = ({ emp, enddate }) => {
  useEffect(() => {
   
   }, []);

  return (
    <div>
      <div className="row">
        <div className="col-lg-4 col-sm-4">
          {/* <p> */}
          <b>{emp.profile_name}'s Initiative</b>
          {/* </p> */}
        </div>
        <div className="col-lg-3 col-sm-3" id="postedon">
          <p style={{ float: "right" }}>Posted on &nbsp;{emp.created_dates}</p>
        </div>
        <div className="col-lg-5 col-sm-5"></div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-sm-4">
          <p>
            Required for {emp.relationship}
            {emp.relationship == "Self" ? (
              <span>({emp.profile_name})</span>
            ) : (
              <span>({emp.required_for})</span>
            )}
          </p>
        </div>
        <div className="col-lg-3 col-sm-3" id="enddate">
          <p style={{ float: "right" }}>
            {enddate.result} &nbsp;
            {emp.required_by}
          </p>
        </div>
        <div className="col-lg-5 col-sm-5">
          <p></p>
        </div>
      </div>
    </div>
  );
};

export default PostComponent;
