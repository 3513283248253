import React, { Component } from 'react';
// import '../assets/css/bootstrap.min.css';
import ScriptTag from 'react-script-tag';
import Script from 'react-load-script'
import Cookies from 'js-cookie';
import { BASE_URLs } from '../Constants/BaseURL';
export default class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {

      // id : this.props.match.params.id, 
      foo: [],
      Menu: [],
      Details: [],
      newArray: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      whatwedodesc: [],
      crowdfunding: [],
      humanelement: [],
      nofeecommision: [],
      nomiddlemen: [],
      encourageaultruism: [], comingsoon: [],
      privacypolicy: [],
      langID: [],
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
    };
    this.initialState = this.state;
    // this.reSubmitotp= this.reSubmitotp;  
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  Footer() {

    


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  DefaultMenu() {
    //  
    let reloads = 0;
    window.sessionStorage.setItem('reloadlogin', (reloads));
    window.sessionStorage.setItem('reload', (reloads));
    window.sessionStorage.setItem('reloadcreate', (reloads));
    let langval = "English";
    
    localStorage.setItem('menuid', langval);
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            
          });


          // 
        });
    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are Indias only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
          var Termsandcondition = typeof items[9] !== "undefined" ? items[9] : null;
          var CopyRight = typeof items[10] !== "undefined" ? items[10] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[7] !== "undefined" ? items[7] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            whatwedodesc: Whatwedodesc,
            langID: LangID,


          });

        });

    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Coming Soon~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
          var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
          var NoFeeCommision = typeof items[7] !== "undefined" ? items[7] : null;
          var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
          var CominSoon = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var EncourageAltruism = typeof items[4] !== "undefined" ? items[4] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            crowdfunding: Crowdfunding,
            humanelement: HumanElement,
            nofeecommision: NoFeeCommision,
            nomiddlemen: NoMiddlemen,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            comingsoon: CominSoon,

          });


        });

    }
  }



  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    localStorage.setItem('menuid', event.target.value);
    // let l_id = JSON.parse(localStorage.getItem('menuid'));
    
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    if (MID != null) {

      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~We are Indias only expenseless direct crowdfunding and support platform that connects the needy with the more fortunate directly without third party involvement or fees~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[8] !== "undefined" ? items[8] : null;
          var Termsandcondition = typeof items[9] !== "undefined" ? items[9] : null;
          var CopyRight = typeof items[10] !== "undefined" ? items[10] : null;

          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile = typeof items[7] !== "undefined" ? items[7] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var Whatwedodesc = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            whatwedodesc: Whatwedodesc,
            langID: LangID,


          });

          // alert(firstItem);
          const trans = "Vijayaasri";
          let l_name = this.state.newArray[0];
          let l_names = l_name;

          
          
          const n = 2;
          const firstNElements = longArray.slice(0, n).map(({ result }) => {
            return (<p >{result}</p>)
          });
          // 
        });

    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~EncourageAltruism~Crowd funding with:~A Human Element~No Fees/Commission~No Middlemen~Coming Soon~Privacy Policy&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Causes = typeof items[0] !== "undefined" ? items[0] : null;
          var Beneficiaries = typeof items[1] !== "undefined" ? items[1] : null;
          var Paropakaris = typeof items[2] !== "undefined" ? items[2] : null;
          var Amountraised = typeof items[3] !== "undefined" ? items[3] : null;

          var Crowdfunding = typeof items[5] !== "undefined" ? items[5] : null;
          var HumanElement = typeof items[6] !== "undefined" ? items[6] : null;
          var NoFeeCommision = typeof items[7] !== "undefined" ? items[7] : null;
          var NoMiddlemen = typeof items[8] !== "undefined" ? items[8] : null;
          var CominSoon = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy = typeof items[10] !== "undefined" ? items[10] : null;
          var EncourageAltruism = typeof items[4] !== "undefined" ? items[4] : null;


          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          this.setState({

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            crowdfunding: Crowdfunding,
            humanelement: HumanElement,
            nofeecommision: NoFeeCommision,
            nomiddlemen: NoMiddlemen,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
            comingsoon: CominSoon,

          });

        });

    }
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,


          });


        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new = typeof items[1] !== "undefined" ? items[1] : null;
        
          var LangID = MID;
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,            
          });
        });
    }

  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size)).fill("")
      .map(function () { return this.splice(0, size) }, longArray.slice());

    
    return (
      <div>{newArray}</div>
    )
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  componentDidMount() {


    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "India's only expenseless, direct crowdfunding platform"
  }


  componentWillUnmount() {


    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
  }

  render() {
    let { foo, Menu } = this.state;
    const { newArray, howitworks, beaparopakari, whatwedo, blog, login, privacypolicy, indiaexpensless,
       ourstory, oppertunitybringsmile, contactus, termsandcondition, causes, beneficiaries, paropakaris, 
       amountraised, copyright, whatwedodesc, encourageaultruism, crowdfunding, humanelement, nofeecommision, 
       nomiddlemen, langID, weareindias, comingsoon, copyright_new, paropakari_new, } = this.state;
       const currentYear = new Date().getFullYear();
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />


          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>

            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             <option value="Spanish">Spanish</option>
            <option value="English">English</option>



          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;


          <div>
            <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
              <div className="mag notranslate">
                <div class="pro-nav">
                  <input type="checkbox" id="pro-nav-check" onClick={this.checkfun} />
                  <div class="pro-nav-header">
                    <div class="pro-nav-title notranslate">
                      <a className="navbar-brand" href="#/Index1" >
                        <h1 className="navbar-new-text"><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '300px' }} />AROPAKARI
                        </h1>
                      </a>
                    </div>
                  </div>

                  <div class="pro-nav-btn">
                    <label for="pro-nav-check">
                      <div className="pro-naav">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </label>
                  </div>

                  {langID == "English" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-menu text-parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Hindi" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-hindi-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-hindi-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-hindi-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-hindi-menu text-parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-hindi-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Kannada" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-kannada-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-kannada-menu text-parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                      </div>
                    </div>
                    </> : langID == "Spanish" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-kannada-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-kannada-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-kannada-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-kannada-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-kannada-menu text-parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-kannada-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : langID == "Tamil" ? <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-tamil-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-tamil-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-tamil-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-tamil-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-tamil-menu text-parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-tamil-menu">{login.result}</a>
                      </div>
                    </div>
                  </> : <>
                    <div class="pro-nav-links pro-side">
                      <p class='pro-center'>
                        <a className="new-telugu-design" href="#/Login">{beaparopakari.result}</a>
                      </p>
                      <div className="parop-boxx parop-boxx-top">
                        <a href="#/Ourstory" className="parop-telugu-menu">{ourstory.result}</a>
                        <a href="#/Howitworks" className="parop-telugu-menu">{howitworks.result}</a>
                        <a href="#/Blog" className="parop-telugu-menu text-parop-menu">{blog.result}</a>
                        <a href="#/Login" className="parop-telugu-menu">{login.result}</a>
                      </div>
                    </div>
                  </>}
                </div>
              </div>

            </header>

            {/* <header id="header" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <nav class="navbar navbar-expand-xxl bg-light">
            <div className="navbar  navbar-inverse" role="banner">
              <div className="container" id="containerid" >
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigassstion</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>

                  <a className="navbar-brand" href="#/Index1">
                    <h1 style={{ color: 'blue', fontFamily: 'Biysk-Regular' }}><img src="./assets/images/plogo.png" alt="logo" style={{ marginBottom: '17px', width: '26%', fontsize: '52px' }} />AROPAKARI

                    </h1>
                  </a>

                </div>


                

                <div className="collapse navbar-collapse">

                <ul class="nav navbar-nav navbar-right">

<li id="paro" className="active">

{(() => {if (langID == 'Tamil' ) {return (
<li id="paro" className="active tamilbtn"><a className="btn1 third" id="tamilbtn1"  href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'English' ) {return (
<li id="paro" className="active englishbtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontsize:'2vw', fontWeight: 'bold',padding:'13px',marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>
);
}
else if (langID == 'Hindi' ) {return (
<li id="paro" className="active hindibtn"><a className="btn1 third" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra',marginTop:'2px',marginBottom:'-7px'}}>

{beaparopakari.result}
</a></li>
);
}
else {return(

<li id="paro" className="active olangbtn"><a className="btn1 third" id="homepagebutton" href="#/Login" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>

{beaparopakari.result}
</a></li>

);}

})()}

</li>

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{ourstory.result}</a>

);
}
else {return(

<a href="#/Ourstory" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{ourstory.result}</a>

);}

})()} 


</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontsize:10,fontWeight: 'bold'}}>{howitworks.result}

</a>

);
}
else {return(

<a href="#/Howitworks" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{howitworks.result}

</a>

);}

})()} 
</li> 
<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{blog.result}</a>

);
}
else {return(

<a href="#/Blog" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{blog.result}</a>

);}

})()} 


</li>         

<li className="data-btn-hide">
{(() => {if (langID == 'Tamil' ) {return (
<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold',fontsize:10}}>{login.result}</a>

);
}
else {return(

<a href="#/Login" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{login.result}</a>

);}

})()} 



</li>                    
</ul>

                </div>

              </div>
            </div>
            </nav>
          </header> */}
            {/* 
<section id="home-slider">
    <div class="container">
            <div class="row">
                <div class="main-slider">
                    <div class="slide-text">
                        <h1>We Are Creative Nerds</h1>
                        <p>Boudin doner frankfurter pig. Cow shank bresaola pork loin tri-tip tongue venison pork belly meatloaf short loin landjaeger biltong beef ribs shankle chicken andouille.</p>
                        <a href="#" class="btn btn-common">SIGN UP</a>
                    </div>
                    <img src="images/home/slider/hill.png" class="slider-hill" alt="slider image"/>
                    <img src="images/home/slider/house.png" class="slider-house" alt="slider image"/>
                    <img src="images/home/slider/sun.png" class="slider-sun" alt="slider image"/>
                    <img src="images/home/slider/birds1.png" class="slider-birds1" alt="slider image"/>
                    <img src="images/home/slider/birds2.png" class="slider-birds2" alt="slider image"/>
                </div>
            </div>
        </div>
        <div class="preloader"><i class="fa fa-sun-o fa-spin"></i></div>
    </section> */}
            <section className={this.state.movedown == true ? null : "home-Tiptop"} id="services" style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>
              <div class="container" id="containerid">
                <div className="row">
                  <div className="col-sm-12 text-center">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <h1 className="margin-bottom">{comingsoon.result}</h1>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
            </section>
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p >{copyright_new.result} &nbsp;{currentYear} © {paropakari_new.result} |&nbsp;<a href="#/Contactus" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicy" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandCondition" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>

              </div>
              <div className="containerss">

              </div>
            </footer>

            {/* <script type="text/javascript" src="js/jquery.js"></script> */}

            {/* <script type='text/javascript' src='./assets/js/jquery.js'/>
    <script type="text/javascript" src='./assets/js/bootstrap.min.js'></script>
    <script type="text/javascript" src="./assets/js/lightbox.min.js"></script>
    <script type="text/javascript" src='./assets/js/wow.min.js'></script>
    <script type="text/javascript" src='./assets/js/main.js'></script>    */}
          </div>

        </div>
      </div>

    )
  }
}
