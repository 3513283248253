import logo from './logo.svg';
import './App.css';
import Index1 from './Component/Index1';
import HomePage from './Component/HomePage';
import Login from './Component/Login';
import Register from './Component/Register';
import Ourstory from './Component/Ourstory';
import Howitworks from './Component/Howitworks';
import ResponsePage from './Component/ResponsePage';
import Notification from './Component/Notification';
import MyActivity from './Component/MyActivity';
import InviteFriend from './Component/InviteFriend';
import Profile from './Component/Profile';
import Create from './Component/Create';
import ChangePassword from './Component/ChangePassword';
import Group from './Component/Group';
import Creategroup from './Component/Creategroup';
import GroupsDetail from './Component/GroupsDetail';
import GroupSeven from './Component/GroupSeven';
import Updategroup from './Component/Updategroup';
// Remove unwanted imports
import Contactus from './Component/Contactus';
import Contactuslogin from './Component/Contactuslogin';
import TermsandCondition from './Component/TermsandCondition';
import TermsandConditionLogin from './Component/TermsandConditionLogin';
import Confirm from './Component/Confirm';
import SliderTest from './Component/SliderTest';
import Editpage from './Component/Editpage';
import TagEdit from './Component/TagEdit';
import EditTag from './Component/EditTag';
import Forgot from './Component/Forgot';
import Likepopup from './Component/Likepopup';
import Popup from './Component/Popup';
import CauseFor from './Component/CauseFor';
import OTP from './Component/OTP';
import Blog from './Component/Blog';
import ResetOTP from './Component/ResetOTP';
import Reset from './Component/Reset';
import Friends from './Component/Friends';
import LoginOTP from './Component/LoginOTP';
import Payment from './Component/Payment';
import SamplePayment from './Component/SamplePayment';
import PrivacyPolicy from './Component/PrivacyPolicy';
import PrivacyPolicyLogin from './Component/PrivacyPolicyLogin';
import Claim from './Component/Claim';
import AdmLognParopakari from './Component/AdmLognParopakari';
import PayoutList from './Component/PayoutList';
import Donateasmile from './Component/Donateasmile';
import DonateSmileWithout80G from './Component/DonateSmileWithout80G';
import PaymentSuccess from './Component/PaymentSuccess';
import ClaimSuccess from './Component/ClaimSuccess';
import PayoutDetail from './Component/PayoutDetail';
import ProfilePageOTP from './Component/ProfilePageOTP';
import LogoutLink from './Component/LogoutLink';
import { BrowserRouter as Router, Route,Switch ,HashRouter  } from "react-router-dom";
const reload = () => window.location.reload();
function App() {
  return (
    
  <HashRouter>
     <Switch><Route exact path='/' component ={Index1}/>
    <Route path="/Index1" component={Index1} onEnter={reload}/>    
    <Route path="/HomePage" component={HomePage}/>       
   <Route path="/Login" component={Login}/>  
    <Route path="/Register" component={Register}/>    
   
    <Route path="/Ourstory" component={Ourstory}/> 
    <Route path="/Howitworks" component={Howitworks}/>
    <Route path="/ResponsePage/:id" component={ResponsePage}/>
    <Route path="/Notification" component={Notification}/>
    <Route path="/MyActivity" component={MyActivity}/>
    <Route path="/InviteFriend" component={InviteFriend}/>
    <Route path="/Profile" component={Profile}/>
    <Route path="/Create" component={Create}/>
    <Route path="/ChangePassword" component={ChangePassword}/>
    <Route path="/Group" component={Group}/>
    <Route path="/Creategroup" component={Creategroup}/>
    <Route path="/GroupsDetail/:id" component={GroupsDetail}/>
    <Route path="/GroupSeven" component={GroupSeven}/>
    <Route path="/Updategroup/:id" component={Updategroup}/>
    <Route path="/Contactus" component={Contactus}/>
    <Route path="/Contactuslogin" component={Contactuslogin}/>
    <Route path="/TermsandCondition" component={TermsandCondition}/>
    <Route path="/TermsandConditionLogin" component={TermsandConditionLogin}/>
    <Route path="/Confirm/:id" component={Confirm}/>
    <Route path="/Editpage/:id" component={Editpage}/>
    <Route path="/EditTag/:id" component={EditTag}/>
    <Route path="/TagEdit/:id" component={TagEdit}/>
    <Route path="/Forgot" component={Forgot}/>
    <Route path="/OTP" component={OTP}/>
    <Route path="/Likepopup" component={Likepopup}/>
    <Route path="/Popup" component={Popup}/>
    <Route path="/SliderTest" component={SliderTest}/>
    <Route path="/Blog" component={Blog}/>
    <Route path="/ResetOTP" component={ResetOTP}/>
    <Route path="/Reset" component={Reset}/>
    <Route path="/Friends" component={Friends}/>
    <Route path="/Payment" component={Payment}/>
    <Route path="/CauseFor" component={CauseFor}/>
    <Route path="/LoginOTP" component={LoginOTP}/>
    <Route path="/SamplePayment" component={SamplePayment}/>
    <Route path="/PrivacyPolicy" component={PrivacyPolicy}/>
    <Route path="/PrivacyPolicyLogin" component={PrivacyPolicyLogin}/>
    <Route path="/Claim" component={Claim}/>
    <Route path="/AdmLognParopakari" component={AdmLognParopakari}/>
    <Route path="/PayoutList" component={PayoutList}/>
    <Route path="/Donateasmile" component={Donateasmile}/>
    <Route path="/DonateSmileWithout80G" component={DonateSmileWithout80G}/>
    <Route path="/PayoutDetail/:id" component={PayoutDetail}/>
    <Route path="/PaymentSuccess" component={PaymentSuccess}/>
    <Route path="/ClaimSuccess" component={ClaimSuccess}/>
    <Route path="/ProfilePageOTP" component={ProfilePageOTP}/>
    <Route path="/LogoutLink" component={LogoutLink}/>
    
    </Switch>
    </HashRouter>
  );
}

export default App;
