import React, { Component } from "react";
import ImagePreview from "./ImagePreview";
// import "../assets/css/Duplicate.css";
import $ from "jquery";
import Progress from "./Progress";
import { ShareSocial } from "react-share-social";
import Popup from "./Popup";
import Likepopup from "./Likepopup";
import Comment from "./Comment";
import Cookies from "js-cookie";
import TaggedFriends from "./TaggedFriends";
import twitterIcon from "../assets/images/home/slider/TwitterIcon.png";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from '../Constants/BaseURL';
import { DocumentViewer } from "react-documents";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  WhatsappIcon,
  XIcon,
  LinkedinIcon,
  EmailIcon,
  FacebookIcon,
} from "react-share";
import Script from "react-load-script";
import { MDBTypo } from "mdbreact";

$(document).ready(function () {
  $(".theme-loader1").fadeOut(1000);
});
// const refreshPage = ()=>{

//   setTimeout(() => window.location.reload(), 2000);

// }

// const refreshPage = (e) => {
//   setTimeout(() => window.location.reload(true), 2000);
//   e.preventDefault();
//   var banner = document.getElementById("#google_translate_element");
//   if (banner) {
//     banner.style.display = "none";
//   }
// };

export default class Confirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      user_id: "",
      profile_imagePath: "",
      profile_name: "",
      upload_id: "",
      created_date: "",
      upload_description: "",
      upload_amount: "",
      upload_place: "",
      upload_title: "",
      status_80G: "",
      upload_num_of_beneficiaries: "",
      category_id: "",
      category_name: "",
      delete_status: false,
      file_name: "",
      file_type: "",
      likes: 0,
      filecount: 0,
      uid: "",
      comments: 3,
      confirmed: 0,
      pending: 0,
      response_pledge: "",
      response_message: "",
      emps: [],
      resp: [],
      youwanttoclaim: [],
      comm: [],
      clm: [],
      like: [],
      notify: [],
      paropakaris: [],
      Supportconfirm: [],
      Tagged: [],
      Liked: [],
      privacypolicy: [],
      foo: [],
      clm: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      search: [],
      categories: [],
      foodandnutrition: [],
      health: [],
      professional: [],
      sports: [],
      socialwefare: [],
      others: [],
      sort: [],
      mostrecent: [],
      mostpopular: [],
      available80g: [],
      amountdesc: [],
      amountasc: [],
      complete: [],
      incomplete: [],
      education: [],
      enddate: [],
      causes: [],
      share: [],
      Submit: [],
      CommentHere: [],
      confirmationposted: [],
      youwantoclain: [],
      yourcausewillend: [],
      beneficiaries: [],
      friends: [],
      paropakariss: [],
      amountraised: [],
      copyright: [],
      contactus: [],
      termsandcondition: [],
      howitworks: [],
      blog: [],
      tag: [],
      likess: [],
      comment: [],
      donateasmile: [],
      year: [],
      activity: [],
      mycauses: [],
      likedcauses: [],
      commentedcauses: [],
      pledgedcauses: [],
      langID: [],
      HappinessMeter: [],
      ihaveconfirm: [],
      Achieved: [],
      OnItsWay: [],
      Names: [],
      MobileNumbers: [],
      Email: [],
      Pledge: [],
      Message: [],
      yorcausellbclosed: [],
      canceltransaction: [],
      Responsedate: [],
      confirmed: [],
      Action: [],
      ihavercvd: [],
      ihaventrcvd: [],
      googtrans: Cookies.get("googtrans", { domain: ".paropakari.com" }),
      movedown: true,
      copyright_new: [],
      paropakari_new: [],
      //cookie
      language: "",
      previousCookieLang: "",
    };

    this.handleClick = this.handleClick.bind(this);
    this.initialState = this.state;
    this.handleYes = this.handleYes.bind(this);
    this.handleNo = this.handleNo.bind(this);
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);

    // this.paymentDetails= this.paymentDetails.bind(this);
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");

    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
    // name = oldName == null ? "English" : name;

   

    // VIpgJd-ZVi9od-vH1Gmf-ibnC6b

 
    // if (banner) {
    //   banner.style.display = "none";
    // }
  };
  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: "./Login",
    };
    this.props.history.push(page);
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  submit = () => {
    try {
      const urll = new URL(window.location.href);
      const Id = urll.searchParams.get("upload_id");

      let url =
        "https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" +
        this.props.match.params.id;
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          let final = data.map((e) => {
            if (e) {
              let img = null;
              if (e.imageData !== null) {
                img = e.imageData.split(",").map((img) => {
                  return {
                    file_name: img.split("$$$")[0],
                    file_type: img.split("$$$")[1],
                  };
                });
              }
              return {
                ...e,
                data: e.imageData === null ? [] : img,
              };
            }
          });
           this.setState({
            emps: [],
          });
          this.setState({
            emps: final,
          });

          //
        });
    } catch (error) {
      console.error(error);
    }
  };

  ClaimAmountDetail = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ClaimAmountDetail?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          clm: data,
        });

        var ClaimAmount = document.getElementById("claimamount").value;

        window.sessionStorage.setItem("ClaimAmount", ClaimAmount);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  ClaimAmount = () => {
    const { youwanttoclaim } = this.state;

    var x = window.confirm(
      youwanttoclaim.result
        ? youwanttoclaim.result
        : "You want to claim donations to your cause? Are you sure?"
    );

    if (x) {
      const { yorcausellbclosed } = this.state;
      var x = window.confirm(
        yorcausellbclosed.result
          ? yorcausellbclosed.result
          : "Your cause will be closed once you claim the money. Do you want to proceed ?"
      );
      if (x) {
        window.location.href = "#/Claim";
      }
      // 
    } else {
      return false;
    }
  };

  Footer = () => {
    let url =
      "https://apitest.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  };

  resp = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ResponseConfirmDetails?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          resp: data,
        });

        //
      });
  };
  AddLiking = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    const url = "https://apitest.paropakari.com/api/GetProfile/Add_LikeDetails";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };
    try {
      const response = await fetch(url, options);
      const result = await response.json();

      if (response.ok) {
        // Assuming the response has a 'success' field
        const urll = new URL(window.location.href);
        const Id = urll.searchParams.get("upload_id");

        let url =
          "https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=" +
          this.props.match.params.id;
        let data = this.state;
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        fetch(url, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
            "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
          },
          //body:JSON.stringify(data)
        })
          .then((response) => response.json())
          .then((data) => {
            let final = data.map((e) => {
              if (e) {
                let img = null;
                if (e.imageData !== null) {
                  img = e.imageData.split(",").map((img) => {
                    return {
                      file_name: img.split("$$$")[0],
                      file_type: img.split("$$$")[1],
                    };
                  });
                }
                return {
                  ...e,
                  data: e.imageData === null ? [] : img,
                };
              }
            });
            this.setState({
              emps: final,
            });
          });
      } else {
        console.error("Failed to update like count");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle errors if necessary
    }
  };

  AddConfirm = async (event) => {
    event.preventDefault();

    // const { response_id } = this.state;
    //
    //
    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = user;
    const url = "https://apitest.paropakari.com/api/Create/ConfirmResponse?id=";
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    };
    this.setState(this.initialState);
    const response = await fetch(url, options);
    const result = await response.json();
    if (response.ok) {
      // alert(' success')

      
    }
    this.setState(this.initialState);
  };

  // paymentDetails= async event => {

  //   // alert("called");
  //   let upload_id = this.props.match.params.id;
  //   let url = "https://apitest.paropakari.com/api/GetProfile/Get_Payoutstatus?id="+upload_id;
  //   let data = this.state;
  //   let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //
  //
  //   fetch(url, {
  //           method: 'GET',
  //           headers: {
  //               "Content-type": "application/json",
  //               "Accept": "application/json",
  //               Authorization: "Bearer " + accessToken,
  //               "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
  //           },
  //           //body:JSON.stringify(data)
  //       }).then(response => response.json())
  //       .then(data => {
  //           this.setState({
  //               payout: data
  //           });
  //
  //           localStorage.setItem('updated_payout_id', JSON.stringify(data[0].payout_id));
  //          //
  //       });

  //       if(localStorage.getItem('updated_payout_id') !== ''){
  //       // alert("called2");
  //         // const data = new FormData(event.target);
  //         //
  //          let updated_payout_id = JSON.parse(localStorage.getItem('updated_payout_id'));
  //         // alert("fdsdfsd",updated_payout_id);
  //           let user = JSON.parse(localStorage.getItem('user'));
  //           const accessToken=user;
  //           const url ="https://apitest.paropakari.com/api/GetProfile/Payoutstatus?payout_id=" +updated_payout_id ;
  //           const options = {
  //            method: "POST",
  //            headers:{
  //                   Accept: 'application/json',
  //                   Authorization: "Bearer " +accessToken
  //               },
  //               body: updated_payout_id,
  //               };

  //             const response = await fetch(url, options);
  //             const result = await response.json();
  //
  //
  //             localStorage.setItem('updated_payout_status', JSON.stringify(result.status));

  //         if (response.ok) {
  //         }
  //        if(localStorage.getItem('updated_payout_status') !== null){
  //       // alert("called3");
  //       let upload_id = this.props.match.params.id;

  //
  //      // let upload_id = JSON.parse(localStorage.getItem('upload_id'));
  //          let updated_payout_status = JSON.parse(localStorage.getItem('updated_payout_status'));

  //           let user = JSON.parse(localStorage.getItem('user'));
  //           const accessToken=user;
  //           const url ="https://apitest.paropakari.com/api/GetProfile/UpdatePayoutdetail?uploadid=" +upload_id+ '&Payout_status=' +updated_payout_status ;
  //           const options = {
  //            method: "POST",
  //            headers:{
  //                   Accept: 'application/json',
  //                   Authorization: "Bearer " +accessToken
  //               },
  //               body: updated_payout_id,
  //               };

  //             const response = await fetch(url, options);
  //             const result = await response.json();
  //

  //         if (response.ok) {
  //       // alert("Successfully completed!");

  //       // this.props.history.push( {pathname: "/Claim",

  //       // state: { data:{

  //       //   upload_id:this.props.match.params.id

  //       //       } }});
  //            }
  //            }
  //           //  }
  //           }
  // }

  Createcontact = async (event) => {
    event.preventDefault();

    // const { response_id } = this.state;
    //
    //
    const data = new FormData(event.target);
    let user = JSON.parse(localStorage.getItem("user"));
    var x = window.confirm(this.state.youwantoclain.result);
    if (x) {
      var x = window.confirm(this.state.yourcausewillend.result);
      if (x) {
        const accessToken = user;

        localStorage.setItem(
          "upload_id",
          JSON.stringify(this.props.match.params.id)
        );

        this.props.history.push({
          pathname: "/Claim",

          state: {
            data: {
              upload_id: this.props.match.params.id,
            },
          },
        });

        //   const url ="https://apitest.paropakari.com/api/GetProfile/CreateContact";
        //   const options = {
        //   method: "POST",
        //   headers:{

        //   Accept: 'application/json',
        //   Authorization: "Bearer " +accessToken
        //   },
        //   body: data,
        //   };
        //  // this.setState(this.initialState)
        //   const response = await fetch(url, options);
        //   const result = await response.json();
        //   localStorage.setItem('Contact_id', JSON.stringify(result.id));

        //
        //   if (response.ok) {
        //     localStorage.setItem('upload_id', JSON.stringify(this.props.match.params.id));

        //    this.props.history.push( {pathname: "/Claim",

        //         state: { data:{

        //           upload_id:this.props.match.params.id

        //               } }});

        //             }
      }
    }
  };

  handlePayment = async (event) => {
    if (localStorage.getItem("razorpay_payment_id") !== null) {
      let razorpay_payment_id = JSON.parse(
        localStorage.getItem("razorpay_payment_id")
      );
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      const url =
        "https://apitest.paropakari.com/api/GetProfile/Paymentstatus?razorpay_payment_id=" +
        razorpay_payment_id;
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
        },
        body: razorpay_payment_id,
      };

      const response = await fetch(url, options);
      const result = await response.json();

      localStorage.setItem("status", JSON.stringify(result.status));
      localStorage.setItem("method", JSON.stringify(result.method));
      if (response.ok) {
        let razorpay_payment_id = JSON.parse(
          localStorage.getItem("razorpay_payment_id")
        );
        let upload_id = JSON.parse(localStorage.getItem("upload_id"));
        let order_id = JSON.parse(localStorage.getItem("order_id"));

        let cardName = JSON.parse(localStorage.getItem("card[name]"));
        let cardNumber = JSON.parse(localStorage.getItem("card[number]"));
        let cvv = JSON.parse(localStorage.getItem("card[cvv]"));
        let expiryMonth = JSON.parse(
          localStorage.getItem("card[expiry_month]")
        );
        let expiryYear = JSON.parse(localStorage.getItem("card[expiry_year]"));
        let method = JSON.parse(localStorage.getItem("method"));
        let status = JSON.parse(localStorage.getItem("status"));
        var data1 = {
          razorpay_payment_id: razorpay_payment_id,
        };
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;
        var urll =
          "https://apitest.paropakari.com/api/GetProfile/Add_Payment?razorpay_payment_id=" +
          razorpay_payment_id +
          "&upload_id=" +
          upload_id +
          "&Order_id=" +
          order_id +
          "&cardName=" +
          cardName +
          "&cardNumber=" +
          cardNumber +
          "&cvv=" +
          cvv +
          "&expiryMonth=" +
          expiryMonth +
          "&expiryYear=" +
          expiryYear +
          "&DonorModeofPayment=" +
          method +
          "&Payment_status=" +
          status;

        var optionss = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: JSON.stringify(data1),
        };

        try {
          const response = await fetch(urll, optionss);
          const result = await response.json();

          if (response.ok) {
            var amount = document.getElementById("claimamount").value;
            var causename = document.getElementById("causename").value;

            alert(
              "Congratulations!! You have claimed " +
                amount +
                "  for the " +
                causename +
                " successfully. You claim money will be credited within 24 hrs"
            );
          } else {
            alert("Failed!. Try Again");

            // 
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        localStorage.setItem("noticount", JSON.stringify(data[0].noticount));
        //
      });
  };

  comment = () => {
    try {
      const urll = new URL(window.location.href);
      const Id = urll.searchParams.get("upload_id");

      let url =
        "https://apitest.paropakari.com/api/GetProfile/Get_Comment?id=" +
        this.props.match.params.id;
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            comm: data,
          });

          //
        });
    } catch (error) {
      console.error(error);
    }
  };

  Tagged = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_TagDetails?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Tagged: data,
        });

        //
      });
  };

  Liked = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_LikedUsers?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          Liked: data,
        });

        //
      });
  };

  paropakaris = () => {
    try {
      const urll = new URL(window.location.href);
      const Id = urll.searchParams.get("upload_id");

      let url =
        "https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisDetails?id=" +
        this.props.match.params.id;
      let data = this.state;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            paropakaris: data,
          });

          //
        });
    } catch (error) {
      console.error(error);
    }
  };

  like = () => {
    const urll = new URL(window.location.href);
    const Id = urll.searchParams.get("upload_id");

    let url =
      "https://apitest.paropakari.com/api/GetProfile/Get_ParopakarisCount?id=" +
      this.props.match.params.id;
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          like: data,
        });

        //
      });
  };

  Cancel = (uploadid) => {
    const { canceltransaction } = this.state;

    var x = window.confirm(
      canceltransaction.result
        ? canceltransaction.result
        : "Are you sure you want to cancel this transaction"
    );
    if (x) {
      // var x = window.confirm('Once You Claim Your Donations, Your Cause will End!');
      // if (x){
      window.location.href = "#/ReaponsePage/" + uploadid;
      // }
      // 
    } else {
      return false;
    }
  };

  handleYes = async (response_id) => {
    var x = window.confirm(this.state.ihaveconfirm.result);
    if (x) {
      //  alert(response_id)
      let user = JSON.parse(localStorage.getItem("user"));
      //  alert(response_id+user);
      const accessToken = user;
      fetch(
        "https://apitest.paropakari.com/api/Create/ConfirmResponse?id=" +
          response_id,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: "data",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            like: data,
          });
          alert(data.status);
          //
        });
      // }
      alert(this.state.confirmationposted.result);
      window.location.reload();
      
    } else {
      return false;
    }
  };

  // handleYes = (event) => {
  //   const { response_id } = this.state;
  //   const urll = new URL(window.location.href);
  //   const upload_id = urll.searchParams.get("upload_id");
  //   event.preventDefault();

  //  const data = new FormData(event.target);
  //

  //  let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //  fetch('https://apitest.paropakari.com/api/Create/ConfirmResponse?id=' + response_id , {
  //    method: 'POST',
  //    headers:{

  //      Accept: 'application/json',
  //        Authorization: "Bearer " +accessToken

  //    },
  //    body: data,
  //  });

  //  alert("Confirmed Successfully!");

  //  

  //
  // }

  // handleNo = (event) => {
  //   const { response_id } = this.state;
  //   const urll = new URL(window.location.href);
  //   const upload_id = urll.searchParams.get("upload_id");
  //   event.preventDefault();

  //  const data = new FormData(event.target);
  //

  //  let user = JSON.parse(localStorage.getItem('user'));
  // const accessToken = user;
  //  fetch('https://apitest.paropakari.com/api/Create/ConfirmResponseNotRecvd?id=' + response_id , {
  //    method: 'POST',
  //    headers:{

  //      Accept: 'application/json',
  //        Authorization: "Bearer " +accessToken

  //    },
  //    body: "data",
  //  });

  //  alert("Confirmed Successfully!");

  //  

  // }

  handleNo = async (response_id) => {
    //alert("called");
    try {
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      fetch(
        "https://apitest.paropakari.com/api/Create/ConfirmResponseNotRecvd?id=" +
          response_id,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: "data",
        }
      );

      alert(this.state.confirmationposted.result);

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  handleClick = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;
    fetch("https://apitest.paropakari.com/api/GetProfile/Add_Comment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: data,
    });

    //alert("Added your Comment Successfully!");

    
  };

  ReloadPage = () => {
    let reloadsmyactivity = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem("confirmpagereload") !== null) {
      reloadsmyactivity = sessionStorage.getItem("confirmpagereload");
      reloadsmyactivity++;
      window.sessionStorage.setItem("confirmpagereload", reloadsmyactivity);
    } else {
      reloadsmyactivity = 1;
      window.sessionStorage.setItem("confirmpagereload", reloadsmyactivity);
    }

    if (reloadsmyactivity == 1) {
      window.location.reload();

    }
  };

  DefaultMenu() {
    let reloads = 0;
    window.sessionStorage.setItem("reloadlogin", reloads);
    window.sessionStorage.setItem("reload", reloads);
    window.sessionStorage.setItem("reloadcreate", reloads);
    window.sessionStorage.setItem("tageditreload", reloads);


    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("donatesmile", reloadsmyactivity);
    window.sessionStorage.setItem("donateasmilewithout80g", reloadsmyactivity);
    window.sessionStorage.setItem("tagedit", reloadsmyactivity);

    let MID = "English";
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    let GGID = this.state.googtrans;

    if (GGID != null || GGID == "undefined") {
      GGID = this.state.googtrans.slice(4, 6);
    } else {
      GGID = MID;
    }

    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    let datatrans =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    localStorage.setItem("GoogleTranslate123", datatrans);

    let GID = sessionStorage.getItem("this.state.googtrans");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID;
    if (datatrans == "en") {
      datatransID = "English";
    } else if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;
          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;
          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var Contactus = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition =
            typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Education = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Education,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~Anticipated~Name&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Names = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Names: Names,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Mobile~Friends~I have Recieved~I haven't Recieved~Support Confirmation&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var MobileNumbers = typeof items[0] !== "undefined" ? items[0] : null;
          var Friends = typeof items[1] !== "undefined" ? items[1] : null;
          var ihavercvd = typeof items[2] !== "undefined" ? items[2] : null;
          var ihaventrcvd = typeof items[3] !== "undefined" ? items[3] : null;
          var Supportconfirm =
            typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            MobileNumbers: MobileNumbers,
            friends: Friends,
            ihavercvd: ihavercvd,
            ihaventrcvd: ihaventrcvd,
            Supportconfirm: Supportconfirm,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Email~Pledge~Message~Response date~Action~Comment Here~Share~Submit&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Email = typeof items[0] !== "undefined" ? items[0] : null;
          var Pledge = typeof items[1] !== "undefined" ? items[1] : null;
          var Message = typeof items[2] !== "undefined" ? items[2] : null;
          var Responsedate = typeof items[3] !== "undefined" ? items[3] : null;
          var Action = typeof items[4] !== "undefined" ? items[4] : null;
          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var Share = typeof items[6] !== "undefined" ? items[6] : null;
          var Submit = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            Email: Email,
            Pledge: Pledge,
            Message: Message,
            Responsedate: Responsedate,
            Action: Action,
            CommentHere: CommentHere,
            share: Share,
            Submit: Submit,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Confirmed~You are confirming that you have received support from donor~Confirmation Posted!~You Want to Claim Donations to Your Cause? Are You Sure?~Once You Claim Your Donations, Your Cause will End!&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Confirmed = typeof items[0] !== "undefined" ? items[0] : null;
          var IhaveConfirm = typeof items[1] !== "undefined" ? items[1] : null;
          var ConfirmationPosted =
            typeof items[2] !== "undefined" ? items[2] : null;
          var YouwantoClaim = typeof items[3] !== "undefined" ? items[3] : null;
          var YourcuasewillENd =
            typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            confirmed: Confirmed,
            ihaveconfirm: IhaveConfirm,
            confirmationposted: ConfirmationPosted,
            youwantoclain: YouwantoClaim,
            yourcausewillend: YourcuasewillENd,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~You want to claim donations to your cause? Are you sure?~Your cause will be closed once you claim the money. Do you want to proceed ?~Are you sure you want to cancel this transaction&lang=" +
        datatransID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var youwanttoclaim =
            typeof items[2] !== "undefined" ? items[2] : null;
          var yorcausellbclosed =
            typeof items[3] !== "undefined" ? items[3] : null;
          var canceltransaction =
            typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            youwanttoclaim: youwanttoclaim,
            yorcausellbclosed: yorcausellbclosed,
            canceltransaction: canceltransaction,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Search~Categories~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;
          var Search = typeof items[8] !== "undefined" ? items[8] : null;
          var Categories = typeof items[9] !== "undefined" ? items[9] : null;
          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            search: Search,
            categories: Categories,
            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Food and Nutrition~Health~Professional~Sports~Social Welfare~Others~Sort~Most Recent~Most popular~80G Available&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var FoodandNutrtion =
            typeof items[0] !== "undefined" ? items[0] : null;
          var Health = typeof items[1] !== "undefined" ? items[1] : null;
          var Professional = typeof items[2] !== "undefined" ? items[2] : null;
          var Sports = typeof items[3] !== "undefined" ? items[3] : null;
          var SocialWelfare = typeof items[4] !== "undefined" ? items[4] : null;
          var Others = typeof items[5] !== "undefined" ? items[5] : null;
          var Sort = typeof items[6] !== "undefined" ? items[6] : null;
          var MostRecent = typeof items[7] !== "undefined" ? items[7] : null;
          var MostPopular = typeof items[8] !== "undefined" ? items[8] : null;
          var Availabl80G = typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            foodandnutrition: FoodandNutrtion,
            health: Health,
            langID: LangID,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            others: Others,
            sort: Sort,
            mostrecent: MostRecent,
            mostpopular: MostPopular,
            available80g: Availabl80G,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Amount Ascending~Amount Descending~Complete~Incomplete~HowItWorks~Blog~Contact Us~Terms and Conditions~Copyright © 2022 Paropakari~Causes&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var AmountAsc = typeof items[0] !== "undefined" ? items[0] : null;
          var AmountDesc = typeof items[1] !== "undefined" ? items[1] : null;
          var Complete = typeof items[2] !== "undefined" ? items[2] : null;
          var Incomplete = typeof items[3] !== "undefined" ? items[3] : null;
          var Howitwork = typeof items[4] !== "undefined" ? items[4] : null;
          var Blog = typeof items[5] !== "undefined" ? items[5] : null;

          var Contactus = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition =
            typeof items[7] !== "undefined" ? items[7] : null;
          var CopyRight = typeof items[8] !== "undefined" ? items[8] : null;
          var Causes = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            amountdesc: AmountDesc,
            amountasc: AmountAsc,
            complete: Complete,
            incomplete: Incomplete,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Beneficiaries~Paropakaris~Amount Raised~Education~End Date~Tag~Like~Comments~Donate a Smile~Year&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Beneficiaries = typeof items[0] !== "undefined" ? items[0] : null;
          var Paropakaris = typeof items[1] !== "undefined" ? items[1] : null;
          var Amountraised = typeof items[2] !== "undefined" ? items[2] : null;

          var Education = typeof items[3] !== "undefined" ? items[3] : null;
          var Enddate = typeof items[4] !== "undefined" ? items[4] : null;
          var Tag = typeof items[5] !== "undefined" ? items[5] : null;
          var Like = typeof items[6] !== "undefined" ? items[6] : null;
          var Comment = typeof items[7] !== "undefined" ? items[7] : null;
          var Donateasmile = typeof items[8] !== "undefined" ? items[8] : null;
          var Year = typeof items[9] !== "undefined" ? items[9] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            beneficiaries: Beneficiaries,
            paropakariss: Paropakaris,
            amountraised: Amountraised,
            education: Education,
            enddate: Enddate,
            tag: Tag,
            likess: Like,
            comment: Comment,
            donateasmile: Donateasmile,
            year: Year,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Activity~My Causes~Liked Causes~Commented Causes~Pledged Causes~Happiness Meter~Achieved~Anticipated~Name&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Activity = typeof items[0] !== "undefined" ? items[0] : null;
          var Mycauses = typeof items[1] !== "undefined" ? items[1] : null;
          var Likedcauses = typeof items[2] !== "undefined" ? items[2] : null;
          var Commentedcauses =
            typeof items[3] !== "undefined" ? items[3] : null;
          var Pledgedcauses = typeof items[4] !== "undefined" ? items[4] : null;

          var HappinessMeter =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Achieved = typeof items[6] !== "undefined" ? items[6] : null;
          var OnItsWay = typeof items[7] !== "undefined" ? items[7] : null;
          var Names = typeof items[8] !== "undefined" ? items[8] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            activity: Activity,
            mycauses: Mycauses,
            likedcauses: Likedcauses,
            commentedcauses: Commentedcauses,
            pledgedcauses: Pledgedcauses,
            HappinessMeter: HappinessMeter,
            Achieved: Achieved,
            OnItsWay: OnItsWay,
            Names: Names,

            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Mobile~Friends~I have Recieved~I haven't Recieved~Support Confirmation&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;

          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var MobileNumbers = typeof items[0] !== "undefined" ? items[0] : null;
          var Friends = typeof items[1] !== "undefined" ? items[1] : null;
          var ihavercvd = typeof items[2] !== "undefined" ? items[2] : null;
          var ihaventrcvd = typeof items[3] !== "undefined" ? items[3] : null;
          var Supportconfirm =
            typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            MobileNumbers: MobileNumbers,
            friends: Friends,
            ihavercvd: ihavercvd,
            ihaventrcvd: ihaventrcvd,
            Supportconfirm: Supportconfirm,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Email~Pledge~Message~Response date~Action~Comment Here~Share~Submit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Email = typeof items[0] !== "undefined" ? items[0] : null;
          var Pledge = typeof items[1] !== "undefined" ? items[1] : null;
          var Message = typeof items[2] !== "undefined" ? items[2] : null;
          var Responsedate = typeof items[3] !== "undefined" ? items[3] : null;
          var Action = typeof items[4] !== "undefined" ? items[4] : null;

          var CommentHere = typeof items[5] !== "undefined" ? items[5] : null;
          var Share = typeof items[6] !== "undefined" ? items[6] : null;
          var Submit = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            Email: Email,
            Pledge: Pledge,
            Message: Message,
            Responsedate: Responsedate,
            Action: Action,
            CommentHere: CommentHere,
            share: Share,
            Submit: Submit,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Confirmed~You are confirming that you have received support from donor~Confirmation Posted!~You Want to Claim Donations to Your Cause? Are You Sure?~Once You Claim Your Donations, Your Cause will End!&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Confirmed = typeof items[0] !== "undefined" ? items[0] : null;
          var IhaveConfirm = typeof items[1] !== "undefined" ? items[1] : null;
          var ConfirmationPosted =
            typeof items[2] !== "undefined" ? items[2] : null;
          var YouwantoClaim = typeof items[3] !== "undefined" ? items[3] : null;
          var YourcuasewillENd =
            typeof items[4] !== "undefined" ? items[4] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          // localStorage.setItem('MenuID', JSON.stringify(LangID));
          // window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            confirmed: Confirmed,
            ihaveconfirm: IhaveConfirm,
            confirmationposted: ConfirmationPosted,
            youwantoclain: YouwantoClaim,
            yourcausewillend: YourcuasewillENd,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright~Paropakari~You want to claim donations to your cause? Are you sure?~Your cause will be closed once you claim the money. Do you want to proceed ?~Are you sure you want to cancel this transaction&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var copyright_new = typeof items[0] !== "undefined" ? items[0] : null;
          var paropakari_new =
            typeof items[1] !== "undefined" ? items[1] : null;
          var youwanttoclaim =
            typeof items[2] !== "undefined" ? items[2] : null;
          var yorcausellbclosed =
            typeof items[3] !== "undefined" ? items[3] : null;
          var canceltransaction =
            typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          this.setState({
            copyright_new: copyright_new,
            paropakari_new: paropakari_new,
            youwanttoclaim: youwanttoclaim,
            yorcausellbclosed: yorcausellbclosed,
            canceltransaction: canceltransaction,
          });
        });
    }
  }

  componentDidMount() {
    window.addEventListener("load", this.submit);
    this.submit();
    window.addEventListener("load", this.comment);
    this.comment();
    window.addEventListener("load", this.like);
    this.like();
    window.addEventListener("load", this.paropakaris);
    this.paropakaris();
    window.addEventListener("load", this.Tagged);
    this.Tagged();
    window.addEventListener("load", this.Liked);
    this.Liked();
    window.addEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.resp);
    this.resp();
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    window.addEventListener("load", this.ClaimAmountDetail);
    this.ClaimAmountDetail();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    const element1 = document.querySelector(
      ".VIpgJd-suEOdc VIpgJd-yAWNEb-L7lbkb"
    );
    if (
      element1 &&
      window.getComputedStyle(element1).getPropertyValue("position") === "fixed"
    ) {
      element1.style.position = "absolute";
    }

    // window.addEventListener('load', this.paymentDetails);
    // this.paymentDetails();
    document.title = "India's only expenseless, direct crowdfunding platform";

    this.interval = setInterval(this.checkAndSetLanguage, 500); // Check every second
    this.checkAndSetLanguage(); // Initial check
  }
  checkAndSetLanguage = () => {
    let Language_value = Cookies.get("googtrans", {
      domain: ".paropakari.com",
    });
    const cookieLang =
      Language_value === undefined ? "en" : Language_value.split("/")[2];

    if (cookieLang !== this.state.previousCookieLang) {
      let newLanguage = "";
      let MID = "English";
      if (cookieLang === "en") {
        newLanguage = "English";
      } else if (cookieLang === "kn") {
        newLanguage = "Kannada";
      } else if (cookieLang === "ta") {
        newLanguage = "Tamil";
      } else if (cookieLang === "te") {
        newLanguage = "Telugu";
      } else if (cookieLang === "hi") {
        newLanguage = "Hindi";
      } else if (cookieLang === "es") {
        newLanguage = "Spanish";
      } else if (cookieLang === MID) {
        newLanguage = MID;
      }

      sessionStorage.setItem("translate", newLanguage);
      this.setState(
        { language: newLanguage, previousCookieLang: cookieLang },
        this.DefaultMenu()
      );
    }
  };
  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener("load", this.submit);
    this.submit();
    window.removeEventListener("load", this.comment);
    this.comment();
    window.removeEventListener("load", this.like);
    this.like();
    window.removeEventListener("load", this.paropakaris);
    this.paropakaris();
    window.removeEventListener("load", this.Tagged);
    this.Tagged();
    window.removeEventListener("load", this.Liked);
    this.Liked();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.resp);
    this.resp();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    window.removeEventListener("load", this.ClaimAmountDetail);
    this.ClaimAmountDetail();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    // window.removeEventListener('load', this.paymentDetails);
    // this.paymentDetails();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  // handleSubmitevents = (event) => {
  //   event.preventDefault();

  //   if (this.handleFormValidation()) {
  //     alert(' success')
  //     this.setState(this.initialState)
  //
  //   }
  //

  // };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }
  render() {
    const { paropakaris } = this.state;
    const { comm } = this.state;
    const { emps, clm } = this.state;
    const { like } = this.state;
    const { Tagged } = this.state;
    const { Liked } = this.state;
    const { notify } = this.state;
    const currentYear = new Date().getFullYear();
    var noticount = JSON.parse(localStorage.getItem("noticount"));
    const { resp } = this.state;
    const {
      howitworks,
      blog,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakariss,
      amountraised,
      copyright,
      beaparopakari,
      myjourney,
      createacause,
      invite,
      profile,
      changepassword,
      group,
      logout,
      search,
      categories,
      education,
      foodandnutrition,
      health,
      professional,
      sports,
      socialwefare,
      others,
      youwantoclain,
      yourcausewillend,
      sort,
      mostrecent,
      mostpopular,
      available80g,
      amountdesc,
      amountasc,
      complete,
      incomplete,
      enddate,
      tag,
      likess,
      friends,
      comment,
      donateasmile,
      year,
      activity,
      mycauses,
      likedcauses,
      commentedcauses,
      pledgedcauses,
      langID,
      HappinessMeter,
      Achieved,
      OnItsWay,
      Names,
      privacypolicy,
      Supportconfirm,
      share,
      submit,
      Submit,
      CommentHere,
      confirmed,
      ihaveconfirm,
      confirmationposted,
      copyright_new,
      paropakari_new,
      youwanttoclaim,
      yorcausellbclosed,
      MobileNumbers,
      canceltransaction,
      Email,
      ihavercvd,
      ihaventrcvd,
      Pledge,
      Message,
      Responsedate,
      Action,
    } = this.state;

    let { foo } = this.state;
    var username = JSON.parse(localStorage.username);

    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
        >
          {/* <meta http-equiv="refresh" content="10"/>   */}
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          {/*[if lt IE 9]>
            
            
        <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          <div onClick={this.refreshPage}>
            <select
              className="form-control"
              style={{
                display: "none",
                width: "10%",
                marginTop: "0",
                marginLeft: "0",
                float: "right",
                width: "fit-content",
              }}
              value={this.state.value}
              onChange={this.handleChanges}
            >
              <option value="English">Languages</option>
              <option value="Kannada">Kannada</option>
              <option value="Tamil">Tamil</option>
              <option value="Hindi">Hindi</option>
              <option value="Telugu">Telugu</option>
              <option value="Spanish">Spanish</option>
              <option value="English">English</option>
            </select>
          </div>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div
            id="google_translate_element"
            onClick={this.refreshPage}
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
          >
            {/* <button onClick={refreshPage}>Click to reload!</button> */}
          </div>
          {/* <header id="header" style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")"}} className="notranslate">      
              <div className="container">
                <div className="row">
                
                </div>
              </div>
              <div className="navbar navbar-inverse" role="banner">
                <div className="container">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                    </button>
                   <a className="navbar-brand"  href="#/HomePage" >
                   <h1 style={{color:'blue',fontFamily:'Biysk-Regular'}}><img src="./assets/images/plogo.png" alt="logo" style={{marginBottom:'17px',width:'26%',fontsize:'52px'}}  />AROPAKARI</h1>
                  </a>
          
                  </div>
                  
                  <div className="collapse navbar-collapse">
                  <ul className="nav navbar-nav navbar-right">
                    
                   
                  {(() => {if (langID == 'Tamil' ) {return (
                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'40px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
                 {beaparopakari.result}
                  </a></li>
                 );
                 }
                 else {return(

                  <li id="paro" className="active"><a className="btn1 third" href="#/CauseFor" style={{backgroundColor:'rgb(102, 71, 18)',color:'white',fontWeight: 'bold',padding:'13px',marginRight:'100px',marginLeft:'-274px',fontSize:16,marginTop:'2px',marginBottom:'-7px',textDecoration:'none',whiteSpace:'nowrap',fontFamily:'cuyabra'}}>
           
                {beaparopakari.result}
                  </a></li>

                 );}

                })()}    
        
                                                 
                            
                                   <li className=""><a href="#/Myactivity" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{myjourney.result}</a></li>     
                                   <li className=""><a href="#/CauseFor" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{createacause.result}</a></li>       
                                   <li className=""><a href="#/InviteFriend" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{invite.result}</a></li>         
                                   <li className=""><a href="#/Notification" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>
                                     
                                   <div class="fa fa-bell" style={{fontsize:'24px'}}>{notify.map((notifyy) => (
                                                        <span class="-count"> 
                                         
                                       
                                         {noticount}
                                        
                                           </span> ))}</div>
                                    </a></li>           
                                    
                                   <li className="dropdown"><a href="#/Profile" style={{fontFamily:'cuyabra',fontWeight: 'bold'}}>{username} <i className="fa fa-angle-down" /></a>
                                     <ul role="menu" className="sub-menu">
                                       <li><a href="#/Profile">{profile.result}</a></li>
                                       <li><a href="#/ChangePassword">{changepassword.result}</a></li>
                                       <li><a href="#/Group">{group.result}</a></li>
                                       <li><a href="#/Login">{logout.result}</a></li>
                                      
                                     </ul>
                                   </li>    
                                                    
                                         
                                 </ul>
                  </div>
                 
                </div>
              </div>
            </header> */}
          <div className="mag notranslate">
            <div class="pro-home-nav">
              <input
                type="checkbox"
                id="pro-home-nav-check"
                onClick={this.checkfun}
              />
              <div class="pro-home-nav-header">
                <div class="pro-home-nav-title notranslate">
                  <a className="navbar-brand" href="#/HomePage">
                    <h1 className="navbar-new-text">
                      <img
                        src="./assets/images/plogo.png"
                        alt="logo"
                        style={{
                          marginBottom: "17px",
                          width: "26%",
                          fontsize: "300px",
                        }}
                      />
                      AROPAKARI
                    </h1>
                  </a>
                </div>
              </div>

              <div class="pro-home-nav-btn">
                <label for="pro-home-nav-check">
                  <div className="pro-naav">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </label>
              </div>

              {langID == "English" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-boxx1 parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Hindi" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-boxx1 parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Tamil" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center-tamil" style={{ marginRight: "10px" }}>
                      <a className="new-tamil-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a className="text-parop-menu" href="#/InviteFriend">
                          {invite.result}
                        </a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Spanish" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-tamil-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Kannada" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-telugu-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/InviteFriend">{invite.result}</a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : langID == "Telugu" ? (
                <>
                  <div class="pro-home-nav-links pro-side pro-top">
                    <p class="pro-center">
                      <a className="new-telugu-design" href="#/CauseFor">
                        {beaparopakari.result}
                      </a>
                    </p>
                    <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                      <li className="parop-menu">
                        <a href="#/Myactivity">{myjourney.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a href="#/CauseFor">{createacause.result}</a>
                      </li>
                      <li className="parop-menu">
                        <a className="text-parop-menu" href="#/InviteFriend">
                          {invite.result}
                        </a>
                      </li>
                      <li className="parop-menu parop-notify">
                        <a href="#/Notification">
                          <div class="fa fa-bell" style={{ fontsize: "24px" }}>
                            {notify.map((notifyy) => (
                              <span class="-count">{notifyy.noticount}</span>
                            ))}
                          </div>
                        </a>
                      </li>
                      <li className="parop-menu">
                        <a>
                          <b>{username}</b> <i className="fa fa-angle-down"></i>
                        </a>
                        <ul class="dropdown">
                          <li className="hower-text">
                            <a href="#/Profile">
                              <b>{profile.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/ChangePassword">
                              <b>{changepassword.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Group">
                              <b>{group.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <a href="#/Friends">
                              <b>{friends.result}</b>
                            </a>
                          </li>
                          <li className="hower-text">
                            <LogoutLink logoutResult={logout.result} />
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {/*/#header*/}
          {/*/#page-breadcrumb*/}
          <section
            id="blog"
            className={this.state.movedown == true ? null : "Tiptop"}
            style={{ backgroundColor: "rgb(174, 230, 219)" }}
          >
            <br />
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-sm-7">
                  <div className="row">
                    {emps.map((emp) => (
                      // <tr key={emp.user_id}>
                      <div className="col-sm-12 col-md-12">
                        {/* <h3 style={{fontsize:'15px'}}><a href={"#/ResponsePage/"+emp.upload_id} style={{fontsize:'20px',textDecoration:'none',color:'black'}}><b>{emp.upload_title}</b></a></h3> */}
                        {(() => {
                          if (emp.profile_name == username) {
                            return (
                              <div>
                                <h3 style={{ fontsize: "15px" }}>
                                  <a
                                    href={"#/ResponsePage/" + emp.upload_id}
                                    style={{
                                      fontsize: "20px",
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    <b>{emp.upload_title}</b>
                                  </a>

                                  <input
                                    type="hidden"
                                    id="causename"
                                    name="causename"
                                    value={emp.upload_title}
                                  />

                                  {(() => {
                                    if (emp.status_80G == "1") {
                                      return (
                                        <a
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "15px",
                                            color: "gray",
                                          }}
                                        >
                                          <blink>
                                            <b className="notranslate">
                                              {available80g.result}
                                            </b>
                                          </blink>
                                        </a>
                                      );
                                    } else {
                                      return <a></a>;
                                    }
                                  })()}
                                </h3>
                                {(() => {
                                  if (emp.causefor_Id == "0") {
                                    return (
                                      <p style={{ float: "" }}>
                                        Required Amount {emp.upload_amount}
                                      </p>
                                    );
                                  } else if (emp.causefor_Id == "1") {
                                    return (
                                      <p style={{ float: "" }}>
                                        Number of Beneficiaries{" "}
                                        {emp.upload_num_of_beneficiaries}
                                      </p>
                                    );
                                  }
                                })()}
                              </div>
                            );
                          } else {
                            return (
                              <div>
                                <h3 style={{ fontsize: "15px" }}>
                                  <a
                                    href={"#/ResponsePage/" + emp.upload_id}
                                    style={{
                                      fontsize: "20px",
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                  >
                                    <b>{emp.upload_title}</b>
                                  </a>
                                  {(() => {
                                    if (emp.status_80G == "1") {
                                      return (
                                        <a
                                          style={{
                                            marginLeft: "10px",
                                            fontSize: "15px",
                                            color: "gray",
                                          }}
                                        >
                                          <blink>
                                            <b className="notranslate">
                                              {available80g.result}
                                            </b>
                                          </blink>
                                        </a>
                                      );
                                    } else {
                                      return <a></a>;
                                    }
                                  })()}
                                </h3>
                                {(() => {
                                  if (emp.causefor_Id == "0") {
                                    return (
                                      <p style={{ float: "" }}>
                                        Required Amount {emp.upload_amount}
                                      </p>
                                    );
                                  } else if (emp.causefor_Id == "1") {
                                    return (
                                      <p style={{ float: "" }}>
                                        Number of Beneficiaries{" "}
                                        {emp.upload_num_of_beneficiaries}
                                      </p>
                                    );
                                  }
                                })()}
                              </div>
                            );
                          }
                        })()}

                        <div className="row">
                          <div className="col-lg-4 col-sm-4">
                            <p>{emp.category_name}</p>
                          </div>
                          <div className="col-lg-7 col-sm-5">
                            <h3
                              style={{
                                fontsize: "15px",
                                float: "right",
                                color: "black",
                              }}
                            >
                              <b>{emp.upload_place}</b>
                            </h3>
                          </div>
                          <div className="col-lg-1 col-sm-4">
                            <p></p>
                          </div>
                        </div>
                        <div className="row" id="imagesssss">
                          <div className="col-lg-11 col-sm-8">
                            {emp.file_type == ".png" ||
                            emp.file_type == ".jpg" ||
                            emp.file_type == ".jfi" ||
                            emp.file_type == ".jfif" ||
                            emp.file_type == ".JPG" ||
                            emp.file_type == ".webp" ||
                            emp.file_type == ".jpeg" ? (
                              <ImagePreview
                                Id={emp.upload_id}
                                datavalue={emp.data}
                              >
                                
                              </ImagePreview>
                            ) : emp.file_type == ".docx" ||
                              emp.file_type == ".doc" ||
                              emp.file_type === ".xlsx"||
                              emp.file_type === ".xls" ? (
                                <ImagePreview
                                        Id={emp.upload_id}
                                        datavalue={emp.data}
                                      >
                                       
                                      </ImagePreview>
                            ) : emp.file_type == ".mp4" ||
                              emp.file_type == ".webm" ? (
                              <ImagePreview
                                Id={emp.upload_id}
                                datavalue={emp.data}
                              >
                               
                              </ImagePreview>
                            ) : emp.file_type == ".pdf" && (
                               
                              <ImagePreview
                                Id={emp.upload_id}
                                datavalue={emp.data}
                              >
                              
                              </ImagePreview>
                            )}
                          </div>

                          <div className="col-lg-4">
                            <p></p>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-lg-4 col-sm-4">
                            <p>
                              <b>{emp.profile_name}'s Initiative</b>
                            </p>
                          </div>

                          {/* <div className="col-lg-7"><p style={{float:'right'}}>{emp.created_date}</p></div> */}
                          <div className="col-lg-7">
                            <p style={{ float: "right" }} id="postedon">
                              Posted on &nbsp;{emp.created_date}
                            </p>
                          </div>
                          <div className="col-lg-1">
                            <p></p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4">
                            <p>
                              Required for {emp.relationship}({emp.required_for}
                              )
                            </p>
                            <p>
                              <b>Description</b>&nbsp;"
                              {emp.upload_description}"
                            </p>
                          </div>

                          <div className="col-lg-7">
                            {/* {(() => {if (emp.causefor_Id == '0') {return (
                   <p style={{float:'right'}}>Required Amount {emp.upload_amount}</p>
                  );
                }
                else if (emp.causefor_Id == '1'){return(
               
                  <p style={{float:'right'}}>Number of Beneficiaries {emp.upload_num_of_beneficiaries}</p>
                )}
              })()} */}
                            <p style={{ float: "right" }}>
                              {enddate.result} &nbsp;{emp.required_by}
                            </p>
                          </div>
                          <div className="col-lg-1">
                            <p></p>
                          </div>
                        </div>
                        <div className="post-content overflow">
                          <div className="single-blog single-column">
                            <div
                              className="container-fluid"
                              style={{ width: "91%" }}
                            >
                              <div className="row">
                                <div
                                  className="post-bottom overflow col-12 d-flex justify-content-between align-items-center flex-nowrap"
                                  style={{ marginLeft: "-1pc" }}
                                >
                                  {/* Tag Section */}
                                  <div className="d-flex align-items-center">
                                    {(() => {
                                      if (emp.profile_name === username) {
                                        return (
                                          <a
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <a
                                              href={
                                                "#/TagEdit/" + emp.upload_id
                                              }
                                              id="taganch"
                                              style={{
                                                textDecoration: "none",
                                                color: "black",
                                              }}
                                            >
                                              <i className="fa fa-tag" />
                                            </a>
                                            <b>
                                              &nbsp;{emp.tagcount}&nbsp;
                                              <TaggedFriends
                                                Translated={
                                                  tag.result
                                                    ? tag.result
                                                    : "Tag"
                                                }
                                                Id={this.props.match.params.id}
                                              ></TaggedFriends>
                                            </b>
                                          </a>
                                        );
                                      } else {
                                        return (
                                          <a
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <i className="fa fa-tag" />
                                            <b>
                                              &nbsp;{emp.tagcount} &nbsp;
                                              <TaggedFriends
                                                Translated={
                                                  tag.result
                                                    ? tag.result
                                                    : "Tag"
                                                }
                                                Id={this.props.match.params.id}
                                              ></TaggedFriends>
                                            </b>
                                          </a>
                                        );
                                      }
                                    })()}
                                  </div>

                                  {/* Like Section */}
                                  <div className="d-flex align-items-center">
                                    <a>
                                      <form
                                        onSubmit={this.AddLiking.bind(this)}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="hidden"
                                          name="id"
                                          onChange={this.handlebenfChange}
                                          id="id"
                                          defaultValue={emp.upload_id}
                                        />
                                        <button
                                          type="submit"
                                          className="fa notranslate"
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            backgroundColor:
                                              "transparent",
                                            fontSize: "inherit",
                                            textRendering: "auto",
                                            WebkitFontSmoothing: "antialiased",
                                            transform: "translate(0, 0)",
                                            fontFamily: "cuyabra",
                                            border: "none",
                                            marginLeft: "-7px",
                                          }}
                                        >
                                          {(() => {
                                            if (emp.uid === "true") {
                                              return (
                                                <img
                                                   
                                                  src="https://apitest.paropakari.com/Images/like.png"
                                                  alt="like"
                                                  className="fa"
                                                  style={{
                                                    height: "14px",
                                                    width: "14px",
                                                    marginRight: "4px",
                                                  }}
                                                />
                                              );
                                            } else {
                                              return (
                                                <img
                                                 
                                                  src="https://paropakari.com/Images/liked.png"
                                                  alt="Unlike"
                                                  className="fa"
                                                  style={{
                                                    height: "14px",
                                                    width: "14px",
                                                    marginRight: "4px",
                                                    border: "none",
                                                    outline: "none",
                                                  }}
                                                />
                                              );
                                            }
                                          })()}
                                          <b
                                            className="notranslate"
                                            style={{ color: "black" }}
                                          >
                                            {emp.likes} &nbsp;{" "}
                                          </b>
                                        </button>
                                      </form>
                                      <b
                                        style={{ color: "black" }}
                                        id="likebut"
                                      >
                                        <Likepopup
                                          Translated={
                                            likess.result
                                              ? likess.result
                                              : "Like"
                                          }
                                          Id={this.props.match.params.id}
                                          refreshcomment={() =>
                                            this.refreshcomment()
                                          }
                                        ></Likepopup>
                                      </b>
                                    </a>
                                  </div>

                                  {/* Comment Section */}
                                  <div className="d-flex align-items-center">
                                    {(() => {
                                      if (emp.profile_name === username) {
                                        return (
                                          <a
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <i
                                              className="fa fa-comments"
                                              id="fa-comments"
                                              style={{ marginRight: "4px" }}
                                            />
                                            <b className="" id="cmtsty">
                                              &nbsp;{emp.comments}
                                              <Comment
                                                Translated={
                                                  comment.result
                                                    ? comment.result
                                                    : "Comments"
                                                }
                                                Submit={
                                                  Submit.result
                                                    ? Submit.result
                                                    : "Submit"
                                                }
                                                CommentHere={
                                                  CommentHere.result
                                                    ? CommentHere.result
                                                    : "Comment Here"
                                                }
                                                refreshcomment={() =>
                                                  this.submit()
                                                }
                                                Id={this.props.match.params.id}
                                                Url={`https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=${this.props.match.params.id}`}
                                                Data={(data) =>
                                                  this.setState({ emps: data })
                                                }
                                              ></Comment>
                                            </b>
                                          </a>
                                        );
                                      } else {
                                        return (
                                          <a
                                            style={{
                                              color: "black",
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <i
                                              className="fa fa-comments"
                                              style={{ marginRight: "4px" }}
                                            />
                                            <b className="">
                                              &nbsp;{emp.comments}
                                              <Comment
                                                Translated={
                                                  comment.result
                                                    ? comment.result
                                                    : "Comments"
                                                }
                                                Submit={
                                                  Submit.result
                                                    ? Submit.result
                                                    : "Submit"
                                                }
                                                CommentHere={
                                                  CommentHere.result
                                                    ? CommentHere.result
                                                    : "Comment Here"
                                                }
                                                refreshcomment={() =>
                                                  this.submit()
                                                }
                                                Id={this.props.match.params.id}
                                                Url={`https://apitest.paropakari.com/api/GetProfile/Get_ResponseDetails?id=${this.props.match.params.id}`}
                                                Data={(data) =>
                                                  this.setState({ emps: data })
                                                }
                                              ></Comment>
                                            </b>
                                          </a>
                                        );
                                      }
                                    })()}
                                  </div>

                                  {/* Share Section */}
                                  <div className="d-flex align-items-center">
                                    <i
                                      className="fa fa-share"
                                      aria-hidden="true"
                                    ></i>
                                    <a
                                      href="#"
                                      style={{
                                        color: "black",
                                        marginLeft: "10px",
                                      }}
                                      className="sharebtn"
                                      data-toggle="modal"
                                      data-target="#myModal"
                                    >
                                      <b className="notranslate"> {share.result}</b>
                                    </a>
                                  </div>
                                  <div
                                    id="myModal"
                                    class="modal fade"
                                    role="dialog"
                                  >
                                    <div class="modal-dialog">
                                      {/* <!-- Modal content--> */}
                                      <div class="modal-content">
                                        <div
                                          class="modal-header"
                                          id="Headerback"
                                        >
                                          <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            style={{ marginTop: "-12px" }}
                                          >
                                            &times;
                                          </button>
                                        </div>
                                        <div class="modal-body text-center">
                                          <h5>
                                            Do you Share with your friends!
                                          </h5>
                                          {/* <div class="mt-5">
        <ul class="share_links">
          <li class="bg_fb"><a href="#" class="share_icon"  rel="tooltip" title="Facebook"><i class="fa fa-facebook"></i></a></li>

             <li class="bg_insta"><a href="#" class="share_icon" rel="tooltip" title="Instagram"><i class=" fa fa-instagram"></i></a></li>

        <li class="bg_whatsapp"><a href="#" class="share_icon" rel="tooltip" title="Whatsapp"><i class="fa fa-whatsapp" aria-hidden="true"></i></a></li></ul>
       </div> */}

                                          <FacebookShareButton
                                            url={
                                              "https://paropakari.com/#/ResponsePage/" +
                                              this.props.match.params.id
                                            }
                                            quote="SS"
                                            hashtag="#Be a Paropakari"
                                          >
                                            <FacebookIcon
                                              logoFillColor="white"
                                              style={{ width: "32px" }}
                                            />
                                          </FacebookShareButton>
                                          <WhatsappShareButton
                                            url={
                                              "https://paropakari.com/#/ResponsePage/" +
                                              this.props.match.params.id
                                            }
                                            quote="SS"
                                            hashtag="#Be a Paropakari"
                                          >
                                            <WhatsappIcon
                                              logoFillColor="white"
                                              style={{ width: "32px" }}
                                            />
                                          </WhatsappShareButton>
                                          <LinkedinShareButton
                                            url={
                                              "https://paropakari.com/#/ResponsePage/" +
                                              this.props.match.params.id
                                            }
                                            quote="SS"
                                            hashtag="#Be a Paropakari"
                                          >
                                            <LinkedinIcon
                                              logoFillColor="white"
                                              style={{ width: "32px" }}
                                            />
                                          </LinkedinShareButton>
                                          <TwitterShareButton
                                            url={
                                              "https://paropakari.com/#/ResponsePage/" +
                                              this.props.match.params.id
                                            }
                                            quote="SS"
                                            hashtag="#Be a Paropakari"
                                          >
                                            <XIcon
                                              logoFillColor="white"
                                              style={{ width: "32px" }}
                                            />
                                            {/* <img src={twitterIcon} style={{
                                                  width: '34px',
                                                  paddingBottom: '58.0px', marginLeft: '-0.2px', marginRight: '0px',marginTop:'-1px'
                                                }} /> */}
                                          </TwitterShareButton>
                                          <EmailShareButton
                                            url={
                                              "https://paropakari.com/#/ResponsePage/" +
                                              this.props.match.params.id
                                            }
                                            quote="SS"
                                            hashtag="#Be a Paropakari"
                                          >
                                            <EmailIcon
                                              logoFillColor="white"
                                              style={{ width: "32px" }}
                                            />
                                          </EmailShareButton>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <ul className="nav navbar-nav post-nav">
                
                  <li><a>
                    
                    
                    
                    
                     <form >
 


  <input type="hidden" name= "id" onChange={this.handlebenfChange} id="id" defaultValue={emp.upload_id}/>   

  <button type="submit" className="fa" style={{display:'contents',backgroundColor:'rgb(174, 230, 219)',fontsize:'inherit',
  textRendering:'auto',WebkitFontSmoothing:'antialiased',transform:'translate(0, 0)',fontFamily:'cuyabra'}}>  </button>


{(() => {if (emp.likes == 0) {return (

<img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/like.png"  className="fa" style={{display:'inline-block',fontsize:'inherit',textRendering:'auto',WebkitFontSmoothing:'antialiased',transform:'translate(0, 0)',height:"20px",width:"25px",border:'none'}}/>
);
}
                 else {return(

<img onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} src="https://paropakari.com/Images/liked.png"  className="fa" style={{display:'inline-block',fontsize:'inherit',textRendering:'auto',WebkitFontSmoothing:'antialiased',transform:'translate(0, 0)',height:"20px",width:"25px",border:'none',outline:'none'}}/>     );}


                 
                 })()}
                  
{like.map((likes) => 
(
            
<h5 className="popup"><b> 
<div className="spacee">
{likes.likes}
</div>  
 </b>

 <div>  
    <Likepopup Translated={likess.result? likess.result:'Like'} Id={this.props.match.params.id}> </Likepopup>
 </div> 

</h5>         

))}



</form>
</a></li>

                  <li><i className="fa fa-comments" /><a style={{marginTop: '-18%',marginLeft: '22px'}}>{emp.comments}<Comment Translated={comment.result?comment.result:'Comments'}></Comment> </a></li>
    
 
                
                </ul> */}
                        </div>
                      </div>

                      // </tr>
                    ))}
                  </div>
                </div>
                <div className="">
                  <div className="col-md-5 col-sm-5">
                    <div className="sidebar blog-sidebar">
                      <div
                        className="sidebar-item  recent"
                        style={{ color: "black" }}
                      >
                        {/* <h3></h3> */}

                        <div className="media">
                          {emps.map((emp) => (
                            <div className="">
                              {/* ngRepeat: par in Paropakaries */}
                              <div
                                ng-repeat="par in Paropakaries"
                                className="ng-scope"
                                style={{ marginLeft: "2%" }}
                              >
                                {(() => {
                                  if (emp.causefor_Id == "0") {
                                    return (
                                      <p className="notranslate"
                                        style={{
                                          paddingBottom: 5,
                                          fontSize: 15,
                                          paddingTop: 5,
                                          color: "black",
                                        }}
                                      >
                                        {HappinessMeter.result
                                          ? HappinessMeter.result
                                          : "Happiness Meter"}
                                        &nbsp;&nbsp;&nbsp;
                                        {like.map((likes) => (
                                          <a
                                            style={{
                                              fontSize: 12,
                                              color: "black",
                                            }}
                                            className="ng-binding"
                                          >
                                            <img
                                              src="./assets/images/Achieved.png"
                                              className="beone"
                                              style={{
                                                width: "1.5%",
                                                height: "5%",
                                              }}
                                            />
                                            &nbsp;
                                            {Achieved.result
                                              ? Achieved.result
                                              : "Achieved"}
                                            &nbsp;
                                            {likes.confirmed1}%
                                          </a>
                                        ))}
                                        {/* &nbsp;&nbsp;
                                    {like.map((likes) => (
                                    <a style={{ fontSize: 12, color: 'black' }} className="ng-binding">
                                                                      <img src="https://paropakari.com/Images/gray.png" className="beone" style={{ width: '1.5%', height: '5%' }} />&nbsp;
                                                                     {OnItsWay.result?OnItsWay.result:'Anticipated'} &nbsp;
                                                                    
                                         {likes.pending1}%
                                            
                                         
                                    </a>
                                    ))} */}
                                      </p>
                                    );
                                  } else if (emp.causefor_Id == "1") {
                                    return (
                                      <p className="notranslate"
                                        style={{
                                          paddingBottom: 5,
                                          fontSize: 15,
                                          paddingTop: 5,
                                          color: "black",
                                        }}
                                      >
                                        {HappinessMeter.result
                                          ? HappinessMeter.result
                                          : "Happiness Meter"}
                                        &nbsp;&nbsp;&nbsp;
                                        {like.map((likes) => (
                                          <a
                                            style={{
                                              fontSize: 12,
                                              color: "black",
                                            }}
                                            className="ng-binding"
                                          >
                                            <img
                                              src="./assets/images/Achieved.png"
                                              className="beone"
                                              style={{
                                                width: "1.5%",
                                                height: "5%",
                                              }}
                                            />
                                            &nbsp;
                                            {Achieved.result
                                              ? Achieved.result
                                              : "Achieved"}
                                            &nbsp;
                                            {likes.confirmed}%
                                          </a>
                                        ))}
                                        &nbsp;&nbsp;
                                        {like.map((likes) => (
                                          <a
                                            style={{
                                              fontSize: 12,
                                              color: "black",
                                            }}
                                            className="ng-binding"
                                          >
                                            <img
                                              src="https://paropakari.com/Images/gray.png"
                                              className="beone"
                                              style={{
                                                width: "1.5%",
                                                height: "5%",
                                              }}
                                            />
                                            &nbsp;
                                            {OnItsWay.result
                                              ? OnItsWay.result
                                              : "Anticipated"}{" "}
                                            &nbsp;
                                            {likes.pending}%
                                          </a>
                                        ))}
                                        &nbsp;&nbsp;
                                      </p>
                                    );
                                  }
                                })()}

                                {/* ngIf: par.confirmed < 100 */}
                                <div
                                  ng-if="par.confirmed < 100"
                                  style={{ marginTop: 24 }}
                                  className="ng-scope"
                                ></div>

                                {/* end ngIf: par.confirmed < 100 */}
                                {/* ngIf: par.confirmed >= 100 */}
                                {/* ngIf: par.confirmed < 100 */}

                                {(() => {
                                  if (emp.causefor_Id == "0") {
                                    return (
                                      <div>
                                        {like.map((likes) => (
                                          <div>
                                            {(() => {
                                              if (likes.confirmed1 < 100) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    {(() => {
                                                      if (
                                                        likes.confirmed1 < 100
                                                      ) {
                                                        return (
                                                          <div>
                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.confirmed1 +
                                                                  "%",
                                                                marginLeft:
                                                                  "-2px",
                                                                backgroundColor:
                                                                  "#6a6666",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "97%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                            {/* <div className="progress1" role="progressbar" style={{ width: likes.pending+'%', marginLeft:"-2px" }}>
    
                                                            </div> */}
                                                            <br />
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.confirmed1 +
                                                                "%",
                                                              marginLeft:
                                                                "-2px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      }
                                                    })()}

                                                    {(() => {
                                                      if (
                                                        likes.pending1 >= 100 &&
                                                        likes.confirmed1 < 100
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "0%",
                                                              backgroundColor:
                                                                "#6a6666",
                                                            }}
                                                          ></div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending1 +
                                                                "%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })()}
                                                    {(() => {
                                                      if (
                                                        likes.pending1 != 0 &&
                                                        likes.confirmed1 == 0
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "100%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          >
                                                            <div
                                                              className="progress-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.pending1 +
                                                                  "%",
                                                                backgroundColor:
                                                                  "gray",
                                                              }}
                                                            ></div>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending1 +
                                                                "%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })()}
                                                    {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
   <b className="ng-binding"></b>
  </div> */}
                                                  </div>
                                                );
                                              } else if (
                                                likes.confirmed1 >= 100
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "blue",
                                                    }}
                                                  >
                                                    {(() => {
                                                      if (
                                                        likes.confirmed1 >= 100
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "100%",
                                                              marginLeft:
                                                                "-2px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.confirmed1 +
                                                                "%",
                                                              marginLeft:
                                                                "-2px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      }
                                                    })()}

                                                    {/* {(() => {if (likes.confirmed < 100) {return (
  <div className="progress1-done" role="progressbar" style={{ width: '0%' }}>
  </div>

);
}
                 else {return(

                  <div className="progress-done" role="progressbar" style={{ width: likes.pending +'%',backgroundColor:'#6a6666' }}>
  </div>

);}

})()} */}

                                                    {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
   <b className="ng-binding"></b>
  </div> */}
                                                  </div>
                                                );
                                              }
                                            })()}

                                            {/* <div ng-if="par.confirmed >= 100" class="progress" style="width:100%;margin-bottom:5px;">
                        <div class="w3-blue" role="progressbar" width="{{par.confirmed}}%" ng-if="par.confirmed >= 100" style="width:100%;">
                            <i style='font-size:14px;margin-left:96.5%;color:purple' class='far blink_me'>&#x1F642;</i>
                            @*{{par.confirmed}}%*@
                        </div>
                        <div class="w3-gray" role="progressbar" width="{{par.pending}}%" ng-if="par.confirmed >= 100" style="width:0%;">
                        </div>
                        <div class="w3-light-gray" role="progressbar" width="{{100 - (par.confirmed + par.pending)}}%" ng-if="par.confirmed >= 100" style="width:0%">
                        </div>
                    </div> */}
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  } else if (emp.causefor_Id == "1") {
                                    return (
                                      <div>
                                        {like.map((likes) => (
                                          <div>
                                            {(() => {
                                              if (
                                                likes.confirmed < 100 &&
                                                likes.pending < 100 &&
                                                likes.confirmed +
                                                  likes.pending <
                                                  100
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    {(() => {
                                                      if (
                                                        likes.confirmed < 100
                                                      ) {
                                                        return (
                                                          <div>
                                                            {likes.pending <=
                                                              100 &&
                                                            likes.confirmed <
                                                              100 ? (
                                                              <div
                                                                className="progress1-done"
                                                                role="progressbar"
                                                                style={{
                                                                  width:
                                                                    (likes.pending >=
                                                                    1
                                                                      ? likes.pending +
                                                                        3
                                                                      : likes.pending) +
                                                                    "%",
                                                                  background:
                                                                    "#6a6666",
                                                                  marginLeft:
                                                                    (likes.pending >=
                                                                    1
                                                                      ? likes.confirmed -
                                                                        3
                                                                      : likes.pending) +
                                                                    "%",
                                                                  position:
                                                                    "absolute",
                                                                  boxShadow:
                                                                    "0 3px 3px -5px gray, 0 2px 5px gray",
                                                                  borderTopRightRadius:
                                                                    "32px",
                                                                  borderBottomRightRadius:
                                                                    "32px",
                                                                }}
                                                              ></div>
                                                            ) : null}
                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.confirmed +
                                                                  "%",
                                                                backgroundColor:
                                                                  "#6a6666",
                                                                position:
                                                                  "absolute",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "100%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                            {/* <div className="progress1" role="progressbar" style={{ width: likes.pending+'%', marginLeft:"-2px" }}>

</div> */}
                                                            <br />
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "100%",
                                                              marginLeft:
                                                                "-2px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      }
                                                    })()}

                                                    {(() => {
                                                      if (
                                                        likes.pending >= 100 &&
                                                        likes.confirmed < 100
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending +
                                                                "%",
                                                              background:
                                                                "#6a6666",
                                                            }}
                                                          ></div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending +
                                                                "%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })()}
                                                    {(() => {
                                                      if (
                                                        likes.pending != 0 &&
                                                        likes.confirmed == 0
                                                      ) {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width: "100%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          >
                                                            <div
                                                              className="progress-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.pending +
                                                                  "%",
                                                                backgroundColor:
                                                                  "gray",
                                                              }}
                                                            ></div>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.pending +
                                                                "%",
                                                              backgroundColor:
                                                                "white",
                                                            }}
                                                          ></div>
                                                        );
                                                      }
                                                    })()}
                                                    {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
<b className="ng-binding"></b>
</div> */}
                                                  </div>
                                                );
                                              }
                                              //                                             else if (likes.confirmed >= 100 || likes.pending >= 100) {
                                              //                                               return (

                                              //                                                 <div className="progress1" style={{ width: '100%', marginBottom: 0, backgroundColor: 'White'}}>

                                              //                                                   {(() => {
                                              //                                                     if (likes.pending >= 100) {
                                              //                                                       return (

                                              //                                                         <div>

                                              // {(likes.pending >= 100 && likes.confirmed < 100)?  <div  className="progress1-done" role="progressbar"
                                              //                                                             style={{ width: 100-likes.confirmed +'%', background: '#6a6666',marginLeft:likes.confirmed+ '%' ,position:"absolute", boxShadow:"0 3px 3px -5px gray, 0 2px 5px gray"}}>
                                              //                                                             </div>:null}
                                              //                                                         <div className="progress1-done" role="progressbar" style={{ width: likes.confirmed +'%', marginLeft: "-2px"}}>
                                              //                                                           <span style={{ fontSize: 14, marginLeft: '97%', color: 'purple' }} className="far blink_me">🙂</span>
                                              //                                                         </div>
                                              //                                                         </div>
                                              //                                                       );
                                              //                                                     }
                                              //                                                     else {
                                              //                                                       return (
                                              //                                                         <div className="progress1-done" role="progressbar" style={{ width: likes.confirmed + '%', marginLeft: "-2px" }}>
                                              //                                                           <span style={{ fontSize: 14, marginLeft: '97%', color: 'purple' }} className="far blink_me">🙂</span>
                                              //                                                         </div>
                                              //                                                       );
                                              //                                                     }

                                              //                                                   })()}

                                              //                                                   {/* {(() => {if (likes.confirmed < 100) {return (
                                              // <div className="progress1-done" role="progressbar" style={{ width: '0%' }}>
                                              // </div>

                                              // );
                                              // }
                                              // else {return(

                                              // <div className="progress-done" role="progressbar" style={{ width: likes.pending +'%',backgroundColor:'#6a6666' }}>
                                              // </div>

                                              // );}

                                              // })()} */}
                                              //                                                   {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
                                              // <b className="ng-binding"></b>
                                              // </div> */}

                                              //                                                 </div>
                                              //                                               );
                                              //                                             }
                                              else if (
                                                likes.confirmed +
                                                  likes.pending >=
                                                100
                                              ) {
                                                return (
                                                  <div
                                                    className="progress1"
                                                    style={{
                                                      width: "100%",
                                                      marginBottom: 0,
                                                      backgroundColor: "White",
                                                      borderRadius: "20px",
                                                    }}
                                                  >
                                                    {(() => {
                                                      if (
                                                        likes.confirmed >= 100
                                                      ) {
                                                        return (
                                                          <div>
                                                            {/* 
 <div  className="progress1-done" role="progressbar" 
                                                            style={{ width: 100-likes.confirmed +'%', background: '#6a6666',marginLeft:likes.confirmed+ '%' ,position:"absolute", boxShadow:"0 3px 3px -5px gray, 0 2px 5px gray"}}> 
                                                            </div> */}

                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width: "100%",
                                                                marginLeft:
                                                                  "-2px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "97%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      } else if (
                                                        likes.confirmed +
                                                          likes.pending >=
                                                        100
                                                      ) {
                                                        return (
                                                          <div>
                                                            {likes.confirmed +
                                                              likes.pending >=
                                                              100 &&
                                                            likes.confirmed ==
                                                              0 ? (
                                                              <div
                                                                className="progress1-done"
                                                                role="progressbar"
                                                                style={{
                                                                  width:
                                                                    100 -
                                                                    likes.confirmed +
                                                                    "%",
                                                                  background:
                                                                    "#6a6666",
                                                                  marginLeft:
                                                                    likes.confirmed -
                                                                    3 +
                                                                    "%",
                                                                  position:
                                                                    "absolute",
                                                                  boxShadow:
                                                                    "0 3px 3px -5px gray, 0 2px 5px gray",
                                                                  borderTopLeftRadius:
                                                                    "20px",
                                                                  borderBottomLeftRadius:
                                                                    "20px",
                                                                }}
                                                              ></div>
                                                            ) : likes.confirmed +
                                                                likes.pending >=
                                                                100 &&
                                                              likes.confirmed >=
                                                                0 ? (
                                                              <div
                                                                className="progress1-done"
                                                                role="progressbar"
                                                                style={{
                                                                  width:
                                                                    100 -
                                                                    likes.confirmed +
                                                                    "%",
                                                                  background:
                                                                    "#6a6666",
                                                                  marginLeft:
                                                                    likes.confirmed +
                                                                    "%",
                                                                  position:
                                                                    "absolute",
                                                                  boxShadow:
                                                                    "0 3px 3px -5px gray, 0 2px 5px gray",
                                                                  borderTopLeftRadius:
                                                                    "0px",
                                                                  borderBottomLeftRadius:
                                                                    "0px",
                                                                }}
                                                              ></div>
                                                            ) : null}

                                                            <div
                                                              className="progress1-done"
                                                              role="progressbar"
                                                              style={{
                                                                width:
                                                                  likes.confirmed +
                                                                  "%",
                                                                marginLeft:
                                                                  "-2px",
                                                                borderTopRightRadius:
                                                                  "0px",
                                                                borderBottomRightRadius:
                                                                  "0px",
                                                              }}
                                                            >
                                                              <span
                                                                style={{
                                                                  fontSize: 14,
                                                                  marginLeft:
                                                                    "97%",
                                                                  color:
                                                                    "purple",
                                                                }}
                                                                className="far blink_me"
                                                              >
                                                                🙂
                                                              </span>
                                                            </div>
                                                          </div>
                                                        );
                                                      } else {
                                                        return (
                                                          <div
                                                            className="progress1-done"
                                                            role="progressbar"
                                                            style={{
                                                              width:
                                                                likes.confirmed +
                                                                "%",
                                                              marginLeft:
                                                                "-2px",
                                                              borderTopRightRadius:
                                                                "0px",
                                                              borderBottomRightRadius:
                                                                "0px",
                                                            }}
                                                          >
                                                            <span
                                                              style={{
                                                                fontSize: 14,
                                                                marginLeft:
                                                                  "97%",
                                                                color: "purple",
                                                              }}
                                                              className="far blink_me"
                                                            >
                                                              🙂
                                                            </span>
                                                          </div>
                                                        );
                                                      }
                                                    })()}

                                                    {/* {(() => {if (likes.confirmed < 100) {return (
<div className="progress1-done" role="progressbar" style={{ width: '0%' }}>
</div>

);
}
else {return(

<div className="progress-done" role="progressbar" style={{ width: likes.pending +'%',backgroundColor:'#6a6666' }}>
</div>

);}

})()} */}
                                                    {/* <div className="w3-light-gray" role="progressbar" style={{ width: 100 - (likes.confirmed + likes.pending) +'%',backgroundColor:'#6a6666' }}>
<b className="ng-binding"></b>
</div> */}
                                                  </div>
                                                );
                                              }
                                            })()}

                                            {/* <div ng-if="par.confirmed >= 100" class="progress" style="width:100%;margin-bottom:5px;">
<div class="w3-blue" role="progressbar" width="{{par.confirmed}}%" ng-if="par.confirmed >= 100" style="width:100%;">
<i style='font-size:14px;margin-left:96.5%;color:purple' class='far blink_me'>&#x1F642;</i>
@*{{par.confirmed}}%*@
</div>
<div class="w3-gray" role="progressbar" width="{{par.pending}}%" ng-if="par.confirmed >= 100" style="width:0%;">
</div>
<div class="w3-light-gray" role="progressbar" width="{{100 - (par.confirmed + par.pending)}}%" ng-if="par.confirmed >= 100" style="width:0%">
</div>
</div> */}
                                          </div>
                                        ))}
                                      </div>
                                    );
                                  }
                                })()}

                                {/* end ngIf: par.confirmed < 100 */}
                                {/* ngIf: par.confirmed >= 100 */}
                                {/* <br /> */}

                                {like.map(
                                  (likes) =>
                                    // <div class="row paropakaris">
                                    (() => {
                                      if (langID === "Tamil") {
                                        return (
                                          <h5
                                            className="popup"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginLeft: "65%",
                                            }}
                                          >
                                            <b>
                                              {(() => {
                                                if (emp.causefor_Id == "1") {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris1}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </b>
                                            {/* &nbsp;&nbsp; */}
                                            <Popup
                                              Translatedword={
                                                paropakariss.result
                                                  ? paropakariss.result
                                                  : "Paropakaris"
                                              }
                                              Id={this.props.match.params.id}
                                              style={{ marginTop: "-18px" }}
                                            >
                                              {" "}
                                            </Popup>
                                          </h5>
                                        );
                                      } else if (langID === "Telugu") {
                                        return (
                                          <h5
                                            className="popup"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginLeft: "73%",
                                            }}
                                          >
                                            <b>
                                              {(() => {
                                                if (emp.causefor_Id == "1") {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris1}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </b>
                                            {/* &nbsp;&nbsp; */}
                                            <Popup
                                              Translatedword={
                                                paropakariss.result
                                                  ? paropakariss.result
                                                  : "Paropakaris"
                                              }
                                              Id={this.props.match.params.id}
                                              style={{ marginTop: "-18px" }}
                                            >
                                              {" "}
                                            </Popup>
                                          </h5>
                                        );
                                      } else if (langID === "Spanish") {
                                        return (
                                          <h5
                                            className="popup"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginLeft: "71%",
                                            }}
                                          >
                                            <b>
                                              {(() => {
                                                if (emp.causefor_Id == "1") {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris1}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </b>
                                            {/* &nbsp;&nbsp; */}
                                            <Popup
                                              Translatedword={
                                                paropakariss.result
                                                  ? paropakariss.result
                                                  : "Paropakaris"
                                              }
                                              Id={this.props.match.params.id}
                                              style={{ marginTop: "-18px" }}
                                            >
                                              {" "}
                                            </Popup>
                                          </h5>
                                        );
                                      } else if (langID === "Kannada") {
                                        return (
                                          <h5
                                            className="popup"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginLeft: "61%",
                                            }}
                                          >
                                            <b>
                                              {(() => {
                                                if (emp.causefor_Id == "1") {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris1}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </b>
                                            {/* &nbsp;&nbsp; */}
                                            <Popup
                                              Translatedword={
                                                paropakariss.result
                                                  ? paropakariss.result
                                                  : "Paropakaris"
                                              }
                                              Id={this.props.match.params.id}
                                              style={{ marginTop: "-18px" }}
                                            >
                                              {" "}
                                            </Popup>
                                          </h5>
                                        );
                                      } else {
                                        return (
                                          <h5
                                            className="popup"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              marginLeft: "70%",
                                            }}
                                          >
                                            <b>
                                              {(() => {
                                                if (emp.causefor_Id == "1") {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris}
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className="space">
                                                      {likes.paropakaris1}
                                                    </div>
                                                  );
                                                }
                                              })()}
                                            </b>
                                            {/* &nbsp;&nbsp; */}
                                            <Popup
                                              Translatedword={
                                                paropakariss.result
                                                  ? paropakariss.result
                                                  : "Paropakaris"
                                              }
                                              Id={this.props.match.params.id}
                                              style={{ marginTop: "-18px" }}
                                            >
                                              {" "}
                                            </Popup>
                                          </h5>
                                        );
                                      }
                                    })()
                                  // </div>
                                )}
                              </div>
                              {/* end ngRepeat: par in Paropakaries */}
                              <br />
                              {/* ngIf: Post.user_id  == 'f9c77357-f20c-4714-894b-93a20a72fb40' && Post.relationship =='Self' */}
                              {/* ngIf: Post.user_id  != 'f9c77357-f20c-4714-894b-93a20a72fb40' || Post.relationship !='Self' */}
                              <div
                                ng-if="Post.user_id  != 'f9c77357-f20c-4714-894b-93a20a72fb40' || Post.relationship !='Self'"
                                className="ng-scope notranslate"
                              >
                                <br />
                              </div>
                              {/* end ngIf: Post.user_id  != 'f9c77357-f20c-4714-894b-93a20a72fb40' || Post.relationship !='Self' */}
                              <div></div>
                            </div>
                          ))}
                          {/* <div className="media-body">
                </div>
                 */}
                        </div>
                        {emps.map((emp) => (
                          <form onSubmit={this.Createcontact}>
                            {(() => {
                              if (
                                emp.causefor_Id == "0" &&
                                emp.profile_name == username
                              ) {
                                return (
                                  <div>
                                    {clm.map((emp1) => (
                                      <p>
                                        {(() => {
                                          if (
                                            emp1.payOutStatus === "processed"
                                          ) {
                                            return (
                                              <div>
                                                <input
                                                  type="hidden"
                                                  id="claimamount"
                                                  name="claimamount"
                                                  value={emp1.claimAmount}
                                                />
                                                Congratulations!! Your Cause is
                                                closed. {emp1.claimAmount} has
                                                credited to beneficiary. Need
                                                Help ? Report
                                              </div>
                                            );
                                          } else if (
                                            emp1.payOutStatus === "processing"
                                          ) {
                                            return (
                                              <div>
                                                Congratulations!! You have
                                                claimed {emp1.claimAmount} for
                                                the {emp.upload_title}{" "}
                                                successfully. You claim money
                                                will be credited to beneficiary
                                                within 24 hrs
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div>
                                                Your cause has received Rs&nbsp;
                                                {emp1.claimAmount} of support
                                                {/* {emp1.payOutStatus} */}
                                                <input
                                                  type="hidden"
                                                  name="claimamount"
                                                  id="claimamount"
                                                  value={emp1.claimAmount}
                                                  className="form-control"
                                                />
                                                <input
                                                  type="hidden"
                                                  name="profile_name"
                                                  id="profile_name"
                                                  defaultValue={
                                                    emp.profile_name
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  name="name"
                                                  id="name"
                                                  defaultValue={
                                                    emp.profile_name
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  name="contact"
                                                  defaultValue={
                                                    emp.profile_mobileNumber
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  name="email"
                                                  defaultValue={
                                                    emp.profile_email
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  name="name"
                                                  id="upload_BankAccount"
                                                  defaultValue={
                                                    emp.upload_BankAccount
                                                  }
                                                />
                                                <input
                                                  type="hidden"
                                                  name="name"
                                                  id="upload_IFSC"
                                                  defaultValue={emp.upload_IFSC}
                                                />
                                                {(() => {
                                                  if (emp1.claimAmount != "0") {
                                                    return (
                                                      <div>
                                                        <button
                                                          type="submit"
                                                          className="btn btn-primary"
                                                          style={{
                                                            color: "white",
                                                            backgroundColor:
                                                              "rgb(102, 71, 18)",
                                                          }}
                                                        >
                                                          <b>&nbsp;Claim</b>
                                                        </button>
                                                      </div>
                                                    );
                                                  }
                                                })()}
                                              </div>
                                            );
                                          }
                                        })()}
                                      </p>
                                    ))}
                                  </div>
                                );
                              }
                            })()}
                          </form>
                        ))}
                      </div>
                      {/* <div className="sidebar-item categories" style={{ float: 'right', position: 'fixed' }}>

                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{ width: "100%", overflowX: "auto" }}
                >
                  {emps.map((emp) => (
                    <table
                      className="table table-bordered"
                      style={{
                        borderLeftColor: "white",
                        borderRightColor: "white",
                        width: "100%",
                        borderCollapse: "collapse",
                        border: "1px solid #ddd",
                      }}
                    >
                      {(() => {
                        if (
                          emp.causefor_Id == "1" &&
                          emp.profile_name == username
                        ) {
                          return (
                            <tbody>
                              <tr className="notranslate">
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>{Names.result ? Names.result : "Name"}</b>
                                </td>
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>
                                    {MobileNumbers.result
                                      ? MobileNumbers.result
                                      : "Mobile Number"}
                                  </b>
                                </td>
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>{Email.result ? Email.result : "Email"}</b>
                                </td>
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>
                                    {Pledge.result ? Pledge.result : "Pledge"}
                                  </b>
                                </td>
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>
                                    {Message.result
                                      ? Message.result
                                      : "Message"}
                                  </b>
                                </td>
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>
                                    {Responsedate.result
                                      ? Responsedate.result
                                      : "Response Date"}
                                  </b>
                                </td>
                                <td
                                  style={{
                                    borderLeftColor: "white",
                                    borderRightColor: "white",
                                  }}
                                >
                                  <b>
                                    {Supportconfirm.result
                                      ? Supportconfirm.result
                                      : "Support Confirmation"}
                                  </b>
                                </td>
                              </tr>
                              {resp.map((response) => (
                                <tr
                                  ng-repeat="con in confirmPost"
                                  ng-if="con.confirm_status"
                                >
                                  <td
                                    style={{
                                      borderLeftColor: "gainsboro",
                                      borderRightColor: "gainsboro",
                                    }}
                                  >
                                    {response.profile_name}
                                  </td>
                                  <td
                                    style={{
                                      borderLeftColor: "gainsboro",
                                      borderRightColor: "gainsboro",
                                    }}
                                  >
                                    {response.profile_mobileNumber}
                                  </td>
                                  <td
                                    style={{
                                      borderLeftColor: "gainsboro",
                                      borderRightColor: "gainsboro",
                                    }}
                                  >
                                    {response.profile_email}
                                  </td>
                                  <td
                                    style={{
                                      borderLeftColor: "gainsboro",
                                      borderRightColor: "gainsboro",
                                    }}
                                  >
                                    {response.response_pledge}%
                                  </td>
                                  <td
                                    style={{
                                      borderLeftColor: "gainsboro",
                                      borderRightColor: "gainsboro",
                                    }}
                                  >
                                    {response.response_message}
                                  </td>
                                  <td
                                    style={{
                                      borderLeftColor: "gainsboro",
                                      borderRightColor: "gainsboro",
                                    }}
                                  >
                                    {response.created_date}
                                  </td>
                                  <td
                                    ng-if="con.confirm_status"
                                    style={{
                                      borderLeftColor: "white",
                                      borderRightColor: "white",
                                    }}
                                  >
                                    {(() => {
                                      if (
                                        response.confirm_status == 1 &&
                                        response.recieve_status == 1
                                      ) {
                                        return <div className="notranslate">{confirmed.result}</div>;
                                      } else {
                                        return (
                                          <div>
                                            {/* <p ng-if="Post.status_80G == '0'">You are responsible for providing any documents to supporter {response.profile_name}</p>  */}
                                            {/* <p ng-if="Post.status_80G == '0'">{ihaveconfirm.result}</p>  */}
                                            <p className="notranslate"> 
                                              <a
                                                onClick={async () => {
                                                  await this.handleYes(
                                                    response.response_id
                                                  );
                                                }}
                                                className="btn btn-dark notranslate"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(102, 71, 18)",
                                                  color: "white",
                                                }}
                                              >
                                                {ihavercvd.result}
                                                {response.confirm_status}
                                              </a>
                                              <a
                                                onClick={async () => {
                                                  await this.handleNo(
                                                    response.response_id
                                                  );
                                                }}
                                                className="btn btn-dark notranslate"
                                                style={{
                                                  backgroundColor:
                                                    "rgb(102, 71, 18)",
                                                  color: "white",
                                                }}
                                              >
                                                {ihaventrcvd.result}
                                                {response.recieve_status}
                                              </a>
                                              
                                            </p>
                                          </div>
                                        );
                                      }
                                    })()}
                                  </td>

                                 
                                </tr>
                              ))}
                             
                            </tbody>
                          );
                        }
                      })()}
                    </table>
                  ))}
                </div>
              </div>
            </div>
          </section>
          {/*/#blog*/}
          <footer
            id="footer"
            style={{ color: "white" }}
            className="notranslate"
          >
            <br />
            <div className="container">
              <div className="copyright">
                <div className="row col-lg-12">
                  <div className="col-lg-9 mb-6">
                    {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                    <p style={{ marginLeft: "45px" }}>
                      {copyright_new.result} &nbsp;{currentYear} ©{" "}
                      {paropakari_new.result} |&nbsp;
                      <a
                        href="#/Contactuslogin"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {contactus.result}&nbsp;
                      </a>
                      |{" "}
                      <a
                        href="#/PrivacyPolicyLogin"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        &nbsp;{privacypolicy.result}&nbsp;
                      </a>
                      |
                      <a
                        href="#/TermsandConditionLogin"
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        &nbsp;{termsandcondition.result}
                      </a>
                    </p>
                  </div>
                  <div className="col-lg-3 mb-3">
                    <div
                      className="social-icons"
                      style={{ color: "white", marginLeft: "72px" }}
                    >
                      <ul className="nav nav-pills">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/beoneparopakari"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                        <li>
                          <a
                            target="_blank"
                            href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                          >
                            <i className="fa fa-youtube-play" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.instagram.com/paropakari_beone/"
                          >
                            <i className="fa fa-instagram" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://www.linkedin.com/company/paropakari/"
                          >
                            <i className="fa fa-linkedin" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
          {/*/#footer*/}
        </div>
      </div>
    );
  }
}
