import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import Cookies from "js-cookie";
import LogoutLink from "./LogoutLink";
import { BASE_URLs } from "../Constants/BaseURL";
import Script from "react-load-script";
import { Phone } from "@material-ui/icons";

// const refreshPage = () => {

//   setTimeout(() => window.location.reload(), 4000);

// }

export default class ProfilePageOTP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // id : this.props.match.params.id,
      pno: "",
      pswd: "",
      foo: [],
      count: 0,
      Details: [],
      newArray: [],
      otpNumberverify: [],
      ourstory: [],
      howitworks: [],
      beaparopakari: [],
      whatwedo: [],
      blog: [],
      login: [],
      OtpSentMobile: [],
      indiaexpensless: [],
      oppertunitybringsmile: [],
      Passwordresend5timesonly: [],
      contactus: [],
      termsandcondition: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      otpNumber: [],
      whatwedodesc: [],
      welcome: [],
      beaparopakari: [],
      myjourney: [],
      createacause: [],
      Sorry: [],
      OTP: [],
      notVerify: [],
      invite: [],
      profile: [],
      changepassword: [],
      group: [],
      logout: [],
      privacypolicy: [],
      usernames: [],
      passwords: [],
      forgotpassword: [],
      signin: [],
      donthaveacc: [],
      signup: [],
      entercaptchvalue: [],
      enter: [],
      langID: [],
      captchavalue: [],
      or: [],
      friends: [],
      emails: [],
      notify: [],
      foo: [],
      mobilenumber: [],
      privacypolicy: [],
      pleaseverifyotp: [],
      otpresentsuccess: [],
      otpverified: [],
      otpnotverified: [],
      submit: [],
      captchadoesnotmatch: [],
      resendotp: [],
      enterotp: [],
      otprequired: [],
      Captchisnotmatch: [],
      movedown: true,
    };
    this.initialState = this.state;
    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
    // this.reSubmitotp= this.reSubmitotp;
  }

  refreshPage = (e) => {
    e.preventDefault();
    var banner = document.getElementById("google_translate_element");
    var name = document
      .getElementsByClassName("VIpgJd-ZVi9od-xl07Ob-lTBxed")[0]
      .innerText.replace("▼", "");
    let oldName = sessionStorage.getItem("CurrentLang");

    oldName =
      oldName == null || oldName == "Select Language" ? "English" : oldName;
  };

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  // reSubmitotp= async event => {
  //     event.preventDefault();
  //  const data = new FormData(event.target);
  //  let Phonenumber = JSON.parse(localStorage.getItem('Phonenumber'));

  //             const url ="https://apitest.paropakari.com/api/Otp/Register";
  //             const options = {
  //                 method: "POST",
  // headers:{

  //     Accept: 'application/json'

  // },
  // body: data,
  // };
  // this.setState(this.initialState)
  // const response = await fetch(url, options);
  // //const result = await response.json();
  // if (response.ok)
  // {
  //       alert('OTP Resend successfully!')

  // }
  // this.setState(this.initialState)
  //
  // }
  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    localStorage.clear();
    window.location.href = "#/Login";
  };

  // decipher = (salt) => {
  //   const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
  //   const applySaltToChar = (code) =>
  //     textToChars(salt).reduce((a, b) => a ^ b, code);
  //   console.log("textToChars", textToChars);
  //   console.log("applySaltToChar", applySaltToChar);
  //   return (encoded) => //console.log(encoded);
  //   encoded.match(/.{1,2}/g)
  //   .map((hex) => parseInt(hex, 16))
  //   .map(applySaltToChar)
  //   .map((charCode) => String.fromCharCode(charCode))
  //   .join('');
  // };

  decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (encoded) => {
      if (!encoded) {
        throw new Error("Encoded string cannot be null or undefined");
      }

      return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
    };
  };

  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  CheckConfirm() {
    //alert("called");

    let Phonenumber = JSON.parse(localStorage.getItem("Phonenumber"));
    let Email = JSON.parse(localStorage.getItem("Email"));

    if (Email != null) {
      let url =
        "https://apitest.paropakari.com/api/Otp/ConfirmEmailorMobile?EmailOrMobile=" +
        Email;

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            GroupList: data,
            allPosts: data,
          });

          //
          localStorage.setItem(
            "EmailConfirm",
            JSON.stringify(data[0].emailConfirmed)
          );

          localStorage.setItem(
            "PhoneConfirm",
            JSON.stringify(data[0].phoneNumberConfirmed)
          );
          let EmailConfirm = JSON.parse(localStorage.getItem("EmailConfirm"));
          let PhoneConfirm = JSON.parse(localStorage.getItem("PhoneConfirm"));

          if (EmailConfirm == true || PhoneConfirm == true) {
            var page = {
              pathname: "Login",
              pno: this.state.pno,
            };
            this.props.history.push(page);
          } else {
            alert(this.state.pleaseverifyotp.result);
          }
        });
    }
    if (Phonenumber != null) {
      let url =
        "https://apitest.paropakari.com/api/Otp/ConfirmEmailorMobile?EmailOrMobile=" +
        Phonenumber;

      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
        //body:JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            GroupList: data,
            allPosts: data,
          });

          localStorage.setItem(
            "EmailConfirm",
            JSON.stringify(data[0].emailConfirmed)
          );

          localStorage.setItem(
            "PhoneConfirm",
            JSON.stringify(data[0].phoneNumberConfirmed)
          );
          let EmailConfirm = JSON.parse(localStorage.getItem("EmailConfirm"));
          let PhoneConfirm = JSON.parse(localStorage.getItem("PhoneConfirm"));

          if (EmailConfirm == true || PhoneConfirm == true) {
            var page = {
              pathname: "Login",
              pno: this.state.pno,
            };
            this.props.history.push(page);
          } else {
            alert(this.state.pleaseverifyotp.result);
          }
          //
        });
    }
  }

  ReloadPage = () => {
    // let reloads=sessionStorage.getItem('reloadlogin');
    //  // setTimeout(() => window.location.reload(), 2000);
    //   if(sessionStorage.getItem('reloadlogin') !== null ){
    //
    //     reloads= sessionStorage.getItem('reloadlogin');
    //     reloads++;
    //     window.sessionStorage.setItem('reloadlogin', (reloads));
    //
    //   }
    //   else{
    //     reloads=1;
    //     window.sessionStorage.setItem('reloadlogin', (reloads));
    //
    //   }

    //   if(reloads ==1)
    //   {
    //     reloads= sessionStorage.getItem('reloadlogin');
    //
    //     window.location.reload();
    //   }
    let reloads = 0;
    // setTimeout(() => window.location.reload(), 2000);
    if (sessionStorage.getItem("reloadlogin") !== null) {
      reloads = sessionStorage.getItem("reloadlogin");
      reloads++;
      window.sessionStorage.setItem("reloadlogin", reloads);
    } else {
      reloads = 1;
      window.sessionStorage.setItem("reloadlogin", reloads);
    }

    if (reloads == 1) {
      window.location.reload();
    }
  };
  componentDidUpdate() {
    // Store count in localStorage whenever it changes
    localStorage.setItem("count", this.state.count.toString());
  }
  reSubmitOTP = async (event) => {
    event.preventDefault();

    // const code =this.state.otp
    //

    // let otp = JSON.parse(localStorage.getItem('otp'));
    //

    let user_captcha = document.getElementById("user_captcha_input").value;
    this.setState((prevState) => ({
      count: prevState.count + 1,
    }));

    if (this.state.count >= 5) {
      alert(this.state.Passwordresend5timesonly.result + "!");
      //   document.getElementById("linkid").style.visibility = "hidden";
      document.getElementById("linkid").disabled = true;
      // anchor.setAttribute("class", "disabled");

      document.getElementById("linkid").style.pointerEvents = "none";
      document.getElementById("linkid").style.cursor = "default";
    } else {
      //   let Email = JSON.parse(localStorage.getItem('Email'));
      //   // alert(Email);
      //
      let Phonenumber = JSON.parse(
        localStorage.getItem("profile_mobileNumber")
      );
      //   if(Email != '')
      //   {
      //     let EmailOrMobile= Email;
      //     const url ="https://apitest.paropakari.com/api/UploadFile/ResendOTP?Email=" +EmailOrMobile ;
      //     const options = {
      //         method: "POST",
      // headers:{

      // Accept: 'application/json',
      // //Authorization: "Bearer " +accessToken
      // },
      // body: "data",
      // };
      //      // this.setState(this.initialState)
      //       const response = await fetch(url, options);
      //       const result = await response.json();
      //       if (result.message=="OTP Resend successfully!") {
      //         alert(this.state.otpresentsuccess.result);
      //         localStorage.setItem('otp',JSON.stringify(result.otp) );
      //       }
      // // if(response.status==500)
      // // {
      // //   // alert("elsecalled");
      // //     let EmailOrMobile= JSON.parse(localStorage.getItem('Phonenumber'));;
      // //     const url ="https://apitest.paropakari.com/api/Otp/ResendOTPMob?Mobile=" +EmailOrMobile ;
      // //     const options = {
      // //         method: "POST",
      // // headers:{

      // // Accept: 'application/json',
      // // //Authorization: "Bearer " +accessToken
      // // },
      // // body: "data",
      // // };
      // //      // this.setState(this.initialState)
      // //       const response = await fetch(url, options);
      // //       const result = await response.json();
      // //
      // //     if (result.message=="OTP Resend successfully!") {
      // //       alert("OTP Resent Successfully!");
      // //       localStorage.setItem('otp',JSON.stringify(result.otp) );

      // //   //     var page={
      // //   //       pathname:'Login',
      // //   //     pno:this.state.pno,

      // //   //   }
      // //   //  this.props.history.push(page)
      // //  // this.CheckConfirm();
      // // }

      // // }
      //   }
      if (Phonenumber != "" || Phonenumber != null) {
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        let EmailOrMobile = JSON.parse(
          localStorage.getItem("profile_mobileNumber")
        );
        const url =
          "https://apitest.paropakari.com/api/UploadFile/LoginResendOTP?EmailOrMobile=" +
          EmailOrMobile;
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: "data",
        };
        // this.setState(this.initialState)
        const response = await fetch(url, options);
        const result = await response.json();

        if (result.message == "OTP Resend successfully!") {
          alert(this.state.otpresentsuccess.result);
          let myCipher = this.cipher("mySecretSalt");
          // localStorage.setItem('otp', JSON.stringify(data.otp));
          localStorage.setItem("pofile_resendOTP", myCipher(result.otp));
          // localStorage.setItem('pofile_resendOTP', JSON.stringify(result.otp));
        }
      }
    }
  };

  onSubmitOTP = async (event) => {
    event.preventDefault();
    const code = this.state.otp;

    let user_captcha = document.getElementById("user_captcha_input").value;
    let otpvalue = document.getElementById("otp").value;

    const myDecipher = this.decipher("mySecretSalt");
    var otp = localStorage.getItem("Profile_OTP");
    if (otp) {
      try {
        const decipheredValue = myDecipher(otp).toString();
        // console.log('Deciphered Value:', decipheredValue); // Debugging line
        otp = decipheredValue;
        // console.log("otp",otp);
      } catch (e) {
        console.error("Failed to parse JSON:", e);
      }
    }
    // console.log("OTP",otp);
    let resendotp = localStorage.getItem("pofile_resendOTP");
    if (resendotp) {
      resendotp = myDecipher(resendotp).toString();
    }
    // else {
    //     console.error("pofile_resendOTP is missing or null");
    //     return;
    // }
    // console.log("resendotp",resendotp);
    if (otpvalue === "" || otpvalue === null) {
      return alert(
        this.state.otprequired.result
          ? this.state.otprequired.result
          : "OTP is required"
      );
    } else if (user_captcha === "" || user_captcha === null) {
      return alert(
        this.state.captchadoesnotmatch.result
          ? this.state.captchadoesnotmatch.result
          : "Please enter valid captcha"
      );
    }

    // console.log("validateCaptcha(user_captcha)", validateCaptcha(user_captcha));

    if (validateCaptcha(user_captcha)) {
      if (code === otp || resendotp === code) {
        let profile_mobileNumber = JSON.parse(
          localStorage.getItem("profile_mobileNumber")
        );
        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = user;

        const url = `https://apitest.paropakari.com/api/UploadFile/profilenumber?profile_mobileNumber=${profile_mobileNumber}`;
        const options = {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        };

        try {
          const response = await fetch(url, options);
          const result = await response.json();

          if (response.ok) {
            alert(this.state.otpNumberverify.result);
            window.location.href = "#/Login";
          } else {
            alert("Error: " + result.message);
          }
        } catch (error) {
          console.error("Fetch error:", error);
          alert("An error occurred. Please try again.");
        }
      } else {
        alert(
          this.state.Sorry.result +
            ", " +
            this.state.OTP.result +
            " " +
            this.state.notVerify.result
        );
      }
    } else {
      alert(
        this.state.captchadoesnotmatch.result
          ? this.state.captchadoesnotmatch.result
          : "Please enter valid captcha"
      );
    }
  };
  Footer() {
    let url =
      "https://apitest.paropakari.com/api/Authenticate/Get_FooterDetails";
    let data = this.state;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          foo: data,
        });

        //
      });
  }

  DefaultMenu() {
    //

    let RefreshID = 0;
    window.sessionStorage.setItem("reload", RefreshID);
    let reloadsmyactivity = 0;
    window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
    window.sessionStorage.setItem("responsepagereload", reloadsmyactivity);
    window.sessionStorage.setItem("notificationpagereload", reloadsmyactivity);
    window.sessionStorage.setItem("Indexreload", reloadsmyactivity);

    let MID;
    //         if(sessionStorage.getItem('transliteration') !== null){
    //           MID= sessionStorage.getItem('transliteration');
    //         }
    // else
    if (sessionStorage.getItem("translate") !== null) {
      MID = sessionStorage.getItem("translate");
    } else {
      MID = "English";
    }

    let datatrans = MID;

    localStorage.setItem("GoogleTranslate123", this.state.googtrans);

    let GID = sessionStorage.getItem("translate");

    let GIDS = JSON.parse(localStorage.getItem("this.state.googtrans"));

    let datatransID = MID;
    if (datatrans == "kn") {
      datatransID = "Kannada";
    } else if (datatrans == "ta") {
      datatransID = "Tamil";
    } else if (datatrans == "te") {
      datatransID = "Telugu";
    } else if (datatrans == "hi") {
      datatransID = "Hindi";
    } else if (datatrans == "es") {
      datatransID = "Spanish";
    } else if (datatrans == MID) {
      datatransID = MID;
    }

    let LnID = MID;
    if (LnID == "Kannada") {
      LnID = "kn";
    } else if (LnID == "Tamil") {
      LnID = "ta";
    } else if (LnID == "Telugu") {
      LnID = "te";
    } else if (LnID == "Hindi") {
      LnID = "hi";
    } else if (LnID == "Spanish") {
      LnID = "es";
    } else if (LnID == MID) {
      LnID = MID;
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Edit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2024 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;

          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var FamilyandFriends =
            typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            langID: LangID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~PrivacyPolicy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition =
            typeof items[8] !== "undefined" ? items[8] : null;

          var PrivacyPolicy = typeof items[9] !== "undefined" ? items[9] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile =
            typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,

            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome~Username~Password~Forgot password~Login~Dont have an account Please&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causes = typeof items[10] !== "undefined" ? items[10] : null;
          var Beneficiaries =
            typeof items[11] !== "undefined" ? items[11] : null;
          var Paropakaris = typeof items[12] !== "undefined" ? items[12] : null;
          var Amountraised =
            typeof items[13] !== "undefined" ? items[13] : null;

          var Welcome = typeof items[14] !== "undefined" ? items[14] : null;
          var Usernames = typeof items[15] !== "undefined" ? items[15] : null;
          var Passwords = typeof items[16] !== "undefined" ? items[16] : null;
          var ForgotPassword =
            typeof items[17] !== "undefined" ? items[17] : null;
          var Signin = typeof items[18] !== "undefined" ? items[18] : null;
          var DonthavAcc = typeof items[19] !== "undefined" ? items[19] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OTP is Verified~Please enter valid captcha&lang=" +
        MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          //  var OTPVerified = typeof items[0] !== "undefined" ? items[0] : null;
          var Captchadoesnotmatch =
            typeof items[0] !== "undefined" ? items[0] : null;
          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            //   otpverified: OTPVerified,
            // otpnotverified:OTPNotVerified,
            captchadoesnotmatch: Captchadoesnotmatch,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Resend OTP~Sorry~OTP~is not verified~Resend OTP will only be sent 5 times~Friends~OTP is required&lang=" +
        MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ResendOTP = typeof items[0] !== "undefined" ? items[0] : null;
          var Passwordresend5timesonly =
            typeof items[4] !== "undefined" ? items[4] : null;

          var Sorry = typeof items[1] !== "undefined" ? items[1] : null;
          var notVerify = typeof items[3] !== "undefined" ? items[3] : null;
          var OTP = typeof items[2] !== "undefined" ? items[2] : null;
          var friends = typeof items[5] !== "undefined" ? items[5] : null;
          var OtpRequired = typeof items[6] !== "undefined" ? items[6] : null;
          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            resendotp: ResendOTP,
            Passwordresend5timesonly: Passwordresend5timesonly,
            notVerify: notVerify,
            Sorry: Sorry,
            friends: friends,
            OTP: OTP,
            langID: LangID,
            otprequired: OtpRequired,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OTP Sent to Your Updated Mobile Number~OTP Number~OTP verified successfully and Updated your mobile number&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var OtpSentMobile = typeof items[0] !== "undefined" ? items[0] : null;
          var otpNumber = typeof items[1] !== "undefined" ? items[1] : null;
          var otpNumberverify =
            typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            OtpSentMobile: OtpSentMobile,
            otpNumber: otpNumber,
            otpNumberverify: otpNumberverify,
            langID: LangID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Please Verify Your OTP~OTP Resent Successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var PleaseVerifyOTP =
            typeof items[8] !== "undefined" ? items[8] : null;
          var OTPResentSuccess =
            typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            pleaseverifyotp: PleaseVerifyOTP,
            otpresentsuccess: OTPResentSuccess,
            enterotp: EnterOTP,
          });
        });
    }
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    localStorage.setItem("menuid", event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~Privacy Policy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition =
            typeof items[8] !== "undefined" ? items[8] : null;

          var PrivacyPolicy =
            typeof items[10] !== "undefined" ? items[10] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile =
            typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,

            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Causes~Beneficiaries~Paropakaris~Amount Raised~Welcome~Username~Password~Forgot password~Login~Dont have an account Please&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Causes = typeof items[10] !== "undefined" ? items[10] : null;
          var Beneficiaries =
            typeof items[11] !== "undefined" ? items[11] : null;
          var Paropakaris = typeof items[12] !== "undefined" ? items[12] : null;
          var Amountraised =
            typeof items[13] !== "undefined" ? items[13] : null;

          var Welcome = typeof items[14] !== "undefined" ? items[14] : null;
          var Usernames = typeof items[15] !== "undefined" ? items[15] : null;
          var Passwords = typeof items[16] !== "undefined" ? items[16] : null;
          var ForgotPassword =
            typeof items[17] !== "undefined" ? items[17] : null;
          var Signin = typeof items[18] !== "undefined" ? items[18] : null;
          var DonthavAcc = typeof items[19] !== "undefined" ? items[19] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            welcome: Welcome,
            usernames: Usernames,
            passwords: Passwords,
            forgotpassword: ForgotPassword,
            signin: Signin,
            donthaveacc: DonthavAcc,
            langID: LangID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Please Verify Your OTP~OTP Resent Successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var PleaseVerifyOTP =
            typeof items[8] !== "undefined" ? items[8] : null;
          var OTPResentSuccess =
            typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            pleaseverifyotp: PleaseVerifyOTP,
            otpresentsuccess: OTPResentSuccess,
            enterotp: EnterOTP,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OTP is Verified!~Please enter valid captcha&lang=" +
        MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          // var OTPVerified = typeof items[0] !== "undefined" ? items[0] : null;
          var Captchadoesnotmatch =
            typeof items[0] !== "undefined" ? items[0] : null;
          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            //  otpverified: OTPVerified,
            // otpnotverified:OTPNotVerified,
            captchadoesnotmatch: Captchadoesnotmatch,
            langID: LangID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OTP Sent to Your Updated Mobile Number~OTP Number~OTP verified successfully and Updated your mobile number&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var OtpSentMobile = typeof items[0] !== "undefined" ? items[0] : null;
          var otpNumber = typeof items[1] !== "undefined" ? items[1] : null;
          var otpNumberverify =
            typeof items[3] !== "undefined" ? items[3] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            OtpSentMobile: OtpSentMobile,
            otpNumber: otpNumber,
            otpNumberverify: otpNumberverify,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Resend OTP~Sorry~OTP~is not verified~Resend OTP will only be sent 5 times~Friends~OTP is required&lang=" +
        MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ResendOTP = typeof items[0] !== "undefined" ? items[0] : null;
          var Passwordresend5timesonly =
            typeof items[4] !== "undefined" ? items[4] : null;
          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var Sorry = typeof items[1] !== "undefined" ? items[1] : null;
          var notVerify = typeof items[3] !== "undefined" ? items[3] : null;
          var OTP = typeof items[2] !== "undefined" ? items[2] : null;
          var friends = typeof items[5] !== "undefined" ? items[5] : null;
          var OtpRequired = typeof items[6] !== "undefined" ? items[6] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            notVerify: notVerify,
            Sorry: Sorry,
            OTP: OTP,
            resendotp: ResendOTP,
            Passwordresend5timesonly: Passwordresend5timesonly,
            langID: LangID,
            friends: friends,
            otprequired: OtpRequired,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
  }
  renderIcon() {
    const longArray = [1, 2, 3];
    const size = 1;

    const newArray = new Array(Math.ceil(longArray.length / size))
      .fill("")
      .map(function () {
        return this.splice(0, size);
      }, longArray.slice());

    return <div>{newArray}</div>;
  }

  handleChanges(event) {
    this.setState({ value: event.target.value });

    // localStorage.setItem('menuid', event.target.value);
    let values = [
      "OurStory",
      "HowItWorks",
      "Blog",
      "Login",
      "BeaParopakari",
      "What We Do",
    ];

    let l_id = JSON.parse(localStorage.getItem("MenuID"));

    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others~HowItWorks~Edit&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Howitwork = typeof items[9] !== "undefined" ? items[9] : null;

          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;

          var ChangePasssword =
            typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
          var Edit = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            beaparopakari: Beaparopakari,
            myjourney: Myjourney,
            createacause: Createacause,
            invite: Invite,
            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,
            others: Others,
            howitworks: Howitwork,
            langID: LangID,
            edit: Edit,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OurStory~HowItWorks~Blog~Login~BeaParopakari~WhatWeDo~Opportunity to Bring a Smile~Contact Us~Terms and Conditions~PrivacyPolicy&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var Ourstory = typeof items[0] !== "undefined" ? items[0] : null;
          var Howitwork = typeof items[1] !== "undefined" ? items[1] : null;
          var Blog = typeof items[2] !== "undefined" ? items[2] : null;
          var Login = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[7] !== "undefined" ? items[7] : null;
          var Termsandcondition =
            typeof items[8] !== "undefined" ? items[8] : null;

          var PrivacyPolicy = typeof items[9] !== "undefined" ? items[9] : null;
          var Beaparopakari = typeof items[4] !== "undefined" ? items[4] : null;
          var OppertunitytoBSmile =
            typeof items[6] !== "undefined" ? items[6] : null;
          var Whatwedo = typeof items[5] !== "undefined" ? items[5] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            ourstory: Ourstory,
            howitworks: Howitwork,
            blog: Blog,
            login: Login,
            beaparopakari: Beaparopakari,
            whatwedo: Whatwedo,
            oppertunitybringsmile: OppertunitytoBSmile,
            contactus: Contactus,
            termsandcondition: Termsandcondition,

            privacypolicy: PrivacyPolicy,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Sign Up~Enter Captcha~Enter~Or~Email~Mobile Number~Submit~Enter OTP~Please Verify Your OTP~OTP Resent Successfully&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Signup = typeof items[0] !== "undefined" ? items[0] : null;
          var Entercaptchvalue =
            typeof items[1] !== "undefined" ? items[1] : null;
          var Enter = typeof items[2] !== "undefined" ? items[2] : null;
          var Or = typeof items[3] !== "undefined" ? items[3] : null;
          var Email = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var EnterOTP = typeof items[7] !== "undefined" ? items[7] : null;
          var PleaseVerifyOTP =
            typeof items[8] !== "undefined" ? items[8] : null;
          var OTPResentSuccess =
            typeof items[9] !== "undefined" ? items[9] : null;
          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            signup: Signup,
            entercaptchvalue: Entercaptchvalue,
            enter: Enter,
            langID: LangID,
            or: Or,
            email: Email,
            mobilenumber: MobileNumber,
            submit: Submit,
            pleaseverifyotp: PleaseVerifyOTP,
            otpresentsuccess: OTPResentSuccess,
            enterotp: EnterOTP,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Blog~Contact Us~Terms and Conditions~Copyright © 2024 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Blog = typeof items[0] !== "undefined" ? items[0] : null;

          var Contactus = typeof items[1] !== "undefined" ? items[1] : null;
          var Termsandcondition =
            typeof items[2] !== "undefined" ? items[2] : null;
          var CopyRight = typeof items[3] !== "undefined" ? items[3] : null;
          var Causes = typeof items[4] !== "undefined" ? items[4] : null;
          var Beneficiaries = typeof items[5] !== "undefined" ? items[5] : null;
          var Paropakaris = typeof items[6] !== "undefined" ? items[6] : null;
          var Amountraised = typeof items[7] !== "undefined" ? items[7] : null;
          var RequiredFor = typeof items[8] !== "undefined" ? items[8] : null;
          var Self = typeof items[9] !== "undefined" ? items[9] : null;
          var FamilyandFriends =
            typeof items[10] !== "undefined" ? items[10] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            blog: Blog,
            contactus: Contactus,
            termsandcondition: Termsandcondition,
            copyright: CopyRight,
            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised,
            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=~Bank Account~Submit~Cancel~Name~Date of Birth~Mobile Number~Date of Anniversary~IFSC Code~Email~Location~UPI ID&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var BankAccount = typeof items[0] !== "undefined" ? items[0] : null;
          var Submit = typeof items[1] !== "undefined" ? items[1] : null;
          var Cancel = typeof items[2] !== "undefined" ? items[2] : null;
          var Name = typeof items[3] !== "undefined" ? items[3] : null;
          var DateOfBirth = typeof items[4] !== "undefined" ? items[4] : null;
          var MobileNumber = typeof items[5] !== "undefined" ? items[5] : null;
          var DateofAnniversary =
            typeof items[6] !== "undefined" ? items[6] : null;
          var IFSCCode = typeof items[7] !== "undefined" ? items[7] : null;
          var Email = typeof items[8] !== "undefined" ? items[8] : null;
          var Location = typeof items[9] !== "undefined" ? items[9] : null;
          var UPIID = typeof items[10] !== "undefined" ? items[10] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);
          this.setState({
            bankaccountt: BankAccount,
            submit: Submit,
            cancel: Cancel,
            name: Name,
            dateofbirth: DateOfBirth,
            mobilenumberr: MobileNumber,
            dateofaniversary: DateofAnniversary,
            ifsccode: IFSCCode,
            emaill: Email,
            location: Location,
            upiId: UPIID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Updated Successfully~Profile Update~Delete Profile Picture~Select Profile Picture&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var UpdateSuccess = typeof items[1] !== "undefined" ? items[1] : null;
          var UpdateProfile = typeof items[2] !== "undefined" ? items[2] : null;
          var DeleteProfilePic =
            typeof items[3] !== "undefined" ? items[3] : null;
          var SelectProfilePic =
            typeof items[4] !== "undefined" ? items[4] : null;

          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            privacypolicy: PrivacyPolicy,
            updatesuccess: UpdateSuccess,
            updateprofile: UpdateProfile,
            deleteprofilepic: DeleteProfilePic,
            selectprofilepic: SelectProfilePic,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Provide Email Address~Provide Location~Provide Bank Details~Enter Valid IFSC Code~Enter Valid Bank Account Number~Want to Delete Your Profile Picture? Are You Sure?~Enter Valid UPI ID~Enter Captcha&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;
          var ProvideEmail = typeof items[0] !== "undefined" ? items[0] : null;
          var ProvideLocation =
            typeof items[1] !== "undefined" ? items[1] : null;
          var ProvideBankDetails =
            typeof items[2] !== "undefined" ? items[2] : null;
          var ValifIFSCCode = typeof items[3] !== "undefined" ? items[3] : null;
          var ValidBankacc = typeof items[4] !== "undefined" ? items[4] : null;
          var WanttoDelete = typeof items[5] !== "undefined" ? items[5] : null;
          var ValidUPIID = typeof items[6] !== "undefined" ? items[6] : null;
          var CaptchaValue = typeof items[7] !== "undefined" ? items[7] : null;
          var LangID = MID;
          localStorage.setItem("MenuID", JSON.stringify(LangID));
          window.sessionStorage.setItem("translate", LangID);

          this.setState({
            langID: LangID,
            provideemail: ProvideEmail,
            providelocation: ProvideLocation,
            ProvideBankDetails: ProvideBankDetails,
            validifsc: ValifIFSCCode,
            validbankacc: ValidBankacc,
            wanttodelete: WanttoDelete,
            captchavalue: CaptchaValue,
            validupiid: ValidUPIID,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=OTP Sent to Your Updated Mobile Number~OTP Number~OTP verified successfully and Updated your mobile number&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var OtpSentMobile = typeof items[0] !== "undefined" ? items[0] : null;
          var otpNumber = typeof items[1] !== "undefined" ? items[1] : null;
          var otpNumberverify =
            typeof items[3] !== "undefined" ? items[3] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            OtpSentMobile: OtpSentMobile,
            otpNumber: otpNumber,
            otpNumberverify: otpNumberverify,
            langID: LangID,
          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Resend OTP~Sorry~OTP~is not verified~Resend OTP will only be sent 5 times~Friends~OTP is required&lang=" +
        MID;
      // ~OTP is Not Verified!
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          localStorage.setItem("menuid", "English");
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var ResendOTP = typeof items[0] !== "undefined" ? items[0] : null;
          var Passwordresend5timesonly =
            typeof items[4] !== "undefined" ? items[4] : null;
          // var OTPNotVerified = typeof items[2] !== "undefined" ? items[2] : null;

          var Sorry = typeof items[1] !== "undefined" ? items[1] : null;
          var notVerify = typeof items[3] !== "undefined" ? items[3] : null;
          var OTP = typeof items[2] !== "undefined" ? items[2] : null;
          var friends = typeof items[5] !== "undefined" ? items[5] : null;
          var OtpRequired = typeof items[6] !== "undefined" ? items[6] : null;

          var LangID = MID;
          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            notVerify: notVerify,
            Sorry: Sorry,
            OTP: OTP,
            resendotp: ResendOTP,
            friends: friends,
            Passwordresend5timesonly: Passwordresend5timesonly,
            langID: LangID,
            otprequired: OtpRequired,
          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel&lang=" +
        MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;

      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data,
          });

          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size))
            .fill("")
            .map(function () {
              return this.splice(0, size);
            }, longArray.slice());
          var items = data;

          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;

          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;

          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";
          } else if (datatranslirate == "Kannada") {
            datatransIDs = "/en/kn";
          } else if (datatranslirate == "Tamil") {
            datatransIDs = "/en/ta";
          } else if (datatranslirate == "Telugu") {
            datatransIDs = "/en/te";
          } else if (datatranslirate == "Hindi") {
            datatransIDs = "/en/hi";
          } else if (datatranslirate == "Spanish") {
            datatransIDs = "/en/es";
          } else if (datatranslirate == MID) {
            datatransIDs = MID;
          }
          let RefreshID = 0;
          window.sessionStorage.setItem("reload", RefreshID);
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem("reloadsmyactivity", reloadsmyactivity);
          window.sessionStorage.setItem(
            "responsepagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem(
            "notificationpagereload",
            reloadsmyactivity
          );
          window.sessionStorage.setItem("Indexreload", reloadsmyactivity);
          window.sessionStorage.setItem("GoogleTranslateValues", datatransIDs);

          let googletrasvalues = localStorage.setItem(
            "GoogleTranslateValues",
            datatransIDs
          );

          Cookies.remove("googtrans", { domain: ".paropakari.com" });
          Cookies.remove("googtrans", { domain: "https://paropakari.com/" });

          Cookies.remove("googtrans", {
            domain: "https://www.paropakari.com/",
          });
          let usernames = localStorage.getItem(
            "GoogleTranslateValues",
            datatransIDs
          );
          let values = datatransIDs;

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
            const expires = "expires=" + date.toUTCString();
            document.cookie =
              cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set("googtrans", datatransIDs, {
            domain: "https://test.paropakari.com/#/",
          });
          Cookies.set("googtrans", datatransIDs, { domain: ".paropakari.com" });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://www.paropakari.com/",
          });
          Cookies.set("googtrans", datatransIDs, {
            domain: "https://paropakari.com/",
          });
          setCookie("googtrans", datatransIDs, 30);
          setCookie(
            "googtrans",
            datatransIDs,
            "https://test.paropakari.com/#/"
          );
          setCookie("googtrans", datatransIDs, ".paropakari.com");
          setCookie("googtrans", datatransIDs, "https://www.paropakari.com/");
          setCookie("googtrans", datatransIDs, "https://paropakari.com/");
          let cookie = Cookies.get(
            "googtrans",
            "https://test.paropakari.com/#/"
          );

          window.sessionStorage.setItem("translate", LangID);
          localStorage.setItem("MenuID", JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
          });
        });
    }
  }
  notify = () => {
    let url =
      "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    const accessToken = user;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers ",
      },
      //body:JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          notify: data,
        });

        localStorage.setItem("noticount", JSON.stringify(data[0].noticount));
        //
      });
  };
  componentDidMount() {
    const storedCount = localStorage.getItem("count");
    if (storedCount) {
      this.setState({ count: parseInt(storedCount) });
    }
    window.addEventListener("load", this.Footer);
    this.Footer();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.addEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    window.removeEventListener("load", this.notify);
    this.notify();
    window.removeEventListener("load", this.Footer);
    this.Footer();
    const element = document.querySelector(".VIpgJd-ZVi9od-ORHb-OEVmcd");
    if (
      element &&
      window.getComputedStyle(element).getPropertyValue("position") === "fixed"
    ) {
      element.style.position = "absolute";
    }
    document.title = "India's only expenseless, direct crowdfunding platform";
    loadCaptchaEnginge(6);
  }
  componentWillUnmount() {
    loadCaptchaEnginge(6);
    window.removeEventListener("load", this.notify);
    this.notify();
    window.addEventListener("load", this.ReloadPage);
    this.ReloadPage();
    window.removeEventListener("load", this.DefaultMenu);
    this.DefaultMenu();
    window.removeEventListener("load", this.Footer);
    this.Footer();
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false });
  }

  handleScriptError() {
    this.setState({ scriptError: true });
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true });
  }

  render() {
    var Phonenumber = JSON.parse(localStorage.getItem("profile_mobileNumber"));
    var Email = JSON.parse(localStorage.getItem("Email"));
    var OTP = localStorage.OTP;
    const { notify } = this.state;
    const {
      newArray,
      privacypolicy,
      beaparopakari,
      whatwedo,
      blog,
      login,
      captchadoesnotmatch,
      resendotp,
      otpnotverified,
      otpverified,
      pleaseverifyotp,
      indiaexpensless,
      otpresentsuccess,
      ourstory,
      oppertunitybringsmile,
      friends,
      contactus,
      termsandcondition,
      causes,
      beneficiaries,
      paropakaris,
      amountraised,
      copyright,
      whatwedodesc,
      langID,
      welcome,
      usernames,
      passwords,
      forgotpassword,
      signin,
      donthaveacc,
      signup,
      entercaptchvalue,
      enter,
      or,
      emails,
      mobilenumber,
      submit,
      enterotp,
      profile_name,
      profile_email,
      profile_mobileNumber,
      profile_location,
      profile_dateOfAnniversary,
      profile_dateOfBirth,
      remove,
      prof_BankAccount,
      prof_IFSC,
      pro_UPIID,
      OtpSentMobile,
      otpNumber,
      benId,
      myjourney,
    } = this.state;
    const {
      howitworks,
      createacause,
      invite,
      captchavalue,
      profile,
      changepassword,
      group,
      logout,
      otprequired,
      others,
      requiredforr,
      familyfriends,
      provideemail,
      providelocation,
      providebankdetails,
      validifsc,
      validbankacc,
      wanttodelete,
      validupiid,
      self,
      beneficiaryname,
      updateprofile,
      deleteprofilepic,
      selectprofilepic,
      bankaccountt,
      name,
      mobilenumberr,
      emaill,
      dateofbirth,
      dateofaniversary,
      location,
      ifsccode,
      upiId,
      edit,
      cancel,
      updatesuccess,
    } = this.state;
    let { foo } = this.state;
    var username = JSON.parse(localStorage.username);
    return (
      <div
        className="VIpgJd-ZVi9od-ORHb-OEVmcd"
        style={{ position: "absolute" }}
      >
        <div
          style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}
          className="notranslate"
        >
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />
          <link href="./assets/css/Register.css" rel="stylesheet" />
          {/*[if lt IE 9]>
              
              
          <![endif]*/}
          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="images/ico/apple-touch-icon-144-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="images/ico/apple-touch-icon-114-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="images/ico/apple-touch-icon-72-precomposed.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            href="images/ico/apple-touch-icon-57-precomposed.png"
          />
          <select
            className="form-control"
            style={{
              display: "inline",
              width: "10%",
              marginTop: "0",
              marginLeft: "0",
              float: "right",
              width: "fit-content",
            }}
            value={this.state.value}
            onChange={this.handleChanges}
          >
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          {/* </div> */}
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontsize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check" id="labelform">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Profile">
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>

                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Profile">
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Profile">
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Profile">
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Profile">
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontsize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/Profile">
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a className="text-parop-menu" href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div></div>

            {/* <section id="blog" className={this.state.movedown == true ? "padding-top padding-bottom new-padding" : "padding-top padding-bottom new-padding home-Tiptop"} style={{backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }}>    
 
    <div className="container " id="containerid" >
        
      <div className="row">
          
      <div id="rcont" class="rcont" >
  <div   id="login" class="form sign-in" style={{backgroundColor:'#a5d9cf'}}>
  <form role="form"  onSubmit={this.onSubmitOTP} >
      
    <h2 style={{color:'black'}}></h2>

    <input name="__RequestVerificationToken" type="hidden" defaultValue="PdlsOftvA33cACXYmdfrToI7ZxCMY85gro9hMBmX8g3C0ljb1Evh9ZzfgQAXal5wawUEVJy_BuahRFspEFtMa-qiBIxzmCIk15gr0b5UzpY1" />
    
    <label>
      <span>{enterotp.result}</span>
      <input data-val="true" value={this.state.otp} onChange={this.handleChange} pattern="[0-9]*"
             id="otp" maxLength={6} name="otp" placeholder="OTP Number" type="text"  />
    </label>
    <br/>
    <center>
    <div className="Captcha">
                <LoadCanvasTemplate/>
                </div>
               
                <div className="k-row" >
                <input id="user_captcha_input" name="user_captcha_input" style={{width:'50%',color:'black'}} type="text" placeholder={entercaptchvalue.result}></input>
               </div>
               </center>
    <input name="Phonenumber" id="Phonenumber" type="hidden" value={Phonenumber} onChange={this.handleChange} />
    <input name="Email" id="Email" type="hidden" value={Email} onChange={this.handleChange} />
   
    
    <br/>
    {(() => {if(Phonenumber != '' ) {return (
              <center><p> OPT Sent to Your Updated {Phonenumber} </p></center>
            );}
                   else if(Email != '' ) {return (
                    <center><p> OPT Sent to Your Registered {Email} </p></center>
            );}

            })()}

<center><a id="linkid" style={{color:"black"}} onClick={this.reSubmitOTP}><b>Resend OTP</b></a></center>
               <div id="success" />
    <button type="submit" id="Reg"  class="submit">{submit.result}</button>
  
</form>
</div>
  <div class="sub-cont" id="rsub-cont">
    <div  id="rimgsub-cont"  class="img">
      <div class="img__text m--up">

      </div>
      <div class="img__text m--in">

       
      </div>
      <div class="">
      
    </div>
    <div class="form sign-up">
     
      
</div>
  </div>
</div>


      </div>
      
    </div>
    </div>
  </section> */}
            <section
              id="blog"
              className={
                this.state.movedown == true
                  ? "padding-top padding-bottom new-padding"
                  : "padding-top padding-bottom new-padding home-Tiptop"
              }
              style={{
                backgroundImage: "url(" + "./assets/images/10.jpg" + ")",
              }}
            >
              <div className="container " id="containerid">
                <div className="row">
                  <div id="rcont" class="rcont" style={{ height: "470px" }} s>
                    <div
                      id="login"
                      class="form sign-in"
                      style={{ backgroundColor: "#a5d9cf" }}
                    >
                      <form
                        className=""
                        onSubmit={this.onSubmitOTP}
                        role="form"
                        style={{ margin: "5px" }}
                      >
                        <h2 style={{ color: "black" }}></h2>
                        <input
                          name="__RequestVerificationToken"
                          type="hidden"
                          defaultValue="PdlsOftvA33cACXYmdfrToI7ZxCMY85gro9hMBmX8g3C0ljb1Evh9ZzfgQAXal5wawUEVJy_BuahRFspEFtMa-qiBIxzmCIk15gr0b5UzpY1"
                        />

                        <label>
                          <span>{enterotp.result}</span>
                          <input
                            data-val="true"
                            value={this.state.otp}
                            onChange={this.handleChange}
                            pattern="[0-9]*"
                            id="otp"
                            maxLength={6}
                            name="otp"
                            placeholder={otpNumber.result}
                            type="text"
                          />
                        </label>

                        <br />
                        <center>
                          <div className="Captcha">
                            <LoadCanvasTemplate />
                          </div>

                          <div className="k-row">
                            <input
                              id="user_captcha_input"
                              name="user_captcha_input"
                              style={{
                                width: "50%",
                                color: "black",
                                fontSize: "16px",
                              }}
                              type="text"
                              // placeholder={captchavalue.result}
                              placeholder={entercaptchvalue.result}
                            ></input>
                          </div>
                        </center>
                        <input
                          name="Phonenumber"
                          id="Phonenumber"
                          type="hidden"
                          value={Phonenumber}
                          onChange={this.handleChange}
                        />
                        <input
                          name="Email"
                          id="Email"
                          type="hidden"
                          value={Email}
                          onChange={this.handleChange}
                        />

                        <br />
                        {(() => {
                          if (Phonenumber != "") {
                            return (
                              <center>
                                <p>
                                  {OtpSentMobile.result}&nbsp;{Phonenumber}{" "}
                                </p>
                              </center>
                            );
                          } else if (Email != "") {
                            return (
                              <center>
                                <p>
                                  {" "}
                                  OPT Sent to Your Registered &nbsp;{Email}{" "}
                                </p>
                              </center>
                            );
                          }
                        })()}

                        <center>
                          <a
                            id="linkid"
                            style={{ color: "black" }}
                            onClick={this.reSubmitOTP}
                          >
                            <b>{resendotp.result}</b>
                          </a>
                        </center>
                        <div id="success" />
                        {(() => {
                          if (langID == "English") {
                            return (
                              <button type="submit" id="Reg" class="submit">
                                {submit.result}
                              </button>
                            );
                          } else if (langID == "Telugu") {
                            return (
                              <button type="submit" id="Reg" class="submit">
                                {submit.result}
                              </button>
                            );
                          } else if (langID == "Hindi") {
                            return (
                              <button type="submit" id="Reg" class="submit">
                                {submit.result}
                              </button>
                            );
                          } else if (langID == "Kannada") {
                            return (
                              <button type="submit" id="Reg" class="submit">
                                {submit.result}
                              </button>
                            );
                          } else if (langID == "Spanish") {
                            return (
                              <button type="submit" id="Reg" class="submit">
                                {submit.result}
                              </button>
                            );
                          } else if (langID == "Tamil") {
                            return (
                              <button
                                type="submit"
                                id="Reg"
                                class="submit"
                                style={{ width: "150px" }}
                              >
                                {submit.result}
                              </button>
                            );
                          } else {
                            return (
                              <button type="submit" id="Reg2" class="submit">
                                {submit.result}
                              </button>
                            );
                          }
                        })()}

                        {/* <button type="submit" id="Reg"  class="submit">{submit.result}</button> */}
                        <br />
                        <br />
                      </form>
                    </div>

                    <div id="rsub-cont" class="sub-rcont">
                      <div id="rimgsub-cont" class="img">
                        <div class="img__text m--up"></div>
                        <div class="img__text m--in"></div>
                        <div class="tnr" id="tnr"></div>
                      </div>

                      <div class="form sign-up">
                        <h2>Create your Account</h2>
                        <label>
                          <span>Name</span>
                          <input type="text" />
                        </label>
                        <label>
                          <span>Email</span>
                          <input type="email" />
                        </label>
                        <label>
                          <span>Password</span>
                          <input type="password" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            {/*/#blog*/}
            <footer id="footer" style={{ color: "white" }}>
              <br />
              <div className="container">
                <div className="copyright">
                  <div className="row col-lg-12">
                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}

                      <p style={{ marginLeft: "45px" }}>
                        {copyright.result}&nbsp; |&nbsp;
                        <a
                          href="#/Contactuslogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          {contactus.result}&nbsp;
                        </a>
                        |{" "}
                        <a
                          href="#/PrivacyPolicyLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{privacypolicy.result}&nbsp;
                        </a>
                        |
                        <a
                          href="#/TermsandConditionLogin"
                          style={{ textDecoration: "none", color: "white" }}
                        >
                          &nbsp;{termsandcondition.result}
                        </a>
                      </p>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <div
                        className="social-icons"
                        style={{ color: "white", marginLeft: "72px" }}
                      >
                        <ul className="nav nav-pills">
                          <li>
                            <a
                              target="_blank"
                              href="https://www.facebook.com/beoneparopakari"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li>
                            <a
                              target="_blank"
                              href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw"
                            >
                              <i className="fa fa-youtube-play" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.instagram.com/paropakari_beone/"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              href="https://www.linkedin.com/company/paropakari/"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="containerss"></div>
            </footer>
            {/*/#footer*/}
          </div>
        </div>
      </div>
    );
  }
}
