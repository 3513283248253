import { Height, LeakRemoveTwoTone } from '@material-ui/icons';
import React, { Component } from 'react'
import { BrowserRouter as Navlink } from "react-router-dom";
import Dropdowns from './Dropdowns';
import Taggroup from './Taggroup';
import CancelIcon from '@material-ui/icons/Cancel';
import Resizer from 'react-image-file-resizer';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Script from 'react-load-script'
//import SideBars from './SideBars.js';
import { Link } from "react-router-dom";
import Cookies from 'js-cookie';
import LogoutLink from "./LogoutLink";
import Contactuslogin from './Contactuslogin';
import { BASE_URLs } from '../Constants/BaseURL';
//cmd
export default class CauseFor extends Component {
  constructor(props) {
    super(props);
    this.state = { ImageDisplay: '' };
    this.state = {
      // id : this.props.match.params.id,
      BeneficiaryName: "",
      upload_title: "",
      category_id: "",
      relation_id: "",
      upload_description: "",
      upload_amount: "",
      upload_num_of_beneficiaries: "",
      upload_place: "",
      required_by: "",
      upload_UPIID: "",
      upload_IFSC: "",
      upload_BankAccount: "",
      status_80G: "",
      notify: [],
      formErrors: {},
      emps: [],
      foo: [],
      Menu: [],
      beaparopakari: [],
      createayourcause:[],
      myjourney: [],
      createacause: [],
      invite: [],
      submitbtn:[],
      profile: [], changepassword: [], group: [], logout: [],
      requiredforr: [], self: [], familyfriends: [], others: [], beneficiaryname: [], submit: [],
      causes: [],
      beneficiaries: [],
      paropakaris: [],
      amountraised: [],
      copyright: [],
      contactus: [], friends: [],
      termsandcondition: [],
      howitworks: [], enterbankinprofile: [],
      blog: [], donateasmile: [], select: [],
      privacypolicy: [], monetary: [], nonmonetary: [], money: [], donationex: [], selectthecategory: [], Beneficiaries1: [],
      langID: [], categories: [], foodandnutrition: [], health: [], professional: [], sports: [], socialwefare: [], education: [],
      file_name: null,
      disabled: true,
      close:[],
      disable: true,
      movedown: true,
      user1: '',
      dob: moment(),
      capture: "",
      Category_State: "",
      pleaseChooserequiredfor: [],
      requiredForValidation: false,
    };

    this.initialState = this.state;
    
    this.dateChange = this.dateChange.bind(this);
    this.fileChangedHandler = this.fileChangedHandler.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGameClik = this.handleGameClik.bind(this);
    this.accsubmit = this.accsubmit.bind(this);

    this.DefaultMenu = this.DefaultMenu.bind(this);
    this.handleChanges = this.handleChanges.bind(this);
  }


  resetFile(event) {
    event.preventDefault();

    // this.setState({ ImageDisplay: null });
  }

  checkfun = (e) => {
    this.setState({
      movedown: !this.state.movedown
    })
  }

  redirect = (e) => {
    e.preventDefault();
    var page = {
      pathname: '#/Login',

    }
    this.props.history.push(page)
  }
  fileChangedHandler(event) {

    var fileInput = false

    if (event.target.files[0]) {
      fileInput = true
    }
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    });
  }

  dateChange(date) {
    this.setState({
      dob: date
    });
  }
  handleFormValidation() {
    const { upload_title, upload_description, upload_amount,
      upload_num_of_beneficiaries, upload_place, required_by, relation_id,
      category_id, status_80G } = this.state;
    let formErrors = {};
    let formIsValid = true;

    if (!upload_title) {
      formIsValid = false;
      formErrors["opErr"] = "Title is Required.";
    }
    if (!upload_description) {
      formIsValid = false;
      formErrors["npErr"] = "Description is Required.";
    }
    if (!upload_amount) {
      formIsValid = false;
      formErrors["upaErr"] = "Amount is Required.";
    }

    if (!upload_place) {
      formIsValid = false;
      formErrors["uplErr"] = "Place is Required.";
    }
    if (!required_by) {
      formIsValid = false;
      formErrors["reqErr"] = "Date is Required.";
    }

    if (!category_id) {
      formIsValid = false;
      formErrors["PurErr"] = "Purpose is Required.";
    }
    if (!relation_id) {
      formIsValid = false;
      formErrors["RelaErr"] = "Required_for is Required.";
    }
    //   if(!status_80G){
    //     formIsValid=false;
    //     formErrors["StatusErr"]="status_80G is Required.";
    // }

    this.setState({ formErrors: formErrors });
    
    return formIsValid;

  }

  //   handleSubmit = (e) => {    
  //     e.preventDefault();    
  // 
  // //debugger;
  //     if (this.handleFormValidation()) { 
  //      // debugger;   
  //       
  //           const data = new FormData(e.target);
  //           let user = JSON.parse(localStorage.getItem('user'));

  //           const accessToken=user;
  // fetch('api/Create/CreatePost', {
  //   method: 'POST',
  //   headers:{

  //     Accept: 'application/json',             
  //       Authorization: "Bearer " +accessToken 

  //   },
  //   body: data,
  // });
  //    this.setState(this.initialState) 
  //     alert("Your cause on its way to bring happiness");
  //     window.location.href = "#/HomePage";
  //     }    
  // } 

  handleSubmit = async event => {
    event.preventDefault();
    if (this.handleFormValidation()) {
      


      const data = new FormData(event.target);
      let user = JSON.parse(localStorage.getItem('user'));

      const accessToken = user;
      const url = "api/Create/CreatePost";
      const options = {
        method: "POST",
        headers: {

          Accept: 'application/json',
          Authorization: "Bearer " + accessToken
        },
        body: data,
      };
      this.setState(this.initialState)
      const response = await fetch(url, options);
      const result = await response.json();
      if (response.ok) {
      
        window.location.href = "#/HomePage";
      }
      else {
        // alert("Something Went Wrong Please Try Again");
        window.location.reload();
      }
      this.setState(this.initialState)


      
    };
  }

  accsubmit = () => {
   // alert("hi");

  }
  onImageChange = (event) => {
    this.setState({
      file: URL.createObjectURL(event.target.files[0])
    })
    if (event.target.files && event.target.files[0]) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.setState({
          profile_imagePath: e.target.result
        });
      };
      
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  //  fileChangedHandler(event) {

  //   var fileInput = false

  //   if(event.target.files[0]) {
  //       fileInput = true
  //   }
  //   this.setState({
  //       file: URL.createObjectURL(event.target.files[0])
  //     });
  //   }



  Footer = () => {

    


  let url = `${BASE_URLs}Authenticate/Get_FooterDetails`;
    let data = this.state;

    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",

        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          foo: data
        });
        
        // 
      });
  }

  DefaultMenu() {
    //  
    let reloads = 0;
    window.sessionStorage.setItem('reloadlogin', (reloads));
    window.sessionStorage.setItem('reload', (reloads));
    window.sessionStorage.setItem('reloadcreate', (reloads));

    let reloadsmyactivity = 0;
    window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
    window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
    window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
    let MID = "English";
    if (sessionStorage.getItem('translate') !== null) {
      MID = sessionStorage.getItem('translate');
    }
    else {
      MID = "English";
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
         
         
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
         

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
         

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            myjourney: Myjourney,
           
            beaparopakari: Beaparopakari,
           

            createacause: Createacause,
            invite: Invite,


            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,

            langID: LangID,

            others: Others,
           


          });
        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2024 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends~Beneficiary Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var CopyRight = typeof items[0] !== "undefined" ? items[0] : null;
          var Causes = typeof items[1] !== "undefined" ? items[1] : null;
          var Beneficiaries = typeof items[2] !== "undefined" ? items[2] : null;
          var Paropakaris = typeof items[3] !== "undefined" ? items[3] : null;
          var Amountraised = typeof items[4] !== "undefined" ? items[4] : null;
          var RequiredFor = typeof items[5] !== "undefined" ? items[5] : null;
          var Self = typeof items[6] !== "undefined" ? items[6] : null;
          var FamilyandFriends = typeof items[7] !== "undefined" ? items[7] : null;
          var BeneficiaryName = typeof items[8] !== "undefined" ? items[8] : null;
          

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            copyright: CopyRight,

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,


            langID: LangID,

           

            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,
           
          });



        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Monetary~Non Monetary~Money~Select the Category~Donation ex: Books, Education related items, Sports related items, Food Packets, Health related items or Cloths~Enter Bank Details in Your Profile~Friends~Please,Enter Beneficiary Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var Monetary = typeof items[1] !== "undefined" ? items[1] : null;
          var NonMonetary = typeof items[2] !== "undefined" ? items[2] : null;
          var Money = typeof items[3] !== "undefined" ? items[3] : null;
          var SelectTheCategory = typeof items[4] !== "undefined" ? items[4] : null;
          var DonationEx = typeof items[5] !== "undefined" ? items[5] : null;
          var EnterBankDetailsinProfile = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var Beneficiaries1 = typeof items[8] !== "undefined" ? items[8] : null;
          
          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            langID: LangID,
            privacypolicy: PrivacyPolicy,
            monetary: Monetary,
            nonmonetary: NonMonetary,
            money: Money,
            selectthecategory: SelectTheCategory,
            donationex: DonationEx,
            enterbankinprofile: EnterBankDetailsinProfile,
            friends: Friends,
            Beneficiaries1: Beneficiaries1,
            

          });

        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Submit~Food and Nutrition~Health~Professional~Sports~Social Welfare~Education~Create Your Cause~Title&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Submit = typeof items[0] !== "undefined" ? items[0] : null;
          var FoodandNutrtion = typeof items[1] !== "undefined" ? items[1] : null;
          var Health = typeof items[2] !== "undefined" ? items[2] : null;
          var Professional = typeof items[3] !== "undefined" ? items[3] : null;
          var Sports = typeof items[4] !== "undefined" ? items[4] : null;
          var SocialWelfare = typeof items[5] !== "undefined" ? items[5] : null;
          var Educattion = typeof items[6] !== "undefined" ? items[6] : null;
          var CreateYourCause = typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
        
         
          var LangID = MID;

          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            createayourcause: CreateYourCause,
         
            foodandnutrition: FoodandNutrtion,
            education: Educattion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            title: Title,
           
            llangID: LangID,

            submit: Submit

          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Select~Description~HowItWorks~Blog~Contact Us~Click Submit Button~Submit~Terms and Conditions&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Select = typeof items[0] !== "undefined" ? items[0] : null;
          var Description = typeof items[1] !== "undefined" ? items[1] : null;
          var Howitwork = typeof items[2] !== "undefined" ? items[2] : null;
          var Blog = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[4] !== "undefined" ? items[4] : null;
          var submitbtn = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition = typeof items[7] !== "undefined" ? items[7] : null;
         

          var LangID = MID;

          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            select: Select,
            description: Description,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            submitbtn:submitbtn,
            termsandcondition: Termsandcondition,
            submit: Submit,
           



          });
        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Close~Please, Choose Required For&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
        
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var close = typeof items[0] !== "undefined" ? items[0] : null;
          var pleaseChooserequiredfor = typeof items[1] !== "undefined" ? items[1] : null;

          
          
          var LangID = MID;

          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

           
            close: close,
            pleaseChooserequiredfor: pleaseChooserequiredfor,

          });
        });
    }
  }


  handleChanges(event) {
    this.setState({ value: event.target.value });
    
    // localStorage.setItem('menuid', event.target.value);
    let values = ['OurStory', 'HowItWorks', 'Blog', 'Login', 'BeaParopakari', 'What We Do'];
    
    let MID = event.target.value;
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Be a Paropakari~My Journey~Create a Cause~Invite~Profile~Change Password~Group~Logout~Others&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var Beaparopakari = typeof items[0] !== "undefined" ? items[0] : null;
          var Myjourney = typeof items[1] !== "undefined" ? items[1] : null;
          var Createacause = typeof items[2] !== "undefined" ? items[2] : null;
          var Invite = typeof items[3] !== "undefined" ? items[3] : null;
          var Profile = typeof items[4] !== "undefined" ? items[4] : null;
         

          var ChangePasssword = typeof items[5] !== "undefined" ? items[5] : null;
          var Group = typeof items[6] !== "undefined" ? items[6] : null;
          var Logout = typeof items[7] !== "undefined" ? items[7] : null;

          var Others = typeof items[8] !== "undefined" ? items[8] : null;
         

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({
            myjourney: Myjourney,
           
            beaparopakari: Beaparopakari,
           

            createacause: Createacause,
            invite: Invite,


            profile: Profile,
            changepassword: ChangePasssword,
            group: Group,
            logout: Logout,

            langID: LangID,

            others: Others,


          });


        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Submit~Food and Nutrition~Health~Professional~Sports~Social Welfare~Education~Create Your Cause~Title&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Submit = typeof items[0] !== "undefined" ? items[0] : null;
          var FoodandNutrtion = typeof items[1] !== "undefined" ? items[1] : null;
          var Health = typeof items[2] !== "undefined" ? items[2] : null;
          var Professional = typeof items[3] !== "undefined" ? items[3] : null;
          var Sports = typeof items[4] !== "undefined" ? items[4] : null;
          var SocialWelfare = typeof items[5] !== "undefined" ? items[5] : null;
          var Educattion = typeof items[6] !== "undefined" ? items[6] : null;
          var CreateYourCause = typeof items[7] !== "undefined" ? items[7] : null;
          var Title = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;

          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            createayourcause: CreateYourCause,
         
            foodandnutrition: FoodandNutrtion,
            education: Educattion,
            health: Health,
            professional: Professional,
            sports: Sports,
            socialwefare: SocialWelfare,
            title: Title,
           
            llangID: LangID,

            submit: Submit

          });

        });
    }
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Copyright © 2024 Paropakari~Causes~Beneficiaries~Paropakaris~Amount Raised~Required For~Self~Family/Friends~Beneficiary Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var CopyRight = typeof items[0] !== "undefined" ? items[0] : null;
          var Causes = typeof items[1] !== "undefined" ? items[1] : null;
          var Beneficiaries = typeof items[2] !== "undefined" ? items[2] : null;
          var Paropakaris = typeof items[3] !== "undefined" ? items[3] : null;
          var Amountraised = typeof items[4] !== "undefined" ? items[4] : null;
          var RequiredFor = typeof items[5] !== "undefined" ? items[5] : null;
          var Self = typeof items[6] !== "undefined" ? items[6] : null;
          var FamilyandFriends = typeof items[7] !== "undefined" ? items[7] : null;
          var BeneficiaryName = typeof items[8] !== "undefined" ? items[8] : null;
         

          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({

            copyright: CopyRight,

            causes: Causes,
            beneficiaries: Beneficiaries,
            paropakaris: Paropakaris,
            amountraised: Amountraised.result,


            langID: LangID,

           

            requiredforr: RequiredFor,
            self: Self,
            familyfriends: FamilyandFriends,
            beneficiaryname: BeneficiaryName,





            

          });



        });
    }

    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Privacy Policy~Monetary~Non Monetary~Money~Select the Category~Donation ex: Books, Education related items, Sports related items, Food Packets, Health related items or Cloths~Enter Bank Details in Your Profile~Friends~Please,Enter Beneficiary Name&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;
          var PrivacyPolicy = typeof items[0] !== "undefined" ? items[0] : null;
          var Monetary = typeof items[1] !== "undefined" ? items[1] : null;
          var NonMonetary = typeof items[2] !== "undefined" ? items[2] : null;
          var Money = typeof items[3] !== "undefined" ? items[3] : null;
          var SelectTheCategory = typeof items[4] !== "undefined" ? items[4] : null;
          var DonationEx = typeof items[5] !== "undefined" ? items[5] : null;
          var EnterBankDetailsinProfile = typeof items[6] !== "undefined" ? items[6] : null;
          var Friends = typeof items[7] !== "undefined" ? items[7] : null;
          var Beneficiaries1 = typeof items[8] !== "undefined" ? items[8] : null;
          var LangID = MID;
          localStorage.setItem('MenuID', JSON.stringify(LangID));
          window.sessionStorage.setItem('translate', (LangID));

          this.setState({


            langID: LangID,
            privacypolicy: PrivacyPolicy,
            monetary: Monetary,
            nonmonetary: NonMonetary,
            money: Money,
            selectthecategory: SelectTheCategory,
            donationex: DonationEx,
            enterbankinprofile: EnterBankDetailsinProfile,
            friends: Friends,
            Beneficiaries1: Beneficiaries1,
            
            
          });



        });
    }
   
    if (MID != null) {
      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Select~Description~HowItWorks~Blog~Contact Us~Click Submit Button~Submit~Terms and Conditions&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;

          var Select = typeof items[0] !== "undefined" ? items[0] : null;
          var Description = typeof items[1] !== "undefined" ? items[1] : null;
          var Howitwork = typeof items[2] !== "undefined" ? items[2] : null;
          var Blog = typeof items[3] !== "undefined" ? items[3] : null;
          var Contactus = typeof items[4] !== "undefined" ? items[4] : null;
          var submitbtn = typeof items[5] !== "undefined" ? items[5] : null;
          var Submit = typeof items[6] !== "undefined" ? items[6] : null;
          var Termsandcondition = typeof items[7] !== "undefined" ? items[7] : null;
          

          var LangID = MID;

          window.sessionStorage.setItem('translate', (LangID));

          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({

            select: Select,
            description: Description,
            howitworks: Howitwork,
            blog: Blog,
            contactus: Contactus,
            submitbtn:submitbtn,
            termsandcondition: Termsandcondition,
            submit: Submit,
           
          });
        });
    }
    
    if (MID != null) {


      let url =
        "https://apitest.paropakari.com/api/Authenticate/Translatev?values=Cancel~Close~Please, Choose Required For&lang=" + MID;
      let user = JSON.parse(localStorage.getItem("user"));
      const accessToken = user;
      
      fetch(url, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            Details: data
          });
          
          const longArray = data;
          const size = 1;
          var ary = data;
          
          // localStorage.setItem('menuid', 'English');
          const newArrayyy = new Array(Math.ceil(longArray.length / size)).fill("")
            .map(function () { return this.splice(0, size) }, longArray.slice());
          var items = data;


          var Cancel = typeof items[0] !== "undefined" ? items[0] : null;
          var close = typeof items[1] !== "undefined" ? items[1] : null;
          var pleaseChooserequiredfor = typeof items[2] !== "undefined" ? items[2] : null;


          var LangID = MID;
          let GGIDS = MID;

          let datatranslirate = GGIDS;


          



          //localStorage.setItem("GoogleTranslate123",InviteFriendsDropdown)

          let datatransIDs = MID;

          if (datatranslirate == "English") {
            datatransIDs = "/en/en";

          }
          else

            if (datatranslirate == "Kannada") {
              datatransIDs = "/en/kn";
            }

            else
              if (datatranslirate == "Tamil") {
                datatransIDs = "/en/ta";
              }
              else
                if (datatranslirate == "Telugu") {
                  datatransIDs = "/en/te";
                }
                else
                  if (datatranslirate == "Hindi") {
                    datatransIDs = "/en/hi";
                  }
                  else
                  if (datatranslirate == "Spanish") {
                    datatransIDs = "/en/es";
                  }
                  else
                    if (datatranslirate == MID) {
                      datatransIDs = MID;
                    }
          let RefreshID = 0;
          window.sessionStorage.setItem('reload', (RefreshID));
          let reloadsmyactivity = 0;
          window.sessionStorage.setItem('reloadsmyactivity', (reloadsmyactivity));
          window.sessionStorage.setItem('responsepagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('notificationpagereload', (reloadsmyactivity));
          window.sessionStorage.setItem('Indexreload', (reloadsmyactivity));
          window.sessionStorage.setItem('GoogleTranslateValues', (datatransIDs));

          
          let googletrasvalues = localStorage.setItem("GoogleTranslateValues", datatransIDs);

          


          


          Cookies.remove('googtrans', { domain: '.paropakari.com' });
          Cookies.remove('googtrans', { domain: 'https://paropakari.com/' });

          Cookies.remove('googtrans', { domain: 'https://www.paropakari.com/' });
          let usernames = localStorage.getItem("GoogleTranslateValues", datatransIDs);
          let values = datatransIDs;
          
          

          function setCookie(cName, cValue, expDays) {
            let date = new Date();
            date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
            const expires = "expires=" + date.toUTCString();
            document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
            // window.location.reload();
          }

          Cookies.set('googtrans', datatransIDs, { domain: 'https://test.paropakari.com/#/' });
          Cookies.set('googtrans', datatransIDs, { domain: '.paropakari.com' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://www.paropakari.com/' });
          Cookies.set('googtrans', datatransIDs, { domain: 'https://paropakari.com/' });
          setCookie('googtrans', datatransIDs, 30);
          setCookie('googtrans', datatransIDs, 'https://test.paropakari.com/#/');
          setCookie('googtrans', datatransIDs, '.paropakari.com');
          setCookie('googtrans', datatransIDs, 'https://www.paropakari.com/');
          setCookie('googtrans', datatransIDs, 'https://paropakari.com/');
          let cookie = Cookies.get('googtrans', 'https://test.paropakari.com/#/');
          
          window.sessionStorage.setItem('translate', (LangID));
          localStorage.setItem('MenuID', JSON.stringify(LangID));

          this.setState({
            cancel: Cancel,
            close:close,
            pleaseChooserequiredfor: pleaseChooserequiredfor,
           // submitbtn:submitbtn


          });


        });
    }
  }




  notify = () => {


    let url = "https://apitest.paropakari.com/api/GetProfile/Notification_Count";
    let data = this.state;

    let user = JSON.parse(localStorage.getItem('user'));
    const accessToken = user;
    
    
    fetch(url, {
      method: 'GET',
      headers: {
        "Content-type": "application/json",
        "Accept": "application/json",
        Authorization: "Bearer " + accessToken,
        "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
      },
      //body:JSON.stringify(data)
    }).then(response => response.json())
      .then(data => {
        this.setState({
          notify: data
        });
        
        // 
      });
  }

  ImagePreview = (event) => {
    var fileInput = false
    var maxSize = '1024';
    const imageFile = event.target.files[0];
    if (event.target.files[0]) {
      fileInput = true
    }

    
    var reader = new FileReader();
    var url = reader.readAsDataURL(imageFile);
    
    reader.onloadend = function (e) {
      this.setState({
        ImageDisplay: [reader.result],
      })
    }.bind(this);
    if (fileInput) {

      Resizer.imageFileResizer(
        imageFile,
        30,
        30,
        'PNG',
        10,
        0,
        uri => {
          
        },
        'base64'
      );
    }

    var fsize = event.target.files[0].size / 15;

    // if (!imageFile) {
    //   this.setState({ invalidImage: 'Please select image.' });
    //   return false;
    // }

    if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
      this.setState({ invalidImage: 'Please select valid image.' });
      return false;
    }

    else if (fsize < maxSize) {
      this.setState({ invalidImage: 'Please select Quality image.' });
      return false;
    }
    // this.reader.onload = (e) => {
    //   const img = new Image();
    //   img.onload = () => {
    //     this.setState({ selectedFile: imageFile, invalidImage: null });
    //   };
    //   img.onerror = () => {
    //     this.setState({ invalidImage: 'Invalid image content.' });
    //     return false;
    //   };

    //   img.src = e.target.result;
    // };
    // this.reader.readAsDataURL(imageFile);
  }


  handleothersClik() {

    const { requiredForValidation, pleaseChooserequiredfor } = this.state;
 
    if (requiredForValidation) {
       let user = JSON.parse(localStorage.getItem('id'));
      if (user == 3) {

        var e = document.getElementById("category_id");
        var result = e.options[e.selectedIndex].text;

        

        localStorage.setItem('Category_text', JSON.stringify(result));
        var CauseForID = e.options[e.selectedIndex].value;
        localStorage.setItem('CauseForID', JSON.stringify(CauseForID));
        let Category_text = JSON.parse(localStorage.getItem('Category_text'));

        

        if (Category_text == '' || Category_text == "Select" || Category_text == "தேர்ந்தெடு" || Category_text == "ಆಯ್ಕೆ ಮಾಡಿ" || Category_text == "चुनते हैं" || Category_text == "ఎంచుకోండి" || Category_text == "Seleccionar") {

          document.getElementById("result").innerHTML = this.state.selectthecategory.result;
          // alert(this.state.selectthecategory.result);

        }
        else {
          
          let url = "https://apitest.paropakari.com/api/Create/CheckSelf";
          let data = this.state;
          let user = JSON.parse(localStorage.getItem('user'));
          const accessToken = user;
          

          fetch(url, {
            method: 'GET',
            headers: {
              "Content-type": "application/json",
              "Accept": "application/json",
              Authorization: "Bearer " + accessToken,
              "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
            },
            //body:JSON.stringify(data)
          }).then(response => response.json())
            .then(data => {
              this.setState({
                emps: data
              });
              
              // 
            });

          var page = {
            pathname: '/Create',
            state: {
              data: {
                name: this.state.BeneficiaryName,

              }
            }
          }
        }
        this.props.history.push(page)
      }
      else {

        var e = document.getElementById("category_id");
        var result = e.options[e.selectedIndex].text;
        var CauseForID = e.options[e.selectedIndex].value;
        localStorage.setItem('CauseForID', JSON.stringify(CauseForID));
        
        localStorage.setItem('Category_text', JSON.stringify(result));

        let Category_text = JSON.parse(localStorage.getItem('Category_text'));
        
        if (Category_text == '' || Category_text == "Select" || Category_text == "தேர்ந்தெடு" || Category_text == "ಆಯ್ಕೆ ಮಾಡಿ" || Category_text == "चुनते हैं" || Category_text == "ఎంచుకోండి" || Category_text == "Seleccionar") {

          document.getElementById("result").innerHTML = this.state.selectthecategory.result;
          alert(this.state.selectthecategory.result);

        }
        else
          if (this.state.BeneficiaryName != "") {
            localStorage.setItem('benname', JSON.stringify(this.state.BeneficiaryName));
            
            var page = {
              pathname: '/Create',
              state: {
                data: {
                  name: this.state.BeneficiaryName,
                  id: user
                }
              }
            }
          }
          else {
            alert(this.state.Beneficiaries1.result);
          }
        this.props.history.push(page)
      }
    }
    else {
    
      alert(this.state.pleaseChooserequiredfor.result);
    }
  }

  LogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem('username')
    localStorage.removeItem('user')

    localStorage.clear();
    window.location.href = "#/Login";

  }
  // handleMoney=(event)=> {
  //   alert("called");
  //   
  //   //alert(`hello, ${name}`);
  //  // localStorage.setItem('money_id', JSON.stringify(`${name}`));
  // }


  handleDisable = () => {

    var e = document.getElementById("category_id");

    var result = e.options[e.selectedIndex].value;

    
    // alert("Check");
    // alert("CALLED HERE");
    // localStorage.setItem('Category_text', JSON.stringify(result));
    // let Category_text=JSON.parse(localStorage.getItem('Category_text'));
    // 
    if (result == '' || result == "Select") {
      // alert("Called");
      document.getElementById("result").innerHTML = this.state.selectthecategory.result;
      // alert("Check1");
    }
    else {
      document.getElementById("result").innerHTML = "";
      // alert("Check2");
    }
  }

  handleGameClik = (name) => {
    
    this.setState({ capture: name });
    this.setState({ requiredForValidation: true });
    //alert(`hello, ${name}`);  
    localStorage.setItem('id', JSON.stringify(`${name}`));
    let user1 = JSON.parse(localStorage.getItem('id'));
    document.getElementById("disable").disabled = "";
    if (user1 == 3) {
      document.getElementById("disable").disabled = "true";
      document.getElementById("finalbutton").disabled = "";
      
      let url = "https://apitest.paropakari.com/api/Create/CheckSelf";
      let data = this.state;
      let user = JSON.parse(localStorage.getItem('user'));
      const accessToken = user;
      
      fetch(url, {
        method: 'GET',
        headers: {
          "Content-type": "application/json",
          "Accept": "application/json",
          Authorization: "Bearer " + accessToken,
          "Access-Control-Allow-Headers": "Access-Control-Request-Headers "
        },
        //body:JSON.stringify(data)
      }).then((result) => {
        result.json().then((resp) => {
          if (resp.message == "Enter Bank Details in Your Profile") {
            // alert(this.state.enterbankinprofile.result);
            
          }
          else {
            var e = document.getElementById("category_id");
            var result = e.options[e.selectedIndex].text;
            
            // alert("CALLED HERE");
            // localStorage.setItem('Category_text', JSON.stringify(result));
            // let Category_text=JSON.parse(localStorage.getItem('Category_text'));
            // 
            if (result == '' || result == "Select" || result == "தேர்ந்தெடு" || result == "ಆಯ್ಕೆ ಮಾಡಿ" || result == "चुनते हैं" || result == "ఎంచుకోండి" || result == "Seleccionar") {
              // alert("Called");
              document.getElementById("result").innerHTML = this.state.selectthecategory.result;
            }
            else {

              var page = {
                // pathname: '/Create',
                state: {
                  data: {
                    name: this.state.BeneficiaryName,
                    id: user1
                  }
                }
              }
            }
            this.props.history.push(page)
          }
        });
      })
    }
    this.setState({ disabled: !this.state.disabled })
  }

  // sayHello(name) {
  //     alert("hello");
  //     
  //   }
  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  handleKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      alert('Please click Submit button');
    }
  }
  componentDidMount() {
    window.addEventListener('load', this.notify);
    this.notify();
    window.addEventListener('load', this.Footer);
    this.Footer();
    window.addEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const elements = document.getElementById('goog-gt-vt');
    if (elements) {
      elements.style.display = 'none'; // Hide the element if it exists
    }
    const element = document.querySelector('.VIpgJd-ZVi9od-ORHb-OEVmcd');
    if (element && window.getComputedStyle(element).getPropertyValue('position') === 'fixed') {
      element.style.position = 'absolute';
    }
    document.title = "Create your cause at paropakari"
  }
  GetSelectedValue() {
    alert("hi");
    var e = document.getElementById("country");
    var result = e.options[e.selectedIndex].value;

    document.getElementById("result").innerHTML = result;
  }
  GetSelectedText() {
    alert("hi");
    var e = document.getElementById("category_id");
    var result = e.options[e.selectedIndex].text;

    document.getElementById("result").innerHTML = result;
  }
  componentWillUnmount() {
    window.removeEventListener('load', this.notify);
    this.notify();
    window.removeEventListener('load', this.Footer);
    this.Footer();
    window.removeEventListener('load', this.DefaultMenu);
    this.DefaultMenu();
    const element = document.getElementById('goog-gt-vt');
    if (element) {
      element.style.display = 'none'; // Hide the element if it exists
    }
  }
  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }
  handleScriptError() {
    this.setState({ scriptError: true })
  }

  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }
  render() {
    const { opErr, npErr, cnpErr,
      upaErr,
      befErr,
      uplErr,
      reqErr,
      PurErr,
      RelaErr, UocErr,
      relErr } = this.state.formErrors;
    const { BeneficiaryName, user1 } = this.state;
    //  const isEnabled = BeneficiaryName.length > 0 ;
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0 so need to add 1 to make it 1!
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() + 1;
    var mm2 = today.getMonth() + 2; //January is 0 so need to add 1 to make it 1!


    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    today = yyyy + '-' + mm + '-' + dd;
    var nextmonth = yyyy + '-' + mm2 + '-' + dd;
    var nextYear = yyyy2 + '-' + mm + '-' + dd;
    
    
    

    const { emps, upload_amount, category_id, created_date, delete_status, device_type, ip_address, upload_num_of_beneficiaries,pleaseChooserequiredfor
      , upload_place, relation_id, required_by, upload_title, upload_description, user_id, upload_BankAccount, upload_IFSC, upload_UPIID,
      upload_otherCategory, upload_otherRelation, status_80G } = this.state;
    const { notify } = this.state;
    const { howitworks, close,blog, contactus, termsandcondition, select, causes, beneficiaries, paropakaris, amountraised, copyright, beaparopakari, myjourney, createacause, invite,
      profile, changepassword, group, logout, others, requiredforr, familyfriends, self, submit, beneficiaryname, privacypolicy, enterbankinprofile, friends,
      langID, education, foodandnutrition, health, professional, sports, socialwefare, monetary, nonmonetary, money, donationex, Beneficiaries1, selectthecategory } = this.state;

    let { foo, Menu } = this.state;
    var username = JSON.parse(localStorage.username);

    // 
    return (
      <div className="VIpgJd-ZVi9od-ORHb-OEVmcd" style={{ position: "absolute" }}>
        <div style={{ backgroundImage: "url(" + "./assets/images/10.jpg" + ")" }} className="notranslate">
          <link href="./assets/css/bootstrap.min.css" rel="stylesheet" />
          <link href="./assets/css/font-awesome.min.css" rel="stylesheet" />
          <link href="./assets/css/lightbox.css" rel="stylesheet" />
          <link href="./assets/css/animate.min.css" rel="stylesheet" />
          <link href="./assets/css/main.css" rel="stylesheet" />

          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />


          <link rel="shortcut icon" href="images/ico/favicon.ico" />
          <link rel="apple-touch-icon-precomposed" sizes="144x144" href="images/ico/apple-touch-icon-144-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="114x114" href="images/ico/apple-touch-icon-114-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" sizes="72x72" href="images/ico/apple-touch-icon-72-precomposed.png" />
          <link rel="apple-touch-icon-precomposed" href="images/ico/apple-touch-icon-57-precomposed.png" />
          {/*/head*/}

          <select className="form-control" style={{ display: 'inline', width: '10%', marginTop: '0', marginLeft: '0', float: 'right', width: 'fit-content' }} value={this.state.value} onChange={this.handleChanges}>
            <option value="English">Languages</option>
            <option value="Kannada">Kannada</option>
            <option value="Tamil">Tamil</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
             <option value="Spanish">Spanish</option>
            <option value="English">English</option>
          </select>
          &nbsp;&nbsp; &nbsp;&nbsp;
          <div>
            <div className="mag notranslate">
              <div class="pro-home-nav">
                <input
                  type="checkbox"
                  id="pro-home-nav-check"
                  onClick={this.checkfun}
                />
                <div class="pro-home-nav-header">
                  <div class="pro-home-nav-title notranslate">
                    <a className="navbar-brand" href="#/HomePage">
                      <h1 className="navbar-new-text">
                        <img
                          src="./assets/images/plogo.png"
                          alt="logo"
                          style={{
                            marginBottom: "17px",
                            width: "26%",
                            fontSize: "300px",
                          }}
                        />
                        AROPAKARI
                      </h1>
                    </a>
                  </div>
                </div>

                <div class="pro-home-nav-btn">
                  <label for="pro-home-nav-check">
                    <div className="pro-naav">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </label>
                </div>

                {langID == "English" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu text-parop-menu">
                          <a className="text-parop-menu" href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Hindi" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design notranslate" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu notranslate">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Tamil" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p
                        class="pro-center-tamil"
                        style={{ marginRight: "10px" }}
                      >
                        <a className="new-tamil-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Kannada" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Spanish" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : langID == "Telugu" ? (
                  <>
                    <div class="pro-home-nav-links pro-side pro-top">
                      <p class="pro-center">
                        <a className="new-telugu-design" href="#/CauseFor">
                          {beaparopakari.result}
                        </a>
                      </p>
                      <ul className="parop-home-tamil-boxx1 parop-boxx-top">
                        <li className="parop-menu">
                          <a href="#/Myactivity">{myjourney.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a className="text-parop-menu" href="#/CauseFor">{createacause.result}</a>
                        </li>
                        <li className="parop-menu">
                          <a href="#/InviteFriend">{invite.result}</a>
                        </li>
                        <li className="parop-menu parop-notify">
                          <a href="#/Notification">
                            <div
                              class="fa fa-bell"
                              style={{ fontSize: "24px" }}
                            >
                              {notify.map((notifyy) => (
                                <span class="-count">{notifyy.noticount}</span>
                              ))}
                            </div>
                          </a>
                        </li>
                        <li className="parop-menu">
                          <a>
                            <b>{username}</b>{" "}
                            <i className="fa fa-angle-down"></i>
                          </a>
                          <ul class="dropdown">
                            <li className="hower-text">
                              <a href="#/Profile">
                                <b>{profile.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/ChangePassword">
                                <b>{changepassword.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Group">
                                <b>{group.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <a href="#/Friends">
                                <b>{friends.result}</b>
                              </a>
                            </li>
                            <li className="hower-text">
                              <LogoutLink logoutResult={logout.result} />
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <section id="about-company" className={this.state.movedown == true ? "wow fadeInUp" : "wow fadeInUp Tiptop"} data-wow-duration="400ms" data-wow-delay="400ms" style={{ backgroundColor: '#aee6db' }}>

              <div className="causefor-container">
                {/* Page Content */}

                <div className="causefor-container2">
                  <br />
                  <center>
                    <h2 style={{ marginLeft: "17px", color: 'black' }}><b>{requiredforr.result}</b></h2>
                  </center>
                  <br />
                  <form onSubmit={this.handleSubmit}>
                    <div className="row" style={{ marginLeft: 3 }}>
                      {(() => {
                        if (langID == 'Tamil' || langID == 'Telugu' || langID == 'Kannada') {
                          return (
                            <center>
                              {/* rgb(65, 140, 168) */}
                              <button class="btn btn-small" type="submit" onClick={() => this.handleGameClik(3)} style={{ width: "116px", Height: "9%", color: 'white', backgroundColor: this.state.capture == 3 ? 'rgb(65, 140, 168)' : 'rgb(102, 71, 18)' }}
                                Value="3" id="clicky" >{self.result}</button>
                              <button class="btn btn-small" style={{ width: "160px", Height: "9%", color: 'white', backgroundColor: this.state.capture == 2 ? 'rgb(65, 140, 168)' : 'rgb(102, 71, 18)' }}
                                Value="2" id="clicky" onClick={() => this.handleGameClik(2)}>{familyfriends.result}</button>
                              <button class="btn btn-smalll" style={{ width: "116px", Height: "9%", color: 'white', backgroundColor: this.state.capture == 1 ? 'rgb(65, 140, 168)' : 'rgb(102, 71, 18)' }}
                                Value="1" id="clicky" onClick={() => this.handleGameClik(1)} >{others.result}</button>
                            </center>
                          );
                        }
                        else {
                          return (
                            <center>
                              <button class="btn btn-small jumba" type="submit" onClick={() => this.handleGameClik(3)} style={{ width: "116px", Height: "9%", color: 'white', backgroundColor: this.state.capture == 3 ? 'rgb(65, 140, 168)' : 'rgb(102, 71, 18)' }}
                                Value="3" id="clicky" >{self.result}</button>
                              <button type="submit" class="btn btn-smalll" style={{ width: "116px", Height: "9%", color: 'white', backgroundColor: this.state.capture == 2 ? 'rgb(65, 140, 168)' : 'rgb(102, 71, 18)' }}
                                Value="2" id="clicky" onClick={() => this.handleGameClik(2)}>{familyfriends.result}</button>
                              <button class="btn btn-smalll" type="submit" style={{ width: "116px", Height: "9%", color: 'white', backgroundColor: this.state.capture == 1 ? 'rgb(65, 140, 168)' : 'rgb(102, 71, 18)' }}
                                Value="1" id="clicky" onClick={() => this.handleGameClik(1)} >{others.result}</button>
                            </center>
                          );
                        }
                      })()}
                      <br />
                     
                      <center>
                      
                       
                        <div class="form-group">
                        <div className="row">
                          <div class="col-md-4">

                          </div>
                          <div class="col-md-4">
                            
                          <center>
                          <a href="#?id=collapseTwo" data-toggle="modal" data-target="#fileModal" style={{ textDecoration: 'none', lineHeight: '1.2', marginLeft: '59%', marginTop: 0, fontSize: '1.5rem', color: 'black' }}>
                          <b>&#9432;</b></a></center>
                          <br/>
                            <select className="form-control" style={{ width: '65%' }} required onChange={this.handleDisable}
                              id="category_id" name="category_id"><option value>{select.result}</option>
                              <option value={0}>{monetary.result}</option>
                              <option value={1}>{nonmonetary.result}</option>
                            </select>
                      
                          </div>
                          <div class="col-md-4">
                          {/* <center>
                      <a href="#?id=collapseTwo" data-toggle="modal" data-target="#fileModal" style={{ textDecoration: 'none', lineHeight: '1.2', marginLeft: '18%', marginTop: 300, fontSize: '1.5rem', color: 'black' }}>
                          <b>&#9432;</b></a></center> */}
                          </div>
                        </div>
                        </div>
                        <br />
                        <p id="result" style={{ color: "red" }}></p>
                        {/* <select id="country">
			<option value="None">-- Select --</option>
			<option value="ID001">China</option>
			<option value="ID002" selected>United State</option>
			<option value="ID003">Malaysia</option>
		  </select>
      <button type="button" onClick={this.GetSelectedValue}>Get Selected Value</button>
      <button type="button" onClick={this.GetSelectedText}>Get Selected Text</button> */}
                        <div class="form-group">
                          <div className="row">
                            <div class="col-lg-4  col-md-4 ">
                            </div>
                            <div class="col-lg-4  col-md-4" >
                              <input type="text" disabled="disabled" onKeyDown={this.handleKeyDown} id="disable" maxLength={30} name="BeneficiaryName" onChange={this.handleChange}
                                className="form-control input-md" placeholder={this.state.capture == 3 ? username : beneficiaryname.result} value={this.state.capture == 3 ? username : this.state.BeneficiaryName} style={{ width: '65%' }} >
                              </input>
                            </div>
                            <div class="col-lg-4 col-md-4 ">
                            </div></div></div>
                        <br /><br />
                      </center>
                    </div>
                    <div className="row" style={{ marginLeft: 3 }}>
                      {(() => {
                        if (langID == 'Tamil') {
                          return (
                            <center>
                              <button type="submit" style={{ width: "130px", Height: "9%", color: 'white', marginTop: '2px', backgroundColor: 'rgb(102, 71, 18)' }}
                                Value="" className="btn btn-primary" id="finalbutton" onClick={() => this.handleothersClik()}>{submit.result}</button>
                            </center>
                          );
                        }
                        else {
                          return (
                            <center>
                              <button type="submit" style={{ width: "116px", Height: "9%", color: 'white', marginTop: '2px', backgroundColor: 'rgb(102, 71, 18)' }}
                                Value="" className="btn btn-primary" id="finalbutton" onClick={() => this.handleothersClik()}>{submit.result}</button>
                            </center>
                          );
                        }
                      })()}
                    </div>
                    {/* <Link to={{
  pathname: '#/Create',
  state: [{id: 1, name: 'Ford', color: 'red'}]
}}> Your Page </Link> */}
                  </form>

                  {/* <div className="modal fade" id="fileModal" role="dialog">
      <div className="modal-dialog">
      
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Include documents that support your cause</h5>
            <button type="button" className="close" data-dismiss="modal">×</button>
          </div>
          <div className="modal-body">
           
            For Example: Medical Bills, Medical Certificates, School Register, Images etc
          </div>
          <div className="modal-footer">
            <button type="submit" className="btn btn-default" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div> */}

                  <br />
                </div>
              </div><br />
              <br />
            </section>
            <div className="modal fade" id="fileModal" role="dialog">
              <div className="modal-dialog">
                {/* Modal content*/}
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title"><b>{monetary.result} </b>{money.result}</h5>
                    {/* <button type="button" className="close" data-dismiss="modal">×</button> */}
                  </div>
                  <div className="modal-body">
                    {/* Go to www.addthis.com/dashboard to customize your tools */}
                    <b>{nonmonetary.result} </b>{donationex.result}
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-default" data-dismiss="modal">{close.result}</button>
                  </div>
                </div>
              </div>
            </div>
            {/*/#team*/}
            <footer id="footer" style={{ color: 'white' }}>
              <br />
              <div className="container">
                <div className="copyright">

                  <div className="row col-lg-12">

                    <div className="col-lg-9 mb-6">
                      {/* {foo.map((fo) => (
          <p className="m-0 text-center text-black" style={{paddingTop: '-50px', fontSize: 12,color:'white'}}>
     <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.posts}</a>&nbsp;Causes 
      <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}>&nbsp;{fo.beneficiaries}</a>&nbsp;Beneficiaries
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.paropakaris}</a>&nbsp;Paropakaris
       <a className="blink_me" style={{color: '#ffd9a2', fontSize: 18}}> &nbsp;{fo.raisedAmount}</a>&nbsp;Amount Raised
       </p>
       ))} */}


                      <p style={{ marginLeft: '45px' }}>{copyright.result}&nbsp; |&nbsp;<a href="#/Contactuslogin" style={{ textDecoration: 'none', color: 'white' }}>{contactus.result}&nbsp;</a>| <a href="#/PrivacyPolicyLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{privacypolicy.result}&nbsp;</a>|<a href="#/TermsandConditionLogin" style={{ textDecoration: 'none', color: 'white' }}>&nbsp;{termsandcondition.result}</a></p>

                    </div>
                    <div className="col-lg-3 mb-3">

                      <div className="social-icons" style={{ color: 'white', marginLeft: '72px' }}>
                        <ul className="nav nav-pills">
                          <li><a target="_blank" href="https://www.facebook.com/beoneparopakari"><i className="fa fa-facebook" /></a></li>
                          {/* <li><a target="_blank"  href="https://twitter.com/login?lang=en" ><i className="fa fa-twitter" /></a></li> */}
                          <li><a target="_blank" href="https://www.youtube.com/channel/UCP9oeqbf4lCy3io7uy1-NQw" ><i className="fa fa-youtube-play" /></a></li>
                          <li><a target="_blank" href="https://www.instagram.com/paropakari_beone/" ><i className="fa fa-instagram" /></a></li>
                          <li><a target="_blank" href="https://www.linkedin.com/company/paropakari/"><i className="fa fa-linkedin" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="containerss">

              </div>
            </footer>
            {/*/#footer*/}

          </div>

        </div>
      </div>
    )
  }
}